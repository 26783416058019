import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useDispatch } from 'react-redux'
import { Moment } from 'moment'
import { getCompletedQuarter, getQuarterAndYear } from '../../../utils/moment'
import { getRebatesTableData } from '../Rebates/RebatesTable/api'
import { getPaymentStatusData } from '../Rebates/api'
import { getReportsData } from '../utils'
import { setLoading } from '../../../redux/store/common/slice'
import { DEFAULT_PAGE } from '../../../components/Table/constants'
import {
  TRebatesTableParams,
  TRebatesTableRequestData,
  TRebatesTableType
} from '../Rebates/RebatesTable/types'
import { PaymentStatus } from '../types'
import { TPageInfo } from '../../../components/Table/types'

type ContextProps = {
  state: {
    totalNumber: number
    totalAmount: number
    firstLoad: boolean
    period: Moment
    pageInfo: TPageInfo
    tableData: TRebatesTableType[]
    reports: PaymentStatus[]
    sortParams?: TRebatesTableRequestData
  }
  actions: {
    setPeriod: Dispatch<SetStateAction<Moment>>
    setPageInfo: Dispatch<SetStateAction<TPageInfo>>
    getTableData: (args: TRebatesTableRequestData) => Promise<void>
    getChartsData: () => Promise<void>
    updateRebatesData: () => Promise<void>
  }
}

const FinancialsRebatesContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const FinancialsRebatesProvider: FC<PropsWithChildren> = ({ children }) => {
  const [totalNumber, setTotalNumber] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const [firstLoad, setFirstLoad] = useState(true)
  const [period, setPeriod] = useState<Moment>(getCompletedQuarter())
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const [sortParams, setSortParams] = useState<TRebatesTableRequestData>()
  const [tableData, setTableData] = useState<TRebatesTableType[]>([])
  const [reports, setReports] = useState<PaymentStatus[]>([])
  const dispatch = useDispatch()

  useEffect(() => {
    if (!!period) {
      getChartsData()
    }
  }, [period])

  const getTableData = async ({
    sortField,
    sortOrder,
    filters,
    page
  }: TRebatesTableRequestData) => {
    try {
      dispatch(setLoading(true))
      const params: TRebatesTableParams = {
        ...filters,
        ...getQuarterAndYear(period)
      }
      if (sortField) {
        if (sortOrder) {
          params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
        }
        setSortParams({
          sortField,
          sortOrder
        })
      }
      const dataPage = page ? page : pageInfo
      params.limit = dataPage.pageSize
      params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
      const resp = await getRebatesTableData({ params })
      if (!resp.data?.results) {
        return
      }
      setTableData(
        resp.data.results.map((row) => ({
          ...row,
          key: row.uuid
        }))
      )
      setTotalNumber(resp.data.count)
      if (firstLoad) {
        setFirstLoad(false)
      }
    } finally {
      dispatch(setLoading(false))
    }
  }

  const getChartsData = async () => {
    try {
      dispatch(setLoading(true))
      const { data } = await getPaymentStatusData({
        params: getQuarterAndYear(period)
      })
      const results = getReportsData(data.results)
      setReports(results)
      setTotalAmount(results.reduce((prev, cur) => prev + cur.amount, 0))
    } finally {
      dispatch(setLoading(false))
    }
  }

  const updateRebatesData = async () => {
    await getTableData({ ...sortParams, page: pageInfo })
    await getChartsData()
  }

  const context = useMemo(
    () => ({
      state: {
        totalNumber,
        sortParams,
        tableData,
        firstLoad,
        totalAmount,
        reports,
        period,
        pageInfo
      },
      actions: {
        setPeriod,
        setPageInfo,
        getTableData,
        getChartsData,
        updateRebatesData
      }
    }),
    [
      totalNumber,
      sortParams,
      tableData,
      firstLoad,
      totalAmount,
      reports,
      period,
      pageInfo
    ]
  )

  return (
    <FinancialsRebatesContext.Provider value={context}>
      {children}
    </FinancialsRebatesContext.Provider>
  )
}

export const useFinancialsRebatesContext = () =>
  useContext(FinancialsRebatesContext)

export default FinancialsRebatesProvider
