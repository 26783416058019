import { useCallback } from 'react'
import { ContentText, TDocumentDefinitions } from 'pdfmake/interfaces'
import { useDocContentGeneration } from './useDocContentGeneration'
import { COMMENTS_ON_INSTRUCTIONS, DEFAULT_PDF_DOC_TXT } from './constants'
import {
  TCommonsRebateDocData,
  TCommunityRebateDocData,
  TVendorRebateDocData
} from './types'

export const useFinancialsRebatesDocGeneration = () => {
  const {
    getPdfFileName,
    generateDocumentHeader,
    generateReceiverInfo,
    generateDocumentInfo,
    generateBillToInfo,
    generateCommentsSection,
    generateThanksSection,
    generateRebateInvoiceTable,
    generateTotalTable,
    launchDocGeneration
  } = useDocContentGeneration()

  const _generateCommunityRemitAdviceDocHeader = useCallback(
    (
      communityData: TCommunityRebateDocData['communityData']
    ): ContentText[] => [
      ...generateDocumentHeader(DEFAULT_PDF_DOC_TXT.REMITTANCE_ADVICE),
      ...generateReceiverInfo({
        hospitalName: communityData.hospital_name,
        hospitalAddress: communityData.hospital_address,
        hospitalId: communityData.hospital_id
      }),
      ...generateDocumentInfo({
        docHeaderName: DEFAULT_PDF_DOC_TXT.REMIT_ADVICE,
        docDate: communityData.remit_advice_date,
        docId: communityData.document_number,
        docDueOn: communityData.due_date
      }),
      ...generateBillToInfo({
        vendorName: communityData.supplier_name,
        vendorAddress: communityData.supplier_address,
        vendorPhoneNumber: communityData.supplier_phone_number
      }),
      ...generateCommentsSection(COMMENTS_ON_INSTRUCTIONS.CREDIT_NET)
    ],
    [
      generateCommentsSection,
      generateDocumentHeader,
      generateDocumentInfo,
      generateReceiverInfo,
      generateBillToInfo
    ]
  )

  const _generateCommonsRemitAdviceDocHeader = useCallback(
    (commonsData: TCommonsRebateDocData['commonsData']): ContentText[] => [
      ...generateDocumentHeader(DEFAULT_PDF_DOC_TXT.REMITTANCE_ADVICE),
      ...generateReceiverInfo({
        hospitalName: commonsData.cognus_legal_name,
        hospitalAddress: commonsData.cognus_address,
        hospitalId: commonsData.hospital_id
      }),
      ...generateDocumentInfo({
        docHeaderName: DEFAULT_PDF_DOC_TXT.REMIT_ADVICE,
        docDate: commonsData.remit_advice_date,
        docId: commonsData.document_number,
        docDueOn: commonsData.due_date
      }),
      ...generateBillToInfo({
        vendorName: commonsData.supplier_name,
        vendorAddress: commonsData.supplier_address,
        vendorPhoneNumber: commonsData.supplier_phone_number
      }),
      ...generateCommentsSection(COMMENTS_ON_INSTRUCTIONS.CREDIT)
    ],
    [
      generateCommentsSection,
      generateDocumentHeader,
      generateDocumentInfo,
      generateReceiverInfo,
      generateBillToInfo
    ]
  )

  const _generateVendorInvoiceDocHeader = useCallback(
    (vendorData: TVendorRebateDocData['vendorData']): ContentText[] => [
      ...generateDocumentHeader(DEFAULT_PDF_DOC_TXT.INVOICE),
      ...generateReceiverInfo({
        hospitalName: vendorData.hospital_name,
        hospitalAddress: vendorData.hospital_address,
        hospitalId: vendorData.hospital_id
      }),
      ...generateDocumentInfo({
        docHeaderName: DEFAULT_PDF_DOC_TXT.INVOICE,
        docDate: vendorData.invoice_date,
        docId: vendorData.document_number,
        docDueOn: vendorData.due_date
      }),
      ...generateBillToInfo({
        vendorName: vendorData.supplier_name,
        vendorAddress: vendorData.supplier_address,
        vendorPhoneNumber: vendorData.supplier_phone_number
      }),
      ...generateCommentsSection(COMMENTS_ON_INSTRUCTIONS.DEBIT)
    ],
    [
      generateCommentsSection,
      generateDocumentHeader,
      generateDocumentInfo,
      generateReceiverInfo,
      generateBillToInfo
    ]
  )

  const generateCommunityRebateRemitAdviceDoc = useCallback(
    ({ communityData, callback }: TCommunityRebateDocData) => {
      const content: TDocumentDefinitions['content'] = [
        ..._generateCommunityRemitAdviceDocHeader(communityData),
        ...generateRebateInvoiceTable(communityData.invoices),
        ...generateTotalTable([
          {
            label: DEFAULT_PDF_DOC_TXT.TOTAL_REBATE,
            value: communityData.rebate_amount_pre_cf
          },
          {
            label: DEFAULT_PDF_DOC_TXT.PROCESSING_FEE,
            value: communityData.processing_fee
          },
          {
            label: DEFAULT_PDF_DOC_TXT.NET_TO_HOSPITAL,
            value: communityData.rebate_amount
          }
        ]),
        ...generateThanksSection()
      ]
      launchDocGeneration(
        content,
        getPdfFileName(
          DEFAULT_PDF_DOC_TXT.REMIT_ADVICE,
          communityData.document_number
        ),
        callback
      )
    },
    [
      _generateCommunityRemitAdviceDocHeader,
      launchDocGeneration,
      generateRebateInvoiceTable,
      generateThanksSection,
      generateTotalTable,
      getPdfFileName
    ]
  )

  const generateCommonsRebateRemitAdviceDoc = useCallback(
    ({ commonsData, callback }: TCommonsRebateDocData) => {
      const content: TDocumentDefinitions['content'] = [
        ..._generateCommonsRemitAdviceDocHeader(commonsData),
        ...generateRebateInvoiceTable(commonsData.invoices),
        ...generateTotalTable([
          {
            label: DEFAULT_PDF_DOC_TXT.TOTAL_REBATE,
            value: commonsData.rebate_amount_pre_cf
          },
          {
            label: DEFAULT_PDF_DOC_TXT.COGNUS_FEE,
            value: commonsData.processing_fee
          }
        ]),
        ...generateThanksSection()
      ]
      launchDocGeneration(
        content,
        getPdfFileName(
          DEFAULT_PDF_DOC_TXT.REMIT_ADVICE,
          commonsData.document_number
        ),
        callback
      )
    },
    [
      _generateCommonsRemitAdviceDocHeader,
      launchDocGeneration,
      generateRebateInvoiceTable,
      generateThanksSection,
      generateTotalTable,
      getPdfFileName
    ]
  )

  const generateVendorRebateInvoiceDoc = useCallback(
    ({ vendorData, callback }: TVendorRebateDocData) => {
      const content: TDocumentDefinitions['content'] = [
        ..._generateVendorInvoiceDocHeader(vendorData),
        ...generateRebateInvoiceTable(vendorData.invoices),
        ...generateTotalTable([
          {
            label: DEFAULT_PDF_DOC_TXT.TOTAL_DUE,
            value: vendorData.rebate_amount_pre_cf
          }
        ]),
        ...generateThanksSection()
      ]
      launchDocGeneration(
        content,
        getPdfFileName(DEFAULT_PDF_DOC_TXT.INVOICE, vendorData.document_number),
        callback
      )
    },
    [
      _generateVendorInvoiceDocHeader,
      launchDocGeneration,
      generateRebateInvoiceTable,
      generateThanksSection,
      generateTotalTable,
      getPdfFileName
    ]
  )

  return {
    generateCommunityRebateRemitAdviceDoc,
    generateCommonsRebateRemitAdviceDoc,
    generateVendorRebateInvoiceDoc
  }
}
