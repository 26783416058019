import SelectWithAdditionalOption from '../../../../Calendar/AddEventPopup/SelectWithAdditionalOption/SelectWithAdditionalOption'
import { LABELS, PLACEHOLDERS } from '../../../../../constants'
import { Form } from 'antd'
import { Input } from '../../../../../components/Input'
import { formatPhoneNumber } from '../../../../../helper/common'
import { useCallback, useEffect, useMemo, useState } from 'react'
import cn from 'classnames'
import { TAddSignatorySelect } from './types'
import { TOption } from '../../../../../components/Select/types'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../../redux/store/user/getters'

export const AddSignatorySelect = ({
  form,
  selectPropsItem,
  className,
  optionsRequest,
  phoneNumberFieldName = 'phone_number'
}: TAddSignatorySelect) => {
  const fieldName = selectPropsItem.name

  const otherValueSelected = Form.useWatch(fieldName, form)

  const [userOptions, setUserOptions] = useState<TOption[]>([])

  const user = useSelector(getUser)

  const setSignatoryUsers = useCallback(async () => {
    const response = await optionsRequest()
    setUserOptions([
      ...response.data.results
        .sort((userOption) => (userOption.uuid === user.uuid ? 1 : -1))
        .map((userOption) => {
          return {
            label: `${userOption.first_name} ${userOption.last_name} `,
            value: userOption.uuid,
            className:
              userOption.uuid === user.uuid ? 'additional-label-text' : ''
          }
        }),
      {
        value: 'other',
        label: ''
      }
    ])
  }, [optionsRequest])

  useEffect(() => {
    setSignatoryUsers()
  }, [])

  const isEmailTyped = useMemo(
    () =>
      Boolean(
        userOptions.find(
          (userOption) =>
            otherValueSelected === userOption.label &&
            userOption.value === 'other'
        )
      ),
    [userOptions, otherValueSelected]
  )

  return (
    <div className={cn('column gap-16', className)}>
      <SelectWithAdditionalOption
        propsItem={{
          ...selectPropsItem,
          rules: isEmailTyped ? { type: 'email' } : {}
        }}
        additionalItemInputProps={{
          placeholder: PLACEHOLDERS.EMAIL
        }}
        propsSelect={{
          placement: 'bottomLeft',
          getPopupContainer: (trigger) => document.body ?? trigger.parentNode
        }}
        className="full-width"
        onChangeValue={(value, other?: string | null) => {
          form.setFieldsValue({
            [fieldName]: !!value
              ? value === 'other'
                ? other || ''
                : value
              : ''
          })
        }}
        value={Form.useWatch(fieldName, form)}
        options={userOptions}
        otherType={''}
        setOptions={setUserOptions}
      />
      {isEmailTyped && (
        <Input.Phone
          propsItem={{
            name: phoneNumberFieldName,
            label: `${selectPropsItem.label} ${LABELS.SIGNATORY_PHONE_NUMBER}`,
            rules: [
              {
                pattern: /^[0-9]{10}$/,
                message: 'Enter a valid phone number'
              },
              { required: true }
            ],
            validateTrigger: ['onSubmit']
          }}
          propsInputNumber={{
            controls: false,
            formatter: formatPhoneNumber
          }}
        />
      )}
    </div>
  )
}
