import { Forms } from '../../components/Forms'
import { Form } from 'antd'
import { Container } from '../../components/Container/Container'
import { ADD_BUSINESS_DETAILS_BREADCRUMBS } from './constants'
import { FloatFooter } from '../../components/FloatFooter/FloatFooter'
import { BTN_TXT } from '../../constants'
import { useCallback, useEffect, useState } from 'react'
import { addBusinessDetailsInitialValues } from '../../components/Forms/constants'
import { getDataForBusinessCreation } from '../../features/PaymentDetails/utils'
import { getUser } from '../../redux/store/user/getters'
import { useParams } from 'react-router-dom'
import { getHospitalData } from '../../features/HealthSystemDetails/HospitalsTable/api'
import { useDispatch, useSelector } from 'react-redux'
import useRouter from '../../hooks/useRouter'
import { setLoading } from '../../redux/store/common/slice'
import { THospital } from '../../features/HealthSystemDetails/HospitalsTable/types'

const AddBusinessDetails = () => {
  const [form] = Form.useForm()
  const [isActive, setIsActive] = useState(false)
  const user = useSelector(getUser)

  const dispatch = useDispatch()

  const { hospitalId } = useParams()
  const {
    query: { hsId }
  } = useRouter()

  const submitCancellation = () => {
    handleCancelForm()
  }

  const [details, setDetails] = useState<THospital>()

  const getHospitalDetails = useCallback(async () => {
    try {
      dispatch(setLoading(true))
      const response = await getHospitalData(
        user.community ?? '',
        hsId ?? '',
        hospitalId ?? ''
      )
      setDetails(response.data)
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [user, hospitalId, hsId])

  useEffect(() => {
    if (hospitalId && hsId) {
      getHospitalDetails()
    }
  }, [])

  const handleCancelForm = useCallback(() => {
    setIsActive(false)
    form.setFieldsValue(addBusinessDetailsInitialValues)
    history.back()
  }, [form, setIsActive])

  const getPredefinedData = useCallback(async () => {
    const res = await getDataForBusinessCreation(user, hospitalId)
    if (!!res?.data?.address) {
      form.setFieldsValue({
        address: res?.data?.address?.street,
        zip_code: res?.data?.address?.zip_code,
        state: res?.data?.address?.state,
        city: res?.data?.address?.city
      })
    }
    if (!!res?.data?.tax_id) {
      form.setFieldsValue({ tax_id: res?.data?.tax_id })
    }
  }, [user])

  useEffect(() => {
    if (user.uuid) getPredefinedData()
  }, [user])

  return (
    <Container
      title={'Add Business details'}
      breadcrumbs={ADD_BUSINESS_DETAILS_BREADCRUMBS(details?.name)}
      className="business-details-container"
    >
      <div>
        <Forms.AddBusinessDetails form={form} setIsActive={setIsActive} />
      </div>
      <FloatFooter
        isActive={isActive}
        onCancel={submitCancellation}
        onSave={form.submit}
        isSaveDisabled={!isActive}
        saveBtnText={BTN_TXT.ADD}
      />
    </Container>
  )
}

export default AddBusinessDetails
