import { TAddBusinessDetails } from './BusinessDetails/types'
import { TAddBankAccountDetails } from './BankAccount/types'
import { SETTINGS_STATUSES } from '../../pages/Settings/constants'

export const PAYMENT_DETAILS_OPTIONS = [
  { label: 'Business details', value: 'business_details' },
  {
    label: 'Bank account',
    value: 'bank_account'
  }
]

export const addBusinessDetailsInitialValues: TAddBusinessDetails = {
  legal_name: '',
  tax_id: '',
  tax_id_type: null,
  incorporation_date: '',
  uuid: '',
  status: '',
  country: null,
  address_type: null,
  state: null,
  city: '',
  zip_code: '',
  address: '',
  bank_accounts: []
}
export const bankAccountDetailsInitialValues: TAddBankAccountDetails = {
  account_number: '',
  routing_number: '',
  account_holder_name: '',
  account_type: null,
  uuid: '',
  status: SETTINGS_STATUSES.CREATED
}
