import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'antd'
import { Popup } from 'components/Popup'
import { Button, BUTTON_TYPES } from 'components/Button'
import { BTN_TXT } from 'constants/txt'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { Menu } from 'components/Menu/Menu'
import { Forms } from 'components/Forms'
import { Table } from 'components/Table'
import { setLoading } from 'redux/store/common/slice'
import { getUser } from 'redux/store/user/getters'
import {
  archiveHospitals,
  getHospitalData,
  getHospitals,
  activateHospitals
} from './api'
import { columns } from './constants'
import { fullFormatPhoneNumber, isEqualObjects } from 'helper/common'
import { ability } from 'features/Permission/ability'
import { ACTIONS, Can, SUBJECTS } from 'features/Permission'
import { HEALTH_SYSTEM_TABLES_TABS, initialHospitalData } from '../constants'
import { HOSPITAL_STATUSES } from 'constants/common'
import { DEFAULT_PAGE } from 'components/Table/constants'
import { ReactComponent as Edit16 } from 'assets/svg/Edit16.svg'
import { ReactComponent as Delete16 } from 'assets/svg/Delete16.svg'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { TAddEditHospitalResponse } from 'components/Forms/forms.addEditHospital.d'
import {
  THospital,
  THospitalsFilters,
  THospitalsFiltersFunc,
  THospitalsParams,
  THospitalsTableProps,
  THospitalsTableRequestData
} from './types'

export const HospitalsTable = ({
  hsId,
  activeTab,
  setActiveTab
}: THospitalsTableProps) => {
  const [data, setData] =
    useState<TAddEditHospitalResponse>(initialHospitalData)
  const [selectedRows, setSelectedRows] = useState<THospital[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
  const [sortParams, setSortParams] = useState<THospitalsTableRequestData>({})
  const [tableData, setTableData] = useState<THospital[]>([])
  const [changedValues, setChangedValues] = useState({})
  const [filters, setFilters] = useState<THospitalsFilters>()
  const [totalNumber, setTotalNumber] = useState(0)
  const [isEdit, setIsEdit] = useState(false)
  const [openAddEditPopup, setOpenAddEditPopup] = useState(false)
  const [openArchivePopup, setOpenArchivePopup] = useState(false)
  const [openActivatePopup, setOpenActivatePopup] = useState(false)
  const [isDiscardChanges, setDiscardChanges] = useState(false)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const user = useSelector(getUser)
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  useEffect(() => {
    if (hsId) {
      getTableData({ ...sortParams, filters })
    }
  }, [hsId])
  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: THospitalsTableRequestData) => {
    dispatch(setLoading(true))
    const params: THospitalsParams = { ...filters }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    getHospitals(user.uuid, hsId, params)
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            phone_number: fullFormatPhoneNumber(i.phone_number),
            key: i.uuid
          }))
        )
        setTotalNumber(resp.data.count)
      })
      .finally(() => dispatch(setLoading(false)))
  }
  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }
  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }
  const applyFilters: THospitalsFiltersFunc = (field, appliedFilters) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    setFilters(newFilters)
    getTableData({ ...sortParams, filters: newFilters })
  }
  const handleSelectRow = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRows(selectedRows)
  }
  const handleArchiveHospitals = () => {
    dispatch(setLoading(true))
    archiveHospitals(user.uuid, hsId, {
      hospitals: selectedRowKeys
    })
      .then(() => {
        setOpenArchivePopup(false)
        setOpenAddEditPopup(false)
        setSelectedRowKeys([])
        setSelectedRows([])
        getTableData({ ...sortParams, filters })
      })
      .catch(() => dispatch(setLoading(false)))
  }
  const handleActivateHospitals = () => {
    dispatch(setLoading(true))
    activateHospitals(user.uuid, hsId, {
      hospital: selectedRowKeys[0]
    })
      .then(() => {
        setOpenActivatePopup(false)
        setOpenAddEditPopup(false)
        setSelectedRowKeys([])
        setSelectedRows([])
        getTableData({ ...sortParams, filters })
      })
      .catch(() => dispatch(setLoading(false)))
  }
  const findChangesHandler = () => {
    const initial = {
      departments: data.departments,
      email: data.email,
      name: data.name,
      phone_number: data.phone_number,
      tax_id: data.tax_id,
      ...data.address
    }
    if (!Object.keys(changedValues).length) {
      return false
    }
    return !isEqualObjects(initial, changedValues)
  }
  const changesHandler = (newValues) => {
    setChangedValues(newValues)
  }
  const onCancelEdit = () => {
    if (findChangesHandler()) {
      setDiscardChanges(true)
    } else {
      handleCloseAddEditHospitalPopup()
    }
  }

  const handleArchiveActivateBackHospital = () =>
    data.status !== HOSPITAL_STATUSES.ARCHIVED
      ? setOpenArchivePopup(true)
      : setOpenActivatePopup(true)
  const handleOpenHospitalForEdit = () => {
    dispatch(setLoading(true))
    getHospitalData(user.uuid, hsId, selectedRowKeys[0])
      .then((resp) => {
        setData(resp.data)
        setOpenAddEditPopup(true)
        setIsEdit(true)
      })
      .catch(() => dispatch(setLoading(false)))
  }
  const handleCloseAddEditHospitalPopup = () => {
    setOpenAddEditPopup(false)
    setIsEdit(false)
    setDiscardChanges(false)
    setChangedValues({})
    setData(initialHospitalData)
    setSelectedRowKeys([])
    setSelectedRows([])
    form.resetFields()
  }
  const onSelect = (e) => setActiveTab(e.key)
  return (
    <>
      <div className="hs-details__tables-menu">
        {!!selectedRowKeys.length ? (
          <Typography.Body1
            className="table-wrapper__select-title"
            weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
          >
            Selected {selectedRowKeys.length} of {totalNumber}
          </Typography.Body1>
        ) : (
          <Menu
            mode="horizontal"
            onSelect={onSelect}
            defaultSelectedKeys={[activeTab]}
            items={HEALTH_SYSTEM_TABLES_TABS}
          />
        )}
        <div className="hs-details__tables-actions">
          <Can I={ACTIONS.CRUD} a={SUBJECTS.HOSPITAL}>
            {selectedRowKeys.length === 1 && (
              <Button
                type={BUTTON_TYPES.PRIMARY}
                icon={<Edit16 />}
                onClick={() => handleOpenHospitalForEdit()}
                upperCase
                className="hs-details__tables-actions__edit-btn"
              >
                {BTN_TXT.EDIT}
              </Button>
            )}
            {!!selectedRows.length &&
              selectedRows.filter(
                (i) => i.status !== HOSPITAL_STATUSES.ARCHIVED
              ).length === selectedRows.length && (
                <Button
                  type={BUTTON_TYPES.PRIMARY}
                  icon={<Delete16 />}
                  onClick={() => setOpenArchivePopup(true)}
                  upperCase
                  danger
                >
                  {BTN_TXT.ARCHIVE}
                </Button>
              )}
            {!selectedRowKeys.length && (
              <Button
                type={BUTTON_TYPES.PRIMARY}
                icon={<Plus16 />}
                onClick={() => setOpenAddEditPopup(true)}
                upperCase
              >
                {BTN_TXT.ADD_HOSPITAL}
              </Button>
            )}
          </Can>
        </div>
      </div>
      <Table<THospital>
        dataSource={tableData}
        onChange={handleTableChange}
        className="hospitals-list-table"
        columns={columns({
          applyFilters,
          filters,
          userId: user.uuid,
          hsId
        }).filter((i) =>
          ability.can(ACTIONS.ADD, SUBJECTS.BANK_ACCOUNT)
            ? { ...i }
            : i.key !== 'is_connected_to_payment_system'
        )}
        onChangePage={handleChangePageSize}
        pageSize={pageInfo.pageSize}
        pagination={{
          pageSize: pageInfo.pageSize,
          current: pageInfo.pageNumber,
          total: totalNumber
        }}
        createButton={{
          buttonText: BTN_TXT.ADD_HOSPITAL,
          availability: ability.can(ACTIONS.CRUD, SUBJECTS.HOSPITAL),
          action: () => setOpenAddEditPopup(true)
        }}
        rowSelection={
          ability.can(ACTIONS.CRUD, SUBJECTS.HOSPITAL)
            ? {
                onChange: handleSelectRow,
                selectedRowKeys
              }
            : undefined
        }
      />
      <Popup
        visible={openArchivePopup}
        onCancel={() => setOpenArchivePopup(false)}
        title={`Archive ${
          selectedRows.length === 1 ? selectedRows[0].name : 'hospitals'
        }?`}
        wrapClassName="popup-over"
        width={442}
        footer={
          <>
            <Button
              onClick={() => setOpenArchivePopup(false)}
              type={BUTTON_TYPES.DEFAULT}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={handleArchiveHospitals}
              upperCase
              danger
            >
              {BTN_TXT.ARCHIVE}
            </Button>
          </>
        }
      >
        <Typography.Body1>
          Users might have contracts.
          <br />
          Do you really want to archive Hospital?
        </Typography.Body1>
      </Popup>
      <Popup
        visible={openActivatePopup}
        onCancel={() => setOpenActivatePopup(false)}
        title={`Activate back ${
          selectedRows.length === 1 ? selectedRows[0].name : 'hospitals'
        }?`}
        wrapClassName="popup-over"
        width={442}
        footer={
          <>
            <Button
              onClick={() => setOpenActivatePopup(false)}
              type={BUTTON_TYPES.DEFAULT}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={handleActivateHospitals}
              upperCase
            >
              {BTN_TXT.ACTIVATE}
            </Button>
          </>
        }
      >
        <Typography.Body1>
          All related users will become active again. Do you really want to
          activate hospital back?
        </Typography.Body1>
      </Popup>
      <Popup
        visible={openAddEditPopup}
        onCancel={onCancelEdit}
        title={`${isEdit ? 'Edit' : 'Add'} hospital`}
        width={910}
        hasArchive={isEdit}
        footer={
          <>
            {isEdit && (
              <Button
                onClick={handleArchiveActivateBackHospital}
                type={BUTTON_TYPES.DEFAULT}
                danger={data.status !== HOSPITAL_STATUSES.ARCHIVED}
                upperCase
              >
                {data.status === HOSPITAL_STATUSES.ARCHIVED
                  ? BTN_TXT.ACTIVATE_BACK
                  : BTN_TXT.ARCHIVE}
              </Button>
            )}
            <div className="popup-buttons">
              <Button
                onClick={onCancelEdit}
                type={BUTTON_TYPES.DEFAULT}
                upperCase
              >
                {BTN_TXT.CANCEL}
              </Button>
              <Button
                type={BUTTON_TYPES.PRIMARY}
                htmlType="submit"
                form="add-edit-hospital-form"
                upperCase
              >
                {isEdit ? BTN_TXT.SAVE : BTN_TXT.ADD}
              </Button>
            </div>
          </>
        }
      >
        <Forms.AddEditHospital
          callback={() => {
            getTableData({ ...sortParams, filters })
            handleCloseAddEditHospitalPopup()
          }}
          onChange={changesHandler}
          data={data}
          hsId={hsId}
          form={form}
        />
      </Popup>
      <Popup.Discard
        visible={isDiscardChanges}
        onCancel={() => setDiscardChanges(false)}
        onSubmit={handleCloseAddEditHospitalPopup}
      />
    </>
  )
}
