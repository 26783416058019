import { DESTINATION_TYPES, TRANSFER_STATUSES } from '../types'

export const READABLE_TRANSFER_STATUS = {
  [TRANSFER_STATUSES.COMPLETED]: 'Completed',
  [TRANSFER_STATUSES.CREATED]: 'Created',
  [TRANSFER_STATUSES.FAILED]: 'Failed',
  [TRANSFER_STATUSES.PENDING]: 'Pending',
  [TRANSFER_STATUSES.SETTLED]: 'Settled'
} as const

export const READABLE_DESTINATION_TYPE = {
  [DESTINATION_TYPES.COMMONS]: 'Commons',
  [DESTINATION_TYPES.HEALTH_SYSTEM]: 'Health System'
} as const
