import { DatePicker, Form } from 'antd'
import { KeyboardEvent } from 'react'
import cn from 'classnames'
import { ReactComponent as ArrowRight16 } from 'assets/svg/ArrowRight16.svg'
import { ReactComponent as ArrowLeft16 } from 'assets/svg/ArrowBack16.svg'
import {
  timelineDateFormat,
  timelineDateFormatShort
} from '../Timelines/constants'
import { TDatePickerDefault as Props } from './types'
import './datepicker.default.scss'

export const Default = ({ className, ...props }: Props) => {
  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (
      !/^(\d|Backspace|Delete|\/|ArrowLeft|ArrowRight|Enter|Tab)$/.test(
        e.key
      ) &&
      !(e.ctrlKey || e.metaKey)
    ) {
      e.preventDefault()
    }
  }

  return (
    <Form.Item
      className={cn('custom-datepicker datepicker-default', className)}
      colon={false}
      {...props.propsItem}
    >
      <DatePicker
        format={[timelineDateFormat, timelineDateFormatShort]}
        getPopupContainer={(node) => node}
        nextIcon={<ArrowRight16 />}
        prevIcon={<ArrowLeft16 />}
        superNextIcon={<ArrowRight16 />}
        superPrevIcon={<ArrowLeft16 />}
        {...props.propsDate}
        allowClear={false}
        onKeyDown={onKeyDown}
        dropdownClassName="datepicker-dropdown"
      />
    </Form.Item>
  )
}
