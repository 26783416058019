import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Table } from '../../../components/Table'
import { getRFPResults } from './api'
import { getUser } from 'redux/store/user/getters'
import { columns } from './constants'
import { setLoading } from 'redux/store/common/slice'
import { TRFPResultData } from './types'
import './styles.scss'

export const RFPResults = () => {
  const [tableData, setTableData] = useState<TRFPResultData[]>([])
  const params = useParams()
  const user = useSelector(getUser)
  const dispatch = useDispatch()

  const getTableData = async (id: string) => {
    try {
      dispatch(setLoading(true))
      const resp = await getRFPResults(id)
      if (!resp.data?.results) {
        return
      }
      setTableData(
        resp.data.results.map((i) => ({
          ...i,
          key: i.uuid
        }))
      )
    } finally {
      dispatch(setLoading(false))
    }
  }

  useEffect(() => {
    getTableData(String(params?.id))
  }, [params?.id])

  return (
    <Table<TRFPResultData> dataSource={tableData} columns={columns(user)} />
  )
}
