import { INITIAL_DATA_REPORTS_LEGEND } from './constants'
import {
  PaymentStatus,
  PaymentStatusData,
  FINANCIAL_STATUS,
  DueDateStatuses
} from './types'

export const getReportsData = (data: PaymentStatusData[]): PaymentStatus[] =>
  INITIAL_DATA_REPORTS_LEGEND.map((legend) => {
    const value = data.find((payment) => payment.status === legend.status)
    const res: PaymentStatus = { ...legend }
    if (value) {
      res.status = value.status
      res.percentage = `${value.percentage}`
      res.amount = +value.amount
    }
    return res
  })

export const getDisabledStatuses = (
  role: string,
  due_date_status: DueDateStatuses
) => {
  const disabledStatuses = [
    FINANCIAL_STATUS.DISPUTED,
    FINANCIAL_STATUS.PAID,
    FINANCIAL_STATUS.PAYMENT_IN_PROGRESS
  ]

  if (role.includes('community')) {
    disabledStatuses.push(FINANCIAL_STATUS.WAITING_FOR_RESPONSE)
    if (due_date_status === DueDateStatuses.OVERDUE) {
      disabledStatuses.push(FINANCIAL_STATUS.PAYMENT_FAILED)
    }
  }

  return disabledStatuses
}
