import { ColumnsType } from 'antd/es/table'
// import { SortOrder } from 'antd/es/table/interface'
import { CellWithSubtitle } from 'components/CellWithSubtitle/CellWithSubtitle'
// import { Sorter } from 'components/Sorter/Sorter'
import { CONTARCT_STATUS_TYPES_TO_CLASSNAME } from 'components/Status/constants'
import Status from 'components/Status/Status'
import { CONTRACT_STATUS_NAME } from 'features/ContractDetails/constants'
import moment from 'moment'
import { routes } from 'router/Config/config.routes'
import { TExpiringContract } from './types'
import { Link } from 'components/Link'
import {
  RFP_STATUSES_NAMES,
  RFP_STATUS_TO_CLASSNAME
} from 'features/RFP/constants'
import { durationAsString } from '../../helper/dates'

export const CHARTS_COLORS_TXT = {
  contract_contracting_count: {
    color: '#D3D8DE',
    name: 'Contracting'
  },
  contract_out_for_signature_count: {
    color: '#FFEBC2',
    name: 'Out for signature'
  },
  contract_executed_count: {
    color: '#759FD6',
    name: 'Executed'
  },
  contract_active_count: {
    color: '#C3DD8C',
    name: 'Active'
  },
  rfp_created_count: {
    color: '#C3DD8C',
    name: 'Created'
  },
  rfp_vendor_acceptance: {
    color: '#D3D8DE',
    name: 'Vendor acceptance'
  },
  rfp_bidding_qa: {
    color: '#759FD6',
    name: 'Bidding Q&A'
  },
  rfp_bidding_in_progress: {
    color: '#FFEBC2',
    name: 'Bidding in progress'
  },
  rfp_price_files_analysis: {
    color: '#2767A9',
    name: 'Price file analysis'
  },
  rfp_voting_in_progress: {
    color: '#C47E00',
    name: 'Voting in progress'
  },
  rfp_vendors_selection: {
    color: '#103861',
    name: 'Vendor selection'
  }
}

export const CHARTS_COLORS = {
  contract_contracting_count: '#D3D8DE',
  contract_out_for_signature_count: '#FFEBC2',
  contract_executed_count: '#759FD6',
  contract_active_count: '#C3DD8C',
  rfp_created_count: '#C3DD8C',
  rfp_vendor_acceptance: '#D3D8DE',
  rfp_bidding_qa: '#759FD6',
  rfp_bidding_in_progress: '#FFEBC2',
  rfp_price_files_analysis: '#2767A9',
  rfp_voting_in_progress: '#C47E00',
  rfp_vendors_selection: '#103861'
}

export const DASHBOARD_TABS = [
  {
    key: 'contracts',
    label: 'Contracts'
  },
  {
    key: 'contacting_steps',
    label: 'Contracting steps'
  },
  {
    key: 'rfp_steps',
    label: 'RFP Steps'
  }
]

const checkDate = (value) =>
  +moment(value).diff(moment(), 'days') < 0
    ? 0
    : durationAsString(moment(), moment(value))

export const columns = (activeTab): ColumnsType<TExpiringContract> => [
  {
    dataIndex: 'name',
    key: 'name',
    width: activeTab !== DASHBOARD_TABS[0].key ? 660 : 420,
    // title: ({ sortOrder, sortColumn }) => (
    //   <Sorter<TExpiringContract>
    //     dataIndex="name"
    //     sortColumn={sortColumn}
    //     sortOrder={sortOrder as SortOrder}
    //   >
    //     {activeTab === DASHBOARD_TABS[2].key ? 'RFP name' : 'Contract name'}
    //   </Sorter>
    // ),
    title: activeTab === DASHBOARD_TABS[2].key ? 'RFP name' : 'Contract name',
    render: (_, { name, uuid }) => (
      <CellWithSubtitle
        title={
          <Link
            to={`${
              activeTab === DASHBOARD_TABS[2].key
                ? `${routes.bidding}${routes.rfp}`
                : routes.contracts
            }/${uuid}`}
          >
            {name?.name}
          </Link>
        }
        subtitle={name?.number}
      />
    ),
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'status',
    key: 'status',
    width:
      activeTab !== DASHBOARD_TABS[0].key
        ? 'calc(calc(100% - 660px) / 2)'
        : 'calc(calc(100% - 420px) / 3)',
    // title: ({ sortOrder, sortColumn }) => (
    //   <Sorter<TExpiringContract>
    //     dataIndex="status"
    //     sortColumn={sortColumn}
    //     sortOrder={sortOrder as SortOrder}
    //   >
    //     Status
    //   </Sorter>
    // ),
    title: 'Status',
    render: (_, { status }) => (
      <Status.Default
        className={
          activeTab === DASHBOARD_TABS[2].key
            ? RFP_STATUS_TO_CLASSNAME[status]
            : CONTARCT_STATUS_TYPES_TO_CLASSNAME[status]
        }
      >
        {activeTab === DASHBOARD_TABS[2].key
          ? RFP_STATUSES_NAMES[status]
          : CONTRACT_STATUS_NAME[status]}
      </Status.Default>
    ),
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'finish_date',
    key: 'finish_date',
    width:
      activeTab !== DASHBOARD_TABS[0].key
        ? 'calc(calc(100% - 660px) / 2)'
        : 'calc(calc(100% - 420px) / 3)',
    // title: ({ sortOrder, sortColumn }) => (
    //   <Sorter<TExpiringContract>
    //     dataIndex="finish_date"
    //     sortColumn={sortColumn}
    //     sortOrder={sortOrder as SortOrder}
    //   >
    //     Time left
    //   </Sorter>
    // ),
    title: 'Time left',
    render: (_, { finish_date, expire_at }) => (
      <div>
        {activeTab === DASHBOARD_TABS[0].key
          ? checkDate(moment(finish_date).endOf('days'))
          : checkDate(expire_at)}
      </div>
    ),
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'finish_date',
    key: 'finish_date',
    width:
      activeTab !== DASHBOARD_TABS[0].key
        ? 'calc(calc(100% - 660px) / 2)'
        : 'calc(calc(100% - 420px) / 3)',
    className:
      activeTab !== DASHBOARD_TABS[0].key ? 'non-contract__finish_date' : '',
    // title: ({ sortOrder, sortColumn }) => (
    //   <Sorter<TExpiringContract>
    //     dataIndex="finish_date"
    //     sortColumn={sortColumn}
    //     sortOrder={sortOrder as SortOrder}
    //   >
    //     Finish date
    //   </Sorter>
    // ),
    title: 'Finish date',
    render: (_, { finish_date }) => (
      <div>{moment(finish_date).format('MM/DD/YYYY')} </div>
    ),
    sorter: false,
    showSorterTooltip: false
  }
]
