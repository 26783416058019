import { useCallback, useMemo } from 'react'

import './styles.scss'

import { AttachmentsList } from 'components/AttachmentsList/AttachmentsList'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import { Typography } from 'components/Typography'
import { BTN_TXT } from 'constants/txt'
import { DOCUMENT_TYPE } from 'components/FileUpload/constants'
import { validateFileTypes } from 'helper/common'
import { FileUpload } from 'components/FileUpload/FileUpload'
import { TDocumentTypeUnion } from 'components/FileUpload/types'
import { getAcceptFileTypesByDocumentType } from 'components/FileUpload/utils'
import ContractVersionHistory from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsDocuments/ContractVersionHistory/ContractVersionHistory'
import { ATTACHMENTS_FILE_TYPES } from 'features/ContractDetails/constants'
import { ReactComponent as Docs16 } from 'assets/svg/Docs16.svg'
import { formatAmendmentDocument } from './utils'
import ContractDetailsSignUsers from '../ContractDetailsSignUsers/ContractDetailsSignUsers'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../redux/store/user/getters'
import { AddSignatoryPopup } from './AddSignatoryPopup/AddSignatoryPopup'
import { Banner } from '../../../../components/Banner/Banner'
import { ROLES_NAMES } from '../../../../constants'
import { useContractDetailsPopup } from '../../Providers/ContractDetailsPopupProvider'
import AcceptContractDocumentPopup from '../ContractDetailsSignUsers/AcceptContractDocument/AcceptContractDocumentPopup'

const ContractDetailsDocuments = () => {
  const user = useSelector(getUser)
  const contractDetailsContext = useContractDetailsContext()
  const { acceptContractDocumentPopup } = useContractDetailsPopup()

  const {
    details,
    contractDocuments,
    canEditContract,
    isOnboarded,
    isAutoGeneratedContract,
    canAddSignatory,
    canViewSignatory,
    canUploadAttachments,
    canUploadInitialDocument,
    canUploadNewDocument,
    canDownloadTemplates,
    canAcceptContract,
    canDownloadDocuments
  } = contractDetailsContext.state
  const {
    handleUploadAttachmentsAsync,
    handleUploadContractDocumentsAsync,
    handleDeleteAttachmentsAsync
  } = contractDetailsContext.actions

  const disableAttachmentListActions = !canEditContract

  const isRedliningButtonsVisible = Boolean(
    isAutoGeneratedContract && !!contractDocuments?.length
  )

  const isAcceptVisible = useMemo(() => {
    if (contractDocuments?.length) {
      const lastDoc = contractDocuments[0]
      return lastDoc.accepted_by.every(
        (acceptedUser) => !acceptedUser.role.includes(user.role.split('_')[0])
      )
    }
    return false
  }, [contractDocuments, user.role])

  const renderUploadFileButton = useCallback(
    (documentType: TDocumentTypeUnion, isContract?: boolean) => {
      const acceptFileTypes = isContract
        ? ATTACHMENTS_FILE_TYPES
        : getAcceptFileTypesByDocumentType(documentType)

      const handleUploadFile = isContract
        ? handleUploadContractDocumentsAsync
        : handleUploadAttachmentsAsync

      const handleDropAcceptedFile = async (file) => {
        await handleUploadFile(file, documentType)
      }

      const validateFile = (file) => validateFileTypes(acceptFileTypes, file)
      // specific view for redlining flow
      let redliningButtonProps = {}
      if (
        isRedliningButtonsVisible &&
        documentType === DOCUMENT_TYPE.TEMPLATE
      ) {
        redliningButtonProps = {
          uploadButtonText: BTN_TXT.UPLOAD_REDLINES,
          icon: <></>,
          small: true,
          btnType: BUTTON_TYPES.DEFAULT
        }
      }

      return (canUploadAttachments &&
        documentType === DOCUMENT_TYPE.ATTACHMENTS) ||
        canUploadInitialDocument ||
        canUploadNewDocument ? (
        <FileUpload
          documentType={documentType}
          uploadBtnText={BTN_TXT.UPLOAD}
          handleUploadFile={handleUploadFile}
          {...redliningButtonProps}
          uploadFilesProps={{
            multiple: false,
            maxSize: 5242880,
            onDropAccepted: handleDropAcceptedFile,
            accept: acceptFileTypes,
            validator: validateFile
          }}
        />
      ) : null
    },
    [
      isRedliningButtonsVisible,
      canUploadInitialDocument,
      canUploadNewDocument,
      canUploadAttachments,
      handleUploadContractDocumentsAsync,
      handleUploadAttachmentsAsync
    ]
  )

  const uploadedContractsBanner = useMemo(() => {
    if (!isAutoGeneratedContract) return undefined
    if (isAcceptVisible) {
      return (
        <Banner.InfoWithTitle
          className="mb-8"
          title="A new version of the contract is now available"
          text="Please review and either accept the contract  or provide a new version with your revisions."
        />
      )
    } else if (contractDocuments?.length) {
      const lastDoc = contractDocuments[0]
      const acceptedInstanceUser = lastDoc.accepted_by.find((acceptedUser) =>
        acceptedUser.role.includes(user.role.split('_')[0])
      )
      if (acceptedInstanceUser) {
        return (
          <Banner.Warning
            className="mb-8"
            text={`The contract has been accepted by ${
              ROLES_NAMES[acceptedInstanceUser.role]
            }.`}
          />
        )
      }
    }
    return undefined
  }, [contractDocuments, isAcceptVisible, isAutoGeneratedContract, user.role])

  const hasAnyContractDocumentTemplates = Boolean(
    details?.contract_document_templates?.length
  )
  const hasAnyContractDocuments = Boolean(contractDocuments?.length)
  const hasAnyContractAttachments = Boolean(
    details?.contract_attachments?.length
  )
  const amendmentDocument = !!details?.amendment_document
    ? [formatAmendmentDocument(details.amendment_document)]
    : []
  const hasAmendmentDocument = !!amendmentDocument.length && isOnboarded

  const isDocuments =
    canEditContract ||
    hasAnyContractDocumentTemplates ||
    hasAnyContractDocuments ||
    hasAnyContractAttachments ||
    hasAmendmentDocument

  return (
    <>
      <AcceptContractDocumentPopup />
      {isDocuments && (
        <div className="contact-details-documents">
          <Typography.Headline6 className="contact-details-documents__title">
            Documents
          </Typography.Headline6>
          <div className="contact-details-documents__grid">
            {hasAnyContractDocumentTemplates &&
              !isOnboarded &&
              !isRedliningButtonsVisible &&
              canDownloadTemplates && (
                <div className="contact-details-documents__row">
                  <AttachmentsList
                    label="Templates"
                    files={details.contract_document_templates.filter(
                      (d) => d.document_type === 'contract_document'
                    )}
                    disableActions
                  />
                </div>
              )}
            <div className="contact-details-documents__row">
              <div>
                {!isOnboarded &&
                  (canAddSignatory ||
                    canViewSignatory ||
                    canUploadInitialDocument ||
                    canUploadNewDocument ||
                    canDownloadDocuments) && (
                    <AttachmentsList.WithHistory
                      label="Contract"
                      infoTooltipText={
                        isRedliningButtonsVisible
                          ? 'Please download the contract, review it, add your redlines, and upload the updated file with your changes.'
                          : undefined
                      }
                      files={contractDocuments ?? []}
                      beforeAttachmentChildren={uploadedContractsBanner}
                      versionHistory={<ContractVersionHistory />}
                    >
                      {(canAddSignatory || canViewSignatory) && (
                        <ContractDetailsSignUsers />
                      )}
                    </AttachmentsList.WithHistory>
                  )}
                {hasAmendmentDocument && (
                  <AttachmentsList
                    label="LOP"
                    files={amendmentDocument}
                    icon={<Docs16 />}
                    disableActions
                  />
                )}
                <div className="flex row">
                  {isRedliningButtonsVisible &&
                    isAcceptVisible &&
                    canAcceptContract && (
                      <Button
                        type={BUTTON_TYPES.DEFAULT}
                        className="mr-8"
                        small
                        onClick={() =>
                          acceptContractDocumentPopup.actions.open()
                        }
                        upperCase
                      >
                        {BTN_TXT.ACCEPT_CONTRACT}
                      </Button>
                    )}
                  {renderUploadFileButton(DOCUMENT_TYPE.TEMPLATE, true)}
                </div>
              </div>
              {(canEditContract || canUploadAttachments) && (
                <div>
                  <AttachmentsList
                    label="Attachments"
                    files={details.contract_attachments}
                    onDelete={handleDeleteAttachmentsAsync}
                    disableActions={disableAttachmentListActions}
                  />
                  {(canEditContract || canUploadAttachments) &&
                    details.contract_attachments.length < 10 &&
                    renderUploadFileButton(DOCUMENT_TYPE.ATTACHMENTS)}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {canAddSignatory && <AddSignatoryPopup />}
    </>
  )
}

export default ContractDetailsDocuments
