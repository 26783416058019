import Typography from '../../../components/Typography/Typography'
import './style.scss'
import { Collapse } from '../../../components/Collapse'
import { useEffect, useRef, useState } from 'react'
import { Collapse as CollapseAntd } from 'antd'
import { DEFAULT_PAGE } from '../../../components/Table/constants'
import axios, { CancelTokenSource } from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../../redux/store/common/slice'
import {
  getOpportunityAnalysisList,
  getOpportunityAnalysisSavings
} from '../../Contracts/api'
import { Table } from '../../../components/Table'
import { columns } from '../OpportunityAnalysis/constants'
import { isTableHasParams } from '../../../helper/common'
import {
  TOpportunityAnalysisList,
  TOpportunityAnalysisListFilters,
  TOpportunityAnalysisListFiltersFunc,
  TOpportunityAnalysisListParams,
  TOpportunityAnalysisRequestData
} from './types'
import { AcceptModal } from './Menu/AcceptModal'
import { useCognusRepositoryPopup } from '../Providers/CognusRepositoryPopupProvider'
import { getUser } from 'redux/store/user/getters'
import { SupportInformation } from './SupportInformation/SupportInformation'
import { TPurchaseOrderResult } from '../../PurchaseOrders/types'
import { TerminateModal } from './Menu/TerminateModal'
import ClinicalReviewModal from './Menu/ClinicalReviewModal'
import { TotalSavings } from './TotalSavings'
import { TSavingResponse } from '../../RequestForAnalysisDetails/types'
import { InitiateClinicalReview } from '../InitiateClinicalReview/InitiateClinicalReview'
import { getInvoiceDataTemplate } from '../../../pages/InvoiceData/api'
const { Panel } = CollapseAntd
type TProps = {
  setIsCanRunClinicalReview: (value: boolean) => void
}
export const OpportunityAnalysis = ({ setIsCanRunClinicalReview }: TProps) => {
  const [activeKey, setActiveKey] = useState('')

  const { acceptContractPopup, terminateContractPopup, clinicalReviewPopup } =
    useCognusRepositoryPopup()

  const [tableData, setTableData] = useState<TOpportunityAnalysisList[]>([])
  const [sortParams, setSortParams] =
    useState<TOpportunityAnalysisRequestData>()
  const [firstLoad, setFirstLoad] = useState(true)
  const [filters, setFilters] = useState<TOpportunityAnalysisListFilters>({})
  const [totalNumber, setTotalNumber] = useState(0)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const tableDataTokenRef = useRef<CancelTokenSource>()
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const [savings, setSavings] = useState<TSavingResponse>({
    total_savings: 0,
    saving_goals: 0,
    opportunity_analysis_savings: 0
  })

  const [files, setFiles] = useState<TPurchaseOrderResult[]>([])
  useEffect(() => {
    getInvoiceDataTemplate().then((res) =>
      setFiles(
        res.data.results.map((i) => ({
          ...i,
          uuid: '1'
        }))
      )
    )
  }, [])

  useEffect(() => {
    getTableData({ ...sortParams, ...filters, page: { ...DEFAULT_PAGE } })
    setPageInfo({ ...DEFAULT_PAGE })
  }, [])
  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])

  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: TOpportunityAnalysisRequestData) => {
    dispatch(setLoading(true))
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: TOpportunityAnalysisListParams = { ...filters }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    getOpportunityAnalysisList({
      cancelToken: tableDataTokenRef.current?.token,
      params
    })
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            key: i.uuid
          }))
        )
        setIsCanRunClinicalReview(!!resp.data.results?.length)
        setTotalNumber(resp.data.count)
        if (firstLoad) {
          setFirstLoad(false)
        }
      })
      .finally(() => dispatch(setLoading(false)))
    getOpportunityAnalysisSavings({
      cancelToken: tableDataTokenRef.current?.token
    }).then((res) => {
      if (res?.data) setSavings(res?.data)
    })
  }
  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }
  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }

  const callbackAfterAccept = () => {
    acceptContractPopup.actions.close()
    setFilters({})
    getTableData({ page: { ...DEFAULT_PAGE } })
  }
  const callbackAfterTerminate = () => {
    terminateContractPopup.actions.close()
    setFilters({})
    getTableData({ page: { ...DEFAULT_PAGE } })
  }

  const applyFilters: TOpportunityAnalysisListFiltersFunc = (
    field,
    appliedFilters
  ) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    const newPageInfo = { ...pageInfo, pageNumber: 1 }
    setFilters(newFilters)
    setPageInfo(newPageInfo)
    getTableData({ ...sortParams, filters: newFilters, page: newPageInfo })
  }
  const onChange = (uuid) => setActiveKey(uuid)
  const handleClinicalReviewModal = () => clinicalReviewPopup.actions.open()
  return (
    <div className={'opportunity-analysis-container'}>
      {!tableData?.length ? (
        !firstLoad && (
          <div className="opportunity-analysis-container__empty-state">
            <div className="opportunity-analysis-container__empty-state__wrapper">
              <Typography.Headline6>
                There are no contracts for evaluation
              </Typography.Headline6>
              <Typography.Body1 className="mt-8">
                To start Opportunity analysis, please go to the Available for
                evaluation tab and select Contracts for evaluation
              </Typography.Body1>
            </div>
          </div>
        )
      ) : (
        <Collapse
          activeKey={activeKey}
          accordion={false}
          onChange={onChange}
          className="mt-16"
        >
          <Panel key={'support_information'} header={'Support information'}>
            <SupportInformation files={files} />
          </Panel>
          <Panel key={'evaluation'} header={'Evaluation'}>
            <div className="table-wrapper community-users available-for-evaluation">
              {!firstLoad && (
                <Table<TOpportunityAnalysisList>
                  dataSource={tableData}
                  columns={columns({
                    applyFilters,
                    filters,
                    handleClinicalReviewModal,
                    isVendor: !!user.vendor,
                    user: user
                  })}
                  className="contracts-table"
                  onChange={handleTableChange}
                  hasSearchOrFilters={isTableHasParams<TOpportunityAnalysisListFilters>(
                    '',
                    filters
                  )}
                  onChangePage={handleChangePageSize}
                  pageSize={pageInfo.pageSize}
                  pagination={{
                    pageSize: pageInfo.pageSize,
                    current: pageInfo.pageNumber,
                    total: totalNumber
                  }}
                />
              )}
              <TotalSavings savings={savings} />
            </div>
          </Panel>
        </Collapse>
      )}
      <AcceptModal callbackAfterAccept={callbackAfterAccept} />
      <InitiateClinicalReview callbackAfterAccept={callbackAfterAccept} />
      <TerminateModal callback={callbackAfterTerminate} />
      {clinicalReviewPopup.state.visible && <ClinicalReviewModal />}
    </div>
  )
}
