import { ColumnsType } from 'antd/es/table'
import { Link } from '../../../components/Link'
import { canSeeMyContracts } from '../../../helper/common'
import { routes } from '../../../router'
import { TRFPResultData } from './types'
import { IUser } from '../../../redux/store/user/types'

export const columns = (user: IUser): ColumnsType<TRFPResultData> => {
  const defaultColumns: ColumnsType<TRFPResultData> = [
    {
      dataIndex: 'contract_category_name',
      key: 'contract_category_name',
      width: 400,
      title: 'Contract categories',
      render: (_, { uuid, contract_category_name }) => (
        <Link
          to={`${routes.contracts}${
            canSeeMyContracts(user.role) ? '/my_contracts' : ''
          }/${uuid}`}
        >
          {contract_category_name}
        </Link>
      )
    }
  ]

  if (!user.vendor) {
    defaultColumns.push({
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      width: 740,
      title: 'Vendor name'
    })
  }

  return defaultColumns
}
