import { Button, BUTTON_TYPES } from '../../components/Button'
import { BTN_TXT } from '../../constants'
import { Popup } from '../../components/Popup'
import { useContractDetailsPopup } from '../ContractDetails/Providers/ContractDetailsPopupProvider'
import { Radio, Space } from 'antd'
import { Typography } from '../../components/Typography'
import {
  CONTRACT_ITEM_LIST_TYPES,
  DOCUMENT_TYPE
} from '../../components/FileUpload/constants'
import { validateFileTypes } from '../../helper/common'
import { FileUpload } from '../../components/FileUpload/FileUpload'
import { TDocumentTypeUnion } from '../../components/FileUpload/types'

export const ContractUpdateItemsListPopup = ({
  replaceOldItems,
  setReplaceOldItems,
  handleUploadFile
}: {
  replaceOldItems: boolean
  setReplaceOldItems: (v: boolean) => void
  handleUploadFile: (
    file: File[],
    documentType: TDocumentTypeUnion
  ) => Promise<unknown>
}) => {
  const { updateItemListPopup } = useContractDetailsPopup()
  const { state, actions } = updateItemListPopup

  return (
    <Popup
      {...state}
      className="create-contract-pipeline-modal"
      onCancel={actions.close}
      width={442}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.DEFAULT}
            upperCase
            onClick={actions.close}
            className="mr-8"
          >
            {BTN_TXT.CANCEL}
          </Button>
          <FileUpload
            documentType={DOCUMENT_TYPE.QUESTIONS}
            uploadBtnText={BTN_TXT.UPLOAD}
            uploadButtonText={BTN_TXT.UPDATE}
            handleUploadFile={handleUploadFile}
            icon={' '}
            uploadFilesProps={{
              multiple: false,
              maxSize: 5242880,
              onDropAccepted: (file) => handleUploadFile(file, 'questions'),
              accept: {
                ...CONTRACT_ITEM_LIST_TYPES
              },
              validator: (file) =>
                validateFileTypes(CONTRACT_ITEM_LIST_TYPES, file)
            }}
          />
        </>
      }
    >
      <Space direction="vertical" size={16}>
        <Typography.Body2>
          Please select how you will upload items:
        </Typography.Body2>
        <Radio.Group
          size="large"
          value={replaceOldItems}
          onChange={(e) => setReplaceOldItems(e.target.value)}
        >
          <Radio value={false} className="mb-16">
            Add new items to the current file
          </Radio>
          <Radio value={true}>Replace the existing file</Radio>
        </Radio.Group>
      </Space>
    </Popup>
  )
}
