import { ReactElement, useCallback } from 'react'
import SignPopup from 'features/SignPopup/SignPopup'
import Typography from '../../components/Typography/Typography'
import { TYPOGRAPHY_WEIGHT } from '../../components/Typography'
import { Button, BUTTON_TYPES } from '../../components/Button'
import { BTN_TXT, VALIDATION_MESSAGES } from '../../constants'
import InvalidLink from '../../assets/svg/InvalidLink.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getPasswordData } from '../../redux/store/setPassword/getters'
import { resendSignUpEmail } from '../../features/SignPopup/api'
import { setLoading } from '../../redux/store/common/slice'
import { notification } from '../../components/Notification'
import { routes } from '../../router'
import useRouter from '../../hooks/useRouter'

const LinkExpired = (): ReactElement => {
  const { email, token } = useSelector(getPasswordData)
  const dispatch = useDispatch()
  const { push } = useRouter()

  const handleResend = useCallback(() => {
    dispatch(setLoading(true))
    resendSignUpEmail({ token })
      .then(() => {
        notification.success({
          message: `Email ${VALIDATION_MESSAGES.SM0009} resent`
        })
      })
      .catch((response) => {
        const { status, data } = response

        if (status === 400 && data?.length) {
          notification.error({ message: data[0] })
        }
      })
      .finally(() => {
        push(routes.login)
        dispatch(setLoading(false))
      })
  }, [dispatch, push, token])

  return (
    <SignPopup
      isBack
      onBackPress={() => push(routes.login)}
      email={email}
      newLogo={InvalidLink}
      className="pending-approval-container"
    >
      <Typography.HeadlineD4 className="mt-32" weight={TYPOGRAPHY_WEIGHT.BOLD}>
        This link has expired
      </Typography.HeadlineD4>
      <Typography.Body1 className="mt-12">
        Your registration link has expired. Please request a new invitation or
        contract support.
      </Typography.Body1>
      <div className="row align-center mt-32 gap-12 full-width">
        <Button
          type={BUTTON_TYPES.PRIMARY}
          className="full-width"
          upperCase
          onClick={handleResend}
        >
          {BTN_TXT.RESEND_INVITATION}
        </Button>
      </div>
    </SignPopup>
  )
}

export default LinkExpired
