import { useMemo, useState } from 'react'
import { Popup } from 'components/Popup'
import { Button, BUTTON_TYPES } from 'components/Button'
import { AddAmendment } from 'features/ContractDetails/HSList/AddAmendment/AddAmendment'
import { useContractDetailsPopup } from 'features/ContractDetails/Providers/ContractDetailsPopupProvider'
import { BTN_TXT } from 'constants/txt'
import { TPreviewDocument } from 'features/ContractDetails/ExtendContract/types'
import { Moment } from 'moment'
import { useContractDetailsContext } from '../../Providers/ContractDetailsContextProvider'

export const AddAmendmentPopup = () => {
  const { addAmendmentPopup } = useContractDetailsPopup()

  const [amendmentDocument, setAmendmentDocument] =
    useState<TPreviewDocument | null>(null)
  const [pricingEffectiveDate, setPricingEffectiveDate] =
    useState<Moment | null>(null)

  const contractDetailsContext = useContractDetailsContext()
  const { canAddLoPSignatories } = contractDetailsContext.state

  const disableAddAmendmentButton = useMemo(
    () => amendmentDocument === null || pricingEffectiveDate === null,
    [amendmentDocument, pricingEffectiveDate]
  )

  const FormActions = useMemo(
    () => (
      <>
        <Button onClick={addAmendmentPopup.actions.close} upperCase>
          {BTN_TXT.CANCEL}
        </Button>
        <Button
          type={BUTTON_TYPES.PRIMARY}
          form="add-amendment-form-id"
          htmlType="submit"
          upperCase
          disabled={disableAddAmendmentButton}
        >
          {BTN_TXT.ADD}
        </Button>
      </>
    ),
    [disableAddAmendmentButton, addAmendmentPopup.actions.close]
  )

  return (
    <Popup
      className="add-amendment-popup"
      {...addAmendmentPopup.state}
      width={canAddLoPSignatories ? 676 : 442}
      footer={FormActions}
    >
      <AddAmendment
        amendmentDocument={amendmentDocument}
        pricingEffectiveDate={pricingEffectiveDate}
        setAmendmentDocument={setAmendmentDocument}
        setPricingEffectiveDate={setPricingEffectiveDate}
      />
    </Popup>
  )
}
