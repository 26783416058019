import Typography from '../../../../components/Typography/Typography'
import { TGroupedLinearChart } from '../../../../pages/Compliance/types'
import ItemLine from './ItemLine'

type TProps = {
  item: TGroupedLinearChart
}
const Chart = ({ item }: TProps) => {
  return (
    <div
      className="line-chart-compliance row space-between full-width align-start gap-24"
      data-testid="lineChartRow"
    >
      {!!item?.category && (
        <Typography.Caption className="line-chart-compliance__title full-width mt-4">
          {item.category}
        </Typography.Caption>
      )}
      <div className="column gap-8">
        {item.led_by?.map((ledByItem, idx) => {
          return (
            <ItemLine
              item={ledByItem}
              key={'spend-category_' + item.category + '_line' + idx}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Chart
