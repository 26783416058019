import { CognusARData } from './types'
import { FINANCIAL_STATUS } from '../../types'
import { IUser } from '../../../../redux/store/user/types'

export const getTooltipForRow = (row: CognusARData, user: IUser) =>
  user.community && row.status === FINANCIAL_STATUS.WAITING_FOR_RESPONSE
    ? 'Vendor responsible for payment'
    : ''

export const getDisabledStatuses = (row: CognusARData, user: IUser) => {
  const disabledStatuses = [
    FINANCIAL_STATUS.DISPUTED,
    FINANCIAL_STATUS.PAID,
    FINANCIAL_STATUS.PAYMENT_IN_PROGRESS
  ]

  if (user.community) {
    disabledStatuses.push(FINANCIAL_STATUS.WAITING_FOR_RESPONSE)
    disabledStatuses.push(FINANCIAL_STATUS.PAYMENT_FAILED)
  }

  return disabledStatuses.includes(row.status)
}
