export const SPEND_BY_VENDOR_COLORS = {
  0: 'blue',
  1: 'green',
  2: 'red',
  3: 'yellow',
  4: 'light_red',
  5: 'dark_gray'
}
export const SPEND_BY_VENDOR_CHART_COLORS = {
  0: '#5B8FCE',
  1: '#9CD652',
  2: '#F66',
  3: '#FFB71B',
  4: '#F99',
  5: '#C2C3C4'
}

export const INITIAL_DATA_SPEND_BY_VENDOR_LEGEND = [
  {
    name: '',
    amount: 0,
    percentage: '0',
    on_contract: false
  }
]
