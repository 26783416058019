import { Container } from '../../components/Container/Container'
import { HOSPITAL_PAYMENT_DETAILS_BREADCRUMBS } from './constants'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  addBusinessDetailsInitialValues,
  bankAccountDetailsInitialValues,
  PAYMENT_DETAILS_OPTIONS
} from '../PaymentDetails/constants'
import RadioGroup from '../../components/RadioGroup/RadioGroup'
import BusinessDetails from '../PaymentDetails/BusinessDetails/BusinessDetails'
import BankAccount from '../PaymentDetails/BankAccount/BankAccount'
import { TAddBusinessDetails } from '../PaymentDetails/BusinessDetails/types'
import { TAddBankAccountDetails } from '../PaymentDetails/BankAccount/types'
import { setLoading } from '../../redux/store/common/slice'
import { getBankAccount } from '../PaymentDetails/api'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../redux/store/user/getters'
import { useParams } from 'react-router-dom'
import { getHospitalData } from '../HealthSystemDetails/HospitalsTable/api'
import useRouter from '../../hooks/useRouter'
import { THospital } from '../HealthSystemDetails/HospitalsTable/types'
import { SETTINGS_STATUSES } from '../../pages/Settings/constants'

export const HospitalsPaymentDetails = () => {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const { hospitalId } = useParams()
  const {
    query: { hsId, tab }
  } = useRouter()

  const [activeSubTab, setActiveSubTab] = useState(
    PAYMENT_DETAILS_OPTIONS[0].value
  )
  const [businessDetails, setBusinessDetails] = useState<TAddBusinessDetails>(
    addBusinessDetailsInitialValues
  )
  const [bankAccount, setBankAccount] = useState<TAddBankAccountDetails>(
    bankAccountDetailsInitialValues
  )

  const [details, setDetails] = useState<
    THospital & {
      business_resource: TAddBusinessDetails
    }
  >()

  const getHospitalDetails = useCallback(async () => {
    try {
      dispatch(setLoading(true))
      const response = await getHospitalData(
        user.community ?? '',
        hsId ?? '',
        hospitalId ?? ''
      )
      setDetails(response.data)
      if (response.data.business_resource) {
        setBusinessDetails(response.data.business_resource)
        if (!!response.data.business_resource.bank_accounts?.length) {
          const resBankAccount = await getBankAccount(
            response.data.business_resource.bank_accounts[0]?.uuid
          )
          setBankAccount({
            ...resBankAccount.data,
            account_number: '*****' + resBankAccount.data.account_number,
            routing_number: '********' + resBankAccount.data.routing_number
          })
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [user, hospitalId, hsId])

  const handleTabs = (e) => {
    setActiveSubTab(e)
  }

  useEffect(() => {
    getHospitalDetails()
    if (tab === 'bankAccount') {
      setActiveSubTab(PAYMENT_DETAILS_OPTIONS[1].value)
    }
  }, [])

  const paymentsOptions = useMemo(() => {
    return PAYMENT_DETAILS_OPTIONS.map((item, idx) =>
      idx === 1 ? { ...item, disabled: !businessDetails.uuid } : item
    )
  }, [businessDetails])

  return (
    <Container
      title={details?.name}
      breadcrumbs={HOSPITAL_PAYMENT_DETAILS_BREADCRUMBS(details?.name)}
    >
      <div className="mb-24">
        <RadioGroup.Buttons
          options={paymentsOptions}
          defaultValue={activeSubTab}
          onChange={handleTabs}
        />
      </div>
      {!activeSubTab && (
        <BusinessDetails
          businessDetails={businessDetails}
          getDetails={getHospitalDetails}
        />
      )}
      {activeSubTab === PAYMENT_DETAILS_OPTIONS[0].value && (
        <BusinessDetails
          businessDetails={businessDetails}
          getDetails={getHospitalDetails}
        />
      )}
      {activeSubTab === PAYMENT_DETAILS_OPTIONS[1].value && (
        <BankAccount
          bankAccount={bankAccount}
          isVerified={businessDetails?.status === SETTINGS_STATUSES.VERIFIED}
          getDetails={getHospitalDetails}
        />
      )}
    </Container>
  )
}
