import moment from 'moment'
import { ColumnsType } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import AmendmentCell from 'features/ContractDetails/HSList/HSTable/AmendmentCell/AmendmentCell'
import { CellWithSubtitle } from '../../../../components/CellWithSubtitle/CellWithSubtitle'
import { Sorter } from 'components/Sorter/Sorter'
import { Link } from '../../../../components/Link'
import { Tooltip } from '../../../../components/Tooltip/Tooltip'
import { AddLOPCell } from './AddLOPCell/AddLOPCell'
import { timelineDateFormat } from '../../../../components/Timelines/constants'
import { ReactComponent as WarningRed16 } from 'assets/svg/WarningRed16.svg'
import { IContractHealthSystem } from '../types'
import { SIGNATORY_STATUS_LABELS } from '../../constants'
import { UserListItem } from '../../ContractDetailsGeneral/ContractDetailsSignUsers/UserListItemSignatory/UserListItemSignatory'

const _getMyCommunityLink = (name: string, { uuid }: IContractHealthSystem) => (
  <CellWithSubtitle
    title={<Link to={`/my-community/health-system/${uuid}/`}>{name}</Link>}
  />
)

export const columnsShortView = (canSeeSignatories: boolean) => {
  let columns = [
    {
      dataIndex: 'not_completed',
      key: 'not_completed',
      width: 40,
      title: '',
      render: (_, { not_completed }) =>
        not_completed && (
          <Tooltip title="To participate in the contract, please provide the effective date, and a letter of participation.">
            <WarningRed16 />
          </Tooltip>
        )
    },
    {
      dataIndex: 'name',
      key: 'name',
      width: 480,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<IContractHealthSystem>
          dataIndex="name"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Health system
        </Sorter>
      ),
      render: _getMyCommunityLink,
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'pricing_effective_date',
      key: 'pricing_effective_date',
      width: 280,
      title: 'Pricing effective date',
      render: (dateString) =>
        !!dateString ? moment(dateString).format(timelineDateFormat) : '-'
    },
    {
      dataIndex: 'amendment_document',
      key: 'amendment_document',
      width: 380,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<IContractHealthSystem>
          dataIndex="amendment_document"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          LoP / Amendment
        </Sorter>
      ),
      render: (_, { amendment_document }) => (
        <AmendmentCell amendment_document={amendment_document} />
      ),
      sorter: true,
      showSorterTooltip: false
    }
  ] as ColumnsType<IContractHealthSystem>

  if (canSeeSignatories) {
    columns = columns.concat([
      {
        dataIndex: 'community_signatory',
        key: 'community_signatory',
        width: 380,
        title: 'Health system signatory',
        render: (_, { community_signatory }) =>
          community_signatory ? (
            <UserListItem
              bigAvatar
              user={community_signatory?.signatory ?? undefined}
              email={community_signatory?.email ?? undefined}
              statusLabel={
                SIGNATORY_STATUS_LABELS[
                  community_signatory?.status ?? 'waiting_for_signature'
                ]
              }
            />
          ) : (
            '-'
          )
      },
      {
        dataIndex: 'vendor_signatory',
        key: 'vendor_signatory',
        width: 380,
        title: 'Vendor signatory',
        render: (_, { vendor_signatory }) =>
          vendor_signatory ? (
            <UserListItem
              bigAvatar
              user={vendor_signatory?.signatory ?? undefined}
              email={vendor_signatory?.email ?? undefined}
              statusLabel={
                SIGNATORY_STATUS_LABELS[
                  vendor_signatory?.status ?? 'waiting_for_signature'
                ]
              }
            />
          ) : (
            '-'
          )
      }
    ])
  }

  columns.push({
    dataIndex: 'action',
    key: 'action',
    width: 64,
    render: (_, { uuid, vendor_signatory, community_signatory }) =>
      !vendor_signatory && !community_signatory ? (
        <AddLOPCell selectedHSRowKey={uuid} />
      ) : null
  })

  return columns
}

export const onboardedHsColumns = (canSeeSignatories: boolean) => {
  let columns = [
    {
      dataIndex: 'name',
      key: 'name',
      width: 480,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<IContractHealthSystem>
          dataIndex="name"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Health system
        </Sorter>
      ),
      render: _getMyCommunityLink,
      sorter: true,
      showSorterTooltip: false
    }
  ] as ColumnsType<IContractHealthSystem>

  if (canSeeSignatories) {
    columns = columns.concat([
      {
        dataIndex: 'community_signatory',
        key: 'community_signatory',
        width: 380,
        title: 'Health system signatory',
        render: (_, { community_signatory }) =>
          community_signatory ? (
            <UserListItem
              bigAvatar
              user={community_signatory?.signatory ?? undefined}
              email={community_signatory?.email ?? undefined}
              statusLabel={
                SIGNATORY_STATUS_LABELS[
                  community_signatory?.status ?? 'waiting_for_signature'
                ]
              }
            />
          ) : (
            '-'
          )
      },
      {
        dataIndex: 'vendor_signatory',
        key: 'vendor_signatory',
        width: 380,
        title: 'Vendor signatory',
        render: (_, { vendor_signatory }) =>
          vendor_signatory ? (
            <UserListItem
              bigAvatar
              user={vendor_signatory?.signatory ?? undefined}
              email={vendor_signatory?.email ?? undefined}
              statusLabel={
                SIGNATORY_STATUS_LABELS[
                  vendor_signatory?.status ?? 'waiting_for_signature'
                ]
              }
            />
          ) : (
            '-'
          )
      }
    ])
  }

  return columns
}
