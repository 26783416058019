import {
  convertToFixed,
  currencyFormatter
} from '../../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { Pie } from '@ant-design/plots'
import { TSpendByVendor } from '../../../../pages/Compliance/types'
import { SPEND_BY_VENDOR_CHART_COLORS } from './constants'
import { memo } from 'react'
type TProps = {
  data: TSpendByVendor[]
}
export const Chart = memo(({ data }: TProps) => {
  const config = {
    appendPadding: 1,
    data: data,
    tooltip: {
      customContent: (_title, data) => {
        return data.map((i, idx) => (
          <div className="chart__tooltip" key={idx}>
            {i.data.name} <br /> ${currencyFormatter(convertToFixed(i.value))} (
            {(Number(i.data.percentage) * 100).toFixed(2)}
            %)
          </div>
        ))
      }
    },
    pieStyle: {
      lineWidth: 2
    },
    angleField: 'amount',
    colorField: 'name',
    radius: 0.8,
    indicator: false,
    color: data.find((i) => !!i.amount)
      ? data.map((i, idx) =>
          +i.amount <= 0 ? '#F0F3F7' : SPEND_BY_VENDOR_CHART_COLORS[idx]
        )
      : ['#F0F3F7'],
    label: {
      type: 'inner',
      offset: '-50%',
      content: '',
      text: (d) => d.percentage + '%',
      style: {
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 500
      }
    },
    legend: false as const,
    interactions: [],
    statistic: {
      title: {
        style: {
          color: '#103861',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '16px',
          lineHeight: '24px'
        },
        offsetY: 5
      },
      content: {
        style: {
          color: '#6D747D',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px'
        },
        offsetY: 8,
        content: ''
      }
    }
  }
  return <Pie {...config} />
})
