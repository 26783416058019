import Typography from 'components/Typography/Typography'
import { ReactComponent as HospitalIcon } from 'assets/svg/Hospital.svg'
import { Button, BUTTON_TYPES } from 'components/Button'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { BTN_TXT } from '../../../constants'
import { useNavigate } from 'react-router'
import { routes } from '../../../router'
import { useParams } from 'react-router-dom'
import useRouter from '../../../hooks/useRouter'

const EmptyState = () => {
  const navigate = useNavigate()
  const { hospitalId } = useParams()

  const {
    query: { hsId }
  } = useRouter()

  const handleRedirect = () =>
    navigate(
      `${hospitalId ? routes.hospitals + '/' + hospitalId : ''}${
        routes.addBusinessDetails
      }${hsId ? `?hsId=` + hsId : ''}`
    )

  return (
    <div className="business-details-container__empty-state row justify-center">
      <div className="business-details-container__empty-state__wrapper column align-center gap-24">
        <HospitalIcon />
        <Typography.Headline6 className="mt-16">
          No Business details added yet.
        </Typography.Headline6>
        <Button
          className="contract-categories__add-btn"
          onClick={handleRedirect}
          icon={<Plus16 />}
          type={BUTTON_TYPES.PRIMARY}
          upperCase
          medium
        >
          {BTN_TXT.ADD_BUSINESS_DETAILS}
        </Button>
      </div>
    </div>
  )
}

export default EmptyState
