import FinancialsProvider from './Providers/FinancialsProvider'
import FinancialsAbilityProvider from './Providers/FinancialsAbilityProvider'
import FinancialsActionReasonPopupProvider from './Providers/FinancialsActionReasonPopupProvider'
import FinancialsRebatesProvider from './Providers/FinancialsRebatesProvider'
import FinancialsCognusARProvider from './Providers/FinancialsCognusARProvider'
import { FinancialsRouter } from './Router/Router'
import { FinancialsContainer } from './Container/Container'

export const Financials = () => (
  <FinancialsAbilityProvider>
    <FinancialsProvider>
      <FinancialsRebatesProvider>
        <FinancialsCognusARProvider>
          <FinancialsActionReasonPopupProvider>
            <FinancialsContainer>
              <FinancialsRouter />
            </FinancialsContainer>
          </FinancialsActionReasonPopupProvider>
        </FinancialsCognusARProvider>
      </FinancialsRebatesProvider>
    </FinancialsProvider>
  </FinancialsAbilityProvider>
)
