import { routes } from '../../router'

export const HOSPITAL_PAYMENT_DETAILS_BREADCRUMBS = (hospitalName) => [
  {
    children: 'Settings',
    href: routes.settings
  },
  {
    children: hospitalName
  }
]
