import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { TRFPResultsResponse } from './types'

const fetcher = new Fetcher({})

export const getRFPResults = async (id: string) =>
  fetcher.request<unknown, TRFPResultsResponse>({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.CONTRACTS}`,
    method: HTTP_METHODS.GET
  })
