import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Popup } from '../../../../components/Popup'
import { Typography } from '../../../../components/Typography'
import { Tooltip } from '../../../../components/Tooltip/Tooltip'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { initiateRebatesPayment, initiateCognusARPayment } from './api'
import { currencyFormatter } from '../../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { setLoading } from '../../../../redux/store/common/slice'
import { notification } from '../../../../components/Notification'
import { BTN_TXT, VALIDATION_MESSAGES } from '../../../../constants'
import { ConfirmPaymentPopupProps } from './types'
import { PAYMENT_TYPES } from '../../types'

export const ConfirmPaymentPopup = ({
  type,
  selectedLength,
  selectedRowData,
  onResetSelection,
  updateTableData,
  showBankAccountError
}: ConfirmPaymentPopupProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const tooltipText = useMemo(
    () =>
      showBankAccountError
        ? 'Please create bank account to initiate a payment.'
        : 'Payment cannot be initiated for multiple reports.',
    [showBankAccountError]
  )
  const popupText = useMemo(
    () =>
      selectedRowData &&
      `${type} of ${currencyFormatter(
        selectedRowData.total
      )}$ will be paid to QSight Community. Do you want to proceed?`,
    [type, selectedRowData]
  )
  const dispatch = useDispatch()

  const onCancel = () => setIsOpen(false)

  const onProceedPayment = async () => {
    try {
      dispatch(setLoading(true))
      const request =
        type === PAYMENT_TYPES.REBATES
          ? initiateRebatesPayment
          : initiateCognusARPayment
      await request(String(selectedRowData?.uuid))
      await updateTableData()
      onResetSelection()
      onCancel()
      notification.success({
        message: VALIDATION_MESSAGES.SM0164
      })
    } catch (e) {
      dispatch(setLoading(false))
    }
  }

  return (
    <>
      <Popup
        visible={isOpen}
        onCancel={onCancel}
        title={`Pay ${type}`}
        width={442}
        footer={
          <>
            <Button onClick={onCancel} type={BUTTON_TYPES.DEFAULT} upperCase>
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={onProceedPayment}
              upperCase
            >
              {BTN_TXT.PROCEED}
            </Button>
          </>
        }
      >
        <Typography.Body1>{popupText}</Typography.Body1>
      </Popup>
      {selectedLength > 1 || showBankAccountError ? (
        <Tooltip title={tooltipText}>
          <div>
            <Button type={BUTTON_TYPES.PRIMARY} disabled upperCase>
              {BTN_TXT.PAY}
            </Button>
          </div>
        </Tooltip>
      ) : (
        <Button
          type={BUTTON_TYPES.PRIMARY}
          onClick={() => setIsOpen(true)}
          upperCase
        >
          {BTN_TXT.PAY}
        </Button>
      )}
    </>
  )
}
