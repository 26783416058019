import cn from 'classnames'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { TFieldWithLabel } from './types'
import './styles.scss'
import { Tooltip } from '../Tooltip/Tooltip'
import { ReactComponent as Information } from 'assets/svg/Information.svg'

export const FieldWithLabel = ({
  title,
  className,
  children,
  infoTooltipText
}: TFieldWithLabel) =>
  children ? (
    <div className={cn('field-with-label', className)}>
      <div className="flex row">
        <Typography.Label
          weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
          className={cn({ 'mr-4': infoTooltipText?.length })}
        >
          {title}
        </Typography.Label>
        {!!infoTooltipText?.length && (
          <Tooltip title={<div>{infoTooltipText}</div>}>
            <Information />
          </Tooltip>
        )}
      </div>
      <Typography.Body1>{children}</Typography.Body1>
    </div>
  ) : (
    <></>
  )
