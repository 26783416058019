import { FC } from 'react'
import cn from 'classnames'
import Typography from '../Typography/Typography'
import { ReactComponent as Information } from 'assets/svg/Information.svg'
import { BannerProps } from './types'
import './banner.infoWithTitle.scss'

const InfoWithTitle: FC<BannerProps> = ({ text, className, title }) => (
  <div
    className={cn('info-banner-with-title row gap-12 align-start', className)}
  >
    <div className="info-banner-with-title__icon row align-center">
      <Information />
    </div>
    <div className="column gap-8">
      {title && <Typography.Body2>{title}</Typography.Body2>}
      <Typography.Caption>{text}</Typography.Caption>
    </div>
  </div>
)

export default InfoWithTitle
