import { FC } from 'react'
import cn from 'classnames'
import { Button, BUTTON_TYPES } from '../Button'
import { Typography } from '../Typography'
import { ReactComponent as ErrorIcon } from 'assets/svg/ErrorIcon.svg'
import { BannerProps } from './types'
import './banner.error.scss'

const Error: FC<BannerProps> = ({ text, button, className }) => (
  <div
    className={cn(
      'error-banner row gap-12 space-between align-center',
      className
    )}
  >
    <div className="row gap-12 align-start">
      <ErrorIcon />
      <Typography.Body1 className="error-banner__content-text">
        {text}
      </Typography.Body1>
    </div>
    {button && (
      <Button
        type={BUTTON_TYPES.GHOST}
        onClick={button.onButtonClick}
        upperCase
      >
        {button.buttonText}
      </Button>
    )}
  </div>
)

export default Error
