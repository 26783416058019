import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { VALIDATION_MESSAGES } from 'constants/txt'
import { notification } from 'components/Notification'
import { ITimeline } from 'components/Timelines/types'
import { TDocumentTypeUnion } from 'components/FileUpload/types'
import { setLoading } from 'redux/store/common/slice'
import { getUser } from 'redux/store/user/getters'
import {
  ContractCreationWays,
  ContractStatusUnion,
  IContractDetails,
  ManualContractCreationRequestData,
  TContractDocument
} from 'features/ContractDetails/types'
import {
  CONTRACT_DETAILS_TABS,
  CONTRACT_STATUS,
  INITIAL_CONTRACT_DETAILS
} from 'features/ContractDetails/constants'
import {
  ACTIONS,
  contractSubject,
  SUBJECTS
} from 'features/ContractDetails/Abilities'
import {
  acceptContractDocumentRequestAsync,
  addSignatoryAction,
  createContractRequestAsync,
  extendContractDetailsRequestAsync,
  getContractDetailsRequestAsync,
  getContractDocumentsRequestAsync,
  setupContractDetailsTimeRequestAsync,
  updateContractDetailsRequestAsync,
  updateContractRebateRequestAsync,
  uploadContractAttachmentsRequestAsync,
  uploadContractDocumentsRequestAsync
} from 'features/ContractDetails/api'
import { useContractDetailsAbility } from 'features/ContractDetails/Providers/ContractDetailsAbilityProvider'
import { IRenewFinishDatePayload } from 'features/ContractDetails/ExtendContract/types'
import { useContractDetailsPopup } from 'features/ContractDetails/Providers/ContractDetailsPopupProvider'
import { timelinesRequestDataConverter } from 'features/ContractDetails/ContractDetailsTimelines/utils'
import {
  HSAmendmentRequestData,
  IAddHSAmendmentPayload,
  THSAmendmentFile
} from 'features/ContractDetails/HSList/types'
import {
  addHSAmendmentRequestAsync,
  uploadHSAmendmentDocumentRequestAsync
} from 'features/ContractDetails/HSList/api'
import { RebateDataRequest } from 'features/ContractDetails/RebateConditions/RebateConditionsForm/types'
import { contractDetailsRoute } from 'features/ContractDetails/ContractDetailsRouter/routes'
import useTabs, { TabsReturnActions, TabsReturnState } from 'hooks/useTabs'
import { convertDateToRequestFormat } from 'utils/moment'
import { ROLES } from 'features/Permission'
import { CONTRACT_STATUSES } from '../../../constants'
import useRouter from '../../../hooks/useRouter'
import {
  getContractItemsPendingTableData,
  getContractItemsPendingVendorsTableData
} from '../../ContractItems/api'
import { DEFAULT_PAGE } from '../../../components/Table/constants'
import { ITEM_LIST_STATUS } from '../../ContractItems/constants'
import {
  fetchAdminPaymentFrequency,
  fetchPaymentTerms
} from '../../../pages/CRUDUser/api'
import { formatOptionIdValue } from '../../../helper/optionsFormatters'
import { TOption } from '../../../components/Select/types'
import { TAddSignatoryForm } from '../ContractDetailsGeneral/ContractDetailsDocuments/AddSignatoryPopup/types'
import { formatPhoneNumberToRequest } from '../../../helper/common'

type ContextProps = {
  state: {
    details: IContractDetails
    contractDocuments: TContractDocument[] | null
    canEditContract: boolean
    canManageFacilityUsers: boolean
    canEditRebateConditions: boolean
    isVendor: boolean
    canDeclineContract: boolean
    isCreation: boolean
    expiredAt?: string
    isVCAOrVFO?: boolean
    isVCS?: boolean
    isAllResponsibleUsersAdded: boolean
    isCommunity?: boolean
    isCommons?: boolean
    canAllContractInfo: boolean
    isOnboarded?: boolean
    isFilesUpdate?: boolean
    isPendingBannerVisible?: boolean
    adminPaymentFrequencyOptions: TOption[]
    paymentTermsOptions: TOption[]
    isAutoGeneratedContract: boolean
    vendorCanSeeAddTeamMemberButton: boolean
    canAddSignatory: boolean
    canViewSignatory: boolean
    canUploadInitialDocument: boolean
    canUploadNewDocument: boolean
    canUploadAttachments: boolean
    canDownloadTemplates: boolean
    canDownloadDocuments: boolean
    canAcceptContract: boolean
    canSeeLoPSignatories: boolean
    canAddLoPSignatories: boolean
  } & TabsReturnState
  actions: {
    handleUploadAttachmentsAsync: (
      files: File[],
      documentType: TDocumentTypeUnion
    ) => Promise<void>
    handleDeleteAttachmentsAsync: (uuid: string) => Promise<void>
    handleUploadContractDocumentsAsync: (
      files: File[],
      documentType: TDocumentTypeUnion
    ) => Promise<void>
    handleAcceptContractDocumentAsync: () => Promise<void>
    getContractDetailsAsync: () => Promise<void>
    updateContractDetailsStatusAsync: (
      status: ContractStatusUnion
    ) => Promise<void>
    updateContractDetailsAsync: (
      details: Partial<IContractDetails>
    ) => Promise<void>
    createContractAsync: (
      details: Partial<ManualContractCreationRequestData>
    ) => Promise<string | undefined>
    setupContractDetailsTimeAsync: (
      timelines: ITimeline<typeof CONTRACT_STATUS>[]
    ) => Promise<void>
    extendContractDetailsAsync: (
      details: IRenewFinishDatePayload
    ) => Promise<void>
    updateContractRebatesAsync: (
      details: RebateDataRequest,
      callback?: Function
    ) => Promise<void>
    uploadHSAmendmentDocumentAsync: (
      file: File
    ) => Promise<THSAmendmentFile | undefined>
    addAmendmentDocumentAsync: (
      hsId: string,
      hsAmendmentPayload: IAddHSAmendmentPayload,
      successCallback?: Function
    ) => Promise<void>
    getPendingItemStateAsync: () => Promise<void>
    setIsFilesUpdate: (isUpdate: boolean) => void
    addSignatory: (values) => void
  } & TabsReturnActions
}

const ContractDetailsContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const createRoute = 'create'

const prepareRouteId = (routeId?: string) =>
  routeId ? (routeId === createRoute ? null : routeId) : null

const ContractDetailsContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const dispatch = useDispatch()
  const { id: routeId, tab } = useParams()
  const user = useSelector(getUser)
  const ability = useContractDetailsAbility()

  const { query } = useRouter()

  const {
    extendContractPopup,
    renewFinishDatePopup,
    contractStepsTimePopup,
    acceptContractDocumentPopup,
    addSignatoryPopup
  } = useContractDetailsPopup()

  const [id, setId] = useState<string | null>(prepareRouteId(routeId))
  const [details, _setDetails] = useState<IContractDetails>(
    INITIAL_CONTRACT_DETAILS
  )
  const [contractDocuments, _setContractDocuments] = useState<
    TContractDocument[] | null
  >(null)

  const [isFilesUpdate, setIsFilesUpdate] = useState(false)
  const [isPendingBannerVisible, setIsPendingBannerVisible] = useState(false)
  const [adminPaymentFrequencyOptions, setAdminPaymentFrequencyOptions] =
    useState<TOption[]>([])
  const [paymentTermsOptions, setPaymentTermsOptions] = useState<TOption[]>([])

  const { state: tabsState, actions: tabsActions } = useTabs({
    tabs: CONTRACT_DETAILS_TABS,
    activeTab: tab || CONTRACT_DETAILS_TABS[0].key
  })

  const isCreation = useMemo(
    () =>
      routeId === createRoute &&
      (user.role === ROLES.COMMUNITY_PRESIDENT ||
        user.role === ROLES.VICE_PRESIDENT ||
        user.role === ROLES.COMMONS_CONTRACT_STEWARD ||
        user.role === ROLES.COMMONS_VICE_PRESIDENT ||
        user.role === ROLES.CONTRACT_STEWARD),
    [routeId, user.role]
  )

  const isOnboarded = useMemo(
    () =>
      user.role.includes('community') &&
      user.health_system !== details.creator?.health_system,
    [user.role, details.creator?.health_system]
  )

  const canEditContract = useMemo(
    () =>
      ability.can(ACTIONS.EDIT, contractSubject(SUBJECTS.CONTRACT, details)),
    [ability, details]
  )
  const isAutoGeneratedContract = useMemo(
    () => details.creation_way === ContractCreationWays.AUTO,
    [details]
  )
  const canManageFacilityUsers = useMemo(
    () =>
      ability.can(
        ACTIONS.MANAGE,
        contractSubject(SUBJECTS.FACILITY_USERS, details)
      ),
    [ability, details]
  )

  const canAddSignatory = useMemo(() => {
    const lastDocument = contractDocuments ? contractDocuments[0] : null
    if (!lastDocument) return false
    return (
      isAutoGeneratedContract &&
      ability.can(ACTIONS.ADD, contractSubject(SUBJECTS.SIGNATORY, details)) &&
      (!!user.vendor
        ? !lastDocument.signatories?.find((signatory) =>
            signatory.created_by.includes('vendor')
          )
        : !lastDocument.signatories?.find(
            (signatory) => !signatory.created_by.includes('vendor')
          ))
    )
  }, [
    contractDocuments,
    isAutoGeneratedContract,
    ability,
    details,
    user.vendor
  ])
  const canViewSignatory = useMemo(
    () =>
      isAutoGeneratedContract &&
      ability.can(ACTIONS.VIEW, contractSubject(SUBJECTS.SIGNATORY, details)),
    [ability, details, isAutoGeneratedContract]
  )
  const canUploadInitialDocument = useMemo(
    () =>
      ability.can(
        ACTIONS.UPLOAD,
        contractSubject(SUBJECTS.INITIAL_DOCUMENT, details)
      ),
    [ability, details]
  )
  const canUploadNewDocument = useMemo(
    () =>
      ability.can(
        ACTIONS.UPLOAD,
        contractSubject(SUBJECTS.NEW_DOCUMENT, details)
      ) &&
      (details.creation_way === ContractCreationWays.MANUAL
        ? details.status === CONTRACT_STATUS.CONTRACTING
        : details.status === CONTRACT_STATUS.REDLINING),
    [ability, details]
  )
  const canUploadAttachments = useMemo(
    () =>
      ability.can(
        ACTIONS.UPLOAD,
        contractSubject(SUBJECTS.ATTACHMENTS, details)
      ),
    [ability, details]
  )
  const canDownloadTemplates = useMemo(
    () =>
      ability.can(
        ACTIONS.DOWNLOAD,
        contractSubject(SUBJECTS.TEMPLATES, details)
      ),
    [ability, details]
  )
  const canDownloadDocuments = useMemo(
    () =>
      details.status !== CONTRACT_STATUS.CONTRACTING &&
      details.creation_way !== ContractCreationWays.AUTO &&
      ability.can(
        ACTIONS.DOWNLOAD,
        contractSubject(SUBJECTS.DOCUMENTS, details)
      ),
    [ability, details]
  )
  const canAcceptContract = useMemo(
    () =>
      ability.can(ACTIONS.ACCEPT, contractSubject(SUBJECTS.CONTRACT, details)),
    [ability, details]
  )

  const canAddLoPSignatories = useMemo(() => {
    return (
      isAutoGeneratedContract &&
      ability.can(
        ACTIONS.SUBMIT,
        contractSubject(SUBJECTS.LOP_SIGNATORY, details)
      )
    )
  }, [isAutoGeneratedContract, details, ability])

  const canSeeLoPSignatories = useMemo(() => {
    return (
      isAutoGeneratedContract &&
      ability.can(
        ACTIONS.VIEW,
        contractSubject(SUBJECTS.LOP_SIGNATORY, details)
      )
    )
  }, [isAutoGeneratedContract, details, ability])

  const canViewRebatesTab = useMemo(
    () =>
      ability.can(
        ACTIONS.VIEW,
        contractSubject(SUBJECTS.REBATE_CONDITIONS, details)
      ),
    [ability, details]
  )
  const canViewItemListTab = useMemo(
    () =>
      ability.can(ACTIONS.VIEW, contractSubject(SUBJECTS.ITEM_LIST, details)),
    [ability, details]
  )
  const canAllContractInfo = useMemo(
    () =>
      ability.can(ACTIONS.VIEW, contractSubject(SUBJECTS.ALL_INFO, details)),
    [ability, details]
  )

  const canEditRebateConditions = useMemo(
    () =>
      ability.can(
        ACTIONS.EDIT,
        contractSubject(SUBJECTS.REBATE_CONDITIONS, details)
      ),
    [ability, details]
  )

  const isVendor = useMemo(() => Boolean(user.vendor), [user.vendor])

  const vendorCanSeeAddTeamMemberButton = useMemo(
    () =>
      ability.can(
        ACTIONS.ADD,
        contractSubject(SUBJECTS.VENDOR_CONTRACT_STEWARD, details)
      ),
    [ability, details]
  )

  const canDeclineContract = useMemo(
    () =>
      isVendor &&
      user.role !== ROLES.VENDOR_ANALYST &&
      user.role !== ROLES.VENDOR_LEGAL,
    [user.vendor]
  )
  const isCommunity = useMemo(() => Boolean(user.community), [user.community])
  const isCommons = useMemo(() => Boolean(user.commons), [user.commons])
  const isVCAOrVFO = useMemo(
    () =>
      Boolean(
        user.role === ROLES.VENDOR_CONTRACT_ADMIN ||
          user.role === ROLES.VENDOR_FINANCE_OFFICER
      ),
    [user.role]
  )
  const isVCS = useMemo(
    () => Boolean(user.role === ROLES.VENDOR_CONTRACT_STEWARD),
    [user.role]
  )

  const expiredAt = useMemo(() => {
    if (
      ability.cannot(ACTIONS.VIEW, contractSubject(SUBJECTS.TIMELINES, details))
    ) {
      return
    }

    const contractTimeline = details.timelines?.find(
      (timeline) => timeline.status === details.status
    )

    return contractTimeline?.expire_at || moment().toISOString()
  }, [ability, details])

  const getContractDetailsAsync = useCallback(async () => {
    if (!id) {
      throw new Error('Contract ID not provided')
    }

    try {
      dispatch(setLoading(true))

      const response = await getContractDetailsRequestAsync(id)

      if (response?.data) {
        const data: IContractDetails = {
          ...response.data,
          creator: {
            ...response.data.creator,
            health_system: response.data.creator?.health_system?.uuid || ''
          }
        }
        _setDetails(data)
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [dispatch, id])

  const getContractDocumentsAsync = useCallback(async () => {
    if (!id) {
      throw new Error('Contract ID not provided')
    }

    try {
      const response = await getContractDocumentsRequestAsync(id)

      if (response?.data?.results) {
        _setContractDocuments(response.data.results)
      }
    } catch (e) {
      console.error(e)
    }
  }, [id])

  const getPendingItemStateAsync = useCallback(async () => {
    if (!details?.uuid) return
    if (
      !ability.can(ACTIONS.UPLOAD, contractSubject(SUBJECTS.ITEM_LIST, details))
    )
      return
    try {
      const request = isVendor
        ? getContractItemsPendingVendorsTableData
        : getContractItemsPendingTableData
      const params = { ...DEFAULT_PAGE }
      if (isVendor) {
        params['pending_substatus'] = [
          ITEM_LIST_STATUS.NEWLY_ADDED,
          ITEM_LIST_STATUS.REPLACED
        ]
      }

      const response = await request({ params: params }, details.uuid)

      setIsPendingBannerVisible(!!response.data.count)
    } catch (e) {
      console.error(e)
    }
  }, [details, isVendor, setIsPendingBannerVisible, ability])

  const handleDeleteAttachmentsAsync = useCallback(
    async (uuid: string) => {
      if (!details.uuid) {
        throw new Error('Contract ID not provided')
      }

      try {
        dispatch(setLoading(true))

        await updateContractDetailsRequestAsync(details.uuid, {
          contract_attachments_ids: details.contract_attachments
            .filter((i) => i.uuid !== uuid)
            ?.map((i) => i.uuid)
        })
        setIsFilesUpdate(true)
        await getContractDetailsAsync()
      } finally {
        dispatch(setLoading(false))
      }
    },
    [
      details.uuid,
      dispatch,
      getContractDetailsAsync,
      details.contract_attachments,
      setIsFilesUpdate
    ]
  )

  const handleUploadAttachmentsAsync = useCallback(
    async (files: File[], documentType: TDocumentTypeUnion) => {
      if (!details.uuid) {
        throw new Error('Contract ID not provided')
      }

      try {
        dispatch(setLoading(true))

        const formData = new FormData()
        formData.append(documentType, files[0], files[0].name)

        await uploadContractAttachmentsRequestAsync({
          contract: details.uuid,
          file: formData.get(documentType)
        })
        setIsFilesUpdate(true)
        await getContractDetailsAsync()
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [details.uuid, dispatch, getContractDetailsAsync, setIsFilesUpdate]
  )

  const handleUploadContractDocumentsAsync = useCallback(
    async (files: File[], documentType: TDocumentTypeUnion) => {
      if (!details.uuid) {
        throw new Error('Contract ID not provided')
      }

      try {
        dispatch(setLoading(true))

        const formData = new FormData()
        formData.append(documentType, files[0], files[0].name)

        await uploadContractDocumentsRequestAsync({
          contract: details.uuid,
          file: formData.get(documentType)
        })
        setIsFilesUpdate(true)
        await getContractDocumentsAsync()
        await getContractDetailsAsync()
        notification.success({
          message: VALIDATION_MESSAGES.SM0044
        })
        if (isAutoGeneratedContract && !details?.contract_attachments?.length) {
          addSignatoryPopup.actions.open()
        }
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [
      details.uuid,
      dispatch,
      getContractDetailsAsync,
      getContractDocumentsAsync,
      setIsFilesUpdate,
      isAutoGeneratedContract,
      details?.contract_attachments
    ]
  )

  const handleAcceptContractDocumentAsync = useCallback(async () => {
    if (!details.uuid) {
      throw new Error('Contract ID not provided')
    }
    if (!contractDocuments?.length) {
      throw new Error('No documents to accept')
    }

    try {
      dispatch(setLoading(true))
      const documentId = contractDocuments[0].uuid

      await acceptContractDocumentRequestAsync({
        contractId: details.uuid,
        documentId
      })
      await getContractDocumentsAsync()
      await getContractDetailsAsync()
      acceptContractDocumentPopup.actions.close()
      notification.success({
        message: `Contract ${VALIDATION_MESSAGES.SM0009} accepted`
      })
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [contractDocuments, details.uuid, dispatch, getContractDocumentsAsync])

  const updateContractDetailsStatusAsync = useCallback(
    async (status: ContractStatusUnion) => {
      if (!id) {
        throw new Error('Contract ID not provided')
      }
      try {
        dispatch(setLoading(true))

        await updateContractDetailsRequestAsync(id, {
          status
        })
        if (status === CONTRACT_STATUS.OUT_FOR_SIGNATURE) {
          notification.success({
            message: VALIDATION_MESSAGES.SM00901
          })
        } else if (
          status === CONTRACT_STATUS.ACTIVE ||
          status === CONTRACT_STATUS.EXECUTED ||
          status === CONTRACT_STATUS.EXPIRED
        ) {
          notification.success({
            message: VALIDATION_MESSAGES.SM00902
          })
        } else {
          notification.success({
            message: `Contract ${VALIDATION_MESSAGES.SM0009} ${
              CONTRACT_STATUSES[status?.toUpperCase()]?.toLowerCase() || status
            }`
          })
        }

        await getContractDetailsAsync()
      } catch (e: any) {
        console.error(e)
        notification.error({
          message: VALIDATION_MESSAGES.SM0067
        })
      } finally {
        dispatch(setLoading(false))
      }
    },
    [id, dispatch, getContractDetailsAsync]
  )

  const extendContractDetailsAsync = useCallback(
    async (data: IRenewFinishDatePayload) => {
      if (!id) {
        throw new Error('Contract ID not provided')
      }

      try {
        dispatch(setLoading(true))

        const formData = new FormData()

        formData.append('contract', id)
        formData.append(
          'file',
          data.extensionDocument.file,
          data.extensionDocument.file.name
        )
        formData.append(
          'finish_date',
          convertDateToRequestFormat(data.newFinishDate)
        )

        await extendContractDetailsRequestAsync(formData)

        await getContractDetailsAsync()

        if (renewFinishDatePopup.state.visible) {
          renewFinishDatePopup.actions.close()
        }

        extendContractPopup.actions.close()
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [
      id,
      dispatch,
      getContractDetailsAsync,
      renewFinishDatePopup.state.visible,
      renewFinishDatePopup.actions,
      extendContractPopup.actions
    ]
  )

  const createContractAsync = useCallback(
    async (details: Partial<ManualContractCreationRequestData>) => {
      try {
        dispatch(setLoading(true))

        const response = await createContractRequestAsync({
          ...details,
          creator: details.creator ?? user.uuid
        })

        return response?.data?.uuid
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [dispatch, user.uuid]
  )

  const updateContractDetailsAsync = useCallback(
    async (details: Partial<IContractDetails>) => {
      if (!id) {
        throw new Error('Contract ID not provided')
      }

      try {
        dispatch(setLoading(true))

        await updateContractDetailsRequestAsync(id, details)
        notification.success({
          message: VALIDATION_MESSAGES.CONTRACT_HAS_BEEN_SAVED
        })

        await getContractDetailsAsync()
      } catch (e: any) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [id, dispatch, getContractDetailsAsync]
  )

  const setupContractDetailsTimeAsync = useCallback(
    async (timelines: ITimeline<typeof CONTRACT_STATUS>[]) => {
      if (!id) {
        throw new Error('Contract ID not provided')
      }

      const requestData = timelinesRequestDataConverter(timelines)

      try {
        dispatch(setLoading(true))

        await setupContractDetailsTimeRequestAsync(id, requestData)

        await getContractDetailsAsync()

        contractStepsTimePopup.actions.close()
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [id, dispatch, getContractDetailsAsync, contractStepsTimePopup.actions]
  )

  const updateContractRebatesAsync = useCallback(
    async (formData: RebateDataRequest, successCallback?: Function) => {
      if (!id) {
        throw new Error('Contract ID not provided')
      }

      try {
        dispatch(setLoading(true))

        await updateContractRebateRequestAsync(id, formData)

        await getContractDetailsAsync()

        notification.success({ message: VALIDATION_MESSAGES.SM0047 })
        if (successCallback) {
          successCallback()
        }
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [id, dispatch, getContractDetailsAsync]
  )

  const uploadHSAmendmentDocumentAsync = useCallback(
    async (file: File) => {
      if (!id) {
        throw new Error('Contract ID not provided')
      }

      try {
        dispatch(setLoading(true))

        const formData = new FormData()

        formData.append('file', file, file.name)

        const response = await uploadHSAmendmentDocumentRequestAsync(formData)

        return response.data
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [id, dispatch]
  )

  const _getRequiredCreationData = useCallback(() => {
    if (!canAllContractInfo && !canEditContract && !isCreation) return
    Promise.all([fetchPaymentTerms(), fetchAdminPaymentFrequency()]).then(
      ([paymentTerms, adminPaymentFrequency]) => {
        if (paymentTerms?.data?.results) {
          setPaymentTermsOptions(
            paymentTerms.data.results.map(formatOptionIdValue)
          )
        }
        if (adminPaymentFrequency?.data?.results) {
          setAdminPaymentFrequencyOptions(
            adminPaymentFrequency.data.results.map(formatOptionIdValue)
          )
        }
      }
    )
  }, [canAllContractInfo, canEditContract, isCreation])

  useEffect(() => {
    _getRequiredCreationData()
  }, [])

  const addAmendmentDocumentAsync = useCallback(
    async (hsId: string, hsAmendmentPayload: IAddHSAmendmentPayload) => {
      if (!id) {
        throw new Error('Contract ID not provided')
      }
      if (!hsId) {
        throw new Error('Health System ID not provided')
      }
      try {
        dispatch(setLoading(true))
        let requestData: HSAmendmentRequestData = {
          file: hsAmendmentPayload.file?.uuid || '',
          pricing_effective_date: hsAmendmentPayload.priceEffectiveDate
        }
        if (
          hsAmendmentPayload.vendor_signatory &&
          hsAmendmentPayload.hs_signatory
        ) {
          requestData = {
            ...requestData,
            vendor_signatory: hsAmendmentPayload.vendor_signatory,
            community_signatory: hsAmendmentPayload.hs_signatory
          }
        }
        await addHSAmendmentRequestAsync(id, hsId, requestData)
        notification.success({
          message: 'Letter of participation is uploaded'
        })
      } finally {
        dispatch(setLoading(false))
      }
    },
    [id, dispatch]
  )

  const addSignatory = useCallback(
    async (values: TAddSignatoryForm) => {
      if (contractDocuments) {
        try {
          dispatch(setLoading(true))

          if (values.phone_number)
            values.phone_number =
              formatPhoneNumberToRequest(values.phone_number) ?? undefined

          const response = await addSignatoryAction(id, {
            ...values,
            document: contractDocuments[0]?.uuid
          })

          if (response?.data) {
            addSignatoryPopup.actions.close()
            await getContractDocumentsAsync()
          }
        } catch (e) {
          console.error(e)
        } finally {
          dispatch(setLoading(false))
        }
      }
    },
    [id, contractDocuments]
  )

  const _adjustTabsAccessibility = useCallback(() => {
    tabsActions.setTabs(
      CONTRACT_DETAILS_TABS.map((i) => {
        const inCreationDisabled =
          i.key !== contractDetailsRoute.general && isCreation
        const isHSListDanger =
          i.key === contractDetailsRoute.hsList &&
          (query.type === 'health_system' ||
            details.creation_type === 'health_system')

        const isItemListDisabled =
          !canViewItemListTab && i.key === contractDetailsRoute.itemList
        const isRebatesDisabled =
          !canViewRebatesTab && i.key === contractDetailsRoute.rebateConditions

        return {
          ...i,
          disabled: inCreationDisabled,
          danger: isHSListDanger || isItemListDisabled || isRebatesDisabled
        }
      })
    )
  }, [
    isCreation,
    tabsActions,
    query,
    details,
    canViewItemListTab,
    canViewRebatesTab
  ])

  useEffect(() => {
    getPendingItemStateAsync()
  }, [details.uuid])

  const isAllResponsibleUsersAdded = useMemo(
    () =>
      isVendor
        ? Boolean(
            details.vendor_responsibles.find(
              (u) => u.role === ROLES.VENDOR_ANALYST
            ) &&
              details.vendor_responsibles.find(
                (u) => u.role === ROLES.VENDOR_LEGAL
              )
          )
        : isCommons
        ? Boolean(
            details.commons_responsibles.find(
              (u) => u.role === ROLES.COMMONS_ANALYST
            ) &&
              details.commons_responsibles.find(
                (u) => u.role === ROLES.COMMONS_LEGAL
              )
          )
        : Boolean(
            details.community_responsibles.find(
              (u) => u.role === ROLES.ANALYST
            ) &&
              details.community_responsibles.find((u) => u.role === ROLES.LEGAL)
          ),
    [
      isVendor,
      isCommons,
      details.community_responsibles,
      details.vendor_responsibles,
      details.commons_responsibles
    ]
  )

  useLayoutEffect(() => {
    _adjustTabsAccessibility()
  }, [_adjustTabsAccessibility])

  useEffect(() => {
    if (!isCreation) {
      getContractDetailsAsync()
      getContractDocumentsAsync()
    }
  }, [getContractDetailsAsync, isCreation])

  useEffect(() => {
    setId(prepareRouteId(routeId))
  }, [routeId])

  const context = useMemo(
    () => ({
      state: {
        details,
        contractDocuments,
        canEditContract,
        canManageFacilityUsers,
        canEditRebateConditions,
        isVendor,
        isCreation,
        expiredAt,
        isVCAOrVFO,
        isVCS,
        ...tabsState,
        isAllResponsibleUsersAdded,
        isCommunity,
        isCommons,
        canDeclineContract,
        canAllContractInfo,
        isOnboarded,
        isFilesUpdate,
        isPendingBannerVisible,
        paymentTermsOptions,
        adminPaymentFrequencyOptions,
        isAutoGeneratedContract,
        vendorCanSeeAddTeamMemberButton,
        canAddSignatory,
        canViewSignatory,
        canUploadInitialDocument,
        canUploadNewDocument,
        canUploadAttachments,
        canDownloadTemplates,
        canDownloadDocuments,
        canAcceptContract,
        canSeeLoPSignatories,
        canAddLoPSignatories
      },
      actions: {
        ...tabsActions,
        isVCAOrVFO,
        handleDeleteAttachmentsAsync,
        handleUploadAttachmentsAsync,
        handleUploadContractDocumentsAsync,
        handleAcceptContractDocumentAsync,
        getContractDetailsAsync,
        updateContractDetailsStatusAsync,
        createContractAsync,
        updateContractDetailsAsync,
        setupContractDetailsTimeAsync,
        extendContractDetailsAsync,
        updateContractRebatesAsync,
        uploadHSAmendmentDocumentAsync,
        addAmendmentDocumentAsync,
        setIsFilesUpdate,
        getPendingItemStateAsync,
        addSignatory
      }
    }),
    [
      details,
      contractDocuments,
      isCommunity,
      canEditContract,
      canManageFacilityUsers,
      canEditRebateConditions,
      isVendor,
      isCreation,
      expiredAt,
      tabsState,
      tabsActions,
      handleDeleteAttachmentsAsync,
      handleUploadAttachmentsAsync,
      handleUploadContractDocumentsAsync,
      handleAcceptContractDocumentAsync,
      getContractDetailsAsync,
      updateContractDetailsStatusAsync,
      createContractAsync,
      updateContractDetailsAsync,
      setupContractDetailsTimeAsync,
      extendContractDetailsAsync,
      updateContractRebatesAsync,
      uploadHSAmendmentDocumentAsync,
      addAmendmentDocumentAsync,
      isAllResponsibleUsersAdded,
      isVCS,
      canDeclineContract,
      isCommons,
      canAllContractInfo,
      isOnboarded,
      isFilesUpdate,
      setIsFilesUpdate,
      isPendingBannerVisible,
      getPendingItemStateAsync,
      paymentTermsOptions,
      adminPaymentFrequencyOptions,
      isAutoGeneratedContract,
      vendorCanSeeAddTeamMemberButton,
      addSignatory,
      canAddSignatory,
      canViewSignatory,
      canUploadInitialDocument,
      canUploadNewDocument,
      canUploadAttachments,
      canDownloadTemplates,
      canDownloadDocuments,
      canAcceptContract,
      canSeeLoPSignatories,
      canAddLoPSignatories
    ]
  )

  return (
    <ContractDetailsContext.Provider value={context}>
      {children}
    </ContractDetailsContext.Provider>
  )
}

export const useContractDetailsContext = () =>
  useContext(ContractDetailsContext)

export default ContractDetailsContextProvider
