import RadioGroup from '../../components/RadioGroup/RadioGroup'
import useRouter from '../../hooks/useRouter'
import { useEffect, useState } from 'react'
import { safeString } from '../../helper/common'
import { routes } from '../../router'
import { useNavigate } from 'react-router'
import { CONTRACTS_PAGE_OPTIONS } from './routesConstants'
import { ContractsTable } from './ContactsTable'
import CategoriesTable from '../Categories/CategoriesTable'
import { useContractPageContext } from '../../pages/Contracts/Providers/ContractPageContextProvider'

const ContractsPage = () => {
  const navigate = useNavigate()
  const { query, push, location } = useRouter()
  const { state } = useContractPageContext()
  const { isCanSeeCategories, isCanSeeShortCategoriesOnly } = state
  const [activeSubTab, setActiveSubTab] = useState(
    query?.subtab || CONTRACTS_PAGE_OPTIONS[0].value
  )
  const handleTabs = (e) => {
    setActiveSubTab(e)
    push(e)
  }
  useEffect(() => {
    if (location?.pathname?.includes(CONTRACTS_PAGE_OPTIONS[0].value)) {
      setActiveSubTab(CONTRACTS_PAGE_OPTIONS[0].value)
    } else if (location?.pathname?.includes(CONTRACTS_PAGE_OPTIONS[1].value)) {
      setActiveSubTab(CONTRACTS_PAGE_OPTIONS[1].value)
    }
  }, [])
  useEffect(() => {
    if (query?.subtab) {
      setActiveSubTab(safeString(query?.subtab))
      navigate(routes.contracts + routes.cognusRepository, {
        replace: true
      })
    }
  }, [query?.subtab])
  return (
    <div className="contracts-page-wrapper">
      {isCanSeeCategories && (
        <div className="mb-24">
          <RadioGroup.Buttons
            options={CONTRACTS_PAGE_OPTIONS}
            defaultValue={activeSubTab}
            onChange={handleTabs}
          />
        </div>
      )}
      {!activeSubTab && <ContractsTable />}
      {activeSubTab === CONTRACTS_PAGE_OPTIONS[0].value && <ContractsTable />}
      {activeSubTab === CONTRACTS_PAGE_OPTIONS[1].value &&
        isCanSeeCategories && (
          <CategoriesTable shortView={isCanSeeShortCategoriesOnly} />
        )}
    </div>
  )
}

export default ContractsPage
