import { Dispatch, SetStateAction } from 'react'
import { SortOrder } from 'antd/lib/table/interface'
import { AxiosResponse, CancelToken } from 'axios'
import { TPageInfo, TQueryParams } from 'components/Table/types'
import { IUser } from '../../redux/store/user/types'
import { RangeValue } from '../../pages/Compliance/types'

export type TFinancialsList = {
  start_period: string
  amount: string
  status: string
  type: string
  facility_name: string
  contract_id: string
  contract_number: string
  vendor_id: string
  vendor_name: string
  uuid: string
  calculation_report_files: [file: any]
}

export type TFinancialsListFilters = {
  facilities?: string[] | string
  vendors?: string[] | string
  date_from?: string
  date_to?: string
  statuses?: string[] | string
  status?: string[] | string
}

export type TFinancialsListParams = TFinancialsListFilters & TQueryParams

export type TFinancialsTableRequestData = {
  sortField?: keyof TFinancialsList
  sortOrder?: SortOrder
  filters?: TFinancialsListFilters
  page?: TPageInfo
}

export type TFinancialsListResponse = {
  count: number
  next?: string
  previous?: string
  results: TFinancialsList[]
}

export type TFinancialsListFiltersFunc = (
  field: keyof TFinancialsListFilters,
  filters: string[]
) => void

export type TFinancialsListRequest = (data: {
  params?: TFinancialsListParams
  cancelToken?: CancelToken
}) => Promise<AxiosResponse<TFinancialsListResponse>>

export type TPopup = {
  declinePopup?: boolean
  acceptPopup?: boolean
  resendPopup?: boolean
}

export type TVendors = {
  count: number
  results: TVendorsOptions[]
}

export type TVendorsOptions = {
  uuid: string
  name: string
}

export type TFinancialsColumns = {
  onResendHandler: (id: string) => void
  isCanResend: boolean
  isVendor: boolean
  applyFilters: any
  filters: any
  user: IUser
}

export type TFinancialsTable = {
  period: RangeValue
  isVendor: boolean
  isUpdate: boolean
  setIsUpdate: Dispatch<SetStateAction<boolean>>
  getChartsData: () => void
}

export type TFacilities = {
  hospitals: TVendorsOptions[]
  communities: TVendorsOptions[]
  health_systems: TVendorsOptions[]
}

export type TDeclineValue = {
  decline_reason: string
}

export type TGetLinkPayment = {
  facility_type: string
  facility_id: string
}

export enum DueDateStatuses {
  DUE_SOON = 'due_soon',
  OVERDUE = 'overdue'
}

export enum FINANCIAL_STATUS {
  PAYMENT_IN_PROGRESS = 'payment_in_progress',
  PAID = 'paid',
  PAYMENT_FAILED = 'payment_failed',
  PAST_DUE = 'past_due',
  DISPUTED = 'disputed',
  WAITING_FOR_RESPONSE = 'waiting_for_response'
}

export type PaymentStatus = Omit<PaymentStatusData, 'percentage'> & {
  percentage: string
}

export type SelectedRowData = {
  uuid: string
  supplier: string
  health_system: string
  total: number
}

export enum PAYMENT_TYPES {
  ADMIN_FEE = 'Admin Fee',
  REBATES = 'Rebates',
  COGNUS_AR = 'Cognus AR'
}

export type PaymentStatusData = {
  amount: number
  percentage: number
  status: string
}

export enum TRANSFER_STATUSES {
  COMPLETED = 'completed',
  CREATED = 'created',
  FAILED = 'failed',
  PENDING = 'pending',
  SETTLED = 'settled'
}

export enum DESTINATION_TYPES {
  HEALTH_SYSTEM = 'health_system',
  COMMONS = 'commons'
}

export type TransferData = {
  amount: string
  currency: string
  destination_type: DESTINATION_TYPES
  status: TRANSFER_STATUSES
  uuid: string
}
