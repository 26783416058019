import '../../styles.scss'
import { Select } from '../../../../components/Select'
import { PLACEHOLDERS } from '../../../../constants'
import { AddAdditionalOption } from './AddAdditionalOption'
import { ReactElement, useEffect, useState } from 'react'
import { SelectWithAdditionalOptionProps } from './types'

const SelectWithAdditionalOption = ({
  onChangeValue,
  options,
  setOptions,
  value,
  otherType,
  propsItem,
  propsSelect = {},
  className,
  additionalItemInputProps
}: SelectWithAdditionalOptionProps): ReactElement => {
  const [otherLabel, setOtherLabel] = useState<string>('')

  useEffect(() => {
    setOtherLabel(otherType)
  }, [otherType])

  useEffect(() => {
    if (value && options) {
      onChangeValue(
        'other',
        options.find((type) => type.value === 'other')?.label
      )
    }
  }, [options])
  return (
    <Select
      className={`calendar__modal__type-of-event-select ${className ?? ''}`}
      options={options}
      propsItem={{
        ...propsItem,
        rules: [{ required: true, ...(propsItem.rules || {}) }]
      }}
      propsSelect={{
        placeholder: PLACEHOLDERS.PLEASE_SELECT,
        onChange: (v) => {
          const newValue =
            options.find((o) => o.value === v)?.value.toString() ?? null
          onChangeValue(newValue, otherLabel)
        },
        dropdownRender: (originNode) => {
          return (
            <div className="calendar__modal__type-of-event-select__rendered-options">
              {originNode}
              <AddAdditionalOption
                additionalItemInputProps={additionalItemInputProps}
                onChangeOtherType={setOtherLabel}
                onChangeSelectedType={(v) => {
                  if (v.length) {
                    setOptions(
                      options.map((sO) => {
                        if (sO.value === 'other')
                          return {
                            ...sO,
                            label: v
                          }
                        return sO
                      })
                    )
                  }
                }}
                otherType={otherLabel}
              />
            </div>
          )
        },
        ...propsSelect
      }}
    />
  )
}

export default SelectWithAdditionalOption
