import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Typography, TYPOGRAPHY_WEIGHT } from '../../../components/Typography'
import { Button } from '../../../components/Button'
import { BankAccountError } from '../BankAccountError/BankAccountError'
import { ConfirmPaymentPopup } from './ConfirmPaymentPopup/ConfirmPaymentPopup'
import {
  Can,
  useFinancialsAbility
} from '../Providers/FinancialsAbilityProvider'
import { getUser } from '../../../redux/store/user/getters'
import { useFinancialsContext } from '../Providers/FinancialsProvider'
import { ACTIONS, SUBJECTS } from '../Abilities'
import { BTN_TXT } from '../../../constants'
import { PaymentActionsProps } from './types'
import { PAYMENT_TYPES } from '../types'

export const PaymentActions = ({
  type,
  selectedLength,
  selectedRowData,
  pageInfo,
  totalNumber,
  onResetSelection,
  updateTableData
}: PaymentActionsProps) => {
  const {
    state: { hasBankAccount }
  } = useFinancialsContext()
  const user = useSelector(getUser)
  const ability = useFinancialsAbility()
  const showBankAccountError = useMemo(
    () =>
      !hasBankAccount &&
      ability.can(ACTIONS.ADD, SUBJECTS.BUSINESS_RESOURCE) &&
      ((type === PAYMENT_TYPES.REBATES && !!user.vendor) ||
        (type === PAYMENT_TYPES.COGNUS_AR &&
          (!!user.vendor || !!user.community))),
    [hasBankAccount, type, user.community, user.vendor]
  )

  return (
    <div className="column">
      {showBankAccountError && <BankAccountError />}
      {!!selectedLength && (
        <div className="table-wrapper__header row space-between align-center">
          <div className="column">
            <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}>
              Selected {selectedLength} of {totalNumber}
            </Typography.Body1>
            <Typography.Caption className="page-number">
              Page {pageInfo.pageNumber} of{' '}
              {Math.ceil(totalNumber / pageInfo.pageSize)}
            </Typography.Caption>
          </div>
          <Can I={ACTIONS.INITIATE} a={SUBJECTS.PAYMENT}>
            <div className="table-wrapper__header_buttons">
              {selectedLength && (
                <div className="row gap-8">
                  <Button onClick={onResetSelection} upperCase>
                    {BTN_TXT.CANCEL}
                  </Button>
                  <ConfirmPaymentPopup
                    selectedRowData={selectedRowData}
                    updateTableData={updateTableData}
                    selectedLength={selectedLength}
                    onResetSelection={onResetSelection}
                    showBankAccountError={showBankAccountError}
                    type={type}
                  />
                </div>
              )}
            </div>
          </Can>
        </div>
      )}
    </div>
  )
}
