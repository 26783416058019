import moment from 'moment'
import cn from 'classnames'
import { ColumnsType } from 'antd/es/table'
import { Tooltip } from '../../../../components/Tooltip/Tooltip'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import {
  FINANCIAL_STATUS_TYPES_TO_CLASSNAME,
  Status
} from '../../../../components/Status'
import { currencyFormatter } from '../../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { FINANCIAL_STATUS_LABEL } from '../../constants'
import { timelineDateFormat } from '../../../../components/Timelines/constants'
import { ReactComponent as RemitAdvice16 } from 'assets/svg/RemitAdvice16.svg'
import { ReactComponent as QuarterlyReport16 } from 'assets/svg/QuarterlyReport16.svg'
import { CognusARData, CognusARTableColumnsFunc } from './types'
import { DueDateStatuses } from '../../types'

export const columns = ({
  isVendor,
  hideHSName,
  hideInvoices,
  isCommons,
  generateAndDownloadCognusARDocument
}: CognusARTableColumnsFunc): ColumnsType<CognusARData> => {
  const cols: ColumnsType<CognusARData> = []
  if (!isVendor) {
    cols.push({
      dataIndex: 'supplier',
      key: 'supplier',
      width: 250,
      title: 'Supplier',
      sorter: false,
      showSorterTooltip: false
    })
  }
  if (!hideHSName) {
    cols.push({
      dataIndex: 'health_system',
      key: 'health_system',
      width: 250,
      title: 'HS name',
      sorter: false,
      showSorterTooltip: false
    })
  }

  return cols.concat([
    {
      dataIndex: 'period_ending',
      key: 'period_ending',
      width: 158,
      title: 'Period ending',
      render: (_, { period_ending }) => (
        <span className="separate-item">
          {period_ending
            ? moment(period_ending).format(timelineDateFormat)
            : '-'}
        </span>
      ),
      sorter: false,
      showSorterTooltip: false
    },
    {
      dataIndex: 'due_date',
      key: 'due_date',
      width: 158,
      title: 'Due date',
      render: (_, { due_date, due_date_status }) => (
        <div
          className={cn('td-custom-class', {
            'yellow-cells': due_date_status === DueDateStatuses.DUE_SOON,
            'red-cells': due_date_status === DueDateStatuses.OVERDUE
          })}
        >
          {due_date ? moment(due_date).format(timelineDateFormat) : '-'}
        </div>
      ),
      sorter: false,
      showSorterTooltip: false
    },
    {
      dataIndex: 'invoices',
      key: 'invoices',
      title: 'Invoices',
      width: 220,
      render: (_, { invoice_count, uuid }) => (
        <div className="row space-between align-center">
          <div className="row align-center gap-4">
            Invoices ({invoice_count})
            {!hideInvoices && (
              <Tooltip title="Download the quarterly rebate report">
                <Button.Icon
                  icon={<QuarterlyReport16 />}
                  type={BUTTON_TYPES.DEFAULT}
                />
              </Tooltip>
            )}
            <Tooltip
              title={`Download the ${
                isCommons ? 'remit advice' : 'invoice'
              } document`}
            >
              <Button.Icon
                icon={<RemitAdvice16 />}
                type={BUTTON_TYPES.DEFAULT}
                disabled={!invoice_count}
                onClick={() => generateAndDownloadCognusARDocument(uuid)}
              />
            </Tooltip>
          </div>
        </div>
      ),
      sorter: false,
      showSorterTooltip: false
    },
    {
      dataIndex: 'total',
      key: 'total',
      title: 'Total, $',
      width: 145,
      render: (_, { total }) => currencyFormatter(total),
      sorter: false,
      showSorterTooltip: false
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: 'Status',
      width: 145,
      render: (_, { status }) => (
        <Status.Default className={FINANCIAL_STATUS_TYPES_TO_CLASSNAME[status]}>
          {FINANCIAL_STATUS_LABEL[status]}
        </Status.Default>
      ),
      sorter: false,
      showSorterTooltip: false
    }
  ])
}
