import { FINANCIAL_STATUS_FILTER } from '../constants'
import { PaymentStatus } from '../types'

export const getFinancialStatusLegendOrder = (data: PaymentStatus[]) =>
  data.sort((a, b) => {
    const indexA =
      FINANCIAL_STATUS_FILTER?.find((i) => a.status === i.value)?.index || 0
    const indexB =
      FINANCIAL_STATUS_FILTER?.find((i) => b.status === i.value)?.index || 0
    if (indexA < indexB) return -1
    else return 1
  })
