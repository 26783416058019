import { Typography } from '../../../../components/Typography'
import { currencyFormatter } from '../../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { StatusDataProps } from './types'
import {
  READABLE_TRANSFER_STATUS,
  READABLE_DESTINATION_TYPE
} from '../constants'

export const StatusData = ({
  destination_type,
  status,
  amount
}: StatusDataProps) => (
  <Typography.Body2>
    {READABLE_DESTINATION_TYPE[destination_type]}: ${currencyFormatter(amount)}{' '}
    {READABLE_TRANSFER_STATUS[status]}.
  </Typography.Body2>
)
