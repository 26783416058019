import { Button, BUTTON_TYPES } from '../../../components/Button'
import { ReactComponent as Redirect16 } from 'assets/svg/Redirect16.svg'
import { BTN_TXT } from '../../../constants'
import { TPropsLinkAccount } from '../types'
import '../style.scss'
import { ReactComponent as Plus16 } from '../../../assets/svg/Plus16.svg'
import { ability } from 'features/Permission/ability'
import { ACTIONS, SUBJECTS } from '../../Permission'
import { routes } from '../../../router'

const LinkAccount = ({ uuid, business_resource, hsId }: TPropsLinkAccount) => {
  const canAddAccount = ability.can(ACTIONS.CREATE, SUBJECTS.PAYMENT_DETAILS)
  const showAddAccountButton =
    canAddAccount && !business_resource?.bank_accounts?.length
  const isBankAccountAdded = business_resource?.bank_accounts?.length

  const handleBankAccount = () => {
    if (showAddAccountButton) {
      if (business_resource?.uuid) {
        window.open(
          `${routes.hospitals}/${uuid}${routes.paymentDetails}?tab=bankAccount&hsId=${hsId}`,
          '_blank'
        )
      } else {
        window.open(
          `${routes.hospitals}/${uuid}${routes.paymentDetails}?hsId=${hsId}`,
          '_blank'
        )
      }
    } else if (business_resource?.bank_accounts?.length) {
      window.open(
        `${routes.hospitals}/${uuid}${routes.paymentDetails}?tab=bankAccount&hsId=${hsId}`,
        '_blank'
      )
    }
  }

  return (
    <div className="row link-bank-account">
      {isBankAccountAdded || showAddAccountButton ? (
        <Button
          type={BUTTON_TYPES.GHOST}
          onClick={handleBankAccount}
          className="link-bank-account__btn"
          icon={showAddAccountButton && <Plus16 />}
          upperCase={showAddAccountButton}
        >
          <div className="row">
            {showAddAccountButton ? (
              <>{BTN_TXT.ADD_ACCOUNT}</>
            ) : (
              <>
                {BTN_TXT.LINKED_ACCOUNT} &nbsp;
                <Redirect16 />
              </>
            )}
          </div>
        </Button>
      ) : (
        '-'
      )}
    </div>
  )
}

export default LinkAccount
