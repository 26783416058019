import {
  FC,
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  Key
} from 'react'

import usePopup, {
  PopupReturnType,
  PopupReturnTypeWithPayload
} from 'hooks/usePopup'
import { DeleteResponseUserPayload } from 'features/ContractDetails/ContractDetailsGeneral/ResponsibleUsers/DeleteUserPopup/types'
import { IRenewFinishDatePayload } from 'features/ContractDetails/ExtendContract/types'
import { ManualContractCreationRequestData } from 'features/ContractDetails/types'

type ContextProps = {
  declineContractPopup: PopupReturnType
  terminateContractPopup: PopupReturnType
  deleteResponsibleUserPopup: PopupReturnTypeWithPayload<DeleteResponseUserPayload>
  extendContractPopup: PopupReturnType
  renewFinishDatePopup: PopupReturnTypeWithPayload<IRenewFinishDatePayload>
  contractStepsTimePopup: PopupReturnType
  addAmendmentPopup: PopupReturnTypeWithPayload<{
    selectedHSRowKey: Key
  }>
  assignResponsiblePopup: PopupReturnTypeWithPayload<{
    creationDetails: Partial<ManualContractCreationRequestData>
  }>
  addResponsibleUserPopup: PopupReturnType
  deleteFacilityUsersPopup: PopupReturnType
  sendForApprovalPopup: PopupReturnType
  acceptContractDocumentPopup: PopupReturnType
  addFacilityUsersPopup: PopupReturnType
  updateItemListPopup: PopupReturnType
  compareRecordsPopup: PopupReturnType
  reconcileDuplicatesPopup: PopupReturnType
  approveItemListPopup: PopupReturnType
  declineItemListPopup: PopupReturnType
  addSignatoryPopup: PopupReturnType
}

const basePopup = (title: string) => ({
  title
})

const ContractDetailsPopupContext = createContext<ContextProps>({
  declineContractPopup: null!,
  terminateContractPopup: null!,
  deleteResponsibleUserPopup: null!,
  extendContractPopup: null!,
  renewFinishDatePopup: null!,
  contractStepsTimePopup: null!,
  addAmendmentPopup: null!,
  assignResponsiblePopup: null!,
  addResponsibleUserPopup: null!,
  deleteFacilityUsersPopup: null!,
  sendForApprovalPopup: null!,
  acceptContractDocumentPopup: null!,
  addFacilityUsersPopup: null!,
  compareRecordsPopup: null!,
  updateItemListPopup: null!,
  reconcileDuplicatesPopup: null!,
  approveItemListPopup: null!,
  declineItemListPopup: null!,
  addSignatoryPopup: null!
})

const ContractDetailsPopupProvider: FC<PropsWithChildren> = ({ children }) => {
  const terminateContractPopupProps = usePopup(basePopup('Terminate'))
  const declineContractPopupProps = usePopup(basePopup('Decline'))
  const deleteResponsibleUserPopupProps = usePopup<DeleteResponseUserPayload>(
    basePopup('Delete team member?')
  )
  const extendContractPopupProps = usePopup(basePopup('Extend'))
  const renewFinishDatePopupProps = usePopup<IRenewFinishDatePayload>(
    basePopup('New finish dates are overlapped. Renew finish date anyway?')
  )
  const contractStepsTimePopupProps = usePopup(basePopup('Contract steps time'))
  const addAmendmentPopupProps = usePopup<{
    selectedHSRowKey: Key
  }>(basePopup('Add LOP'))
  const addResponsibleUserPopupProps = usePopup(
    basePopup('Add team member to the contract')
  )
  const deleteFacilityUsersPopupProps = usePopup(basePopup('Delete user'))
  const addFacilityUsersPopupProps = usePopup(basePopup('Add users'))
  const updateItemListPopupProps = usePopup(basePopup('Update price file'))
  const assignResponsiblePopupProps = usePopup<{
    creationDetails: Partial<ManualContractCreationRequestData>
  }>(basePopup('Assign responsible for the contract'))
  const sendForApprovalPopupProps = usePopup(basePopup('Send for approval?'))
  const acceptContractDocumentPopupProps = usePopup(
    basePopup('Do you want to accept the contract?')
  )
  const compareRecordsPopupProps = usePopup(basePopup('Compare records'))
  const declineItemListPopupProps = usePopup(basePopup('Decline'))
  const approveItemListPopupProps = usePopup(basePopup('Approve'))
  const reconcileDuplicatesPopupProps = usePopup(
    basePopup('Reconcile duplicate records')
  )
  const addSignatoryPopupProps = usePopup(basePopup('Add a Signatory'))

  const context = useMemo(
    () => ({
      declineContractPopup: declineContractPopupProps,
      terminateContractPopup: terminateContractPopupProps,
      deleteResponsibleUserPopup: deleteResponsibleUserPopupProps,
      extendContractPopup: extendContractPopupProps,
      renewFinishDatePopup: renewFinishDatePopupProps,
      contractStepsTimePopup: contractStepsTimePopupProps,
      addAmendmentPopup: addAmendmentPopupProps,
      assignResponsiblePopup: assignResponsiblePopupProps,
      addResponsibleUserPopup: addResponsibleUserPopupProps,
      deleteFacilityUsersPopup: deleteFacilityUsersPopupProps,
      addFacilityUsersPopup: addFacilityUsersPopupProps,
      updateItemListPopup: updateItemListPopupProps,
      compareRecordsPopup: compareRecordsPopupProps,
      sendForApprovalPopup: sendForApprovalPopupProps,
      acceptContractDocumentPopup: acceptContractDocumentPopupProps,
      reconcileDuplicatesPopup: reconcileDuplicatesPopupProps,
      declineItemListPopup: declineItemListPopupProps,
      approveItemListPopup: approveItemListPopupProps,
      addSignatoryPopup: addSignatoryPopupProps
    }),
    [
      deleteResponsibleUserPopupProps,
      terminateContractPopupProps,
      extendContractPopupProps,
      renewFinishDatePopupProps,
      contractStepsTimePopupProps,
      addAmendmentPopupProps,
      assignResponsiblePopupProps,
      declineContractPopupProps,
      addResponsibleUserPopupProps,
      deleteFacilityUsersPopupProps,
      sendForApprovalPopupProps,
      acceptContractDocumentPopupProps,
      addFacilityUsersPopupProps,
      compareRecordsPopupProps,
      updateItemListPopupProps,
      reconcileDuplicatesPopupProps,
      declineItemListPopupProps,
      approveItemListPopupProps,
      addSignatoryPopupProps
    ]
  )

  return (
    <ContractDetailsPopupContext.Provider value={context}>
      {children}
    </ContractDetailsPopupContext.Provider>
  )
}

export const useContractDetailsPopup = () =>
  useContext(ContractDetailsPopupContext)

export default ContractDetailsPopupProvider
