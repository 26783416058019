import { FC, useCallback, useMemo } from 'react'
import { Form } from 'antd'

import './styles.scss'

import Timelines from 'components/Timelines/Timelines'
import useTimelines from 'components/Timelines/hooks/useTimelines'
import {
  getTimelinesBySettings,
  getTimeOptionsStartFrom
} from 'components/Timelines/utils'
import { systemContractTimelinesSettings } from 'features/ContractDetails/ContractDetailsTimelines/constants'
import { CONTRACT_STATUS } from 'features/ContractDetails/constants'
import { Props } from './types'

const timeOptions = getTimeOptionsStartFrom('00:00 AM')

const ContractDetailsTimelines: FC<Props> = ({
  onFinish,
  ...useTimelinesProps
}) => {
  const systemTimelines = useMemo(
    () => getTimelinesBySettings(systemContractTimelinesSettings),
    []
  )

  const { state, actions } = useTimelines<typeof CONTRACT_STATUS>({
    ...useTimelinesProps,
    systemTimelines
  })

  const { getHasValidationError, getDisabledByKey } = actions

  const handleFormSubmit = useCallback(() => {
    if (!getHasValidationError()) {
      const onlyAvailableTimelines = state.filter(
        (timeline) => !getDisabledByKey(timeline.key)
      )

      onFinish(onlyAvailableTimelines)
    }
  }, [getDisabledByKey, getHasValidationError, onFinish, state])

  return (
    <Form
      id="contract-timeline-form-id"
      className="contract-timeline-form"
      onFinish={handleFormSubmit}
    >
      <Timelines<typeof CONTRACT_STATUS>
        timelines={state}
        timeOptions={timeOptions}
        {...actions}
      />
    </Form>
  )
}

export default ContractDetailsTimelines
