import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from 'react'
import { notification, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { ReactComponent as SuccessIcon } from '../../assets/svg/SuccessIcon.svg'
import { ReactComponent as ErrorIcon } from '../../assets/svg/ErrorIcon.svg'
import moment from 'moment'
import {
  ContextProps,
  TNotification,
  TNotificationType,
  TNotificationTypeName
} from './types'
import { Button, BUTTON_TYPES } from '../../components/Button'
import { VALIDATION_MESSAGES } from '../../constants'

const BottomNotificationsContext = createContext<ContextProps>({
  actions: null!
})

const BottomNotificationsContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const [api, contextHolder] = notification.useNotification()

  useEffect(() => {
    // get all notifications from local store on page refresh
    const allNotificationTypes = Object.keys(TNotificationTypeName)

    allNotificationTypes.forEach((notificationType) => {
      const notifications = localStorage.getItem(
        TNotificationTypeName[notificationType]
      )
      if (notifications) {
        JSON.parse(notifications).map((currentNotification) => {
          openNotification(
            currentNotification,
            TNotificationTypeName[notificationType]
          )
        })
      }
    })
  }, [])

  const getTitle = useCallback(
    (
      { type, numberOfItems, messageProps }: TNotification,
      typeName: TNotificationTypeName
    ) => {
      const errorMessage = messageProps?.specificErrorMessage?.message
      switch (typeName) {
        case TNotificationTypeName.LOADING_CONTRACTS: {
          if (type === 'info') {
            return `Creating ${numberOfItems} contract${
              numberOfItems === 1 ? '' : 's'
            }`
          } else if (type === 'success') {
            return `${numberOfItems} contract${
              numberOfItems === 1 ? '' : 's'
            } created`
          } else {
            return errorMessage ?? 'Contracts creation failed.'
          }
        }
        case TNotificationTypeName.EXPORT_REJECTED_INVOICES: {
          if (type === 'info') {
            return `Exporting ${numberOfItems} invoice line${
              numberOfItems === 1 ? '' : 's'
            }`
          } else if (type === 'success') {
            return `${numberOfItems} invoice line${
              numberOfItems === 1 ? '' : 's'
            } exported`
          } else {
            return errorMessage ?? 'Invoices export failed.'
          }
        }
        case TNotificationTypeName.DOWNLOAD_CURRENT_ITEM_LIST: {
          if (type === 'info') {
            return 'Downloading Current item list in progress'
          } else if (type === 'success') {
            return 'Current item list downloaded'
          } else {
            return errorMessage ?? 'Downloading Current item list failed.'
          }
        }
        case TNotificationTypeName.UPLOAD_ITEM_LIST: {
          if (type === 'info') {
            return 'Uploading the Item list in progress'
          } else if (type === 'success') {
            return 'Item list has been successfully uploaded'
          } else {
            return errorMessage ?? 'Item list upload failed.'
          }
        }
        default: {
          return ''
        }
      }
    },
    []
  )

  const getDescription = (notification: TNotification, typeName) => {
    const errorDescription =
      notification.messageProps?.specificErrorMessage?.message

    // for items with redirect route we have special messages & button
    if (
      notification.messageProps?.itemUuid &&
      notification.messageProps?.redirectRoute
    ) {
      if (notification.type === TNotificationType.INFO) {
        return null
      } else {
        const onBtnClick = () => {
          if (
            notification.messageProps?.itemUuid &&
            notification.messageProps?.redirectRoute
          ) {
            onCloseNotification(notification.id, typeName)
            window.location.replace(
              `${notification.messageProps?.redirectRoute}${
                errorDescription ? '?withDuplicatesModal=true' : ''
              }`
            )
          }
        }
        return (
          <div>
            {notification.type === TNotificationType.ERROR && (
              <span>{errorDescription ?? 'Please try again.'}</span>
            )}
            {notification.messageProps?.buttonText && (
              <div className="column notification-link align-end">
                <Button
                  type={BUTTON_TYPES.GHOST}
                  onClick={onBtnClick}
                  upperCase
                >
                  {notification.messageProps.buttonText}
                </Button>
              </div>
            )}
          </div>
        )
      }
    } else if (notification.type === TNotificationType.ERROR) {
      return 'Please try again.'
    }
    return null
  }

  const onCloseNotification = useCallback(
    (id: string, typeName: TNotificationTypeName) => {
      // remove notification from Local store when user closes the notification
      const notifications = localStorage.getItem(typeName)
      if (notifications) {
        const parsedNotifications = JSON.parse(notifications)
        const currentNotification = parsedNotifications.find((n) => n.id === id)

        if (currentNotification.type === TNotificationType.INFO) {
          // remove date to hide the notification
          delete currentNotification.date
          localStorage.setItem(
            typeName,
            JSON.stringify([
              ...parsedNotifications.filter((n) => n.id !== id),
              currentNotification
            ])
          )
        } else {
          localStorage.setItem(
            typeName,
            JSON.stringify(parsedNotifications.filter((n) => n.id !== id))
          )
        }
      }
    },
    []
  )

  const openNotification = useCallback(
    (
      notification: TNotification,
      typeName: TNotificationTypeName,
      setNotificationToLocalStorage?: boolean
    ) => {
      const {
        id,
        type = TNotificationType.SUCCESS,
        numberOfItems,
        date,
        messageProps
      } = notification
      // open notification and add it to the Local Storage
      const icon =
        type === TNotificationType.INFO ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
        ) : type === TNotificationType.SUCCESS ? (
          <SuccessIcon className="bottom-notification__icon" />
        ) : (
          <ErrorIcon className="bottom-notification__icon" />
        )
      const description = getDescription(notification, typeName)

      const title = getTitle(notification, typeName)

      const duration = date
        ? moment.duration(moment().diff(moment(date))).asMinutes()
        : 0

      const hideCloseButton = type === TNotificationType.INFO && duration <= 35

      if (type === TNotificationType.INFO ? !!date : true) {
        api.open({
          className: 'bottom-notification',
          message: title,
          description,
          duration: 0,
          icon,
          placement: 'bottomRight',
          onClose: () => onCloseNotification(id, typeName),
          closeIcon: hideCloseButton ? <></> : undefined,
          key: id
        })
      }

      if (setNotificationToLocalStorage) {
        const notifications = localStorage.getItem(typeName) ?? '[]'
        const newNotification = {
          id,
          type,
          numberOfItems,
          messageProps
        } as TNotification
        if (date) newNotification.date = date
        if (notifications) {
          // if we already have notification with this JOB id - remove it from the local store and add new item
          if (
            JSON.parse(notifications).find(
              (currentNotification) => currentNotification.id === id
            )
          ) {
            localStorage.setItem(
              typeName,
              JSON.stringify([
                ...JSON.parse(notifications).filter(
                  (currentNotification) => currentNotification.id !== id
                ),
                newNotification
              ])
            )
            // if not - just add it to the list
          } else {
            localStorage.setItem(
              typeName,
              JSON.stringify([...JSON.parse(notifications), newNotification])
            )
          }
        }
      }
    },
    [api]
  )

  const onChangeNotification = useCallback(
    (
      { id, type, messageProps }: TNotification,
      typeName: TNotificationTypeName
    ) => {
      // if it is some response from the BE - close prev notification & open new with status from response
      const notifications = localStorage.getItem(typeName)
      if (notifications) {
        JSON.parse(notifications).forEach((currentNotification) => {
          if (currentNotification.id === id) {
            notification.close(id)
            openNotification(
              {
                id,
                type,
                numberOfItems: currentNotification.numberOfItems,
                messageProps: {
                  specificErrorMessage: messageProps?.specificErrorMessage,
                  itemUuid: currentNotification.messageProps?.itemUuid,
                  redirectRoute:
                    currentNotification.messageProps?.redirectRoute,
                  buttonText: messageProps?.buttonText
                }
              },
              typeName,
              true
            )
            if (
              typeName === TNotificationTypeName.UPLOAD_ITEM_LIST &&
              currentNotification.numberOfItems > 0 &&
              type === TNotificationType.SUCCESS
            ) {
              notification.success({ message: VALIDATION_MESSAGES.SM0144 })
            }
          }
        })
      }
    },
    [localStorage]
  )

  const destroyAllNotifications = useCallback(() => {
    const allNotificationTypes = Object.keys(TNotificationTypeName)

    allNotificationTypes.forEach((notificationType) => {
      const notifications = localStorage.getItem(
        TNotificationTypeName[notificationType]
      )
      if (notifications) {
        JSON.parse(notifications).forEach(({ id }) => {
          notification.close(id)
        })
        localStorage.setItem(TNotificationTypeName[notificationType], '[]')
      }
    })
  }, [])

  const checkIfProcessIsRunning = useCallback(
    (uuid: string, typeName: TNotificationTypeName) => {
      const notifications = localStorage.getItem(typeName)
      if (notifications) {
        return !!JSON.parse(notifications).find(
          (notification) =>
            notification.messageProps?.itemUuid === uuid &&
            notification.type === TNotificationType.INFO &&
            !!notification.date
        )
      }
      return false
    },
    [localStorage]
  )

  const context = useMemo(
    () => ({
      actions: {
        onCloseNotification,
        openNotification,
        onChangeNotification,
        destroyAllNotifications,
        checkIfProcessIsRunning
      }
    }),
    [
      onCloseNotification,
      openNotification,
      onChangeNotification,
      destroyAllNotifications,
      checkIfProcessIsRunning
    ]
  )

  return (
    <BottomNotificationsContext.Provider value={context}>
      {children}
      {contextHolder}
    </BottomNotificationsContext.Provider>
  )
}

export const useBottomNotificationsContext = () =>
  useContext(BottomNotificationsContext)

export default BottomNotificationsContextProvider
