import Fetcher from '../../../../services/Fetcher'
import {
  API_DICTIONARY,
  HTTP_METHODS,
  ResponseWithPagination
} from '../../../../constants'
import {
  CognusARData,
  CognusARTableRequest,
  TCognusARDocumentData
} from './types'

const fetcher = new Fetcher({})

export const getCognusARTableData: CognusARTableRequest = async (data) =>
  fetcher.request<unknown, ResponseWithPagination<CognusARData>>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.COGNUS_AR}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const getCognusARTableDocumentInfo = async (monthId: string) =>
  fetcher.request<unknown, TCognusARDocumentData>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.COGNUS_AR}${monthId}/${API_DICTIONARY.PDF}`,
    method: HTTP_METHODS.GET
  })
