import { useEffect, useState, useMemo, Key } from 'react'
import { useSelector } from 'react-redux'
import { Table } from '../../../../components/Table'
import { PaymentActions } from '../../PaymentActions/PaymentActions'
import { isTableHasParams } from '../../../../helper/common'
import { useFinancialsRebatesContext } from '../../Providers/FinancialsRebatesProvider'
import { getUser } from '../../../../redux/store/user/getters'
import { useFinancialsAbility } from '../../Providers/FinancialsAbilityProvider'
import { useFinancialsContext } from '../../Providers/FinancialsProvider'
import { columns } from './constants'
import { getDisabledStatuses } from '../../utils'
import { ACTIONS, SUBJECTS } from '../../Abilities'
import {
  TRebatesTableFilters,
  TRebatesTableFunc,
  TRebatesTableType
} from './types'
import { PAYMENT_TYPES, SelectedRowData } from '../../types'

export const RebatesTable = () => {
  const [selectedRowData, setSelectedRowData] =
    useState<SelectedRowData | null>(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [filters, setFilters] = useState<TRebatesTableFilters>({})
  const {
    actions: { generateAndDownloadRebateDocument }
  } = useFinancialsContext()
  const user = useSelector(getUser)
  const {
    state: { totalNumber, tableData, sortParams, firstLoad, period, pageInfo },
    actions: { getTableData, updateRebatesData, setPageInfo }
  } = useFinancialsRebatesContext()
  const ability = useFinancialsAbility()
  const canInitiatePayment = useMemo(
    () => !!user.vendor && ability.can(ACTIONS.INITIATE, SUBJECTS.PAYMENT),
    [ability, user.vendor]
  )

  useEffect(() => {
    getTableData({ ...sortParams, page: pageInfo })
    setSelectedRowKeys([])
  }, [period])

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }

  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }

  const handleSelectRow = (
    selectedRowKeys: Key[],
    selectedRows: TRebatesTableType[]
  ) => {
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRowData({
      uuid: selectedRows[0].uuid,
      supplier: selectedRows[0].supplier_name,
      health_system: selectedRows[0].health_system_name,
      total: selectedRows[0].total
    })
  }

  const applyFilters: TRebatesTableFunc = (field, appliedFilters) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    const newPageInfo = { ...pageInfo, pageNumber: 1 }
    setFilters(newFilters)
    setPageInfo(newPageInfo)
    getTableData({ ...sortParams, filters: newFilters, page: newPageInfo })
  }

  const onResetSelection = () => {
    setSelectedRowData(null)
    setSelectedRowKeys([])
  }

  const updateTableData = async () => {
    try {
      await updateRebatesData()
      onResetSelection()
    } finally {
    }
  }

  return (
    <div>
      <PaymentActions
        type={PAYMENT_TYPES.REBATES}
        totalNumber={totalNumber}
        pageInfo={pageInfo}
        selectedRowData={selectedRowData}
        selectedLength={selectedRowKeys.length}
        updateTableData={updateTableData}
        onResetSelection={onResetSelection}
      />
      {firstLoad ? null : (
        <Table<TRebatesTableType>
          dataSource={tableData}
          columns={columns({
            applyFilters,
            filters,
            user,
            generateAndDownloadRebateDocument
          })}
          className="financials-table"
          onChange={handleTableChange}
          hasSearchOrFilters={isTableHasParams<TRebatesTableFilters>(
            '',
            filters
          )}
          onChangePage={handleChangePageSize}
          pageSize={pageInfo.pageSize}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
          rowSelection={
            canInitiatePayment
              ? {
                  selectedRowKeys,
                  onChange: handleSelectRow,
                  getCheckboxProps: (record) => ({
                    disabled: getDisabledStatuses(
                      user.role,
                      record.due_date_status
                    ).includes(record.status)
                  })
                }
              : undefined
          }
        />
      )}
    </div>
  )
}
