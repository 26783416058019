import { useCallback, useEffect, useMemo, useState } from 'react'

import { sortAscByKey } from 'helper/common'
import { notification } from 'components/Notification'
import { validateTimeline } from 'components/Timelines/utils'
import {
  ITimeline,
  ITimelineValidation,
  TTimelines,
  TTimelinesValidation
} from 'components/Timelines/types'
import { getDisabledPrsTimeline } from '../../../features/PRS/PrsDetailsTimelines/utils'
import { PRS_STATUSES_TIMERS } from '../../../pages/ProductRequests/constants'
import { TPRSDetailsResponse } from '../../../features/PRS/PRSDetails/types'

export type UseTimelinesProps<TKey extends Record<string, string>> = {
  systemTimelines: TTimelines<TKey>
  timelines?: TTimelines<TKey>
  validation?: TTimelinesValidation<TKey>
  disabledBeforeKey?: ValueOfObject<TKey>
  isPrs?: boolean
  data?: TPRSDetailsResponse
  resetErrors?: () => void
}

const mergeTimelines = <TKey extends Record<string, string>>(
  initialTimelines: TTimelines<TKey>,
  systemTimelines: TTimelines<TKey>
) => ({
  ...systemTimelines,
  ...initialTimelines
})

const useTimelines = <TKey extends Record<string, string>>(
  props: UseTimelinesProps<TKey>
) => {
  const {
    systemTimelines,
    timelines: initialTimelines = {} as TTimelines<TKey>,
    validation: initialValidation = {},
    disabledBeforeKey,
    isPrs = false,
    resetErrors,
    data
  } = props

  const [_timelines, _setTimelines] = useState<TTimelines<TKey>>(
    mergeTimelines<TKey>(initialTimelines, systemTimelines)
  )
  const [_validation, _setValidation] =
    useState<TTimelinesValidation<TKey>>(initialValidation)

  const getDisabledByKey = useCallback(
    (key: ITimeline<TKey>['key']) => {
      if (!disabledBeforeKey) {
        return false
      }

      const disabledBeforeKeyState = _timelines[disabledBeforeKey]
      const keyState = _timelines[key]

      if (isPrs) {
        return getDisabledPrsTimeline(keyState, data)
      } else {
        return disabledBeforeKeyState
          ? disabledBeforeKeyState.order > keyState.order
          : false
      }
    },
    [_timelines, disabledBeforeKey]
  )

  const getHasValidationError = useCallback(
    () => Object.values(_validation).some((v) => Boolean(v)),
    [_validation]
  )

  const getValidationByKey = useCallback(
    (key: ITimeline<TKey>['key']) => {
      return _validation[key] ?? null
    },
    [_validation]
  )

  const getHasChangesByKey = useCallback(
    (key: ITimeline<TKey>['key']) => {
      const keyState = _timelines[key]

      return (
        !keyState.date?.isSame(systemTimelines[key].date, 'day') ||
        keyState.timeLabel !== systemTimelines[key].timeLabel
      )
    },
    [_timelines, systemTimelines]
  )

  const updateTimelineByKey = useCallback(
    (key: ITimeline<TKey>['key']) => (value: Partial<ITimeline<TKey>>) => {
      resetErrors?.()
      _setTimelines((prev) => ({
        ...prev,
        [key]: { ...prev[key], ...value }
      }))
    },
    []
  )

  const resetTimelineByKey = useCallback(
    (key: ITimeline<TKey>['key']) => () => {
      _setTimelines((prev) => ({
        ...prev,
        [key]: systemTimelines[key]
      }))
    },
    [systemTimelines]
  )

  useEffect(() => {
    const validation = Object.values(_timelines).reduce<
      TTimelinesValidation<TKey>
    >((validation, timeline, _, timelines) => {
      const typedTimeline = timeline as ITimeline<TKey>
      const typedTimelines = timelines as ITimeline<TKey>[]

      const { key, order } = typedTimeline

      const prevTimeline = typedTimelines.find((i) => i.order === order - 1)
      if (isPrs) {
        if (key === PRS_STATUSES_TIMERS.OVERALL) {
          return {
            ...validation,
            [key]: getDisabledByKey(key)
              ? null
              : validateTimeline(typedTimeline, null)
          }
        } else {
          return {
            ...validation,
            [key]: getDisabledByKey(key)
              ? null
              : validateTimeline(typedTimelines[0], typedTimeline)
          }
        }
      } else {
        return {
          ...validation,
          [key]: getDisabledByKey(key)
            ? null
            : validateTimeline(typedTimeline, prevTimeline)
        }
      }
    }, {})
    _setValidation(validation)
  }, [_timelines, getDisabledByKey])

  useEffect(() => {
    _setTimelines(mergeTimelines(initialTimelines, systemTimelines))
  }, [initialTimelines, systemTimelines])

  useEffect(() => {
    Object.values(_validation).forEach((validation) => {
      if (validation) {
        const { status, message } = validation as ITimelineValidation
        if (message) {
          notification[status]({ message })
        }
      }
    })
  }, [_validation])

  const state = useMemo(
    () =>
      Object.values<ITimeline<TKey>>(_timelines).sort(
        sortAscByKey<ITimeline<TKey>>('order')
      ),
    [_timelines]
  )

  const actions = useMemo(
    () => ({
      getValidationByKey,
      getHasChangesByKey,
      updateTimelineByKey,
      resetTimelineByKey,
      getHasValidationError,
      getDisabledByKey
    }),
    [
      getHasChangesByKey,
      getValidationByKey,
      resetTimelineByKey,
      updateTimelineByKey,
      getHasValidationError,
      getDisabledByKey
    ]
  )

  return { state, actions }
}
export type TimelinesReturnType = ReturnType<typeof useTimelines>
export type TimelinesReturnState = ReturnType<typeof useTimelines>['state']
export type TimelinesReturnActions = ReturnType<typeof useTimelines>['actions']
export type TimelinesReturnTypeWithKey<TKey extends Record<string, string>> = {
  state: ITimeline<TKey>[]
  actions: {
    getValidationByKey: (
      key: ITimeline<TKey>['key']
    ) => NonNullable<TTimelinesValidation<TKey>[ValueOfObject<TKey>]> | null
    getHasChangesByKey: (
      key: ITimeline<TKey>['key']
    ) => ReturnType<TimelinesReturnActions['getHasChangesByKey']>
    getDisabledByKey: (
      key: ValueOfObject<TKey>
    ) => ReturnType<TimelinesReturnActions['getDisabledByKey']>
    updateTimelineByKey: (
      key: ITimeline<TKey>['key']
    ) => (value: Partial<ITimeline<TKey>>) => void
    resetTimelineByKey: (
      key: ITimeline<TKey>['key']
    ) => ReturnType<TimelinesReturnActions['resetTimelineByKey']>
  } & Pick<TimelinesReturnActions, 'getHasValidationError'>
}

export default useTimelines
