import { routes } from '../../router'
import { COGNUS_REPOSITORY_OPTIONS } from '../CognusRepository/constants'
import { PRS_DETAILS_TABS } from '../../pages/ProductRequests/constants'
import { contractCategoryDetailsRoute } from '../ContractCategoryDetails/routes'

export const NOTIFICATION_TYPE_TO_ROUTE = (
  id: string,
  canSeeMyContracts?: boolean
) => ({
  rfp_details_general_tab: `${routes.bidding}${routes.rfp}/${id}/0`,
  rfp_details_vendor_tab: `${routes.bidding}${routes.rfp}/${id}/2`,
  rfp_details_voting_tab: `${routes.bidding}${routes.rfp}/${id}/4`,
  rfp_details_bidding_tab: `${routes.bidding}${routes.rfp}/${id}/6`,
  rfp_result_tab: `${routes.bidding}${routes.rfp}/${id}/7`,
  clinical_review_tab: `${routes.contracts}${routes.cognusRepository}/?redirectTab=${COGNUS_REPOSITORY_OPTIONS[1].value}`,
  request_for_analysis_tab: `${routes.contracts}${routes.cognusRepository}/${id}`,
  opportunity_analysis_tab: `${routes.contracts}${routes.opportunityAnalysis}/${id}`,
  available_for_evaluation_tab: `${routes.contracts}${routes.cognusRepository}/?redirectTab=${COGNUS_REPOSITORY_OPTIONS[0].value}`,
  hs_list_tab: `${routes.contracts}${routes.myContracts}/${id}/hs_list`,
  chat_tab: `${routes.chat}/${id}`,
  contract_details_general_tab: `${routes.contracts}${
    canSeeMyContracts ? routes.myContracts : ''
  }/${id}`,
  checkin_details_tab: `${routes.checkIns}/${id}`,
  prs_details_general_tab: `${routes.productRequests}/${id}/${PRS_DETAILS_TABS[0].key}`,
  prs_details_facilities_tab: `${routes.productRequests}/${id}/${PRS_DETAILS_TABS[1].key}`,
  prs_details_voting_tab: `${routes.productRequests}/${id}/${PRS_DETAILS_TABS[3].key}`,
  prs_details_result_tab: `${routes.productRequests}/${id}/${PRS_DETAILS_TABS[5].key}`,
  calendar_event_tab: `${routes.calendar}/?event_id=${id}`,
  contract_details_item_list_tab: `${routes.contracts}${
    canSeeMyContracts ? routes.myContracts : ''
  }/${id}/item_list`,
  contract_category_details_general_tab: `${routes.contracts}${routes.contractCategories}/${id}/${contractCategoryDetailsRoute.general}`
})
