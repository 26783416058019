import { AbilityBuilder } from '@casl/ability'
import {
  ACTIONS,
  AppAbility,
  ROLES,
  SUBJECTS
} from 'features/Permission/constants'
import { IUser } from 'redux/store/user/types'
import { TRFPStatusesUnion } from '../../RFP/RFPDetails/types'
import { RFP_STATUSES, RFP_STATUSES_FOR_CANCEL_CLC } from '../../RFP/constants'

const defineRFPAbilities = (
  ability: AbilityBuilder<AppAbility>,
  user: IUser
) => {
  const { can } = ability

  const isOwnerCondition = {
    'creator.uuid': user.uuid
  }

  const isVendorContractStewardCondition = {
    'vendor_contract_steward.uuid': user.uuid
  }

  const isResponsibleCommunityCondition = {
    community_responsibles: {
      $elemMatch: { uuid: user.uuid }
    }
  }

  const isResponsibleVendorCondition = {
    vendor_responsibles: {
      $elemMatch: { uuid: user.uuid }
    }
  }
  const isResponsibleCommonsCondition = {
    commons_responsibles: {
      $elemMatch: { uuid: user.uuid }
    }
  }
  const isAwarded = {
    is_awarded: true
  }

  const isCommunityUser =
    user.role === ROLES.ANALYST || user.role === ROLES.LEGAL

  const isVendorUser =
    user.role === ROLES.VENDOR_ANALYST || user.role === ROLES.VENDOR_LEGAL
  const isInStatuses = (inStatuses: TRFPStatusesUnion[]) => ({
    status: {
      $in: inStatuses
    }
  })
  const isNotInStatuses = (inStatuses: TRFPStatusesUnion[]) => ({
    status: {
      $nin: inStatuses
    }
  })
  const isInType = (isType: string[]) => ({
    participation_type: {
      $in: isType
    }
  })

  can(ACTIONS.VIEW, SUBJECTS.RESPONSIBLE_USER)

  can(ACTIONS.CREATE, SUBJECTS.CHAT, {
    ...isNotInStatuses([
      RFP_STATUSES.ARCHIVED,
      RFP_STATUSES.CREATED,
      RFP_STATUSES.CANCELED
    ])
  })

  // to move on set up permissions on calendar
  can(ACTIONS.VIEW, SUBJECTS.CALENDAR)
  can(ACTIONS.CRUD, SUBJECTS.VENDORS_TAB, isOwnerCondition)

  can(ACTIONS.SEND, SUBJECTS.TO_COMMONS, {
    ...isOwnerCondition,
    ...isInStatuses([RFP_STATUSES.CREATED]),
    ...isInType(['clinically_led_central'])
  })
  can(ACTIONS.RESEND, SUBJECTS.BID, {
    ...isOwnerCondition,
    ...isInType(['clinically_led_self']),
    ...isInStatuses([
      RFP_STATUSES.BIDDING_QA,
      RFP_STATUSES.PRICE_FILES_ANALYSIS,
      RFP_STATUSES.BIDDING_IN_PROGRESS
    ])
  })
  if (
    user.role === ROLES.COMMUNITY_PRESIDENT ||
    user.role === ROLES.VICE_PRESIDENT ||
    user.role === ROLES.CONTRACT_STEWARD
  ) {
    can(ACTIONS.VIEW, SUBJECTS.VOTING)
    can(
      [ACTIONS.CREATE, ACTIONS.DELETE],
      SUBJECTS.RESPONSIBLE_USER,
      isOwnerCondition
    )
    can([ACTIONS.EDIT], SUBJECTS.RFP, {
      ...isOwnerCondition,
      ...isInType(['clinically_led_self'])
    })

    can([ACTIONS.EDIT], SUBJECTS.RFP, {
      ...isOwnerCondition,
      ...isInType(['clinically_led_central']),
      ...isInStatuses([
        RFP_STATUSES.CREATED,
        RFP_STATUSES.WAITING_FOR_ASSIGNMENT
      ])
    })

    can([ACTIONS.CANCEL], SUBJECTS.RFP, {
      ...isOwnerCondition
    })

    can(ACTIONS.SEND, SUBJECTS.RFP, {
      ...isOwnerCondition,
      ...isInType(['clinically_led_self'])
    })
    can([ACTIONS.CREATE, ACTIONS.SEND], SUBJECTS.SURVEY, {
      ...isOwnerCondition,
      ...isInType(['clinically_led_self'])
    })
    can(ACTIONS.UPLOAD, SUBJECTS.RFP_DOCUMENTS, {
      ...isOwnerCondition,
      ...isInType(['clinically_led_self'])
    })
    can(ACTIONS.UPLOAD, SUBJECTS.RFP_DOCUMENTS, {
      ...isOwnerCondition,
      ...isInType(['clinically_led_central']),
      ...isInStatuses([
        RFP_STATUSES.CREATED,
        RFP_STATUSES.WAITING_FOR_ASSIGNMENT
      ])
    })
    can(ACTIONS.AWARD, SUBJECTS.VENDORS, {
      ...isOwnerCondition,
      ...isInType(['clinically_led_self'])
    })
    can(ACTIONS.SUBMIT, SUBJECTS.VOTING, {
      ...isOwnerCondition,
      ...isInType(['clinically_led_self'])
    })
    can([ACTIONS.VIEW], SUBJECTS.VOTING_RESULTS, {
      ...isOwnerCondition,
      ...isInType(['clinically_led_self'])
    })
    can([ACTIONS.CREATE], SUBJECTS.HOSPITAL_USERS, {
      ...isOwnerCondition,
      ...isInType(['clinically_led_self'])
    })
  }

  if (user.role === ROLES.COMMONS_VICE_PRESIDENT) {
    can([ACTIONS.VIEW], SUBJECTS.ASSIGN_RFP_BANNER, {
      ...isInType(['clinically_led_central']),
      ...isInStatuses([RFP_STATUSES.WAITING_FOR_ASSIGNMENT])
    })
  }

  if (
    user.role === ROLES.COMMUNITY_PRESIDENT ||
    user.role === ROLES.VICE_PRESIDENT ||
    user.role === ROLES.CONTRACT_STEWARD ||
    user.role === ROLES.STAKEHOLDER ||
    user.role === ROLES.INFLUENCER ||
    user.role === ROLES.HOSPITAL_ADMIN ||
    user.role === ROLES.COMMONS_CONTRACT_STEWARD
  ) {
    can(ACTIONS.VIEW, SUBJECTS.CHECK_INS)
  }

  if (
    user.role === ROLES.COMMUNITY_PRESIDENT ||
    user.role === ROLES.VICE_PRESIDENT
  ) {
    can([ACTIONS.VIEW], SUBJECTS.RFP_RESULTS)
  }

  if (isCommunityUser) {
    can([ACTIONS.VIEW], SUBJECTS.RFP_RESULTS, {
      ...isResponsibleCommunityCondition
    })
  }

  if (user.role === ROLES.CONTRACT_STEWARD) {
    can([ACTIONS.VIEW], SUBJECTS.RFP_RESULTS, {
      ...isOwnerCondition
    })
  }

  if (isVendorUser) {
    can([ACTIONS.VIEW], SUBJECTS.RFP_RESULTS, {
      ...isResponsibleCommunityCondition,
      ...isAwarded
    })
  }

  if (
    user.role === ROLES.VENDOR_CONTRACT_STEWARD ||
    user.role === ROLES.VENDOR_CONTRACT_ADMIN
  ) {
    can([ACTIONS.VIEW], SUBJECTS.RFP_RESULTS, {
      ...isAwarded
    })
  }

  if (user.role === ROLES.VENDOR_CONTRACT_STEWARD) {
    can([ACTIONS.CANCEL, ACTIONS.EDIT], SUBJECTS.RFP, {
      ...isResponsibleVendorCondition,
      ...isVendorContractStewardCondition,
      ...isInType(['clinically_led_self'])
    })
    can([ACTIONS.CANCEL, ACTIONS.EDIT], SUBJECTS.RFP, {
      ...isResponsibleVendorCondition,
      ...isVendorContractStewardCondition,
      ...isInType(['clinically_led_central']),
      ...isInStatuses([
        RFP_STATUSES.CREATED,
        RFP_STATUSES.WAITING_FOR_ASSIGNMENT
      ])
    })
    can(ACTIONS.SEND, SUBJECTS.RFP, {
      ...isResponsibleVendorCondition,
      ...isVendorContractStewardCondition,
      ...isInType(['clinically_led_self'])
    })
    can([ACTIONS.CREATE, ACTIONS.SEND], SUBJECTS.SURVEY, {
      ...isResponsibleVendorCondition,
      ...isVendorContractStewardCondition,
      ...isInType(['clinically_led_self'])
    })
    can(ACTIONS.UPLOAD, SUBJECTS.RFP_DOCUMENTS, {
      ...isVendorContractStewardCondition
    })
    can(ACTIONS.AWARD, SUBJECTS.VENDORS, {
      ...isResponsibleVendorCondition,
      ...isVendorContractStewardCondition,
      ...isInType(['clinically_led_self'])
    })
    can([ACTIONS.VIEW, ACTIONS.SUBMIT], SUBJECTS.VOTING, {
      ...isResponsibleVendorCondition,
      ...isVendorContractStewardCondition,
      ...isInType(['clinically_led_self'])
    })
    can([ACTIONS.CREATE, ACTIONS.DELETE], SUBJECTS.RESPONSIBLE_USER, {
      ...isVendorContractStewardCondition
    })
    can(ACTIONS.VIEW, SUBJECTS.RFP_VENDOR_ACTIONS, {
      ...isVendorContractStewardCondition
    })
    can([ACTIONS.VIEW], SUBJECTS.VOTING_RESULTS, {
      ...isResponsibleVendorCondition,
      ...isVendorContractStewardCondition,
      ...isInType(['clinically_led_self'])
    })
    can(ACTIONS.CRUD, SUBJECTS.VENDORS_TAB, {
      ...isVendorContractStewardCondition
    })
  }

  if (
    user.role === ROLES.COMMONS_VICE_PRESIDENT ||
    user.role === ROLES.COMMONS_CONTRACT_STEWARD
  ) {
    can(ACTIONS.RESEND, SUBJECTS.BID, {
      ...isResponsibleCommonsCondition,
      ...isInType(['clinically_led_central']),
      ...isInStatuses([
        RFP_STATUSES.BIDDING_QA,
        RFP_STATUSES.PRICE_FILES_ANALYSIS,
        RFP_STATUSES.BIDDING_IN_PROGRESS
      ])
    })
    can(ACTIONS.UPLOAD, SUBJECTS.RFP_DOCUMENTS, {
      ...isOwnerCondition,
      ...isInType(['clinically_led_self'])
    })
    can([ACTIONS.CREATE, ACTIONS.DELETE], SUBJECTS.HOSPITAL_USERS, {
      ...isResponsibleCommonsCondition,
      ...isInType(['clinically_led_central'])
    })
    can(ACTIONS.CRUD, SUBJECTS.VENDORS_TAB, isResponsibleCommonsCondition)
    can(ACTIONS.CANCEL, SUBJECTS.RFP, {
      ...isResponsibleCommonsCondition,
      ...isInStatuses(RFP_STATUSES_FOR_CANCEL_CLC)
    })
    can([ACTIONS.CANCEL, ACTIONS.EDIT], SUBJECTS.RFP, {
      ...isOwnerCondition,
      ...isInType(['clinically_led_self'])
    })
    can(ACTIONS.EDIT, SUBJECTS.RFP, {
      ...isResponsibleCommonsCondition,
      ...isInStatuses([
        RFP_STATUSES.CREATED,
        RFP_STATUSES.VENDOR_ACCEPTANCE,
        RFP_STATUSES.IN_CS_QUEUE,
        RFP_STATUSES.BIDDING_QA,
        RFP_STATUSES.BIDDING_IN_PROGRESS,
        RFP_STATUSES.OUT_FOR_SIGNATURE,
        RFP_STATUSES.CONTRACTING,
        RFP_STATUSES.VENDORS_SELECTION,
        RFP_STATUSES.VOTING_IN_PROGRESS,
        RFP_STATUSES.PRICE_FILES_ANALYSIS
      ])
    })
    can(ACTIONS.EDIT, SUBJECTS.RFP, {
      ...isOwnerCondition,
      ...isInStatuses([RFP_STATUSES.CREATED])
    })
    can(ACTIONS.SEND, SUBJECTS.RFP, {
      ...isOwnerCondition,
      ...isInType(['clinically_led_self'])
    })
    can([ACTIONS.VIEW], SUBJECTS.VOTING)
    can(ACTIONS.SEND, SUBJECTS.RFP, {
      ...isResponsibleCommonsCondition,
      ...isInType(['clinically_led_central']),
      ...isInStatuses([RFP_STATUSES.IN_CS_QUEUE])
    })
    can(
      [ACTIONS.CREATE, ACTIONS.DELETE],
      SUBJECTS.RESPONSIBLE_USER,
      isOwnerCondition
    )
    if (user.role === ROLES.COMMONS_VICE_PRESIDENT) {
      can([ACTIONS.CREATE, ACTIONS.DELETE], SUBJECTS.COMMONS_RESPONSIBLE_USER, {
        ...isInType(['clinically_led_central'])
      })
    }

    if (user.role === ROLES.COMMONS_CONTRACT_STEWARD) {
      can([ACTIONS.VIEW], SUBJECTS.RFP_RESULTS, {
        ...isOwnerCondition
      })
    }
    can(
      [ACTIONS.CREATE, ACTIONS.SEND],
      SUBJECTS.SURVEY,
      isResponsibleCommonsCondition
    )
    can([ACTIONS.CREATE, ACTIONS.SEND], SUBJECTS.SURVEY, {
      ...isOwnerCondition,
      ...isInType(['clinically_led_self'])
    })
    can(ACTIONS.SUBMIT, SUBJECTS.VOTING, {
      ...isOwnerCondition,
      ...isInType(['clinically_led_self'])
    })
    can(ACTIONS.AWARD, SUBJECTS.VENDORS, {
      ...isOwnerCondition,
      ...isInType(['clinically_led_self'])
    })
    can([ACTIONS.CREATE, ACTIONS.DELETE], SUBJECTS.COMMONS_RESPONSIBLE_USERS, {
      ...isResponsibleCommonsCondition,
      ...isInType(['clinically_led_central'])
    })
    can(ACTIONS.UPLOAD, SUBJECTS.RFP_DOCUMENTS, isResponsibleCommonsCondition)
    can(ACTIONS.AWARD, SUBJECTS.VENDORS, isResponsibleCommonsCondition)
    can(ACTIONS.SUBMIT, SUBJECTS.VOTING, isResponsibleCommonsCondition)
    can([ACTIONS.VIEW], SUBJECTS.VOTING_RESULTS, isResponsibleCommonsCondition)
    can([ACTIONS.VIEW], SUBJECTS.VOTING_RESULTS, isOwnerCondition)
    can([ACTIONS.VIEW], SUBJECTS.RFP_RESULTS, {
      ...isResponsibleCommonsCondition
    })
    can([ACTIONS.VIEW], SUBJECTS.RFP_RESULTS, {
      ...isOwnerCondition
    })
    can(
      [ACTIONS.CREATE, ACTIONS.DELETE],
      SUBJECTS.HOSPITAL_USERS,
      isResponsibleCommonsCondition
    )
  }

  if (user.role === ROLES.VENDOR_LEGAL || user.role === ROLES.VENDOR_ANALYST) {
    can(ACTIONS.UPLOAD, SUBJECTS.RFP_DOCUMENTS, isResponsibleVendorCondition)
  }

  if (user.role === ROLES.LEGAL || user.role === ROLES.ANALYST) {
    can(ACTIONS.UPLOAD, SUBJECTS.RFP_DOCUMENTS, isResponsibleCommunityCondition)
  }
  if (
    user.role === ROLES.COMMONS_LEGAL ||
    user.role === ROLES.COMMONS_ANALYST
  ) {
    can(ACTIONS.UPLOAD, SUBJECTS.RFP_DOCUMENTS, isResponsibleCommunityCondition)
  }

  if (user.role === ROLES.STAKEHOLDER) {
    can([ACTIONS.VOTE, ACTIONS.SUBMIT], SUBJECTS.SURVEY)
    can([ACTIONS.VIEW], SUBJECTS.VOTING)
  }

  if (user.role === ROLES.COMMONS_STAKEHOLDER) {
    can([ACTIONS.VOTE, ACTIONS.SUBMIT], SUBJECTS.SURVEY)
    can([ACTIONS.VIEW], SUBJECTS.VOTING)
  }
}

export default defineRFPAbilities
