import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Table } from '../../components/Table'
import { AddCCPopup } from './AddCCPopup/AddCCPopup'
import { Button, BUTTON_TYPES } from '../../components/Button'
import { setLoading } from '../../redux/store/common/slice'
import { columns } from './constants'
import { changeCommunityLevel } from './api'
import { useCRUDUserContext } from '../../pages/CRUDUser/Provider/CRUDUserProvider'
import { notification } from '../../components/Notification'
import { BTN_TXT, VALIDATION_MESSAGES } from '../../constants'
import { ReactComponent as Delete16 } from 'assets/svg/Delete16.svg'
import { ChangeCommunityLevelFunc, UserContractCategoriesProps } from './types'

export const UserContractCategories = ({
  role,
  userId,
  communityLevelCheckboxesVisible,
  communityLevelCheckboxesEditable
}: UserContractCategoriesProps) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const dispatch = useDispatch()
  const {
    state: { totalNumber, pageInfo, sortParams, firstLoad, tableData },
    actions: { getContractCategories, setPageInfo }
  } = useCRUDUserContext()

  const onChangeCommunityLevel: ChangeCommunityLevelFunc = async (
    ccId,
    value
  ) => {
    try {
      dispatch(setLoading(true))
      await changeCommunityLevel(userId, ccId, {
        community_level: value
      }).catch((err) => {
        console.log(err)
        notification.error({
          message: VALIDATION_MESSAGES.COMMUNITY_LEVEL_FOR_VP_REMOVE_ERROR
        })
      })
      await onUpdateCCList()
    } catch (err: any) {
      dispatch(setLoading(false))
    }
  }

  const tableColumns = useMemo(
    () =>
      role
        ? columns(
            role,
            onChangeCommunityLevel,
            communityLevelCheckboxesVisible,
            communityLevelCheckboxesEditable
          )
        : [],
    [role]
  )

  const handleChangePageSize = async (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    await getContractCategories({
      ...sortParams,
      page
    })
    setPageInfo(page)
  }

  const handleTableChange = async (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    await getContractCategories({
      sortField: sorter.field,
      sortOrder: sorter.order,
      page
    })
    setPageInfo(page)
  }

  const onUpdateCCList = async () => {
    const page = {
      pageNumber: 1,
      pageSize: pageInfo.pageSize
    }
    await getContractCategories({
      ...sortParams,
      page
    })
    setSelectedRowKeys([])
    setPageInfo(page)
  }

  const handleSelectRow = (selectedRowKeys) =>
    setSelectedRowKeys(selectedRowKeys)

  const onOpenAddModal = () => setIsAddModalOpen(true)

  return (
    <div className="user-contract-categories">
      <div className="row justify-end mb-4">
        {!!selectedRowKeys.length ? (
          <Button
            type={BUTTON_TYPES.PRIMARY}
            icon={<Delete16 />}
            upperCase
            danger
          >
            {BTN_TXT.DELETE}
          </Button>
        ) : (
          <Button
            onClick={onOpenAddModal}
            type={BUTTON_TYPES.PRIMARY}
            upperCase
          >
            {BTN_TXT.ASSIGN_CONTRACT_CATEGORY}
          </Button>
        )}
      </div>
      {!firstLoad && (
        <Table
          dataSource={tableData}
          className="user-contract-categories-table"
          columns={tableColumns}
          onChange={handleTableChange}
          onChangePage={handleChangePageSize}
          pageSize={pageInfo.pageSize}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
          createButton={{
            buttonText: BTN_TXT.ASSIGN_CONTRACT_CATEGORY,
            availability: true,
            action: onOpenAddModal
          }}
          rowSelection={{
            selectedRowKeys,
            onChange: handleSelectRow
          }}
        />
      )}
      <AddCCPopup
        isOpen={isAddModalOpen}
        userId={userId}
        setIsOpen={setIsAddModalOpen}
        onUpdateCCList={onUpdateCCList}
      />
    </div>
  )
}
