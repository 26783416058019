import moment from 'moment'
import { FinancialsChartLegendPart } from '../FinancialsChartLegendPart/FinancialsChartLegendPart'
import { Datepicker } from '../../../components/Datepicker'
import { PaymentStatusChart } from '../PaymentStatusChart/PaymentStatusChart'
import { Typography } from '../../../components/Typography'
import { RebatesTable } from './RebatesTable/RebatesTable'
import { useFinancialsRebatesContext } from '../Providers/FinancialsRebatesProvider'
import { getFinancialStatusLegendOrder } from '../FinancialsChartLegendPart/constants'
import { FINANCIAL_STATUS_LABEL, REPORTS_COLORS } from '../constants'
import { timelineQuarterFormat } from '../../../components/Timelines/constants'
import './styles.scss'

export const Rebates = () => {
  const {
    state: { period, totalAmount, reports },
    actions: { setPeriod }
  } = useFinancialsRebatesContext()

  return (
    <div className="rebates column gap-24">
      <div className="rebates__date-filter">
        <Datepicker
          propsDate={{
            picker: 'quarter',
            format: timelineQuarterFormat,
            value: period,
            onChange: (date) => setPeriod(moment(date).startOf('quarter'))
          }}
          propsItem={{
            label: 'Period'
          }}
        />
      </div>
      <div className="rebates__container column gap-24">
        <Typography.Headline4>Payment Status</Typography.Headline4>
        <div className="rebates__chart-container row">
          <div className="rebates__chart">
            <PaymentStatusChart
              total={totalAmount}
              data={getFinancialStatusLegendOrder(reports)}
            />
          </div>
          <div className="rebates__legend">
            {getFinancialStatusLegendOrder(reports).map((i, idx) => (
              <FinancialsChartLegendPart
                key={idx}
                color={REPORTS_COLORS[i.status]}
                name={FINANCIAL_STATUS_LABEL[i.status]}
                price={i.amount}
              />
            ))}
          </div>
        </div>
      </div>
      <RebatesTable />
    </div>
  )
}
