export const FINANCIAL_TABS = [
  {
    key: '0',
    label: 'Rebates'
  },
  {
    key: '1',
    label: 'Admin'
  },
  {
    key: '2',
    label: 'Cognus AR'
  },
  {
    key: '3',
    label: 'Payables'
  },
  {
    key: '4',
    label: 'All'
  }
]

export const REPORTS_COLORS = {
  disputed: 'light_red',
  waiting_for_response: 'blue',
  payment_in_progress: 'gray',
  paid: 'green',
  payment_failed: 'red',
  past_due: 'orange'
} as const

export const FINANCIAL_STATUS_FILTER = [
  {
    label: 'Paid',
    value: 'paid',
    index: 0
  },
  {
    label: 'Waiting for response',
    value: 'waiting_for_response',
    index: 1
  },
  {
    label: 'Payment in progress',
    value: 'payment_in_progress',
    index: 2
  },
  {
    label: 'Payment failed',
    value: 'payment_failed',
    index: 3
  },
  {
    label: 'Disputed',
    value: 'disputed',
    index: 4
  },
  {
    label: 'Past due',
    value: 'past_due',
    index: 5
  }
]

export const FINANCIAL_STATUS_LABEL = {
  disputed: 'Disputed',
  paid: 'Paid',
  payment_failed: 'Payment failed',
  payment_in_progress: 'Payment in progress',
  past_due: 'Past due',
  waiting_for_response: 'Waiting for response'
}

export const INITIAL_DATA_REPORTS_LEGEND = [
  {
    status: 'paid',
    amount: 0,
    percentage: '0'
  },
  {
    status: 'waiting_for_response',
    amount: 0,
    percentage: '0'
  },
  {
    status: 'payment_in_progress',
    amount: 0,
    percentage: '0'
  },
  {
    status: 'payment_failed',
    amount: 0,
    percentage: '0'
  },
  {
    status: 'disputed',
    amount: 0,
    percentage: '0'
  },
  {
    status: 'past_due',
    amount: 0,
    percentage: '0'
  }
]
