import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import {
  TArchiveHospitalsRequest,
  THospitalsListRequest,
  THospitalsListResponse,
  THospital,
  THospitalDetailsRequest,
  TDownloadQRCodeRequest,
  TDownloadQRCodeResponse,
  TActivateHospitalsRequest
} from './types'
import { TAddEditHospitalRequest } from '../types'
import { VALIDATION_MESSAGES } from '../../../constants'

const fetcher = new Fetcher({})

export const getHospitals: THospitalsListRequest = async (uuid, id, params) =>
  fetcher.request<unknown, THospitalsListResponse>({
    url: `${API_DICTIONARY.COMMUNITIES}${uuid}/${API_DICTIONARY.HEALTH_SYSTEMS}${id}/${API_DICTIONARY.HOSPITALS}`,
    method: HTTP_METHODS.GET,
    params
  })

export const archiveHospitals: TArchiveHospitalsRequest = async (
  uuid,
  id,
  data
) =>
  fetcher.request<unknown, THospitalsListResponse>({
    url: `${API_DICTIONARY.COMMUNITIES}${uuid}/${API_DICTIONARY.HEALTH_SYSTEMS}${id}/${API_DICTIONARY.HOSPITALS}archive/`,
    method: HTTP_METHODS.POST,
    data
  })

export const activateHospitals: TActivateHospitalsRequest = async (
  uuid,
  id,
  data
) =>
  fetcher.request<unknown, THospitalsListResponse>({
    url: `${API_DICTIONARY.COMMUNITIES}${uuid}/${API_DICTIONARY.HEALTH_SYSTEMS}${id}/${API_DICTIONARY.HOSPITALS}activate/`,
    method: HTTP_METHODS.POST,
    data
  })

export const addHospital: TAddEditHospitalRequest = async (uuid, id, data) =>
  fetcher.request<unknown, THospitalsListResponse>({
    url: `${API_DICTIONARY.COMMUNITIES}${uuid}/${API_DICTIONARY.HEALTH_SYSTEMS}${id}/${API_DICTIONARY.HOSPITALS}`,
    method: HTTP_METHODS.POST,
    successMessage: `Hospital ${VALIDATION_MESSAGES.SM0009} added.`,
    data
  })

export const editHospital: TAddEditHospitalRequest = async (
  uuid,
  id,
  data,
  hospitalId
) =>
  fetcher.request<unknown, THospitalsListResponse>({
    url: `${API_DICTIONARY.COMMUNITIES}${uuid}/${API_DICTIONARY.HEALTH_SYSTEMS}${id}/${API_DICTIONARY.HOSPITALS}${hospitalId}/`,
    method: HTTP_METHODS.PUT,
    successMessage: `Hospital ${VALIDATION_MESSAGES.SM0009} saved.`,
    data
  })

export const getHospitalData: THospitalDetailsRequest = async (
  communityId,
  hsId,
  hospitalId
) =>
  fetcher.request<unknown, THospital>({
    url: `${API_DICTIONARY.COMMUNITIES}${communityId}/${API_DICTIONARY.HEALTH_SYSTEMS}${hsId}/${API_DICTIONARY.HOSPITALS}${hospitalId}/`,
    method: HTTP_METHODS.GET
  })

export const getHealthSystemQRCOde: TDownloadQRCodeRequest = async (
  hsId,
  type
) =>
  fetcher.request<unknown, TDownloadQRCodeResponse>({
    url: `${API_DICTIONARY.AUTH}${API_DICTIONARY.SELF_SIGN_UP}${API_DICTIONARY.QR_CODE}?health_system=${hsId}&file_type=${type}`,
    method: HTTP_METHODS.GET
  })
