import { Key, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Table } from '../../../../components/Table'
import { CheckboxCell } from '../../../../components/CheckboxCell/CheckboxCell'
import { PaymentActions } from '../../PaymentActions/PaymentActions'
import { getUser } from '../../../../redux/store/user/getters'
import { useFinancialsAbility } from '../../Providers/FinancialsAbilityProvider'
import { useFinancialsCognusARContext } from '../../Providers/FinancialsCognusARProvider'
import { useFinancialsContext } from '../../Providers/FinancialsProvider'
import { columns } from './constants'
import { getTooltipForRow } from './helpers'
import { getDisabledStatuses } from '../../utils'
import { ACTIONS, SUBJECTS } from '../../Abilities'
import { ROLES } from '../../../Permission'
import { CognusARData } from './types'
import { PAYMENT_TYPES, SelectedRowData } from '../../types'

export const CognusARTable = () => {
  const [selectedRowData, setSelectedRowData] =
    useState<SelectedRowData | null>(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const {
    state: { totalNumber, tableData, pageInfo, firstLoad },
    actions: { getTableData, updateCognusARData, setPageInfo }
  } = useFinancialsCognusARContext()
  const user = useSelector(getUser)
  const ability = useFinancialsAbility()
  const {
    actions: { generateAndDownloadCognusARDocument }
  } = useFinancialsContext()
  const hideHSName = useMemo(
    () =>
      user.role === ROLES.VICE_PRESIDENT ||
      user.role === ROLES.CONTRACT_STEWARD ||
      user.role === ROLES.ANALYST ||
      user.role === ROLES.COMMUNITY_RCA,
    [user.role]
  )
  const hideInvoices = user.role === ROLES.COMMUNITY_PRESIDENT
  const isVendor = !!user.vendor
  const isCommons = !!user.commons

  const handleTableChange = (pagination) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({ page })
    setPageInfo(page)
  }

  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({ page })
    setPageInfo(page)
  }

  const handleSelectRow = (
    selectedRowKeys: Key[],
    selectedRows: CognusARData[]
  ) => {
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRowData({
      uuid: selectedRows[0].uuid,
      health_system: selectedRows[0].health_system,
      supplier: selectedRows[0].supplier,
      total: selectedRows[0].total
    })
  }

  const onResetSelection = () => {
    setSelectedRowData(null)
    setSelectedRowKeys([])
  }

  const updateTableData = async () => {
    try {
      await updateCognusARData()
      onResetSelection()
    } finally {
    }
  }

  return (
    <div>
      <PaymentActions
        type={PAYMENT_TYPES.COGNUS_AR}
        totalNumber={totalNumber}
        pageInfo={pageInfo}
        selectedRowData={selectedRowData}
        selectedLength={selectedRowKeys.length}
        updateTableData={updateTableData}
        onResetSelection={onResetSelection}
      />
      {firstLoad ? null : (
        <Table<CognusARData>
          dataSource={tableData}
          columns={columns({
            isVendor,
            isCommons,
            hideHSName,
            hideInvoices,
            generateAndDownloadCognusARDocument
          })}
          className="financials-table"
          onChange={handleTableChange}
          onChangePage={handleChangePageSize}
          pageSize={pageInfo.pageSize}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
          rowSelection={
            ability.can(ACTIONS.INITIATE, SUBJECTS.PAYMENT)
              ? {
                  selectedRowKeys,
                  onChange: handleSelectRow,
                  renderCell: (_, record, __, node) => (
                    <CheckboxCell tooltipText={getTooltipForRow(record, user)}>
                      {node}
                    </CheckboxCell>
                  ),
                  getCheckboxProps: (record) => ({
                    disabled: getDisabledStatuses(
                      user.role,
                      record.due_date_status
                    ).includes(record.status)
                  })
                }
              : undefined
          }
        />
      )}
    </div>
  )
}
