import { LABELS, PLACEHOLDERS } from '../../../../constants'
import {
  currencyFormatter,
  percentageParser
} from '../../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { Input } from '../../../../components/Input'
import { FloatFooter } from '../../../../components/FloatFooter/FloatFooter'
import { validateMessages } from '../../../../helper/common'
import { Form } from 'antd'
import { TSetupEditContractCategoryFormType } from './types'
import { Select } from '../../../../components/Select'

import { useSetupEditContractCategory } from '../../hooks/useSetupEditContractCategory'
import { Typography } from '../../../../components/Typography'
import { Checkbox } from '../../../../components/Checkbox'
import { LED_BY_VALUE } from '../../../../components/LeadByIndicator/constants'

export const EditContractCategory = ({ isNew }: { isNew: boolean }) => {
  const {
    onSubmit,
    form,
    errors,
    isActive,
    setIsActive,
    resetFormValues,
    ledByOptions,
    contractCategories,
    selectedContractCategory,
    setSelectedContractCategory,
    notMeasuringCompliance,
    selectedLedBy,
    organizationOptions
  } = useSetupEditContractCategory(isNew)

  const onFormChange = () => {
    setIsActive(true)
  }

  const onCancel = () => {
    setIsActive(false)
    resetFormValues()
  }

  return (
    <div className="contract-category-details-general__edit">
      <Form<TSetupEditContractCategoryFormType>
        form={form}
        validateMessages={validateMessages}
        onFinish={onSubmit}
        onFieldsChange={onFormChange}
        id="add-edit-contract-pipeline-form"
        className="column gap-24"
      >
        {isNew && (
          <>
            <Select
              options={contractCategories}
              propsItem={{
                name: 'contract_category',
                label: LABELS.CONTRACT_CATEGORY,
                rules: [{ required: true }],
                help: errors.contract_category && errors.contract_category[0],
                validateStatus: errors.contract_category && 'error'
              }}
              propsSelect={{
                onChange: (value) => {
                  setSelectedContractCategory(value)
                  form.setFieldsValue({ led_by: undefined })
                },
                value: selectedContractCategory,
                placeholder: PLACEHOLDERS.PLEASE_SELECT,
                className: 'row mt-0'
              }}
            />
            <Select
              propsSelect={{
                disabled: !selectedContractCategory,
                placeholder: PLACEHOLDERS.PLEASE_SELECT,
                className: 'row mt-0'
              }}
              options={ledByOptions}
              propsItem={{
                name: 'led_by',
                help: errors.led_by && errors.led_by[0],
                validateStatus: errors.led_by && 'error',
                label: LABELS.LED_BY,
                rules: [{ required: true }]
              }}
            />
            {(selectedLedBy === LED_BY_VALUE.COGNUS_FOR_COMMUNITY ||
              selectedLedBy === LED_BY_VALUE.COGNUS_FOR_HEALTH_SYSTEM) && (
              <Select
                propsSelect={{
                  disabled: !selectedLedBy,
                  placeholder: PLACEHOLDERS.PLEASE_SELECT,
                  className: 'row mt-0'
                }}
                options={organizationOptions}
                propsItem={{
                  name: 'organization_id',
                  help: errors.organization && errors.organization[0],
                  validateStatus: errors.organization && 'error',
                  label:
                    selectedLedBy === LED_BY_VALUE.COGNUS_FOR_COMMUNITY
                      ? LABELS.COMMUNITY
                      : LABELS.HEALTH_SYSTEM,
                  rules: [{ required: true }]
                }}
              />
            )}
          </>
        )}
        <div className="row gap-16 space-between align-center mt-0">
          <Input.Number
            propsItem={{
              name: 'expected_spend',
              label: LABELS.EXPECTED_SPEND,
              help: errors.expected_spend && errors.expected_spend[0],
              validateStatus: errors.expected_spend && 'error'
            }}
            propsInputNumber={{
              min: '0',
              maxLength: 64,
              controls: false,
              formatter: currencyFormatter,
              placeholder: '0'
            }}
          />
          <Input.Number
            propsItem={{
              name: 'expected_savings',
              label: LABELS.EXPECTED_SAVINGS,
              help: errors.expected_savings && errors.expected_savings[0],
              validateStatus: errors.expected_savings && 'error'
            }}
            propsInputNumber={{
              min: '0',
              maxLength: 64,
              controls: false,
              formatter: currencyFormatter,
              placeholder: '0'
            }}
          />
        </div>
        <div className="column gap-24">
          <Typography.Headline6>Compliance</Typography.Headline6>
          <Checkbox
            propsItem={{
              name: 'not_measuring_compliance',
              valuePropName: 'checked'
            }}
            className="mt-8"
          >
            Not measuring compliance
          </Checkbox>
          <div className="row mt-0">
            <Input.Number
              propsItem={{
                name: 'compliance_requirement',
                label: LABELS.COMPLIANCE_REQUIREMENT,
                help:
                  errors.compliance_requirement &&
                  errors.compliance_requirement[0],
                validateStatus: errors.compliance_requirement && 'error'
              }}
              propsInputNumber={{
                disabled: notMeasuringCompliance,
                min: '0',
                controls: false,
                parser: percentageParser,
                max: 100,
                placeholder: '0'
              }}
            />
          </div>
        </div>
      </Form>
      <FloatFooter
        isActive={isActive}
        onCancel={onCancel}
        onSave={() => form.submit()}
      />
    </div>
  )
}
