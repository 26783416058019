import './styles.scss'
import EmptyState from './EmptyState'
import { TAddBusinessDetails } from './types'
import { Forms } from '../../../components/Forms'
import { Form } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { convertDetailsToForm } from '../utils'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { Status } from 'components/Status'
import {
  SETTINGS_STATUSES,
  SETTINGS_STATUSES_CLASSNAME,
  SETTINGS_STATUSES_NAME,
  SETTINGS_STATUSES_TOOLTIP
} from '../../../pages/Settings/constants'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { FloatFooter } from '../../../components/FloatFooter/FloatFooter'
import { BTN_TXT } from '../../../constants'
import { useNavigate } from 'react-router'
import { routes } from '../../../router'
import { useParams } from 'react-router-dom'
import useRouter from '../../../hooks/useRouter'
import ConfirmationModal from '../BankAccount/ConfirmationModal'
type TProps = {
  businessDetails: TAddBusinessDetails
  getDetails: () => void
}
const BusinessDetails = ({ businessDetails, getDetails }: TProps) => {
  const navigate = useNavigate()

  const [isActive, setIsActive] = useState(false)
  const [isShowPopup, setIsShowPopup] = useState(false)

  const [form] = Form.useForm()

  const { hospitalId } = useParams()
  const {
    query: { hsId }
  } = useRouter()

  useEffect(() => {
    form.setFieldsValue(convertDetailsToForm(businessDetails))
  }, [businessDetails])

  const submitCancellation = () => {
    handleCancelForm()
  }

  const handleClosePopup = () => {
    setIsShowPopup(false)
  }

  const handleSubmitPopup = () => {
    setIsShowPopup(false)
    form.submit()
  }

  const handleCancelForm = useCallback(() => {
    setIsActive(false)
    getDetails()
  }, [form, setIsActive, businessDetails])

  const handleAddBankAccount = () => {
    if (hospitalId && hsId) {
      navigate(
        `${routes.hospitals}/${hospitalId}${routes.addBankAccount}?hsId=${hsId}`
      )
    } else {
      navigate(routes.addBankAccount)
    }
  }

  const handleSubmit = () => {
    if (isActive) {
      setIsShowPopup(true)
    } else {
      handleAddBankAccount()
    }
  }

  return (
    <div className="business-details-container full-width full-height">
      {businessDetails?.uuid ? (
        <div className="container__content details-content">
          <div className="row mb-24 gap-16">
            <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.BOLD}>
              Business details
            </Typography.Body1>
            <Tooltip
              placement="topLeft"
              title={
                SETTINGS_STATUSES_TOOLTIP[
                  businessDetails.status || SETTINGS_STATUSES.CREATED
                ]
              }
            >
              <div>
                <Status.Default
                  className={
                    SETTINGS_STATUSES_CLASSNAME[
                      businessDetails.status || SETTINGS_STATUSES.CREATED
                    ]
                  }
                >
                  {
                    SETTINGS_STATUSES_NAME[
                      businessDetails.status || SETTINGS_STATUSES.CREATED
                    ]
                  }
                </Status.Default>
              </div>
            </Tooltip>
          </div>
          <Forms.AddBusinessDetails
            form={form}
            callback={() => {
              getDetails()
            }}
            setIsActive={setIsActive}
          />
          <FloatFooter
            isActive={isActive}
            onCancel={submitCancellation}
            onSave={handleSubmit}
            isSaveDisabled={false}
            saveBtnText={BTN_TXT.SAVE}
          />
        </div>
      ) : (
        <EmptyState />
      )}
      <ConfirmationModal
        visible={isShowPopup}
        handleSubmit={handleSubmitPopup}
        handleReject={handleClosePopup}
      />
    </div>
  )
}

export default BusinessDetails
