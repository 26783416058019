import { Container } from '../../components/Container/Container'
import { ADD_BANK_ACCOUNT_BREADCRUMBS } from './constants'
import { Forms } from '../../components/Forms'
import { FloatFooter } from '../../components/FloatFooter/FloatFooter'
import { BTN_TXT } from '../../constants'
import { Form } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { addBankAccountInitialValues } from '../../components/Forms/constants'
import { useParams } from 'react-router-dom'
import useRouter from '../../hooks/useRouter'
import { getHospitalData } from '../../features/HealthSystemDetails/HospitalsTable/api'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../redux/store/user/getters'
import { setLoading } from '../../redux/store/common/slice'
import { THospital } from '../../features/HealthSystemDetails/HospitalsTable/types'
const AddBankAccount = () => {
  const [form] = Form.useForm()
  const [isActive, setIsActive] = useState(false)

  const dispatch = useDispatch()

  const [hospitalDetails, setHospitalDetails] = useState<THospital>()
  const { hospitalId } = useParams()
  const {
    query: { hsId }
  } = useRouter()

  const user = useSelector(getUser)

  const getHospitalDetails = useCallback(async () => {
    try {
      dispatch(setLoading(true))
      const response = await getHospitalData(
        user.community ?? '',
        hsId ?? '',
        hospitalId ?? ''
      )
      setHospitalDetails(response.data)
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [user, hospitalId, hsId])

  useEffect(() => {
    if (hospitalId && hsId) {
      getHospitalDetails()
    }
  }, [])

  const submitCancellation = () => {
    handleCancelForm()
  }

  const handleCancelForm = useCallback(() => {
    setIsActive(false)
    form.setFieldsValue(addBankAccountInitialValues)
    history.back()
  }, [form, setIsActive])
  return (
    <Container
      title={'Add Bank account'}
      breadcrumbs={ADD_BANK_ACCOUNT_BREADCRUMBS(hospitalDetails?.name)}
    >
      <div>
        <Forms.AddBankAccount form={form} setIsActive={setIsActive} />
      </div>
      <FloatFooter
        isActive={isActive}
        onCancel={submitCancellation}
        onSave={form.submit}
        isSaveDisabled={!isActive}
        saveBtnText={BTN_TXT.ADD}
      />
    </Container>
  )
}

export default AddBankAccount
