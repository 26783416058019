import { useMemo } from 'react'
import { BTN_TXT } from 'constants/txt'
import { Popup } from 'components/Popup'
import { Typography } from 'components/Typography'
import { Button, BUTTON_TYPES } from 'components/Button'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import { useContractDetailsPopup } from 'features/ContractDetails/Providers/ContractDetailsPopupProvider'

export default () => {
  const contractDetailsContext = useContractDetailsContext()
  const { acceptContractDocumentPopup } = useContractDetailsPopup()
  const { handleAcceptContractDocumentAsync } = contractDetailsContext.actions

  const { close } = acceptContractDocumentPopup.actions

  const Footer = useMemo(
    () => (
      <>
        <Button type={BUTTON_TYPES.DEFAULT} onClick={close} upperCase>
          {BTN_TXT.CANCEL}
        </Button>
        <Button
          onClick={handleAcceptContractDocumentAsync}
          type={BUTTON_TYPES.PRIMARY}
          upperCase
        >
          {BTN_TXT.ACCEPT}
        </Button>
      </>
    ),
    [close, handleAcceptContractDocumentAsync]
  )

  return (
    <Popup {...acceptContractDocumentPopup.state} width={442} footer={Footer}>
      <Typography.Body1>
        Before proceeding, please review all details carefully. Once accepted,
        changes may not be possible.
      </Typography.Body1>
    </Popup>
  )
}
