import { useNavigate } from 'react-router'
import { Banner } from '../../../components/Banner/Banner'
import { useFinancialsContext } from '../Providers/FinancialsProvider'
import { BTN_TXT } from '../../../constants'
import { routes } from '../../../router'

export const BankAccountError = () => {
  const navigate = useNavigate()
  const {
    state: { businessResource, hasBankAccount }
  } = useFinancialsContext()

  const handleAddBankAccount = () => {
    if (!businessResource) {
      navigate(routes.addBusinessDetails)
    } else if (!hasBankAccount) {
      navigate(routes.addBankAccount)
    }
  }

  return (
    <Banner.Error
      text="Please create bank account to initiate a payment."
      className="mb-24"
      button={{
        buttonText: BTN_TXT.ADD_BANK_ACCOUNT,
        onButtonClick: handleAddBankAccount
      }}
    />
  )
}
