import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability'

import { IUser } from 'redux/store/user/types'
import { ROLES } from 'features/Permission'
import {
  Conditions,
  ContractAbility,
  ContractAbilityBuilder,
  PossibleAbilities
} from 'features/ContractDetails/Abilities/types'
import { ACTIONS, SUBJECTS } from 'features/ContractDetails/Abilities/constants'
import {
  isAutoContract,
  isFinishDateEmpty,
  isInStatuses,
  isNotInStatuses,
  isOwnerCondition,
  isResponsibleCommonsCondition,
  isResponsibleCommunityCondition,
  isResponsibleVendorCondition,
  isStartDateEmpty,
  isManualContract,
  areStartAndFinishNotEmptyForAuto
} from 'features/ContractDetails/Abilities/utils'
import { CONTRACT_STATUS } from 'features/ContractDetails/constants'

const ContractAbilityClass = Ability as AbilityClass<ContractAbility>

export const ability = new Ability<PossibleAbilities, Conditions>()

export default (user: IUser) => {
  const builder = new AbilityBuilder(ContractAbilityClass)

  defineAnonymousAbilities(builder)

  switch (user.role) {
    case ROLES.COMMUNITY_PRESIDENT:
    case ROLES.VICE_PRESIDENT:
    case ROLES.CONTRACT_STEWARD:
      defineOwnerAbilities(builder, user)
      break
    case ROLES.VENDOR_CONTRACT_STEWARD:
      defineVCSAbilities(builder, user)
      break
    case ROLES.ANALYST:
    case ROLES.LEGAL:
      defineResponsibleCommunityAbilities(builder, user)
      break
    case ROLES.COMMONS_VICE_PRESIDENT:
    case ROLES.COMMONS_CONTRACT_STEWARD:
    case ROLES.COMMONS_ANALYST:
    case ROLES.COMMONS_LEGAL:
    case ROLES.COMMONS_CLINICAL_COORDINATOR:
      defineCognusCommonsAbilities(builder, user)
      break
    case ROLES.VENDOR_ANALYST:
    case ROLES.VENDOR_LEGAL:
      defineResponsibleVendorAbilities(builder, user)
      break
    case ROLES.VENDOR_FINANCE_OFFICER:
      defineResponsibleVendorFinancialAbilities(builder)
      break
    case ROLES.VENDOR_CONTRACT_ADMIN:
      defineResponsibleVendorContactAdminAbilities(builder)
      break
  }

  return new Ability(builder.rules) as ContractAbility
}

const defineOwnerAbilities = (builder: ContractAbilityBuilder, user: IUser) => {
  builder.can(
    [ACTIONS.VIEW, ACTIONS.CANCEL],
    SUBJECTS.CONTRACT,
    isOwnerCondition(user)
  )
  builder.can(ACTIONS.VIEW, SUBJECTS.ITEM_LIST)
  builder.can(ACTIONS.VIEW, SUBJECTS.REBATE_CONDITIONS)
  builder.can(ACTIONS.VIEW, SUBJECTS.ALL_INFO)

  builder.can(ACTIONS.EDIT, SUBJECTS.CONTRACT, {
    ...isOwnerCondition(user),
    ...isInStatuses([
      CONTRACT_STATUS.CONTRACTING,
      CONTRACT_STATUS.REDLINING,
      CONTRACT_STATUS.OUT_FOR_SIGNATURE
    ])
  })

  builder.can(ACTIONS.EDIT, SUBJECTS.CONTRACT, {
    ...isOwnerCondition(user),
    ...isInStatuses([CONTRACT_STATUS.EXECUTED]),
    ...isStartDateEmpty(),
    ...isAutoContract()
  })
  builder.can(ACTIONS.EDIT, SUBJECTS.CONTRACT, {
    ...isOwnerCondition(user),
    ...isInStatuses([CONTRACT_STATUS.EXECUTED]),
    ...isFinishDateEmpty(),
    ...isAutoContract()
  })

  if (user.role !== ROLES.COMMUNITY_PRESIDENT) {
    builder.can(ACTIONS.UPLOAD, SUBJECTS.ITEM_LIST, {
      ...isOwnerCondition(user),
      ...isNotInStatuses([
        CONTRACT_STATUS.EXPIRED,
        CONTRACT_STATUS.TERMINATED,
        CONTRACT_STATUS.DECLINED
      ])
    })
    builder.can(ACTIONS.SUBMIT, SUBJECTS.LOP_SIGNATORY, {
      ...isOwnerCondition(user)
    })
    builder.can(ACTIONS.VIEW, SUBJECTS.LOP_SIGNATORY, {
      ...isOwnerCondition(user)
    })

    builder.can(ACTIONS.ADD, SUBJECTS.SIGNATORY, {
      ...isOwnerCondition(user),
      ...isInStatuses([CONTRACT_STATUS.CONTRACTING, CONTRACT_STATUS.REDLINING])
    })
    builder.can(ACTIONS.VIEW, SUBJECTS.SIGNATORY, {
      ...isOwnerCondition(user)
    })
    builder.can(ACTIONS.UPLOAD, SUBJECTS.INITIAL_DOCUMENT, {
      ...isOwnerCondition(user),
      ...isInStatuses([CONTRACT_STATUS.CONTRACTING])
    })
    builder.can(ACTIONS.UPLOAD, SUBJECTS.NEW_DOCUMENT, {
      ...isOwnerCondition(user),
      ...isInStatuses([CONTRACT_STATUS.CONTRACTING, CONTRACT_STATUS.REDLINING])
    })
    builder.can(ACTIONS.UPLOAD, SUBJECTS.ATTACHMENTS, {
      ...isOwnerCondition(user),
      ...isNotInStatuses([
        CONTRACT_STATUS.EXPIRED,
        CONTRACT_STATUS.TERMINATED,
        CONTRACT_STATUS.DECLINED
      ])
    })
    builder.can(ACTIONS.DOWNLOAD, SUBJECTS.TEMPLATES, {
      ...isOwnerCondition(user),
      ...isInStatuses([CONTRACT_STATUS.CONTRACTING])
    })
    builder.can(ACTIONS.DOWNLOAD, SUBJECTS.DOCUMENTS, {
      ...isOwnerCondition(user)
    })
    builder.can(ACTIONS.ACCEPT, SUBJECTS.CONTRACT, {
      ...isOwnerCondition(user),
      ...isInStatuses([CONTRACT_STATUS.REDLINING]),
      ...isAutoContract()
    })
  }

  if (user.role === ROLES.COMMUNITY_PRESIDENT) {
    builder.can(ACTIONS.VIEW, SUBJECTS.LOP_SIGNATORY)
  }

  builder.can(ACTIONS.SUBMIT, SUBJECTS.CONTRACT_SIGNED, {
    ...isOwnerCondition(user),
    ...isManualContract()
  })

  builder.can(ACTIONS.SETUP, SUBJECTS.TIMELINES, {
    ...isOwnerCondition(user),
    ...isInStatuses([
      CONTRACT_STATUS.CONTRACTING,
      CONTRACT_STATUS.REDLINING,
      CONTRACT_STATUS.OUT_FOR_SIGNATURE
    ])
  })

  builder.can(ACTIONS.EXTEND, SUBJECTS.CONTRACT, {
    ...isOwnerCondition(user),
    ...isInStatuses([CONTRACT_STATUS.ACTIVE, CONTRACT_STATUS.EXPIRED])
  })

  builder.can(ACTIONS.EXTEND, SUBJECTS.CONTRACT, {
    ...isOwnerCondition(user),
    ...areStartAndFinishNotEmptyForAuto(),
    ...isInStatuses([
      CONTRACT_STATUS.EXECUTED,
      CONTRACT_STATUS.PENDING_FOR_START
    ])
  })

  builder.can(ACTIONS.EXTEND, SUBJECTS.CONTRACT, {
    ...isOwnerCondition(user),
    ...isManualContract(),
    ...isInStatuses([
      CONTRACT_STATUS.EXECUTED,
      CONTRACT_STATUS.PENDING_FOR_START
    ])
  })

  builder.can(ACTIONS.EDIT, SUBJECTS.REBATE_CONDITIONS, {
    ...isOwnerCondition(user),
    ...isInStatuses([
      CONTRACT_STATUS.CONTRACTING,
      CONTRACT_STATUS.REDLINING,
      CONTRACT_STATUS.OUT_FOR_SIGNATURE
    ])
  })

  builder.can(ACTIONS.SUBMIT, SUBJECTS.LOP, {
    ...isOwnerCondition(user),
    ...isNotInStatuses([
      CONTRACT_STATUS.TERMINATED,
      CONTRACT_STATUS.EXPIRED,
      CONTRACT_STATUS.DECLINED
    ])
  })
}

const defineVCSAbilities = (builder: ContractAbilityBuilder, user: IUser) => {
  builder.can(
    [ACTIONS.VIEW, ACTIONS.CANCEL],
    SUBJECTS.CONTRACT,
    isResponsibleVendorCondition(user)
  )
  builder.can(ACTIONS.VIEW, SUBJECTS.LOP_SIGNATORY, {
    ...isResponsibleVendorCondition(user)
  })

  builder.can(ACTIONS.VIEW, SUBJECTS.ITEM_LIST)
  builder.can(ACTIONS.VIEW, SUBJECTS.REBATE_CONDITIONS)
  builder.can(ACTIONS.VIEW, SUBJECTS.ALL_INFO)
  builder.can(ACTIONS.ADD, SUBJECTS.RESPONSIBLE_USER, {
    ...isResponsibleVendorCondition(user),
    ...isInStatuses([
      CONTRACT_STATUS.CONTRACTING,
      CONTRACT_STATUS.OUT_FOR_SIGNATURE,
      CONTRACT_STATUS.REDLINING,
      CONTRACT_STATUS.ACTIVE,
      CONTRACT_STATUS.EXECUTED,
      CONTRACT_STATUS.EXPIRED
    ])
  })
  builder.can(ACTIONS.DELETE, SUBJECTS.RESPONSIBLE_USER, {
    ...isResponsibleVendorCondition(user),
    ...isInStatuses([
      CONTRACT_STATUS.CONTRACTING,
      CONTRACT_STATUS.OUT_FOR_SIGNATURE,
      CONTRACT_STATUS.REDLINING,
      CONTRACT_STATUS.ACTIVE,
      CONTRACT_STATUS.EXECUTED,
      CONTRACT_STATUS.EXPIRED
    ])
  })
  builder.can(ACTIONS.ADD, SUBJECTS.SIGNATORY, {
    ...isResponsibleVendorCondition(user),
    ...isInStatuses([CONTRACT_STATUS.REDLINING])
  })
  builder.can(ACTIONS.VIEW, SUBJECTS.SIGNATORY, {
    ...isResponsibleVendorCondition(user)
  })
  builder.can(ACTIONS.UPLOAD, SUBJECTS.NEW_DOCUMENT, {
    ...isResponsibleVendorCondition(user),
    ...isInStatuses([CONTRACT_STATUS.CONTRACTING, CONTRACT_STATUS.REDLINING])
  })
  builder.can(ACTIONS.DOWNLOAD, SUBJECTS.DOCUMENTS, {
    ...isResponsibleVendorCondition(user)
  })
  builder.can(ACTIONS.ACCEPT, SUBJECTS.CONTRACT, {
    ...isResponsibleVendorCondition(user),
    ...isInStatuses([CONTRACT_STATUS.REDLINING]),
    ...isAutoContract()
  })

  builder.can(ACTIONS.DECLINE, SUBJECTS.CONTRACT, {
    ...isResponsibleVendorCondition(user),
    ...isInStatuses([
      CONTRACT_STATUS.CONTRACTING,
      CONTRACT_STATUS.OUT_FOR_SIGNATURE,
      CONTRACT_STATUS.PENDING_FOR_START,
      CONTRACT_STATUS.EXECUTED,
      CONTRACT_STATUS.REDLINING,
      CONTRACT_STATUS.ACTIVE
    ])
  })
  builder.can(ACTIONS.UPLOAD, SUBJECTS.ITEM_LIST, {
    ...isResponsibleVendorCondition(user),
    ...isNotInStatuses([
      CONTRACT_STATUS.EXPIRED,
      CONTRACT_STATUS.TERMINATED,
      CONTRACT_STATUS.DECLINED
    ])
  })
}

const defineResponsibleVendorFinancialAbilities = (
  builder: ContractAbilityBuilder
) => {
  builder.can(ACTIONS.VIEW, SUBJECTS.ITEM_LIST)
  builder.can(ACTIONS.VIEW, SUBJECTS.REBATE_CONDITIONS)
  builder.can(ACTIONS.VIEW, SUBJECTS.ALL_INFO)
}
const defineResponsibleVendorContactAdminAbilities = (
  builder: ContractAbilityBuilder
) => {
  builder.can(ACTIONS.VIEW, SUBJECTS.ALL_INFO)
  builder.can(ACTIONS.ADD, SUBJECTS.VENDOR_CONTRACT_STEWARD)
}

const defineResponsibleVendorAbilities = (
  builder: ContractAbilityBuilder,
  user: IUser
) => {
  if (user.role === ROLES.VENDOR_LEGAL) {
    builder.can(ACTIONS.VIEW, SUBJECTS.LOP_SIGNATORY, {
      ...isResponsibleVendorCondition(user)
    })
    builder.can(ACTIONS.ADD, SUBJECTS.SIGNATORY, {
      ...isResponsibleVendorCondition(user),
      ...isInStatuses([CONTRACT_STATUS.REDLINING])
    })
    builder.can(ACTIONS.VIEW, SUBJECTS.SIGNATORY, {
      ...isResponsibleVendorCondition(user)
    })
    builder.can(ACTIONS.UPLOAD, SUBJECTS.NEW_DOCUMENT, {
      ...isResponsibleVendorCondition(user),
      ...isInStatuses([CONTRACT_STATUS.CONTRACTING, CONTRACT_STATUS.REDLINING])
    })
    builder.can(ACTIONS.DOWNLOAD, SUBJECTS.DOCUMENTS, {
      ...isResponsibleVendorCondition(user)
    })
    builder.can(ACTIONS.ACCEPT, SUBJECTS.CONTRACT, {
      ...isResponsibleVendorCondition(user),
      ...isInStatuses([CONTRACT_STATUS.REDLINING]),
      ...isAutoContract()
    })
  }
  builder.can(ACTIONS.VIEW, SUBJECTS.ITEM_LIST)
  builder.can(ACTIONS.VIEW, SUBJECTS.REBATE_CONDITIONS)
  builder.can(ACTIONS.VIEW, SUBJECTS.ALL_INFO)
  builder.can(ACTIONS.DECLINE, SUBJECTS.CONTRACT, {
    ...isResponsibleVendorCondition(user),
    ...isInStatuses([
      CONTRACT_STATUS.CONTRACTING,
      CONTRACT_STATUS.OUT_FOR_SIGNATURE,
      CONTRACT_STATUS.PENDING_FOR_START,
      CONTRACT_STATUS.EXECUTED,
      CONTRACT_STATUS.REDLINING,
      CONTRACT_STATUS.ACTIVE
    ])
  })
}

const defineResponsibleCommunityAbilities = (
  builder: ContractAbilityBuilder,
  user: IUser
) => {
  builder.can(ACTIONS.VIEW, SUBJECTS.ITEM_LIST)
  builder.can(ACTIONS.VIEW, SUBJECTS.REBATE_CONDITIONS)
  builder.can(ACTIONS.VIEW, SUBJECTS.ALL_INFO)
  if (user.role !== ROLES.ANALYST) {
    builder.can(ACTIONS.SUBMIT, SUBJECTS.LOP_SIGNATORY, {
      ...isResponsibleCommunityCondition(user)
    })
    builder.can(ACTIONS.VIEW, SUBJECTS.LOP_SIGNATORY, {
      ...isResponsibleCommunityCondition(user)
    })

    builder.can(ACTIONS.ADD, SUBJECTS.SIGNATORY, {
      ...isResponsibleCommunityCondition(user),
      ...isInStatuses([CONTRACT_STATUS.CONTRACTING, CONTRACT_STATUS.REDLINING])
    })
    builder.can(ACTIONS.VIEW, SUBJECTS.SIGNATORY, {
      ...isResponsibleCommunityCondition(user)
    })
    builder.can(ACTIONS.UPLOAD, SUBJECTS.INITIAL_DOCUMENT, {
      ...isResponsibleCommunityCondition(user),
      ...isInStatuses([CONTRACT_STATUS.CONTRACTING])
    })
    builder.can(ACTIONS.UPLOAD, SUBJECTS.NEW_DOCUMENT, {
      ...isResponsibleCommunityCondition(user),
      ...isInStatuses([CONTRACT_STATUS.CONTRACTING, CONTRACT_STATUS.REDLINING])
    })
    builder.can(ACTIONS.UPLOAD, SUBJECTS.ATTACHMENTS, {
      ...isResponsibleCommunityCondition(user),
      ...isNotInStatuses([
        CONTRACT_STATUS.EXPIRED,
        CONTRACT_STATUS.TERMINATED,
        CONTRACT_STATUS.DECLINED
      ])
    })
    builder.can(ACTIONS.DOWNLOAD, SUBJECTS.TEMPLATES, {
      ...isResponsibleCommunityCondition(user),
      ...isInStatuses([CONTRACT_STATUS.CONTRACTING])
    })
    builder.can(ACTIONS.DOWNLOAD, SUBJECTS.DOCUMENTS, {
      ...isResponsibleCommunityCondition(user)
    })
    builder.can(ACTIONS.ACCEPT, SUBJECTS.CONTRACT, {
      ...isResponsibleCommunityCondition(user),
      ...isInStatuses([CONTRACT_STATUS.REDLINING]),
      ...isAutoContract()
    })
  }
}

const defineAnonymousAbilities = (builder: ContractAbilityBuilder) => {
  builder.can(ACTIONS.VIEW, SUBJECTS.CONTRACT)

  builder.can(
    ACTIONS.VIEW,
    SUBJECTS.TIMELINES,
    isInStatuses([
      CONTRACT_STATUS.CONTRACTING,
      CONTRACT_STATUS.REDLINING,
      CONTRACT_STATUS.OUT_FOR_SIGNATURE
    ])
  )
}
const defineCognusCommonsAbilities = (
  builder: ContractAbilityBuilder,
  user: IUser
) => {
  builder.can(ACTIONS.VIEW, SUBJECTS.CONTRACT)
  builder.can(ACTIONS.VIEW, SUBJECTS.ITEM_LIST)
  builder.can(ACTIONS.VIEW, SUBJECTS.REBATE_CONDITIONS)
  builder.can(ACTIONS.VIEW, SUBJECTS.ALL_INFO)
  if (user.role === ROLES.COMMONS_LEGAL) {
    builder.can(ACTIONS.ADD, SUBJECTS.SIGNATORY, {
      ...isResponsibleCommonsCondition(user),
      ...isInStatuses([CONTRACT_STATUS.CONTRACTING, CONTRACT_STATUS.REDLINING])
    })
    builder.can(ACTIONS.VIEW, SUBJECTS.SIGNATORY, {
      ...isResponsibleCommonsCondition(user)
    })
    builder.can(ACTIONS.UPLOAD, SUBJECTS.INITIAL_DOCUMENT, {
      ...isResponsibleCommonsCondition(user),
      ...isInStatuses([CONTRACT_STATUS.CONTRACTING])
    })
    builder.can(ACTIONS.UPLOAD, SUBJECTS.NEW_DOCUMENT, {
      ...isResponsibleCommonsCondition(user),
      ...isInStatuses([CONTRACT_STATUS.CONTRACTING, CONTRACT_STATUS.REDLINING])
    })
    builder.can(ACTIONS.UPLOAD, SUBJECTS.ATTACHMENTS, {
      ...isResponsibleCommonsCondition(user),
      ...isNotInStatuses([
        CONTRACT_STATUS.EXPIRED,
        CONTRACT_STATUS.TERMINATED,
        CONTRACT_STATUS.DECLINED
      ])
    })
    builder.can(ACTIONS.DOWNLOAD, SUBJECTS.TEMPLATES, {
      ...isResponsibleCommonsCondition(user),
      ...isInStatuses([CONTRACT_STATUS.CONTRACTING])
    })
    builder.can(ACTIONS.DOWNLOAD, SUBJECTS.DOCUMENTS, {
      ...isResponsibleCommonsCondition(user)
    })
    builder.can(ACTIONS.ACCEPT, SUBJECTS.CONTRACT, {
      ...isResponsibleCommonsCondition(user),
      ...isInStatuses([CONTRACT_STATUS.REDLINING]),
      ...isAutoContract()
    })
  }
  if (
    user.role === ROLES.COMMONS_CONTRACT_STEWARD ||
    user.role === ROLES.COMMONS_VICE_PRESIDENT
  ) {
    builder.can(ACTIONS.EXTEND, SUBJECTS.CONTRACT, {
      ...isOwnerCondition(user),
      ...isInStatuses([CONTRACT_STATUS.ACTIVE, CONTRACT_STATUS.EXPIRED])
    })

    builder.can(ACTIONS.EXTEND, SUBJECTS.CONTRACT, {
      ...isOwnerCondition(user),
      ...areStartAndFinishNotEmptyForAuto(),
      ...isInStatuses([
        CONTRACT_STATUS.EXECUTED,
        CONTRACT_STATUS.PENDING_FOR_START
      ])
    })

    builder.can(ACTIONS.EXTEND, SUBJECTS.CONTRACT, {
      ...isOwnerCondition(user),
      ...isManualContract(),
      ...isInStatuses([
        CONTRACT_STATUS.EXECUTED,
        CONTRACT_STATUS.PENDING_FOR_START
      ])
    })

    builder.can(ACTIONS.CANCEL, SUBJECTS.CONTRACT, {
      ...isResponsibleCommonsCondition(user)
    })
    builder.can(ACTIONS.ADD, SUBJECTS.SIGNATORY, {
      ...isOwnerCondition(user),
      ...isInStatuses([CONTRACT_STATUS.CONTRACTING, CONTRACT_STATUS.REDLINING])
    })
    builder.can(ACTIONS.VIEW, SUBJECTS.SIGNATORY, {
      ...isOwnerCondition(user)
    })
    builder.can(ACTIONS.UPLOAD, SUBJECTS.INITIAL_DOCUMENT, {
      ...isOwnerCondition(user),
      ...isInStatuses([CONTRACT_STATUS.CONTRACTING])
    })
    builder.can(ACTIONS.UPLOAD, SUBJECTS.NEW_DOCUMENT, {
      ...isOwnerCondition(user),
      ...isInStatuses([CONTRACT_STATUS.CONTRACTING, CONTRACT_STATUS.REDLINING])
    })
    builder.can(ACTIONS.UPLOAD, SUBJECTS.ATTACHMENTS, {
      ...isOwnerCondition(user),
      ...isNotInStatuses([
        CONTRACT_STATUS.EXPIRED,
        CONTRACT_STATUS.TERMINATED,
        CONTRACT_STATUS.DECLINED
      ])
    })
    builder.can(ACTIONS.DOWNLOAD, SUBJECTS.TEMPLATES, {
      ...isOwnerCondition(user),
      ...isInStatuses([CONTRACT_STATUS.CONTRACTING])
    })
    builder.can(ACTIONS.DOWNLOAD, SUBJECTS.DOCUMENTS, {
      ...isOwnerCondition(user)
    })
    builder.can(ACTIONS.ACCEPT, SUBJECTS.CONTRACT, {
      ...isOwnerCondition(user),
      ...isInStatuses([CONTRACT_STATUS.REDLINING]),
      ...isAutoContract()
    })

    builder.can(ACTIONS.UPLOAD, SUBJECTS.ITEM_LIST, {
      ...isResponsibleCommonsCondition(user),
      ...isNotInStatuses([
        CONTRACT_STATUS.EXPIRED,
        CONTRACT_STATUS.TERMINATED,
        CONTRACT_STATUS.DECLINED
      ])
    })
    builder.can(ACTIONS.UPLOAD, SUBJECTS.ITEM_LIST, {
      ...isOwnerCondition(user),
      ...isNotInStatuses([
        CONTRACT_STATUS.EXPIRED,
        CONTRACT_STATUS.TERMINATED,
        CONTRACT_STATUS.DECLINED
      ])
    })

    builder.can(ACTIONS.SUBMIT, SUBJECTS.LOP_SIGNATORY, {
      ...isOwnerCondition(user)
    })
    builder.can(ACTIONS.VIEW, SUBJECTS.LOP_SIGNATORY, {
      ...isOwnerCondition(user)
    })

    builder.can(ACTIONS.ADD, SUBJECTS.HEALTH_SYSTEM, {
      ...isOwnerCondition(user),
      ...isInStatuses([
        CONTRACT_STATUS.CONTRACTING,
        CONTRACT_STATUS.OUT_FOR_SIGNATURE,
        CONTRACT_STATUS.PENDING_FOR_START,
        CONTRACT_STATUS.ACTIVE
      ])
    })
    builder.can(ACTIONS.SETUP, SUBJECTS.TIMELINES, {
      ...isOwnerCondition(user),
      ...isInStatuses([
        CONTRACT_STATUS.CONTRACTING,
        CONTRACT_STATUS.REDLINING,
        CONTRACT_STATUS.OUT_FOR_SIGNATURE
      ])
    })
    builder.can(ACTIONS.EDIT, SUBJECTS.CONTRACT, {
      ...isOwnerCondition(user),
      ...isInStatuses([
        CONTRACT_STATUS.CONTRACTING,
        CONTRACT_STATUS.REDLINING,
        CONTRACT_STATUS.OUT_FOR_SIGNATURE
      ])
    })

    builder.can(ACTIONS.EDIT, SUBJECTS.CONTRACT, {
      ...isOwnerCondition(user),
      ...isInStatuses([CONTRACT_STATUS.EXECUTED]),
      ...isAutoContract(),
      ...isStartDateEmpty()
    })

    builder.can(ACTIONS.EDIT, SUBJECTS.CONTRACT, {
      ...isOwnerCondition(user),
      ...isInStatuses([CONTRACT_STATUS.EXECUTED]),
      ...isFinishDateEmpty(),
      ...isAutoContract()
    })

    builder.can(ACTIONS.SUBMIT, SUBJECTS.LOP, {
      ...isOwnerCondition(user),
      ...isNotInStatuses([
        CONTRACT_STATUS.TERMINATED,
        CONTRACT_STATUS.EXPIRED,
        CONTRACT_STATUS.DECLINED
      ])
    })
  }

  builder.can(ACTIONS.SUBMIT, SUBJECTS.CONTRACT_SIGNED, {
    ...isOwnerCondition(user),
    ...isManualContract()
  })
  builder.can(ACTIONS.EDIT, SUBJECTS.REBATE_CONDITIONS, {
    ...isOwnerCondition(user),
    ...isInStatuses([
      CONTRACT_STATUS.CONTRACTING,
      CONTRACT_STATUS.OUT_FOR_SIGNATURE,
      CONTRACT_STATUS.REDLINING
    ])
  })
  builder.can(ACTIONS.CANCEL, SUBJECTS.CONTRACT, { ...isOwnerCondition(user) })

  if (user.role === ROLES.COMMONS_LEGAL) {
    builder.can(ACTIONS.SUBMIT, SUBJECTS.LOP_SIGNATORY, {
      ...isResponsibleCommonsCondition(user)
    })
    builder.can(ACTIONS.VIEW, SUBJECTS.LOP_SIGNATORY, {
      ...isResponsibleCommonsCondition(user)
    })
  }
}
