import { FC, useState } from 'react'
import '../UserList/styles.scss'
import { Typography } from 'components/Typography'
import { Button, BUTTON_TYPES } from 'components/Button'
import { ReactComponent as Delete16 } from 'assets/svg/Delete16.svg'
import { ReactComponent as ReassignIcon } from 'assets/svg/Reassign.svg'
import { TResponsibleUser } from 'features/RFP/RFPDetails/types'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { useRFPDetailsPopupContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsPopupProvider'
import { ROLES_NAMES } from 'constants/common'
import { Avatar } from 'components/Avatar/Avatar'
import { RFP_STATUSES } from '../../../constants'
import { getCapitalCharName } from '../../../../../helper/common'
import { ACTIONS, ROLES, SUBJECTS } from 'features/Permission/constants'
import { Can } from 'features/Permission'
import { subject } from '@casl/ability'
import { Reassign } from '../../../../Reassign/ReassignPopup'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../../redux/store/user/getters'
import { StartChat } from 'components/StartChat/StartChat'
import { onStartChat } from 'features/MyCommunity/utils'

type Props = {
  user: TResponsibleUser
}

export const UserListItemLedCentral: FC<Props> = (props) => {
  const { user } = props
  const currentUser = useSelector(getUser)
  const [isOpenReassign, setIsOpenReassign] = useState(false)

  const rfpDetailsContext = useRFPDetailsContext()
  const { handleGetRfpData } = rfpDetailsContext.actions
  const { deleteResponsibleUserPopup } = useRFPDetailsPopupContext()

  const { data, canReassignCommons, canCRUDCommonsResponsibles } =
    rfpDetailsContext.state
  const { open } = deleteResponsibleUserPopup.actions

  const name = user.first_name + ' ' + user.last_name
  const isNotCurrentUser = currentUser.uuid !== user.uuid

  const onDelete = () => {
    if (user) {
      open({ user })
    }
  }

  const canDeleteResponsibleUser =
    data.status !== RFP_STATUSES.CANCELED &&
    data.status !== RFP_STATUSES.ARCHIVED &&
    canCRUDCommonsResponsibles &&
    (user.role === ROLES.COMMONS_ANALYST || user.role === ROLES.COMMONS_LEGAL)

  const canReassignCommonsUser =
    data.status !== RFP_STATUSES.CANCELED &&
    data.status !== RFP_STATUSES.ARCHIVED &&
    canReassignCommons &&
    (user.role === ROLES.COMMONS_VICE_PRESIDENT ||
      user.role === ROLES.COMMONS_CONTRACT_STEWARD) &&
    user.role.includes('commons')
  const canReassignCommunityUser =
    data.status !== RFP_STATUSES.CANCELED &&
    data.status !== RFP_STATUSES.ARCHIVED &&
    (currentUser.role === ROLES.COMMUNITY_PRESIDENT ||
      currentUser.role === ROLES.VICE_PRESIDENT) &&
    user.role.includes('community')

  return (
    <>
      <div className="rfp-details-responsible-user">
        <div className="rfp-details-responsible-user__user">
          <div className="rfp-details-responsible-user__avatar">
            <Avatar
              name={getCapitalCharName(user.first_name, user?.last_name)}
              alt="Responsible user image"
              src={user.avatar?.file_preview}
            />
          </div>
          <div className="rfp-details-responsible-user__content">
            <Typography.Body1 weight="semi-bold">{name}</Typography.Body1>
            <Typography.Caption className="rfp-details-responsible-user__role">
              {ROLES_NAMES[user.role]}
            </Typography.Caption>
          </div>
        </div>
        <div className="rfp-details-responsible-user__actions flex">
          {canDeleteResponsibleUser ? (
            <Button.Icon
              type={BUTTON_TYPES.LINK}
              icon={<Delete16 />}
              onClick={onDelete}
              small
              danger
            />
          ) : (
            (canReassignCommonsUser || canReassignCommunityUser) && (
              <Button.Icon
                type={BUTTON_TYPES.LINK}
                icon={<ReassignIcon />}
                onClick={() => setIsOpenReassign(true)}
                small
              />
            )
          )}
          {isNotCurrentUser && (
            <Can
              I={ACTIONS.CREATE}
              a={subject(SUBJECTS.CHAT, {
                ...data
              })}
            >
              <StartChat
                onClick={() => onStartChat(user.uuid)}
                type={BUTTON_TYPES.LINK}
              />
            </Can>
          )}
        </div>
      </div>
      {(canReassignCommonsUser || canReassignCommunityUser) && (
        <Reassign
          isRFP
          isReassignPopupOpen={isOpenReassign}
          setIsReassignPopupOpen={setIsOpenReassign}
          ownerRole={user.role}
          ownerName={name}
          uuid={data?.uuid}
          update={handleGetRfpData}
        />
      )}
    </>
  )
}
