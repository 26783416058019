import { Pie } from '@ant-design/plots'
import {
  currencyFormatter,
  convertToFixed
} from '../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { sumFormatter } from '../../../utils/common'
import { REPORTS_CHAT_COLORS } from './constants'
import { FINANCIAL_STATUS_LABEL } from '../constants'
import { PaymentStatusChartProps } from './types'

export const PaymentStatusChart = ({
  data,
  total = 0,
  needMultiply = false
}: PaymentStatusChartProps) => {
  const config = {
    appendPadding: 0,
    data,
    tooltip: {
      customContent: (_title, data) =>
        data.map((i, idx) => (
          <div className="chart__tooltip" key={idx}>
            {FINANCIAL_STATUS_LABEL[i.data.status]} <br /> $
            {currencyFormatter(convertToFixed(i.value))} (
            {Number(
              needMultiply ? i.data.percentage * 100 : i.data.percentage
            ).toFixed(2)}
            %)
          </div>
        ))
    },
    pieStyle: {
      lineWidth: 0
    },
    angleField: 'amount',
    colorField: 'status',
    radius: 1,
    innerRadius: 0.7,
    indicator: false,
    color: data.find((i) => !!i.amount)
      ? data.map((i) => REPORTS_CHAT_COLORS[i.status])
      : ['#F0F3F7'],
    label: {
      type: 'inner',
      offset: '-50%',
      content: '',
      style: {
        textAlign: 'center',
        fontSize: 14
      }
    },
    legend: false as const,
    interactions: [],
    statistic: {
      title: {
        style: {
          color: '#103861',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '16px',
          lineHeight: '24px'
        },
        offsetY: 5,
        customHtml: (_container, _view) =>
          `<div >$${sumFormatter(total, 2)}</div>`
      },
      content: {
        style: {
          color: '#6D747D',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px'
        },
        offsetY: 8,
        content: 'Reports'
      }
    }
  }
  return <Pie {...config} />
}
