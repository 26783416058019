import Typography from '../../../components/Typography/Typography'
import { TYPOGRAPHY_WEIGHT } from '../../../components/Typography'
import { Tooltip } from '../../../components/Tooltip/Tooltip'
import {
  currencyFormatter,
  getCurrencyValue,
  getHeight,
  sumPercentageTotal,
  totalSpendCounting
} from '../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { safeNumber } from '../../../helper/common'
import { useComplianceContext } from '../../../pages/Compliance/Providers/ComplianceContextProvider'
import moment from 'moment'
import cn from 'classnames'

const SpendByMonth = () => {
  const { dataPerMonth, date } = useComplianceContext().state
  const { setDate } = useComplianceContext().actions
  const maxTotalSpent = dataPerMonth
    .map((i) => +i.non_compliant_spend + +i.compliant_spend)
    .reduce((p, v) => (p > v ? p : v), 0)

  return (
    <div className="compliance-container__chart-wrapper mt-24">
      <Typography.Body1
        className="compliance-container__chart-wrapper__title"
        weight={TYPOGRAPHY_WEIGHT.BOLD}
      >
        Compliant Spend by Month
      </Typography.Body1>
      <div className="charts_ongoing-contracts_container spent__widget">
        <div className="chart__container">
          {dataPerMonth.map((dataPart, ind) => (
            <div
              className="compliant__chart-column"
              data-testid="compliantSpendByMonthChartColumn"
              key={ind}
            >
              <div onClick={() => setDate(moment(dataPart.month, 'MMM YYYY'))}>
                <Tooltip
                  title={
                    <div>
                      Non-compliant <br />
                      Spend: $
                      {currencyFormatter(
                        safeNumber(dataPart?.non_compliant_spend).toFixed(2)
                      )}
                      &nbsp;(
                      {sumPercentageTotal(
                        dataPart?.non_compliant_spend,
                        +dataPart?.non_compliant_spend +
                          +dataPart?.compliant_spend
                      )}
                      %) <br /> <br />
                      Compliant <br />
                      Spend: $
                      {currencyFormatter(
                        safeNumber(dataPart?.compliant_spend).toFixed(2)
                      )}
                      &nbsp;(
                      {sumPercentageTotal(
                        dataPart.compliant_spend,
                        +dataPart?.non_compliant_spend +
                          +dataPart?.compliant_spend
                      )}
                      %)
                    </div>
                  }
                  placement="topLeft"
                >
                  <div
                    className="compliant__chart-part"
                    style={{
                      backgroundColor: '#F38B8D',
                      opacity:
                        dataPart.month === moment(date).format('MMM YYYY')
                          ? 1
                          : 0.55,
                      height: `${
                        getHeight(
                          dataPart.non_compliant_spend,
                          maxTotalSpent
                        ) || 0
                      }px`
                    }}
                  />
                </Tooltip>
                <Tooltip
                  title={
                    <div>
                      Compliant <br />
                      Spend: $
                      {currencyFormatter(
                        safeNumber(dataPart?.compliant_spend).toFixed(2)
                      )}
                      &nbsp;(
                      {sumPercentageTotal(
                        dataPart.compliant_spend,
                        +dataPart?.non_compliant_spend +
                          +dataPart?.compliant_spend
                      )}
                      %) <br /> <br />
                      Non-compliant <br />
                      Spend: $
                      {currencyFormatter(
                        safeNumber(dataPart?.non_compliant_spend).toFixed(2)
                      )}
                      &nbsp;(
                      {sumPercentageTotal(
                        dataPart?.non_compliant_spend,
                        +dataPart?.non_compliant_spend +
                          +dataPart?.compliant_spend
                      )}
                      %)
                    </div>
                  }
                  placement="topLeft"
                >
                  <div
                    className="compliant__chart-part"
                    data-testid="compliantSpendByMonthChartPart"
                    style={{
                      backgroundColor: '#5B8FCE',
                      opacity:
                        dataPart.month === moment(date).format('MMM YYYY')
                          ? 1
                          : 0.55,
                      height: `${
                        getHeight(dataPart.compliant_spend, maxTotalSpent) || 0
                      }px`
                    }}
                  />
                </Tooltip>
              </div>
              <Typography.Label
                className={cn('chart-part__description', {
                  active: dataPart.month === moment(date).format('MMM YYYY')
                })}
                weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
                onClick={() => setDate(moment(dataPart.month, 'MMM YYYY'))}
              >
                {dataPart.month}
              </Typography.Label>
            </div>
          ))}
        </div>
        <div className="chart__scale">
          {Object.values(totalSpendCounting(maxTotalSpent)).map((part, ind) => {
            return (
              <div className="scale__part" key={ind}>
                <Typography.Label key={ind}>
                  {getCurrencyValue(part).toLowerCase()}
                </Typography.Label>
                <span className="scale__line"> </span>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default SpendByMonth
