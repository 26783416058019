import {
  CONTRACT_STATUS,
  SIGNATORY_STATUS
} from 'features/ContractDetails/constants'
import { IPartialUserInfo } from 'redux/store/user/types'
import { TListItem } from 'components/Forms/forms.user.d'
import { TCommunityHS } from 'features/HealthSystemDetails/types'
import { TResponsibleUser, TRFPDocument } from 'features/RFP/RFPDetails/types'
import { RebateDataRequest } from 'features/ContractDetails/RebateConditions/RebateConditionsForm/types'
import { TQueryParams } from 'components/Table/types'
import { IContractHealthSystem } from './HSList/types'
import { HealthSystem } from '../MyCommunity/HealthSystems/types'
import { RECONCILIATION_STATUSES } from '../ContractItems/types'

type CreatorResponse = Omit<IPartialUserInfo, 'health_system'> & {
  health_system?: HealthSystem
}

export type ContractDetailsResponse = Omit<IContractDetails, 'creator'> & {
  creator: CreatorResponse
}

export enum ContractCreationWays {
  MANUAL = 'manual',
  AUTO = 'auto'
}

export type IContractDetails = RebateDataRequest & {
  uuid: string
  name: string
  number: string
  status: ContractStatusUnion
  contract_category_name: string
  start_date: string | null
  pricing_effective_date: string | null
  finish_date: string | null
  primary_finish_date: string | null
  admin_fee: string
  payment_term_days: number | null
  admin_payment_frequency: string | null
  creation_way: ContractCreationWays
  creator: IPartialUserInfo
  vendor: TListItem
  community: TCommunityHS
  rfp: string
  rfp_name: string
  amendment_document: string
  community_responsibles: TResponsibleUser[]
  vendor_responsibles: TResponsibleUser[]
  commons_responsibles: TResponsibleUser[]
  contract_document_templates: TRFPDocument[]
  contract_attachments: TRFPDocument[]
  contract_attachments_ids?: string[]
  contract_extension_documents: Array<TRFPDocument & { finish_date: string }>
  timelines: IContractTimeline[]
  prs: string
  prs_name: string
  creation_type: string
  health_systems?: IContractHealthSystem[]
  pending_reconciliation?: TPPendingReconciliationObject
  contract_category_id?: string
  led_by: string
}

export type TContractDocument = {
  uuid: string
  file: string
  creator?: IPartialUserInfo
  accepted_by: Array<IPartialUserInfo>
  signatories: Array<TSignatory>
  created_at?: string
}

export type TSignatory = {
  status: SignatoryStatusUnion
  email?: string
  signatory: IPartialUserInfo | null
  created_by: string
}

export type SignatoryStatusUnion = ValueOfObject<typeof SIGNATORY_STATUS>

export type TPPendingReconciliationObject = {
  uuid: string
  required_items_count: number
  user: string
  status: RECONCILIATION_STATUSES
}

export interface IContractTimeline {
  uuid: string
  status: ContractStatusUnion
  expire_at: string
}

export interface TContractUploadFile {
  contract: string
  file: FormDataEntryValue | null
}

export interface TContractAcceptPayload {
  contractId: string
  documentId: string
}

export type ContractStatusUnion = ValueOfObject<typeof CONTRACT_STATUS>

export type DeleteResponseUserPayload = {
  user: TResponsibleUser
}

export interface ManualContractCreationRequestData {
  creator: string
  contract_category: string
  name: string
  start_date: string
  primary_finish_date: string
  admin_fee: number
  payment_term_days: number | null
  admin_payment_frequency: string | null
  creation_type: string
  vendor: string
  vendor_contract_steward?: string
}

export type SetupContractDetailsTimeRequestData = Array<
  Omit<IContractTimeline, 'uuid'>
>

export type GetVendorContractStewardsArgs = {
  vendors: string[]
  contract_categories: string[]
}

export type TAvailableOwnersParams = TQueryParams & {
  categories: string | string[]
}

export type TEvaluationDocuments = {
  is_visible_for_community: boolean
  is_visible_for_commons: boolean
}
