export const ADD_BANK_ACCOUNT_BREADCRUMBS = (name?: string) => {
  const breadcrumbs = [
    {
      children: 'Settings',
      href: '/settings'
    },
    {
      children: 'Add Bank account'
    }
  ]
  if (name) {
    breadcrumbs.splice(1, 0, {
      children: name
    })
  }

  return breadcrumbs
}
