import {
  API_DICTIONARY,
  HTTP_METHODS,
  ResponseWithPagination,
  TOptionsResponse
} from '../../constants'
import Fetcher from '../../services/Fetcher'
import {
  TAddBusinessDetails,
  TResponseBusinessResource
} from './BusinessDetails/types'
import { TAddBankAccountDetails } from './BankAccount/types'

const fetcher = new Fetcher({})

export const fetchTaxTypeOptions = async () =>
  fetcher.request<unknown, ResponseWithPagination<TOptionsResponse>>({
    url: `${API_DICTIONARY.PAYMENTS}${API_DICTIONARY.ORUM}${API_DICTIONARY.BUSINESS}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.TAX_ID_TYPE}`,
    method: HTTP_METHODS.GET
  })

export const fetchCountriesOptions = async () =>
  fetcher.request<unknown, ResponseWithPagination<TOptionsResponse>>({
    url: `${API_DICTIONARY.PAYMENTS}${API_DICTIONARY.ORUM}${API_DICTIONARY.BUSINESS}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.COUNTRY}`,
    method: HTTP_METHODS.GET
  })

export const fetchStateOptions = async () =>
  fetcher.request<unknown, ResponseWithPagination<TOptionsResponse>>({
    url: `${API_DICTIONARY.PAYMENTS}${API_DICTIONARY.ORUM}${API_DICTIONARY.BUSINESS}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.STATE}`,
    method: HTTP_METHODS.GET
  })

export const fetchAddressTypeOptions = async () =>
  fetcher.request<unknown, ResponseWithPagination<TOptionsResponse>>({
    url: `${API_DICTIONARY.PAYMENTS}${API_DICTIONARY.ORUM}${API_DICTIONARY.BUSINESS}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.ADDRESS_TYPE}`,
    method: HTTP_METHODS.GET
  })
export const fetchAccountTypeOptions = async () =>
  fetcher.request<unknown, ResponseWithPagination<TOptionsResponse>>({
    url: `${API_DICTIONARY.PAYMENTS}${API_DICTIONARY.ORUM}${API_DICTIONARY.BANK_ACCOUNT}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.ACCOUNT_TYPE}`,
    method: HTTP_METHODS.GET
  })

export const fetchResourceId = async (uuid: string) =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.PAYMENTS}${API_DICTIONARY.ORUM}${API_DICTIONARY.BUSINESS}${uuid}/`,
    method: HTTP_METHODS.GET
  })

export const addBusinessAccount = (data: Partial<TAddBusinessDetails>) =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.PAYMENTS}${API_DICTIONARY.ORUM}${API_DICTIONARY.BUSINESS}`,
    method: HTTP_METHODS.POST,
    data
  })
export const updateBusinessAccount = (
  uuid: string,
  data: Partial<TAddBusinessDetails>
) =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.PAYMENTS}${API_DICTIONARY.ORUM}${API_DICTIONARY.BUSINESS}${uuid}/`,
    method: HTTP_METHODS.PUT,
    data
  })

export const addBankAccount = (data: Partial<TAddBankAccountDetails>) =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.PAYMENTS}${API_DICTIONARY.ORUM}${API_DICTIONARY.BANK_ACCOUNT}`,
    method: HTTP_METHODS.POST,
    data
  })
export const updateBankAccount = (
  uuid: string,
  data: Partial<TAddBankAccountDetails>
) =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.PAYMENTS}${API_DICTIONARY.ORUM}${API_DICTIONARY.BANK_ACCOUNT}${uuid}/`,
    method: HTTP_METHODS.PUT,
    data
  })

export const getBusinessDetailsCommunity = async (uuid: string) =>
  fetcher.request<unknown, TResponseBusinessResource>({
    url: `${API_DICTIONARY.COMMUNITIES}${uuid}`,
    method: HTTP_METHODS.GET
  })
export const getBusinessDetailsByUuid = async (uuid: string) =>
  fetcher.request<unknown, TAddBusinessDetails>({
    url: `${API_DICTIONARY.PAYMENTS}${API_DICTIONARY.ORUM}${API_DICTIONARY.BUSINESS}${uuid}`,
    method: HTTP_METHODS.GET
  })

export const getBusinessDetailsHealthSystem = async (
  communityId: string,
  hsId: string
) =>
  fetcher.request<unknown, TResponseBusinessResource>({
    url: `${API_DICTIONARY.COMMUNITIES}${communityId}/${API_DICTIONARY.HEALTH_SYSTEMS}${hsId}/`,
    method: HTTP_METHODS.GET
  })

export const getBusinessDetailsHospital = async (
  communityId: string,
  hsId: string,
  hospitalId: string
) =>
  fetcher.request<unknown, TResponseBusinessResource>({
    url: `${API_DICTIONARY.COMMUNITIES}${communityId}/${API_DICTIONARY.HEALTH_SYSTEMS}${hsId}/${API_DICTIONARY.HOSPITALS}${hospitalId}/`,
    method: HTTP_METHODS.GET
  })
export const getBusinessDetailsVendor = async (vendorId: string) =>
  fetcher.request<unknown, TResponseBusinessResource>({
    url: `${API_DICTIONARY.VENDORS}${vendorId}/`,
    method: HTTP_METHODS.GET
  })
export const getBusinessDetailsCommons = async () =>
  fetcher.request<unknown, TResponseBusinessResource>({
    url: `${API_DICTIONARY.COMMONS}`,
    method: HTTP_METHODS.GET
  })

export const getBankAccount = async (uuid: string) =>
  fetcher.request<unknown, TAddBankAccountDetails>({
    url: `${API_DICTIONARY.PAYMENTS}${API_DICTIONARY.ORUM}${API_DICTIONARY.BANK_ACCOUNT}${uuid}/`,
    method: HTTP_METHODS.GET
  })

export const getBusinessDetails = async (uuid?: string) =>
  fetcher.request<unknown, TAddBusinessDetails>({
    url: `${API_DICTIONARY.PAYMENTS}${API_DICTIONARY.BUSINESS}${uuid}/`,
    method: HTTP_METHODS.GET
  })
