import { useState, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios, { CancelTokenSource } from 'axios'
import { Table } from 'components/Table'
import { Input } from 'components/Input'
import { setLoading } from 'redux/store/common/slice'
import { columns } from './constants'
import { useDebounce } from 'hooks/hooks'
import { canSeeMyContracts, isTableHasParams } from 'helper/common'
import { DEFAULT_PAGE } from 'components/Table/constants'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import {
  TContractsList,
  TContractsListFilters,
  TContractsListFiltersFunc,
  TContractsListParams,
  TContractsTableRequestData
} from './types'
import { getContractsListCommonsAndCommunity } from './api'
import { getUser } from 'redux/store/user/getters'
import { ReactComponent as Close16 } from '../../assets/svg/Clos16.svg'
import CreateContractType from './CreateContract'
import BulkCreate from './BulkCreate'
import { ROLES } from '../Permission'
import './styles.scss'

export const ContractsTable = () => {
  const [tableData, setTableData] = useState<TContractsList[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [sortParams, setSortParams] = useState<TContractsTableRequestData>()
  const [firstLoad, setFirstLoad] = useState(true)
  const [filters, setFilters] = useState<TContractsListFilters>({})
  const [totalNumber, setTotalNumber] = useState(0)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const tableDataTokenRef = useRef<CancelTokenSource>()
  const dispatch = useDispatch()
  const debouncedSearch = useDebounce(searchValue, 500)
  const user = useSelector(getUser)

  const isAllColumnsVisible = useMemo(() => {
    return Boolean(
      user.role !== ROLES.INFLUENCER &&
        user.role !== ROLES.STAKEHOLDER &&
        user.role !== ROLES.HOSPITAL_ADMIN &&
        user.role !== ROLES.COMMONS_STAKEHOLDER
    )
  }, [user.role])

  const isCommons = user?.role.includes('commons')
  const isCommunity = user?.role.includes('community')

  const isUserVendor = !isCommunity && !isCommons && !!user.vendor

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(
      () => {
        getTableData({ ...sortParams, ...filters, page: { ...DEFAULT_PAGE } })
        setPageInfo({ ...DEFAULT_PAGE })
      },
      firstLoad ? 0 : 500
    )
  }, [debouncedSearch])
  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])
  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: TContractsTableRequestData) => {
    dispatch(setLoading(true))
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: TContractsListParams = { ...filters }
    if (searchValue) {
      params.search = searchValue
    }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    const requestFunction = isUserVendor
      ? getContractsListCommonsAndCommunity
      : getContractsListCommonsAndCommunity
    requestFunction({
      cancelToken: tableDataTokenRef.current?.token,
      params
    })
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            key: i.uuid
          }))
        )
        setTotalNumber(resp.data.count)
        if (firstLoad) {
          setFirstLoad(false)
        }
      })
      .finally(() => dispatch(setLoading(false)))
  }

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }
  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }

  const applyFilters: TContractsListFiltersFunc = (field, appliedFilters) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    const newPageInfo = { ...pageInfo, pageNumber: 1 }
    setFilters(newFilters)
    setPageInfo(newPageInfo)
    getTableData({ ...sortParams, filters: newFilters, page: newPageInfo })
  }

  return (
    <div className="table-wrapper community-users">
      <div className="table-wrapper__header">
        <Input
          className="allow-clear"
          propsInput={{
            allowClear: { clearIcon: <Close16 /> },
            placeholder: isUserVendor
              ? 'Contract Category, Community or HS'
              : 'Search by Contract Category or Vendor',
            prefix: <Search16 />,
            value: searchValue,
            onChange: (e) => setSearchValue(e.target.value)
          }}
        />
        <div className="table-wrapper__header-buttons">
          <BulkCreate />
          <CreateContractType />
        </div>
      </div>
      {!firstLoad && (
        <Table<TContractsList>
          dataSource={tableData}
          columns={columns({
            applyFilters,
            filters,
            isAllColumnsVisible,
            canSeeMyContracts: canSeeMyContracts(user.role),
            isVendor: isUserVendor,
            userId: user.uuid
          })}
          className="contracts-table contracts-page"
          onChange={handleTableChange}
          hasSearchOrFilters={isTableHasParams<TContractsListFilters>(
            debouncedSearch,
            filters
          )}
          onChangePage={handleChangePageSize}
          pageSize={pageInfo.pageSize}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
        />
      )}
    </div>
  )
}
