import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../../redux/store/common/slice'
import { getUserContractCategories } from '../../../features/UserContractCategories/api'
import { DEFAULT_PAGE } from '../../../components/Table/constants'
import {
  UserContractCategoriesParams,
  UserContractCategoriesRequestData,
  UserContractCategory
} from '../../../features/UserContractCategories/types'
import { TPageInfo } from '../../../components/Table/types'
import { TUserForm } from 'components/Forms/forms.user.d'

type ContextProps = {
  state: {
    firstLoad: boolean
    totalNumber: number
    sortParams?: UserContractCategoriesRequestData
    pageInfo: TPageInfo
    tableData: UserContractCategory[]
    userData: Partial<TUserForm>
  }
  actions: {
    setPageInfo: Dispatch<SetStateAction<TPageInfo>>
    setUserData: Dispatch<SetStateAction<Partial<TUserForm>>>
    getContractCategories: (
      args: UserContractCategoriesRequestData
    ) => Promise<void>
  }
}

const CRUDUserContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const CRUDUserContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [firstLoad, setFirstLoad] = useState(true)
  const [totalNumber, setTotalNumber] = useState(0)
  const [userData, setUserData] = useState<Partial<TUserForm>>({})
  const [sortParams, setSortParams] =
    useState<UserContractCategoriesRequestData>()
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const [tableData, setTableData] = useState<UserContractCategory[]>([])
  const dispatch = useDispatch()

  useEffect(() => {
    if (userData.uuid) {
      getContractCategories({
        ...sortParams,
        page: pageInfo
      })
    }
  }, [userData.uuid])

  const getContractCategories = async ({
    sortField,
    sortOrder,
    page
  }: UserContractCategoriesRequestData) => {
    try {
      dispatch(setLoading(true))
      const params: UserContractCategoriesParams = {}
      if (sortField) {
        if (sortOrder) {
          params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
        }
        setSortParams({
          sortField,
          sortOrder
        })
      }
      const dataPage = page ? page : pageInfo
      params.limit = dataPage.pageSize
      params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
      const { data } = await getUserContractCategories(
        userData.uuid as string,
        params
      )
      setTableData(
        data.results.map((i) => ({
          ...i,
          key: i.uuid
        }))
      )
      setTotalNumber(data.count)
      if (firstLoad) {
        setFirstLoad(false)
      }
    } finally {
      dispatch(setLoading(false))
    }
  }

  const context = useMemo(
    () => ({
      state: {
        firstLoad,
        totalNumber,
        sortParams,
        pageInfo,
        tableData,
        userData
      },
      actions: { getContractCategories, setPageInfo, setUserData }
    }),
    [firstLoad, pageInfo, sortParams, tableData, totalNumber, userData]
  )

  return (
    <CRUDUserContext.Provider value={context}>
      {children}
    </CRUDUserContext.Provider>
  )
}

export const useCRUDUserContext = () => useContext(CRUDUserContext)

export default CRUDUserContextProvider
