import { Rebates } from '../Rebates/Rebates'
import { CognusAR } from '../CognusAR/CognusAR'

export const FINANCIAL_ROUTES = [
  {
    path: '0',
    component: Rebates
  },
  {
    path: '2',
    component: CognusAR
  }
]
