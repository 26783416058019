export const ACTIONS = {
  VIEW: 'VIEW',
  DISPUTE: 'DISPUTE',
  INITIATE: 'INITIATE',
  ADD: 'ADD'
}

export const SUBJECTS = {
  REBATES_DASHBOARD: 'REBATES_DASHBOARD',
  INVOICE_DETAILS: 'INVOICE_DETAILS',
  INVOICE: 'INVOICE',
  COGNUS_AR: 'COGNUS_AR',
  PAYMENT: 'PAYMENT',
  BUSINESS_RESOURCE: 'BUSINESS_RESOURCE'
}
