import { AddAmendmentPopup } from 'features/ContractDetails/HSList/AddAmendment/Popup'
import { HSTable } from 'features/ContractDetails/HSList/HSTable/HSTable'
import { useHSTable } from 'features/ContractDetails/HSList/hooks/useHSTable'
import HSTableProvider from 'features/ContractDetails/HSList/Providers/HSTableProvider'
import { getContractHealthSystems } from 'features/ContractDetails/HSList/api'
import { columnsShortView, onboardedHsColumns } from './HSTable/constants'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import { useMemo } from 'react'

const HSList = () => {
  const {
    state: { isOnboarded, canSeeLoPSignatories }
  } = useContractDetailsContext()
  const hsTableProps = useHSTable({ getContractHealthSystems })

  const columns = useMemo(() => {
    return isOnboarded
      ? onboardedHsColumns(canSeeLoPSignatories)
      : columnsShortView(canSeeLoPSignatories)
  }, [isOnboarded, canSeeLoPSignatories])

  return (
    <HSTableProvider {...hsTableProps}>
      <div className="contract-hs-list">
        <HSTable columns={columns} {...hsTableProps} />
      </div>
      <AddAmendmentPopup />
    </HSTableProvider>
  )
}

export default HSList
