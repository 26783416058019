import { addBankAccountInitialValues } from './constants'
import { validateMessages } from '../../helper/common'
import cn from 'classnames'
import { Form } from 'antd'
import { useEffect, useState } from 'react'
import { setLoading } from '../../redux/store/common/slice'
import { useDispatch, useSelector } from 'react-redux'
import {
  TAddBankAccountForm,
  TAddBankAccountFormErrorType,
  TAddBankAccoutProps
} from './forms.addBankAccount.d'
import { Input } from '../Input'
import { LABELS, PLACEHOLDERS, VALIDATION_MESSAGES } from '../../constants'
import './forms.addBankAccount.scss'
import { Select } from '../Select'

import {
  addBankAccount,
  fetchAccountTypeOptions,
  updateBankAccount
} from '../../features/PaymentDetails/api'
import { TOption } from '../Select/types'
import { notification } from '../Notification'
import { routes } from '../../router'
import useRouter from '../../hooks/useRouter'
import { getFieldForOrumBusiness } from '../../features/PaymentDetails/utils'
import { getUser } from '../../redux/store/user/getters'
import { useParams } from 'react-router-dom'

export const AddBankAccount = ({
  form,
  setIsActive,
  callback
}: TAddBankAccoutProps) => {
  const { push } = useRouter()
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const [accountType, setAccountType] = useState<TOption[]>([])
  const [errors, setErrors] = useState<TAddBankAccountFormErrorType | any>({})
  const uuid = form.getFieldValue('uuid')
  const routing_number = Form.useWatch('routing_number', form)
  const confirm_account_number = Form.useWatch('confirm_account_number', form)
  const account_number = Form.useWatch('account_number', form)

  const { hospitalId } = useParams()

  const {
    query: { hsId }
  } = useRouter()

  const getAccountTypes = async () => {
    await fetchAccountTypeOptions().then((res) => {
      setAccountType(
        res.data.results.map((option) => ({
          label: option.value,
          value: option.id
        }))
      )

      form.setFieldsValue({ account_type: res.data.results[0]?.id })
    })
  }

  const getSelectorsData = async () => {
    dispatch(setLoading(true))
    try {
      getAccountTypes()
    } finally {
      dispatch(setLoading(false))
    }
  }

  useEffect(() => {
    getSelectorsData()
  }, [])

  const handleFinish = (formData: TAddBankAccountForm) => {
    if (formData.account_number !== formData.confirm_account_number) {
      setErrors({
        confirm_account_number: [VALIDATION_MESSAGES.CONFIRM_ACCOUNT_NUMBER]
      })
      return
    }
    const modifiedData = {
      account_number: formData.account_number,
      routing_number: formData.routing_number,
      account_type: formData.account_type,
      account_holder_name: formData.account_holder_name
    }
    const dataForRequest = {
      ...modifiedData,
      ...getFieldForOrumBusiness(user, hospitalId)
    }

    setIsActive(false)
    dispatch(setLoading(true))
    if (!uuid) {
      addBankAccount(dataForRequest)
        .then(() => {
          notification.success({
            message: VALIDATION_MESSAGES.BANK_ACCOUNT_CREATED
          })
          if (hospitalId && hsId) {
            push(
              `${routes.hospitals}/${hospitalId}${routes.paymentDetails}?tab=bankAccount&hsId=${hsId}`
            )
          } else {
            push(`${routes.settings}/6/bank_account`)
          }
        })
        .catch((e) => {
          setIsActive(true)
          if (e.data.details) {
            notification.error({ message: e?.data.details })
          }
          setErrors(e.data)
        })
        .finally(() => {
          dispatch(setLoading(false))
        })
    } else {
      updateBankAccount(uuid, modifiedData)
        .then(() => {
          notification.success({
            message: VALIDATION_MESSAGES.BANK_ACCOUNT_UPDATED
          })
          if (!!callback) callback()
        })
        .catch((e) => {
          setIsActive(true)
          if (e.data.details) {
            notification.error({ message: e?.data.details })
          }
          setErrors(e.data)
        })
        .finally(() => {
          dispatch(setLoading(false))
        })
    }
  }

  return (
    <Form<TAddBankAccountForm>
      form={form}
      onFinish={handleFinish}
      initialValues={addBankAccountInitialValues}
      validateMessages={validateMessages}
      onChange={() => {
        setIsActive(true)
        if (
          account_number?.includes('*') ||
          confirm_account_number?.includes('*') ||
          routing_number?.includes('*')
        ) {
          form.setFieldsValue({
            account_number: '',
            routing_number: '',
            confirm_account_number: ''
          })
        }
      }}
      id="add-bank-account-form"
      className={cn('add-bank-account-form')}
    >
      <div className="row two-columns">
        <Input
          propsItem={{
            name: 'account_number',
            label: LABELS.ACCOUNT_NUMBER,
            help: errors.account_number && errors.account_number[0],
            validateStatus: errors.account_number && 'error',
            rules: [
              {
                required: true,
                pattern: /^(\d*\.)?\d+$/
              }
            ]
          }}
          propsInput={{
            maxLength: 17,
            onClick: () => {
              if (account_number.includes('*')) {
                form.setFieldsValue({
                  account_number: '',
                  routing_number: '',
                  confirm_account_number: ''
                })
                setIsActive(true)
              }
            }
          }}
        />
        <Input
          propsItem={{
            name: 'confirm_account_number',
            label: LABELS.CONFIRM_ACCOUNT_NUMBER,
            help:
              errors.confirm_account_number && errors.confirm_account_number[0],
            validateStatus: errors.confirm_account_number && 'error',
            rules: [
              {
                required: true,
                pattern: /^(\d*\.)?\d+$/
              }
            ]
          }}
          propsInput={{
            maxLength: 17,
            onClick: () => {
              if (confirm_account_number.includes('*')) {
                form.setFieldsValue({
                  confirm_account_number: '',
                  account_number: '',
                  routing_number: ''
                })
                setIsActive(true)
              }
            }
          }}
        />
      </div>
      <div className="row two-columns">
        <Input
          propsItem={{
            name: 'routing_number',
            label: LABELS.ROUTING_NUMBER,
            help: errors.routing_number && errors.routing_number[0],
            validateStatus: errors.routing_number && 'error',
            rules: [
              {
                required: true,
                pattern: /^(\d*\.)?\d{9,}$/
              }
            ]
          }}
          propsInput={{
            maxLength: 9,
            onClick: () => {
              if (routing_number.includes('*')) {
                form.setFieldsValue({
                  routing_number: '',
                  confirm_account_number: '',
                  account_number: ''
                })
                setIsActive(true)
              }
            }
          }}
        />
        <Input
          propsItem={{
            name: 'account_holder_name',
            label: LABELS.ACCOUNT_HOLDER_NAME,
            help: errors.account_holder_name && errors.account_holder_name[0],
            validateStatus: errors.account_holder_name && 'error',
            rules: [
              {
                required: true,
                pattern: /^[-\w\s]+$/
              }
            ]
          }}
          propsInput={{ maxLength: 255 }}
        />
      </div>
      <div className="row four-in-row gap-24">
        <Select
          options={accountType}
          propsItem={{
            label: LABELS.ACCOUNT_TYPE,
            name: 'account_type',
            help: errors?.account_type ? errors?.account_type[0] : undefined
          }}
          propsSelect={{
            required: true,
            placeholder: PLACEHOLDERS.PLEASE_SELECT
          }}
        />
      </div>
      <div className="mt-24 row description-row" />
    </Form>
  )
}
