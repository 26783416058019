import { Tooltip } from '../../../components/Tooltip/Tooltip'
import { TClinicalReviewColumnProps } from './types'
import { useCognusRepositoryContext } from '../Providers/CognusRepositoryActions'
import { useMemo } from 'react'
import { getChartDataForItem } from './Menu/helper'
import Typography from '../../../components/Typography/Typography'

const ClinicalReviewColumn = ({
  clinical_review_percentage,
  led_by,
  contract_category,
  handleClinicalReviewModal,
  uuid
}: TClinicalReviewColumnProps) => {
  const { setSelectedCategoryName, setSelectedLedBy, setSelectedId } =
    useCognusRepositoryContext().actions
  const isEmpty = !clinical_review_percentage.some(
    (item) => item.percentage !== 0
  )

  const onClick = () => {
    setSelectedLedBy(led_by)
    setSelectedId(uuid)
    setSelectedCategoryName(contract_category)
    handleClinicalReviewModal()
  }

  const chartData = useMemo(() => {
    return getChartDataForItem(clinical_review_percentage)
  }, [clinical_review_percentage])
  return isEmpty ? (
    <Typography.Label>not initiated</Typography.Label>
  ) : (
    <div className="clinical-review-results" onClick={onClick}>
      <Tooltip title={'Сlinical review details'} placement="topLeft">
        <div
          style={{
            backgroundColor: '#9CD652',
            height: '8px',
            width: `${0.8 * chartData.accepted}px`
          }}
          className={'clinical-review-results-item'}
        />
        <div
          style={{
            backgroundColor: '#E5565C',
            height: '8px',
            width: `${0.8 * chartData.declined}px`
          }}
          className={'clinical-review-results-item'}
        />
        <div
          style={{
            backgroundColor: '#E1E7ED',
            height: '8px',
            width: `${0.8 * chartData.not_reviewed}px`
          }}
          className={'clinical-review-results-item'}
        />
      </Tooltip>
    </div>
  )
}
export default ClinicalReviewColumn
