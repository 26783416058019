import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'antd'
import cn from 'classnames'
import { Input } from 'components/Input'
import { Select } from 'components/Select'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { validateMessages, formatPhoneNumber } from 'helper/common'
import { getCommunityUsersList } from 'features/ChapterUsers/api'
import { formatUserNameOption } from 'helper/optionsFormatters'
import { addHealthSystem, editHealthSystem } from 'features/HealthSystems/api'
import { getUser } from 'redux/store/user/getters'
import { setLoading } from 'redux/store/common/slice'
import { LABELS, PLACEHOLDERS } from 'constants/txt'
import { ROLES } from 'features/Permission'
import { HEALTH_SYSTEM_STATUSES } from 'constants/common'
import { TOption } from 'components/Select/types'
import { TAddEditHealthSystemData } from 'features/HealthSystems/types'
import {
  TAddEditHealthSystemForm,
  TAddEditHealthSystemProps,
  TAddEditHealthSystemErrors,
  TAddEditHealthSystemFormFields
} from './forms.addEditHealthSystem.d'
import { healthSystemsInitialValues } from './constants'

export const AddEditHealthSystem = ({
  className,
  form,
  data,
  callback
}: TAddEditHealthSystemProps) => {
  const [VPUsers, setVPUsers] = useState<TOption[]>([])
  const [CSUsers, setCSUsers] = useState<TOption[]>([])
  const [legalUsers, setLegalUsers] = useState<TOption[]>([])
  const [analystUsers, setAnalystUsers] = useState<TOption[]>([])
  const [errors, setErrors] = useState<TAddEditHealthSystemErrors>({})
  const [fields, setFields] = useState<TAddEditHealthSystemFormFields>([])
  const [status, setStatus] = useState(
    data?.status || HEALTH_SYSTEM_STATUSES.ACTIVE
  )
  const user = useSelector(getUser)
  const dispatch = useDispatch()

  const phoneNumber = Form.useWatch('phone_number', form)
  const personNumber = Form.useWatch('person_phone_number', form)

  useEffect(() => {
    if (user.community) {
      dispatch(setLoading(true))
      getCommunityUsersList(user.community, {
        params: {
          health_systems: 'null'
        }
      })
        .then((resp) => {
          const { results } = resp.data

          const users = [...results, ...(data?.users ?? [])]

          setVPUsers(
            users
              .filter((i) => i.role === ROLES.VICE_PRESIDENT)
              .map(formatUserNameOption)
          )
          setCSUsers(
            users
              .filter((i) => i.role === ROLES.CONTRACT_STEWARD)
              .map(formatUserNameOption)
          )
          setLegalUsers(
            users
              .filter((i) => i.role === ROLES.LEGAL)
              .map(formatUserNameOption)
          )
          setAnalystUsers(
            users
              .filter((i) => i.role === ROLES.ANALYST)
              .map(formatUserNameOption)
          )
        })
        .finally(() => dispatch(setLoading(false)))
    }
  }, [user.community])
  useEffect(() => {
    if (data?.uuid) {
      const formattedData = {}
      for (const key in data) {
        if (typeof data[key] === 'object' && key === 'address') {
          for (const item in data[key]) {
            formattedData[item] = data[key][item]
          }
        } else if (key === 'contact_person') {
          for (const item in data[key]) {
            formattedData[`person_${item}`] = data[key][item]
          }
        } else if (key === 'users') {
          const users = data[key]
          const vpUser = users.find((i) => i.role === ROLES.VICE_PRESIDENT)
          if (vpUser) {
            formattedData['vice_president'] = {
              label: `${vpUser.first_name} ${vpUser.last_name}`,
              value: vpUser.uuid
            }
          }
          formattedData['contract_steward'] = users
            .filter((i) => i.role === ROLES.CONTRACT_STEWARD)
            .map(formatUserNameOption)
          formattedData['legal'] = users
            .filter((i) => i.role === ROLES.LEGAL)
            .map(formatUserNameOption)
          formattedData['analyst'] = users
            .filter((i) => i.role === ROLES.ANALYST)
            .map(formatUserNameOption)
        } else {
          formattedData[key] = data[key]
        }
      }
      const fields = Object.keys(formattedData).map((i) => ({
        name: i,
        value: formattedData[i]
      }))
      setFields(fields)
    }
  }, [data?.uuid])
  useEffect(() => {
    if (data?.status) {
      setStatus(data.status)
    }
  }, [data?.status])
  const handleAddEditHealthSystem = (formData: TAddEditHealthSystemForm) => {
    dispatch(setLoading(true))
    const number = !formData?.phone_number?.toString()?.includes('+1')
      ? '+1' + formData.phone_number
      : formData.phone_number
    const personNumber = !formData?.person_phone_number
      ?.toString()
      ?.includes('+1')
      ? '+1' + formData.person_phone_number
      : formData.person_phone_number

    const healthSystemData: TAddEditHealthSystemData = {
      name: formData.name,
      tax_id: formData.tax_id,
      email: formData.email,
      phone_number: formData.phone_number ? number : '',
      address: {
        state: formData.state,
        street: formData.street,
        zip_code: formData.zip_code,
        city: formData.city
      },
      contact_person: {
        first_name: formData.person_first_name,
        last_name: formData.person_last_name,
        position: formData.person_position,
        email: formData.person_email,
        phone_number: formData.person_phone_number ? personNumber : ''
      },
      status: data?.uuid ? status : undefined
    }

    let users: any[] = []

    if (formData.vice_president) {
      users.push(formData.vice_president?.value ?? formData.vice_president)
    }
    if (formData.contract_steward?.length) {
      users = users.concat(
        typeof formData.contract_steward[0] === 'object'
          ? formData.contract_steward.map((i) => i.value)
          : formData.contract_steward
      )
    }
    if (formData.legal?.length) {
      users = users.concat(
        typeof formData.legal[0] === 'object'
          ? formData.legal.map((i) => i.value)
          : formData.legal
      )
    }
    if (formData.analyst?.length) {
      users = users.concat(
        typeof formData.analyst[0] === 'object'
          ? formData.analyst.map((i) => i.value)
          : formData.analyst
      )
    }
    if (users.length) {
      healthSystemData.users_ids = users
    }
    if (data?.uuid) {
      editHealthSystem(user.community as string, healthSystemData, data.uuid)
        .then(() => {
          if (callback) {
            callback()
          }
        })
        .catch((e) => {
          setErrors(e.data)
          dispatch(setLoading(false))
        })
    } else {
      addHealthSystem(user.community as string, healthSystemData)
        .then(() => {
          if (callback) {
            callback()
          }
        })
        .catch((e) => {
          setErrors(e.data)
          dispatch(setLoading(false))
        })
    }
  }
  return (
    <Form<TAddEditHealthSystemForm>
      form={form}
      onFinish={handleAddEditHealthSystem}
      fields={fields}
      initialValues={healthSystemsInitialValues}
      validateMessages={validateMessages}
      id="add-edit-health-system-form"
      className={cn('add-edit-health-system-form', className)}
    >
      <Typography.Body1
        weight={TYPOGRAPHY_WEIGHT.BOLD}
        className="form-subtitle"
      >
        General
      </Typography.Body1>
      <div className="row two-columns">
        <Input
          propsItem={{
            name: 'name',
            label: LABELS.SYSTEM_NAME,
            help: errors.name && errors.name[0],
            validateStatus: errors.name && 'error',
            rules: [
              {
                required: true
              }
            ]
          }}
          propsInput={{
            maxLength: 100
          }}
        />
        <Input
          propsItem={{
            name: 'tax_id',
            label: LABELS.TAX_ID,
            rules: [
              {
                pattern: /^(\d*\.)?\d{9,}$/
              }
            ]
          }}
          propsInput={{
            maxLength: 9
          }}
        />
      </div>
      <div className="row two-columns">
        <Input
          propsInput={{ placeholder: PLACEHOLDERS.EMAIL }}
          propsItem={{
            name: 'email',
            label: LABELS.EMAIL,
            help: errors.email && errors.email[0],
            validateStatus: errors.email && 'error'
          }}
        />
        <Input.Phone
          propsItem={{
            name: 'phone_number',
            label: LABELS.PHONE_NUMBER
          }}
          propsInputNumber={{
            controls: false,
            formatter: formatPhoneNumber,
            prefix: phoneNumber ? '+1' : ''
          }}
        />
      </div>
      <div className="row two-columns">
        <Input
          propsItem={{
            name: 'street',
            label: LABELS.STREET,
            rules: [
              {
                required: true
              }
            ]
          }}
          propsInput={{
            maxLength: 100
          }}
        />
        <Input
          propsItem={{
            name: 'city',
            label: LABELS.CITY,
            rules: [
              {
                required: true
              }
            ]
          }}
        />
      </div>
      <div className="row two-columns">
        <Input
          propsItem={{
            name: 'state',
            label: LABELS.STATE,
            rules: [
              {
                required: true
              }
            ]
          }}
        />
        <Input
          className="column-half"
          propsItem={{
            name: 'zip_code',
            label: LABELS.ZIP_CODE,
            help: errors.address?.zip_code && errors.address?.zip_code[0],
            validateStatus: errors.address?.zip_code && 'error',
            rules: [
              {
                required: true
              }
            ]
          }}
        />
      </div>
      <Typography.Body1
        weight={TYPOGRAPHY_WEIGHT.BOLD}
        className="form-subtitle"
      >
        Contact person
      </Typography.Body1>
      <div className="row two-columns">
        <Input
          propsItem={{
            name: 'person_first_name',
            label: LABELS.FIRST_NAME,
            rules: [
              {
                required: true
              }
            ]
          }}
          propsInput={{
            maxLength: 40
          }}
        />
        <Input
          propsItem={{
            name: 'person_last_name',
            label: LABELS.LAST_NAME,
            rules: [
              {
                required: true
              }
            ]
          }}
          propsInput={{
            maxLength: 40
          }}
        />
      </div>
      <div className="row two-columns">
        <Input
          propsItem={{
            name: 'person_position',
            label: LABELS.POSITION
          }}
          propsInput={{
            maxLength: 40
          }}
        />
      </div>
      <div className="row two-columns">
        <Input
          propsInput={{ placeholder: PLACEHOLDERS.EMAIL }}
          propsItem={{
            name: 'person_email',
            label: LABELS.EMAIL,
            help:
              errors.contact_person?.email && errors.contact_person?.email[0],
            validateStatus: errors.contact_person?.email && 'error'
          }}
        />
        <Input.Phone
          propsItem={{
            name: 'person_phone_number',
            label: LABELS.PHONE_NUMBER
          }}
          propsInputNumber={{
            controls: false,
            formatter: formatPhoneNumber,
            prefix: personNumber ? '+1' : ''
          }}
        />
      </div>
      <Typography.Body1
        weight={TYPOGRAPHY_WEIGHT.BOLD}
        className="form-subtitle"
      >
        HS users
      </Typography.Body1>
      <div className="row two-columns">
        <Select
          options={VPUsers}
          propsItem={{
            label: LABELS.VICE_PRESIDENT,
            name: 'vice_president'
          }}
          propsSelect={{
            placeholder: PLACEHOLDERS.PLEASE_SELECT
          }}
        />
      </div>
      <div className="row">
        <Select.Multi
          options={CSUsers}
          propsItem={{
            name: 'contract_steward',
            label: LABELS.CONTRACT_STEWARD
          }}
          propsSelect={{
            placeholder: PLACEHOLDERS.PLEASE_SELECT
          }}
        />
      </div>
      <div className="row">
        <Select.Multi
          options={legalUsers}
          propsItem={{
            name: 'legal',
            label: LABELS.LEGAL
          }}
          propsSelect={{
            placeholder: PLACEHOLDERS.PLEASE_SELECT
          }}
        />
      </div>
      <div className="row">
        <Select.Multi
          options={analystUsers}
          propsItem={{
            name: 'analyst',
            label: LABELS.ANALYST
          }}
          propsSelect={{
            placeholder: PLACEHOLDERS.PLEASE_SELECT
          }}
        />
      </div>
    </Form>
  )
}
