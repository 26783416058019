import { ITimeline, ITimelineValidation } from 'components/Timelines/types'
import Timeline, { TimelineProps } from 'components/Timelines/Timeline/Timeline'
import { TimelinesReturnTypeWithKey } from 'components/Timelines/hooks/useTimelines'
import { ReactNode } from 'react'

export type TimelinesProps<TKey extends Record<string, string>> = {
  timelines: ITimeline<TKey>[]
  isPrs?: boolean
  children?: ReactNode | null
  errors?: ITimelineValidation[]
} & TimelinesReturnTypeWithKey<TKey>['actions'] &
  Pick<TimelineProps<TKey>, 'timeOptions'>

const Timelines = <TKey extends Record<string, string>>(
  props: TimelinesProps<TKey>
) => {
  const {
    isPrs = false,
    children = null,
    timelines,
    timeOptions,
    errors,
    ...actionProps
  } = props

  const {
    getValidationByKey,
    getHasChangesByKey,
    getDisabledByKey,
    updateTimelineByKey,
    resetTimelineByKey
  } = actionProps

  return (
    <>
      {timelines.map((value, index) => {
        const isOverall = index === 0
        const overallDate = timelines[0]?.date
        return (
          <>
            <Timeline<TKey>
              {...value}
              key={value.key}
              timeOptions={timeOptions}
              prevTimeline={!isOverall ? timelines[index - 1] : null}
              validation={
                getValidationByKey(value.key) || errors?.[index] || null
              }
              hasChanges={getHasChangesByKey(value.key)}
              isPrs={isPrs}
              disabledDateForPrs={
                isPrs && !isOverall && overallDate ? overallDate : undefined
              }
              update={updateTimelineByKey(value.key)}
              disabled={getDisabledByKey(value.key)}
              reset={resetTimelineByKey(value.key)}
            />
            {isPrs && index === 0 && children}
          </>
        )
      })}
    </>
  )
}

export default Timelines
