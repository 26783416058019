import { useCallback } from 'react'
import { ContentText, TDocumentDefinitions } from 'pdfmake/interfaces'
import { useDocContentGeneration } from './useDocContentGeneration'
import { COMMENTS_ON_INSTRUCTIONS, DEFAULT_PDF_DOC_TXT } from './constants'
import {
  TCommonsCognusARDocData,
  TCommunityCognusARDocData,
  TVendorCognusARDocData
} from './types'

export const useFinancialsCognusARDocGeneration = () => {
  const {
    getPdfFileName,
    generateDocumentHeader,
    generateReceiverInfo,
    generateDocumentInfo,
    generateBillToInfo,
    generateCommentsSection,
    generateThanksSection,
    generateCognusARInvoiceTable,
    generateTotalTable,
    launchDocGeneration
  } = useDocContentGeneration()

  const _generateCommunityInvoiceDocHeader = useCallback(
    (
      communityData: TCommunityCognusARDocData['communityData']
    ): ContentText[] => [
      ...generateDocumentHeader(DEFAULT_PDF_DOC_TXT.INVOICE),
      ...generateReceiverInfo({
        hospitalName: communityData.cognus_name,
        hospitalAddress: communityData.cognus_address,
        hospitalId: communityData.health_system_id
      }),
      ...generateDocumentInfo({
        docHeaderName: DEFAULT_PDF_DOC_TXT.INVOICE,
        docDate: communityData.invoice_date,
        docId: communityData.document_number,
        docDueOn: communityData.due_date
      }),
      ...generateBillToInfo({
        vendorName: communityData.health_system_name,
        vendorAddress: communityData.health_system_address,
        vendorPhoneNumber: communityData.health_system_phone_number
      }),
      ...generateCommentsSection(COMMENTS_ON_INSTRUCTIONS.CREDIT)
    ],
    [
      generateCommentsSection,
      generateDocumentHeader,
      generateDocumentInfo,
      generateReceiverInfo,
      generateBillToInfo
    ]
  )

  const _generateCommonsRemitAdviceDocHeader = useCallback(
    (commonsData: TCommonsCognusARDocData['commonsData']): ContentText[] => [
      ...generateDocumentHeader(DEFAULT_PDF_DOC_TXT.REMITTANCE_ADVICE),
      ...generateReceiverInfo({
        hospitalName: commonsData.cognus_name,
        hospitalAddress: commonsData.cognus_address,
        hospitalId: commonsData.health_system_id
      }),
      ...generateDocumentInfo({
        docHeaderName: DEFAULT_PDF_DOC_TXT.REMIT_ADVICE,
        docDate: commonsData.invoice_date,
        docId: commonsData.document_number,
        docDueOn: commonsData.due_date
      }),
      ...generateBillToInfo({
        vendorName: commonsData.supplier_name,
        vendorAddress: commonsData.supplier_address,
        vendorPhoneNumber: commonsData.supplier_phone_number
      }),
      ...generateCommentsSection(COMMENTS_ON_INSTRUCTIONS.CREDIT)
    ],
    [
      generateCommentsSection,
      generateDocumentHeader,
      generateDocumentInfo,
      generateReceiverInfo,
      generateBillToInfo
    ]
  )

  const _generateVendorInvoiceDocHeader = useCallback(
    (vendorData: TVendorCognusARDocData['vendorData']): ContentText[] => [
      ...generateDocumentHeader(DEFAULT_PDF_DOC_TXT.INVOICE),
      ...generateReceiverInfo({
        hospitalName: vendorData.cognus_name,
        hospitalAddress: vendorData.cognus_address,
        hospitalId: vendorData.health_system_id
      }),
      ...generateDocumentInfo({
        docHeaderName: DEFAULT_PDF_DOC_TXT.INVOICE,
        docDate: vendorData.invoice_date,
        docId: vendorData.document_number,
        docDueOn: vendorData.due_date
      }),
      ...generateBillToInfo({
        vendorName: vendorData.supplier_name,
        vendorAddress: vendorData.supplier_address,
        vendorPhoneNumber: vendorData.supplier_phone_number
      }),
      ...generateCommentsSection(COMMENTS_ON_INSTRUCTIONS.DEBIT)
    ],
    [
      generateCommentsSection,
      generateDocumentHeader,
      generateDocumentInfo,
      generateReceiverInfo,
      generateBillToInfo
    ]
  )

  const generateCommunityCognusARInvoiceDoc = useCallback(
    ({ communityData, callback }: TCommunityCognusARDocData) => {
      const content: TDocumentDefinitions['content'] = [
        ..._generateCommunityInvoiceDocHeader(communityData),
        ...generateCognusARInvoiceTable(communityData.invoices),
        ...generateTotalTable([
          {
            label: DEFAULT_PDF_DOC_TXT.TOTAL_DUE,
            value: communityData.total
          }
        ]),
        ...generateThanksSection()
      ]
      launchDocGeneration(
        content,
        getPdfFileName(
          DEFAULT_PDF_DOC_TXT.INVOICE,
          communityData.document_number
        ),
        callback
      )
    },
    [
      _generateCommunityInvoiceDocHeader,
      launchDocGeneration,
      generateCognusARInvoiceTable,
      generateThanksSection,
      generateTotalTable,
      getPdfFileName
    ]
  )

  const generateCommonsCognusARRemitAdviceDoc = useCallback(
    ({ commonsData, callback }: TCommonsCognusARDocData) => {
      const content: TDocumentDefinitions['content'] = [
        ..._generateCommonsRemitAdviceDocHeader(commonsData),
        ...generateCognusARInvoiceTable(commonsData.invoices),
        ...generateTotalTable([
          {
            label: DEFAULT_PDF_DOC_TXT.TOTAL,
            value: commonsData.total
          }
        ]),
        ...generateThanksSection()
      ]
      launchDocGeneration(
        content,
        getPdfFileName(
          DEFAULT_PDF_DOC_TXT.REMIT_ADVICE,
          commonsData.document_number
        ),
        callback
      )
    },
    [
      _generateCommonsRemitAdviceDocHeader,
      launchDocGeneration,
      generateCognusARInvoiceTable,
      generateThanksSection,
      generateTotalTable,
      getPdfFileName
    ]
  )

  const generateVendorCognusARInvoiceDoc = useCallback(
    ({ vendorData, callback }: TVendorCognusARDocData) => {
      const content: TDocumentDefinitions['content'] = [
        ..._generateVendorInvoiceDocHeader(vendorData),
        ...generateCognusARInvoiceTable(vendorData.invoices),
        ...generateTotalTable([
          {
            label: DEFAULT_PDF_DOC_TXT.TOTAL_DUE,
            value: vendorData.total
          }
        ]),
        ...generateThanksSection()
      ]
      launchDocGeneration(
        content,
        getPdfFileName(DEFAULT_PDF_DOC_TXT.INVOICE, vendorData.document_number),
        callback
      )
    },
    [
      _generateVendorInvoiceDocHeader,
      launchDocGeneration,
      generateCognusARInvoiceTable,
      generateThanksSection,
      generateTotalTable,
      getPdfFileName
    ]
  )

  return {
    generateCommunityCognusARInvoiceDoc,
    generateCommonsCognusARRemitAdviceDoc,
    generateVendorCognusARInvoiceDoc
  }
}
