import { useCallback, useMemo } from 'react'
import { Form } from 'antd'
import moment, { Moment } from 'moment'
import './styles.scss'
import { BTN_TXT, LABELS } from 'constants/txt'
import {
  formatPhoneNumberToRequest,
  validateFileTypes,
  validateMessages
} from 'helper/common'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { FileUpload } from 'components/FileUpload/FileUpload'
import { Attachment } from 'components/Attachment/Attachment'
import { useContractDetailsPopup } from 'features/ContractDetails/Providers/ContractDetailsPopupProvider'
import {
  ACCEPT_DOCUMENT_TYPES,
  UPLOAD_DOCUMENTS_CONFIG
} from 'features/ContractDetails/ExtendContract/constants'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import { Props, TAddAmendmentForm } from './types'
import { useHSTableContext } from 'features/ContractDetails/HSList/Providers/HSTableProvider'
import {
  timelineDateFormat,
  timelineDateFormatShort
} from 'components/Timelines/constants'
import { Datepicker } from 'components/Datepicker'
import { convertDateToRequestFormat } from 'utils/moment'
import { AddSignatorySelect } from '../../ContractDetailsGeneral/ContractDetailsDocuments/AddSignatoryPopup/AddSignatorySelect'
import cn from 'classnames'
import {
  getAvailableHSUsersForSignatory,
  getAvailableVendorUsersForSignatory
} from '../../api'
import { useParams } from 'react-router-dom'
import { IAddHSAmendmentPayload } from '../types'

export const AddAmendment = (props: Props) => {
  const {
    amendmentDocument,
    setAmendmentDocument,
    pricingEffectiveDate,
    setPricingEffectiveDate
  } = props

  const contractDetailsContext = useContractDetailsContext()
  const { addAmendmentPopup } = useContractDetailsPopup()

  const [form] = Form.useForm<TAddAmendmentForm>()

  const { id } = useParams()

  const { refreshTableData } = useHSTableContext()
  const { addAmendmentDocumentAsync, uploadHSAmendmentDocumentAsync } =
    contractDetailsContext.actions
  const { canAddLoPSignatories } = contractDetailsContext.state
  const { selectedHSRowKey } = addAmendmentPopup.state.payload

  const handleUploadDocument = useCallback(
    (file: File[]) =>
      new Promise((res) => {
        setAmendmentDocument({
          file: file[0],
          uploadedAt: moment()
        })

        res(null)
      }),
    [setAmendmentDocument]
  )

  const handleDeleteDocument = useCallback(() => {
    setAmendmentDocument(null)
  }, [setAmendmentDocument])

  const checkDateRange = useCallback(
    (date: Moment): boolean => {
      const startDate = moment(contractDetailsContext.state.details.start_date)
      const endDate = moment(contractDetailsContext.state.details.finish_date)
      return !date.isBetween(startDate, endDate, 'days', '[]')
    },
    [
      contractDetailsContext.state.details.finish_date,
      contractDetailsContext.state.details.start_date
    ]
  )

  const closeAmendmentPopup = useCallback(() => {
    addAmendmentPopup.actions.close()
    setAmendmentDocument(null)
    setPricingEffectiveDate(null)
    form.resetFields()
  }, [
    addAmendmentPopup.actions,
    setAmendmentDocument,
    setPricingEffectiveDate,
    form
  ])

  const handleFinishForm = useCallback(
    async (values) => {
      if (amendmentDocument === null) {
        return
      }

      const hsAmendmentFile = await uploadHSAmendmentDocumentAsync(
        amendmentDocument.file
      )

      if (hsAmendmentFile?.uuid && selectedHSRowKey && pricingEffectiveDate) {
        let payload = {
          priceEffectiveDate: convertDateToRequestFormat(pricingEffectiveDate),
          file: hsAmendmentFile
        } as IAddHSAmendmentPayload
        if (canAddLoPSignatories) {
          payload = {
            ...payload,
            hs_signatory: {
              signatory: values.hs_signatory_phone_number
                ? null
                : values.hs_signatory,
              email: values.hs_signatory_phone_number
                ? values.hs_signatory
                : null,
              phone_number: formatPhoneNumberToRequest(
                values.hs_signatory_phone_number
              )
            },
            vendor_signatory: {
              signatory: values.vendor_signatory_phone_number
                ? null
                : values.vendor_signatory,
              email: values.vendor_signatory_phone_number
                ? values.vendor_signatory
                : null,
              phone_number: formatPhoneNumberToRequest(
                values.vendor_signatory_phone_number
              )
            }
          }
        }
        await addAmendmentDocumentAsync(selectedHSRowKey.toString(), payload)
        await refreshTableData()
        closeAmendmentPopup()
      }
    },
    [
      closeAmendmentPopup,
      amendmentDocument,
      selectedHSRowKey,
      refreshTableData,
      pricingEffectiveDate,
      uploadHSAmendmentDocumentAsync,
      canAddLoPSignatories
    ]
  )

  const UploadDocumentField = useMemo(
    () =>
      amendmentDocument ? (
        <Attachment
          className="add-amendment-form__attachment"
          link={URL.createObjectURL(amendmentDocument.file)}
          filename={amendmentDocument.file.name}
          onDelete={handleDeleteDocument}
        />
      ) : (
        <FileUpload
          documentType="attachments"
          uploadBtnText={BTN_TXT.UPLOAD_LOP}
          handleUploadFile={handleUploadDocument}
          uploadFilesProps={{
            ...UPLOAD_DOCUMENTS_CONFIG,
            onDropAccepted: handleUploadDocument,
            validator: (file) => validateFileTypes(ACCEPT_DOCUMENT_TYPES, file)
          }}
        />
      ),
    [amendmentDocument, handleDeleteDocument, handleUploadDocument]
  )

  return (
    <Form<TAddAmendmentForm>
      form={form}
      noValidate={true}
      onFinish={handleFinishForm}
      id="add-amendment-form-id"
      validateMessages={{
        ...validateMessages,
        types: {
          email: 'Enter a valid email'
        }
      }}
      className={cn('add-signatory-form add-edit-event-calendar-form', {
        'two-columns': !canAddLoPSignatories,
        'mb-24 gap-16': canAddLoPSignatories
      })}
    >
      <Typography.Body1>
        To add Health System to the contract, please upload LOP and provide
        pricing effective date.
      </Typography.Body1>
      <div
        className={cn('mt-24 mb-24 gap-16', {
          row: canAddLoPSignatories
        })}
      >
        <div
          className={cn('row', {
            'two-columns mb-24': !canAddLoPSignatories
          })}
        >
          <Datepicker
            propsItem={{
              name: 'pricing_effective_date',
              label: LABELS.PRICING_EFFECTIVE_DATE
            }}
            propsDate={{
              // this required for popup render outside the modal window
              // do not remove this line of code
              getPopupContainer: (node) => document?.body ?? node,
              // ---
              disabledDate: checkDateRange,
              format: [timelineDateFormat, timelineDateFormatShort],
              placeholder: 'mm/dd/yyyy',
              value: pricingEffectiveDate,
              name: 'pricing_effective_date',
              onChange: setPricingEffectiveDate
            }}
          />
        </div>
        <div className="row vertical mt-0">
          <Typography.Label
            className="add-amendment-form__lop-label"
            weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
          >
            Letter of Participation
          </Typography.Label>
          {UploadDocumentField}
        </div>
      </div>
      {canAddLoPSignatories && (
        <>
          <Typography.Headline6>Signatories</Typography.Headline6>
          <div className="row mt-16 gap-16">
            <AddSignatorySelect
              form={form}
              selectPropsItem={{
                label: LABELS.VENDOR_SIGNATORY,
                name: 'vendor_signatory'
              }}
              className="half-width"
              optionsRequest={() =>
                getAvailableVendorUsersForSignatory(
                  id ?? '',
                  selectedHSRowKey.toString()
                )
              }
              phoneNumberFieldName="vendor_signatory_phone_number"
            />
            <AddSignatorySelect
              form={form}
              selectPropsItem={{
                label: LABELS.HS_SIGNATORY,
                name: 'hs_signatory'
              }}
              className="half-width"
              phoneNumberFieldName="hs_signatory_phone_number"
              optionsRequest={() =>
                getAvailableHSUsersForSignatory(
                  id ?? '',
                  selectedHSRowKey.toString()
                )
              }
            />
          </div>
        </>
      )}
    </Form>
  )
}
