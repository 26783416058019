import { useCallback, useMemo } from 'react'

import { CONTRACT_STATUS } from 'features/ContractDetails/constants'
import { TerminateContractAction } from 'features/ContractDetails/ContractDetailsActions/TerminateContract'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import ContractSignedAction from 'features/ContractDetails/ContractDetailsActions/ContractSignedAction'
import { ExtendContractAction } from 'features/ContractDetails/ContractDetailsActions/ExtendContract'
import { DeclineContractAction } from './DeclineContract'

const ContractDetailsActions = () => {
  const contractDetailsContext = useContractDetailsContext()

  const { details, isCommunity, canDeclineContract, isCommons } =
    contractDetailsContext.state

  const hideActions = useMemo(
    () =>
      details.status === CONTRACT_STATUS.TERMINATED ||
      details.status === CONTRACT_STATUS.DECLINED,
    [details.status]
  )

  const renderStatusAction = useCallback(() => {
    switch (details.status) {
      case CONTRACT_STATUS.CONTRACTING:
        return <ContractSignedAction />
      case CONTRACT_STATUS.EXECUTED:
      case CONTRACT_STATUS.ACTIVE:
      case CONTRACT_STATUS.EXPIRED:
        return <ExtendContractAction />
      default:
        return null
    }
  }, [details])

  return hideActions ? null : (
    <>
      {(isCommunity || isCommons) && <TerminateContractAction />}
      {canDeclineContract && <DeclineContractAction />}
      {renderStatusAction()}
    </>
  )
}

export default ContractDetailsActions
