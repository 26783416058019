import { SortOrder } from 'antd/es/table/interface'
import { ColumnsType } from 'antd/es/table'
import { Sorter } from '../../components/Sorter/Sorter'
import { Checkbox } from '../../components/Checkbox'
import { Button, BUTTON_TYPES } from '../../components/Button'
import { Tooltip } from '../../components/Tooltip/Tooltip'
import { ROLES } from '../Permission'
import { ReactComponent as Information } from 'assets/svg/Information.svg'
import { ChangeCommunityLevelFunc, UserContractCategory } from './types'

export const columns = (
  role: string,
  onChangeCommunityLevel: ChangeCommunityLevelFunc,
  communityLevelCheckboxesVisible?: boolean,
  communityLevelCheckboxesEditable?: boolean
) => {
  const mainColumns: ColumnsType<UserContractCategory> = [
    {
      dataIndex: 'name',
      key: 'name',
      width: role === ROLES.CONTRACT_STEWARD ? 380 : undefined,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter
          dataIndex="name"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Contract category
        </Sorter>
      ),
      sorter: true,
      showSorterTooltip: false
    }
  ]
  if (communityLevelCheckboxesVisible) {
    mainColumns.push({
      dataIndex: 'community_level',
      key: 'community_level',
      width: 380,
      title: () => (
        <div className="row align-center gap-4">
          Community level{' '}
          <Tooltip title="Enable this option if the user should have permissions to create RFP, contracts, pipelines at the community level.">
            <Button.Icon
              icon={<Information />}
              type={BUTTON_TYPES.GHOST}
              small
            />
          </Tooltip>
        </div>
      ),
      render: (_, { uuid, community_level, community_level_disabled }) => (
        <Checkbox
          propsCheckbox={{
            disabled:
              !communityLevelCheckboxesEditable || community_level_disabled,
            checked: !!community_level,
            onChange: () => onChangeCommunityLevel(uuid, !community_level)
          }}
        />
      )
    })
  }
  return mainColumns
}
