import { IUser } from 'redux/store/user/types'
import { ROLES } from '../Permission'
import {
  getBusinessDetailsCommons,
  getBusinessDetailsCommunity,
  getBusinessDetailsHealthSystem,
  getBusinessDetailsHospital,
  getBusinessDetailsVendor
} from './api'
import moment from 'moment/moment'
import { addBusinessDetailsInitialValues } from './constants'
import {
  TAddBusinessDetails,
  TAddBusinessDetailsForm,
  TResponseBusinessResource
} from './BusinessDetails/types'
import {
  TAddBankAccountDetails,
  TAddBankAccountDetailsForm
} from './BankAccount/types'

export const getRequestForOrumDetails = (user: IUser) => {
  switch (user.role) {
    case ROLES.COMMUNITY_PRESIDENT:
      return getBusinessDetailsCommunity(user.community || '')
    case ROLES.VICE_PRESIDENT:
    case ROLES.COMMUNITY_RCA:
      return getBusinessDetailsHealthSystem(
        user.community || '',
        user.health_system || ''
      )
    case ROLES.COMMONS_VICE_PRESIDENT:
    case ROLES.COMMONS_CLINICAL_COORDINATOR:
      return getBusinessDetailsCommons()
    case ROLES.VENDOR_CONTRACT_ADMIN:
    case ROLES.VENDOR_FINANCE_OFFICER:
      return getBusinessDetailsVendor(user.vendor || '')
    case ROLES.HOSPITAL_ADMIN:
      return getBusinessDetailsHospital(
        user.community || '',
        user.health_system || '',
        user.hospital || ''
      )
    default:
      return Promise.resolve<{ data: TResponseBusinessResource }>({
        data: {
          business_resource: addBusinessDetailsInitialValues
        }
      })
  }
}
export const getDataForBusinessCreation = (
  user: IUser,
  hospitalId?: string
) => {
  if (hospitalId) {
    return getBusinessDetailsHospital(
      user.community || '',
      user.health_system || '',
      hospitalId
    )
  } else {
    switch (user.role) {
      case ROLES.VICE_PRESIDENT:
      case ROLES.COMMUNITY_RCA:
        return getBusinessDetailsHealthSystem(
          user.community || '',
          user.health_system || ''
        )
      case ROLES.VENDOR_CONTRACT_ADMIN:
      case ROLES.VENDOR_FINANCE_OFFICER:
        return getBusinessDetailsVendor(user.vendor || '')
      case ROLES.HOSPITAL_ADMIN:
        return getBusinessDetailsHospital(
          user.community || '',
          user.health_system || '',
          user.hospital || ''
        )
      default:
        return null
    }
  }
}
export const getFieldForOrumBusiness = (user: IUser, hospitalId?: string) => {
  switch (user.role) {
    case ROLES.COMMUNITY_PRESIDENT:
      return { community: user.community }
    case ROLES.VENDOR_CONTRACT_ADMIN:
    case ROLES.VENDOR_FINANCE_OFFICER:
      return { vendor: user.vendor }
    case ROLES.COMMONS_VICE_PRESIDENT:
    case ROLES.COMMONS_CLINICAL_COORDINATOR:
      return { is_commons: true }
    case ROLES.HOSPITAL_ADMIN:
      return { hospital: user.hospital }
    case ROLES.VICE_PRESIDENT:
    case ROLES.COMMUNITY_RCA:
      return {
        health_system: hospitalId ? null : user.health_system,
        community: null,
        hospital: hospitalId ?? null
      }
    default:
      return {}
  }
}

export const convertDetailsToForm = (
  details: TAddBusinessDetails
): TAddBusinessDetailsForm => ({
  ...details,
  incorporation_date: details.incorporation_date
    ? moment(details.incorporation_date)
    : null
})

export const convertDetailsToBankAccountForm = (
  details: TAddBankAccountDetails
): TAddBankAccountDetailsForm => ({
  ...details,
  confirm_account_number: details.account_number
})
