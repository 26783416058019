import { ReactComponent as CognusLogo } from 'assets/svg/LogoPortalNew.svg'
import { ReactComponent as LinkedInLogo } from 'assets/svg/LinkedIn.svg'
import Typography from '../../../components/Typography/Typography'
type TProps = {
  scroll: (link: string) => void
}

const url = 'https://www.linkedin.com/company/cognushealth/about/'
const Footer = ({ scroll }: TProps) => {
  const handleLink = () => {
    window.open(url)
  }
  return (
    <div className="landing-page__footer row space-between full-width">
      <CognusLogo className="landing-page__footer__logo" />
      <div className="landing-page__footer__links column">
        <Typography.Headline6 onClick={() => scroll('about')}>
          About
        </Typography.Headline6>
        <Typography.Headline6 onClick={() => scroll('who-we-serve')}>
          Who we serve
        </Typography.Headline6>
        <Typography.Headline6 onClick={() => scroll('contact-us')}>
          Contact us
        </Typography.Headline6>
      </div>
      <div className="landing-page__footer__info column">
        {/*<Typography.Label>*/}
        {/*  8694 Essex St. Sunnyside, NY 11104 <br /> CognusHealth LLC*/}
        {/*</Typography.Label>*/}
        <Typography.Label className="landing-page__footer__info__mail">
          <a href="mailto:info@cognushealth.com">info@cognushealth.com</a>
        </Typography.Label>
        <LinkedInLogo onClick={handleLink} />
      </div>
    </div>
  )
}

export default Footer
