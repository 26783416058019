import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts'
import { GENERAL_PDF_FONT_TYPES } from './constants'
import { TDocumentDefinitions } from 'pdfmake/interfaces'

// setting up vfs env for PDF generation overview
// need for library getting required font types for internal directories
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
pdfMake.vfs = { Roboto: pdfFonts }

export const generatePaginatedFooter =
  (): TDocumentDefinitions['footer'] => (currentPage, pageCount) => {
    return {
      columns: [
        {
          text: 'Page ' + currentPage.toString() + ' of ' + pageCount
        }
      ],
      marginLeft: 36
    }
  }

export const generatePDFDoc = (
  docDefinition: TDocumentDefinitions,
  fileName: string,
  callback?: () => void
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  pdfMake.fonts = GENERAL_PDF_FONT_TYPES
  const pdfDocGenerator = pdfMake.createPdf(docDefinition)
  pdfDocGenerator.getBlob((blob) => {
    const url = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.setAttribute('download', fileName)
    anchor.click()
    anchor.remove()
    window.URL.revokeObjectURL(url)
    callback?.()
  })
}
