import {
  TNotificationType,
  TNotificationTypeName
} from '../../BottomNotificationProvider/types'
import { useCallback, useMemo } from 'react'
import { setLoading } from '../../../redux/store/common/slice'
import {
  uploadContractItems,
  downloadCurrentItemList,
  getDownloadCurrentItemListStatus,
  checkCurrentItemListWasGenerated,
  getReconciliationItemsTableData
} from '../api'
import {
  canSeeMyContracts,
  downloadFile,
  handleFilesErrors
} from '../../../helper/common'
import {
  FIREBASE_STATUSES,
  TDocumentsErrors,
  VALIDATION_MESSAGES
} from '../../../constants'
import { useDispatch, useSelector } from 'react-redux'
import { useBottomNotificationsContext } from '../../BottomNotificationProvider/BottomNotificationsContextProvider'
import { IContractDetails } from '../../ContractDetails/types'
import { useContractDetailsPopup } from '../../ContractDetails/Providers/ContractDetailsPopupProvider'
import { getToken } from '../../../redux/store/notifications/getters'
import { usePolling } from '../../../hooks/usePolling'
import { RECONCILIATION_STATUSES } from '../types'
import { routes } from '../../../router'
import { getUser } from '../../../redux/store/user/getters'
import { notification } from '../../../components/Notification'

export const useUploadDownload = (
  details: IContractDetails,
  replaceOldItems: boolean,
  isItemsListEmpty: boolean
) => {
  const {
    actions: { openNotification, checkIfProcessIsRunning, onChangeNotification }
  } = useBottomNotificationsContext()

  const registrationId = useSelector(getToken)

  const { poll } = usePolling()

  const user = useSelector(getUser)

  const {
    updateItemListPopup: { actions, state }
  } = useContractDetailsPopup()

  const dispatch = useDispatch()

  const uploadFinishedCallback = useCallback(
    (data) => {
      if (
        data?.notification_status !== FIREBASE_STATUSES.COMPLETED &&
        data?.status !== RECONCILIATION_STATUSES.WAITING_FOR_REVIEW
      ) {
        notification.error({ message: VALIDATION_MESSAGES.SM0061 })
      }
      onChangeNotification(
        {
          id: data?.uuid,
          type:
            data?.notification_status === FIREBASE_STATUSES.COMPLETED &&
            data?.status === RECONCILIATION_STATUSES.COMPLETED
              ? TNotificationType.SUCCESS
              : TNotificationType.ERROR,
          messageProps:
            data?.status === RECONCILIATION_STATUSES.WAITING_FOR_REVIEW
              ? {
                  specificErrorMessage: {
                    message: 'Duplicates found during upload',
                    description: 'Reconcile duplicates to proceed.'
                  }
                }
              : undefined
        },
        TNotificationTypeName.UPLOAD_ITEM_LIST
      )
    },
    [onChangeNotification]
  )

  const downloadCallback = useCallback(
    (data) => {
      onChangeNotification(
        {
          id: data?.uuid,
          type:
            data?.status === FIREBASE_STATUSES.COMPLETED
              ? TNotificationType.SUCCESS
              : TNotificationType.ERROR
        },
        TNotificationTypeName.DOWNLOAD_CURRENT_ITEM_LIST
      )
      if (
        data?.status === FIREBASE_STATUSES.COMPLETED &&
        data?.file_ref?.file
      ) {
        downloadFile(data?.file_ref?.file)
      }
    },
    [onChangeNotification, localStorage]
  )

  const handleUploadFile = async (files: File[], documentType: string) => {
    dispatch(setLoading(true))

    const formData = new FormData()
    formData.append(documentType, files[0], files[0].name)

    try {
      await uploadContractItems(
        {
          document_type: documentType,
          file: formData.get(documentType),
          reconciliation_required: !replaceOldItems
        },
        details.uuid
      ).then((response) => {
        openNotification(
          {
            id: response.data.reconciliation_id,
            type: TNotificationType.INFO,
            date: new Date().toISOString(),
            numberOfItems: isItemsListEmpty ? 0 : 1,
            messageProps: {
              itemUuid: details.uuid,
              redirectRoute: `${routes.contracts}${
                canSeeMyContracts(user.role) ? routes.myContracts : ''
              }/${details.uuid}/item_list`
            }
          },
          TNotificationTypeName.UPLOAD_ITEM_LIST,
          true
        )
        if (!registrationId) {
          poll({
            request: async () =>
              getReconciliationItemsTableData(
                {},
                details.uuid,
                response.data.reconciliation_id
              ),
            successCallback: uploadFinishedCallback
          })
        }
      })
    } catch (e: any) {
      if (e.status === 400 && user.vendor) {
        notification.error({
          message: VALIDATION_MESSAGES.ITEM_LIST_AWAITING_APPROVAL
        })
      }
      handleFilesErrors(e as TDocumentsErrors)
    } finally {
      if (state.visible) {
        actions.close()
      }
      dispatch(setLoading(false))
    }
  }

  const onDownloadCurrentItemList = useCallback(async () => {
    // if file was already generated - just download it
    await checkCurrentItemListWasGenerated(details.uuid)
      .then(async (resp) => {
        if (resp.data.file) {
          downloadFile(resp.data.file)
          openNotification(
            {
              id: resp.data.file,
              type: TNotificationType.SUCCESS
            },
            TNotificationTypeName.DOWNLOAD_CURRENT_ITEM_LIST,
            true
          )
        }
      })
      // if not generated - trigger file generation
      .catch(async (err) => {
        if (err.status === 404) {
          await downloadCurrentItemList(details.uuid)
            .then((resp) => {
              openNotification(
                {
                  id: resp.data.uuid,
                  type: TNotificationType.INFO,
                  date: new Date().toISOString()
                },
                TNotificationTypeName.DOWNLOAD_CURRENT_ITEM_LIST,
                true
              )
              if (!registrationId) {
                poll({
                  request: async () =>
                    getDownloadCurrentItemListStatus({
                      id: details.uuid,
                      task_id: resp.data.uuid
                    }),
                  successCallback: downloadCallback
                })
              }
            })
            .catch(() => {
              openNotification(
                {
                  id: details.uuid,
                  type: TNotificationType.ERROR
                },
                TNotificationTypeName.DOWNLOAD_CURRENT_ITEM_LIST,
                true
              )
            })
        }
        console.log(err)
      })
  }, [details, registrationId, downloadCallback])

  const uploadNotifications = localStorage.getItem(
    TNotificationTypeName.UPLOAD_ITEM_LIST
  )

  const disableUploadButton = useMemo(() => {
    return (
      checkIfProcessIsRunning(
        details.uuid,
        TNotificationTypeName.UPLOAD_ITEM_LIST
      ) || !!details.pending_reconciliation?.required_items_count
    )
  }, [checkIfProcessIsRunning, details, uploadNotifications])

  return {
    handleUploadFile,
    disableUploadButton,
    onDownloadCurrentItemList
  }
}
