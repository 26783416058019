import { Popup } from '../../../../../components/Popup'
import { Button, BUTTON_TYPES } from '../../../../../components/Button'
import { BTN_TXT, LABELS } from '../../../../../constants'
import { useContractDetailsPopup } from '../../../Providers/ContractDetailsPopupProvider'
import { Typography } from '../../../../../components/Typography'
import { Form } from 'antd'
import { validateMessages } from '../../../../../helper/common'
import cn from 'classnames'
import { TAddSignatoryForm } from './types'

import { useContractDetailsContext } from '../../../Providers/ContractDetailsContextProvider'

import { AddSignatorySelect } from './AddSignatorySelect'
import { getAvailableUsersForSignatory } from '../../../api'
import { useParams } from 'react-router-dom'

export const AddSignatoryPopup = () => {
  const {
    addSignatoryPopup: { state, actions }
  } = useContractDetailsPopup()

  const { id } = useParams()

  const {
    actions: { addSignatory }
  } = useContractDetailsContext()

  const [form] = Form.useForm<TAddSignatoryForm>()

  const onClose = () => {
    actions.close()
    form.resetFields()
  }

  return (
    <Popup
      {...state}
      className="add-signatory-modal"
      onCancel={onClose}
      width={442}
      footer={
        <>
          <Button type={BUTTON_TYPES.DEFAULT} upperCase onClick={onClose}>
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            upperCase
            form="add-signatory-form"
            htmlType="submit"
          >
            {BTN_TXT.ADD}
          </Button>
        </>
      }
    >
      <div className="gap-16 column full-width">
        <Typography.Body2 className="add-signatory-modal__hint">
          The selected signatory will receive the document for signing once
          community and vendor approvals are finalized.
        </Typography.Body2>
        <Form<TAddSignatoryForm>
          form={form}
          noValidate={true}
          onFinish={addSignatory}
          validateMessages={{
            ...validateMessages,
            types: {
              email: 'Enter a valid email'
            }
          }}
          id="add-signatory-form"
          className={cn('add-signatory-form add-edit-event-calendar-form')}
        >
          <div className="column gap-16">
            <AddSignatorySelect
              form={form}
              selectPropsItem={{ label: LABELS.SIGNATORY, name: 'signatory' }}
              optionsRequest={() => getAvailableUsersForSignatory(id)}
            />
          </div>
        </Form>
      </div>
    </Popup>
  )
}
