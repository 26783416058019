import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  generalInitialState,
  initialState,
  participantsInitialState
} from 'features/PRS/PRSDetails/General/constants'
import { ACTIONS, SUBJECTS } from 'features/PRS/Abilities'
import useTabs, { TabsReturnActions, TabsReturnState } from 'hooks/useTabs'
import { getUser } from 'redux/store/user/getters'
import { setLoaderProps, setLoading } from 'redux/store/common/slice'
import {
  PRS_DETAILS_TABS,
  PRS_STATUSES,
  PRS_STATUSES_MESSAGES,
  PRS_STATUSES_TIMERS
} from '../../../../pages/ProductRequests/constants'
import { usePRSDetailsPopupContext } from './PRSDetailsPopupProvider'
import {
  TPrsContract,
  TPRSDetailsResponse,
  TPRSGeneralDetailsResponse
} from '../types'
import { TDocumentTypeUnion } from 'components/FileUpload/types'
import { notification } from 'components/Notification'
import { VALIDATION_MESSAGES } from 'constants/index'
import { Form } from 'antd'
import {
  getContractDetails,
  getFacilityUsers,
  getPrsData,
  getSurveyForCs,
  getSurveyResultsForCs,
  setupPrsDetailsTimeRequestAsync,
  updatePRS,
  uploadDocumentsFiles,
  uploadImagesFiles
} from 'pages/ProductRequests/api'
import {
  handleDeletedAttachmentsIds,
  handleDeletedAttachmentsItems
} from '../../utils'
import { TFormPRSCreationFormFields } from 'components/Forms/forms.prsCreation.d'
import { routes } from 'router'
import useRouter from 'hooks/useRouter'
import { subject } from '@casl/ability'
import { usePrsDetailsAbility } from './PrsDetailsAbilityProvider'
import { ROLES } from 'features/Permission'
import { TQuestionsForm } from 'components/Forms/forms.surveyQuestions.d'
import { initialStateForSurveyCreation } from '../Voting/Survey/constants'
import { prsContractInitialState } from '../constants'
import {
  ITimeline,
  ITimelineValidation
} from '../../../../components/Timelines/types'
import { timelinesRequestDataConverter } from '../../PrsDetailsTimelines/utils'

type ContextProps = {
  state: {
    data: TPRSDetailsResponse
    isVCS: boolean
    form: any
    changedValues: any
    generalInfo: any
    participantsInfo: any
    isShowButtons: boolean
    fields: any
    isNextDisabled: boolean
    isSaveDisabled: boolean
    isNew: boolean
    canCreateOrEditSurvey: boolean
    canOpenSurvey: boolean
    isCanEdit: boolean
    isAbleToEditCommunityResp: boolean
    isCommunityResp: boolean
    isClinicalSponsor: boolean
    isRcaResponsible: boolean
    communityRespHs: string
    clinicalRationaleData: any
    clinicalRationaleResponse: any
    isError: boolean
    page: number
    prsContract: TPrsContract
    isFacilityUser: boolean
    timelinesErrors: ITimelineValidation[]
  } & TabsReturnState
  actions: {
    handleGetPRSData: () => Promise<void>
    handleCancelPRS: VoidFunction
    handleChangePrsStatus: (status: string) => void
    handleDeleteAttachment: (uuid: string, isImage?: boolean) => Promise<void>
    handleUploadFile: (
      files: File[],
      documentType: TDocumentTypeUnion,
      isImage?: boolean
    ) => Promise<void>
    onValueChange: (data: TPRSDetailsResponse) => void
    handleGeneralInfo: (data: TPRSGeneralDetailsResponse) => void
    handleParticipantsInfo: (data: TPRSGeneralDetailsResponse) => void
    setShowButtons: (data: boolean) => void
    setFields: any
    resetFields: () => void
    resetCRR: () => void
    resetChanged: () => void
    setSaveDisabled: (value: boolean) => void
    setIsError: (value: boolean) => void
    setClinicalRationaleData: (value: any) => void
    setSelectedOptions: (uuid: string, data: string[]) => void
    setPage: (val: number) => void
    getSurveyFormForCS: () => void
    resetForm: () => void
    setupPrsDetailsTimeAsync: (
      timelines: ITimeline<typeof PRS_STATUSES_TIMERS>[]
    ) => Promise<void>
    setTimelinesErrors: (data: ITimelineValidation[]) => void
  } & TabsReturnActions
}

const PRSDetailsContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const PRSDetailsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch()
  const { id, tab } = useParams()
  const { navigate } = useRouter()
  const user = useSelector(getUser)

  const [isShowButtons, setShowButtons] = useState(false)
  const [isNextDisabled, setNextDisabled] = useState(true)
  const [isSaveDisabled, setSaveDisabled] = useState(true)
  const [communityRespHs, setCommunityRespHs] = useState('')
  const [prsContract, setPrsContract] = useState<TPrsContract>(
    prsContractInitialState
  )
  const [changedValues, setChangedValues] = useState({})
  const [generalInfo, setGeneralInfo] = useState(generalInitialState)
  const [participantsInfo, setParticipantsInfo] = useState<any>(
    participantsInitialState
  )
  const [timelinesErrors, setTimelinesErrors] = useState<ITimelineValidation[]>(
    []
  )
  const [fields, setFields] = useState<TFormPRSCreationFormFields>([])

  const [isError, setIsError] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)

  const [clinicalRationaleData, setClinicalRationaleData] =
    useState<TQuestionsForm>(initialStateForSurveyCreation)

  const [initialClinicalRationaleData, setInitialClinicalRationaleData] =
    useState<TQuestionsForm>(initialStateForSurveyCreation)

  const [clinicalRationaleResponse, setClinicalRationaleResponse] =
    useState<TQuestionsForm>(initialStateForSurveyCreation)

  const [isFacilityUser, setIsFacilityUser] = useState(false)

  const [form] = Form.useForm()

  const ability = usePrsDetailsAbility()

  useEffect(() => {
    if (tab === '1' && id === 'new')
      navigate(`${routes.productRequests}/new/0`, { replace: true })
  }, [])
  const { cancelPRSPopup, prsStepsTimePopup } = usePRSDetailsPopupContext()

  const { state: tabsState, actions: tabsActions } = useTabs({
    tabs: PRS_DETAILS_TABS,
    activeTab: tab || PRS_DETAILS_TABS[0].key
  })

  const { setTabs } = tabsActions

  const [data, _setData] = useState<TPRSDetailsResponse>(initialState)

  const handleGetPRSContract = useCallback(async () => {
    dispatch(setLoading(true))
    try {
      const resp = await getContractDetails(id as string)
      if (resp.data) {
        setPrsContract(resp.data?.results[0])
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [dispatch, id])

  const handleGetPRSData = useCallback(async () => {
    dispatch(setLoading(true))
    try {
      const resp = await getPrsData(id as string)
      if (resp.data) {
        if (isVCS) {
          setShowButtons(true)
          setNextDisabled(false)
          resetChanged()
        }
        setSaveDisabled(true)
        setCommunityRespHs(resp.data.community_responsible?.health_system || '')
        _setData({
          ...resp.data,
          community: resp.data.community?.uuid,
          community_name: resp.data.community?.name,
          community_user: resp.data.community_responsible,
          rca_user: resp.data.rca,
          community_responsible: resp.data.community_responsible?.uuid,
          clinical_sponsor: !!resp.data.clinical_sponsor?.phone_number
            ? {
                ...resp.data.clinical_sponsor,
                phone_number: resp.data.clinical_sponsor?.phone_number?.replace(
                  '+1',
                  ''
                )
              }
            : null
        })
        if (resp.data.status === PRS_STATUSES.ARCHIVED) {
          handleGetPRSContract()
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [dispatch, id, handleGetPRSContract])

  const handleCancelPRS = useCallback(() => {
    dispatch(setLoading(true))
    // cancelPRS(data.uuid)
    //   .then(() => {
    //   //callback
    //   })
    //   .catch(() => dispatch(setLoading(false)))
  }, [cancelPRSPopup.actions, data.uuid, dispatch, handleGetPRSData])

  const isVCS = useMemo(
    () => Boolean(user?.role === ROLES.VENDOR_CONTRACT_STEWARD),
    [user.role, user.uuid]
  )

  const isCanEdit = useMemo(() => {
    return Boolean(
      isVCS &&
        data.uuid &&
        (data.status === PRS_STATUSES.CREATED ||
          data.status === PRS_STATUSES.DECLINED)
    )
  }, [isVCS, data.status, data.uuid, user.uuid])

  const handleChangePrsStatus = useCallback(
    (status: string) => {
      dispatch(setLoading(true))
      updatePRS(data.uuid, { status })
        .then(() => {
          handleGetPRSData()
          notification.success({
            message: `${PRS_STATUSES_MESSAGES[status]}`
          })
        })
        .catch((err) => {
          notification.error({
            message: err.data?.details[0]
          })
          dispatch(setLoading(false))
        })
    },
    [data.uuid, handleGetPRSData, dispatch]
  )

  const handleGeneralInfo = (data) => {
    setGeneralInfo({ ...generalInfo, ...data })
  }

  const handleParticipantsInfo = (data) => {
    setParticipantsInfo({ ...participantsInfo, ...data })
  }

  const setupPrsDetailsTimeAsync = useCallback(
    async (timelines: ITimeline<typeof PRS_STATUSES_TIMERS>[]) => {
      if (!id) {
        throw new Error('Prs ID not provided')
      }

      const requestData = timelinesRequestDataConverter(timelines)

      try {
        dispatch(setLoading(true))

        await setupPrsDetailsTimeRequestAsync(id, requestData)
        await handleGetPRSData()

        prsStepsTimePopup.actions.close()
      } catch (e: any) {
        if (!!e?.data?.timelines) {
          const errors = e?.data?.timelines.map((i) =>
            !!i?.time_duration
              ? { message: i?.time_duration?.time_duration, status: 'error' }
              : { status: '', message: '' }
          )
          setTimelinesErrors(errors)
        }
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [id, dispatch, handleGetPRSData, prsStepsTimePopup.actions]
  )

  const isAbleToEditCommunityResp = useMemo(
    () =>
      ability.can(ACTIONS.EDIT, subject(SUBJECTS.HEALTH_SYSTEMS, { ...data })),
    [data]
  )

  const isCommunityResp = useMemo(() => {
    return Boolean(data.community_responsible === user.uuid)
  }, [data, user])
  const isClinicalSponsor = useMemo(() => {
    return Boolean(data.clinical_sponsor_stakeholder?.uuid === user.uuid)
  }, [data, user])
  const isRcaResponsible = useMemo(() => {
    return Boolean(data.rca_user?.uuid === user.uuid)
  }, [data, user])

  const resetFields = () => {
    if (id === 'new') {
      setParticipantsInfo((oldValues) => ({
        ...oldValues,
        health_systems: [],
        community_responsible: '',
        clinical_sponsor: null
      }))
    } else {
      _setData((prevData) => ({
        ...prevData,
        health_systems: [],
        community_responsible: '',
        clinical_sponsor: null
      }))
    }
  }

  useEffect(() => {
    if (
      ability.can(ACTIONS.RESPOND, subject(SUBJECTS.PRS, { ...data })) ||
      ability.can(ACTIONS.OPEN, subject(SUBJECTS.VOTING, { ...data })) ||
      ability.can(
        ACTIONS.OPEN,
        subject(SUBJECTS.VOTING_RESPONSE, { ...data })
      ) ||
      ability.can(ACTIONS.CREATE, subject(SUBJECTS.VOTING, { ...data }))
    )
      getIsFacilityUser()
  }, [ability, data])
  const resetCRR = () => {
    if (id === 'new') {
      setParticipantsInfo((oldValues) => ({
        ...oldValues,
        community_responsible: '',
        clinical_sponsor: null
      }))
    } else {
      _setData((prevData) => ({
        ...prevData,
        community_responsible: '',
        clinical_sponsor: null
      }))
    }
  }

  const isNew = useMemo(() => {
    return Boolean(id === 'new')
  }, [id])

  const handleUploadFile = useCallback(
    async (files: File[], documentType: string, isImage?: boolean) => {
      dispatch(setLoading(true))
      dispatch(
        setLoaderProps({
          isFileUpload: true,
          message: 'We are uploading the document. This might take some time...'
        })
      )

      const formData = new FormData()
      formData.append(documentType, files[0], files[0].name)

      try {
        const field = isImage ? 'prs_request_image' : 'prs_request_attachment'
        const request = isImage ? uploadImagesFiles : uploadDocumentsFiles
        if (id !== 'new') setSaveDisabled(false)
        const response = await request({
          document_type: documentType,
          file: formData.get(documentType)
        })
        const uuids = [
          ...data[field + 's'].map((i) => i.uuid),
          response.data.uuid
        ]
        form.setFieldsValue({
          [field + '_ids']: uuids,
          [field + 's']: [...data[field + 's'], response.data]
        })
        setGeneralInfo({
          ...generalInfo,
          [field + '_ids']: uuids,
          [field + 's']: [...data[field + 's'], response.data]
        })
        _setData({
          ...data,
          [field + '_ids']: uuids,
          [field + 's']: [...data[field + 's'], response.data]
        })
        if (!isNew) onValueChange({ [field + 's']: uuids })
        notification.success({ message: VALIDATION_MESSAGES.SM0044 })
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
        dispatch(
          setLoaderProps({
            isFileUpload: false,
            message: ''
          })
        )
      }
    },
    [data, dispatch, handleGetPRSData, generalInfo, tab]
  )

  const onValueChange = (newData) => {
    const isDisable = !!Object.entries(newData).find(
      (item: any) => !item[1] || !item[1].toString().length
    )
    if (tab === '0') {
      if (id === 'new') {
        setGeneralInfo({ ...generalInfo, ...newData })
        setNextDisabled(isDisable)
      } else if (
        !newData.prs_request_attachments &&
        !newData.prs_request_images
      ) {
        _setData({ ...data, ...newData })
      }
    }

    let clinical = { ...data.clinical_sponsor, ...newData.clinical_sponsor }
    if (isNew && !!participantsInfo.clinical_sponsor)
      clinical = {
        ...participantsInfo?.clinical_sponsor,
        ...newData.clinical_sponsor
      }
    if (tab === '1') {
      if (id === 'new') {
        setParticipantsInfo((oldValues) => ({
          ...oldValues,
          ...newData,
          clinical_sponsor: clinical
        }))
        setSaveDisabled(isDisable)
      } else {
        _setData({ ...data, ...newData, clinical_sponsor: clinical })
      }
    }

    if (id !== 'new') setSaveDisabled(false)
    setShowButtons(true)
    setChangedValues({
      ...changedValues,
      ...newData,
      clinical_sponsor: clinical
    })
  }

  const getIsFacilityUser = () => {
    getFacilityUsers(id as string, {
      params: {
        limit: 1,
        offset: 0,
        search: user.email
      }
    }).then((res) => {
      setIsFacilityUser(!!res.data.results?.length)
    })
  }

  const resetChanged = () => setChangedValues({})

  const resetForm = () => {
    setClinicalRationaleData(initialClinicalRationaleData)
  }

  const getSurveyFormForCS = useCallback(() => {
    if (id) {
      dispatch(setLoading(true))
      getSurveyForCs(id).then((res) => {
        setClinicalRationaleData({
          questions: res.data.results
        })
        setInitialClinicalRationaleData({
          questions: res.data.results
        })
      })
      getSurveyResultsForCs(id)
        .then((res) => {
          const newData = res.data.results.reduce((acc, curr) => {
            acc.set(curr.option, curr)
            return acc
          }, new Map())
          setClinicalRationaleResponse(newData)
        })
        .finally(() => dispatch(setLoading(false)))
    }
  }, [id])

  useEffect(() => {
    if (
      ability.can(
        ACTIONS.VIEW,
        subject(SUBJECTS.CLINICAL_RATIONALE, { ...data })
      )
    )
      getSurveyFormForCS()
  }, [id, data, ability])

  const setSelectedOptions = useCallback(
    (uuid, data) => {
      const results = clinicalRationaleData.questions.map((i) =>
        i.uuid === uuid
          ? {
              ...i,
              selectedOptions: data
            }
          : i
      )
      setClinicalRationaleData({ questions: results })
    },
    [clinicalRationaleData]
  )

  const handleDeleteAttachment = useCallback(
    async (id: string, isImage?: boolean) => {
      dispatch(setLoading(true))
      try {
        const field = isImage ? 'prs_request_image' : 'prs_request_attachment'
        const ids = handleDeletedAttachmentsIds(data, id, field + 's')
        const items = handleDeletedAttachmentsItems(data, id, field + 's')
        if (id !== 'new') setSaveDisabled(false)
        form.setFieldsValue({
          [field + '_ids']: ids,
          [field + 's']: items
        })
        setGeneralInfo({
          ...generalInfo,
          [field + '_ids']: ids,
          [field + 's']: items
        })
        _setData({
          ...data,
          [field + '_ids']: ids,
          [field + 's']: items
        })
        if (!isNew) onValueChange({ [field + 's']: ids })
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [data, dispatch, handleGetPRSData, generalInfo, tab]
  )

  useEffect(() => {
    if (id && id !== 'new') {
      handleGetPRSData()
    }
  }, [id, handleGetPRSData, user.vendor])

  const canCreateOrEditSurvey = useMemo(
    () => ability.can(ACTIONS.CREATE, subject(SUBJECTS.SURVEY, { ...data })),
    [ability, data]
  )
  const canOpenSurvey = useMemo(
    () => ability.can(ACTIONS.OPEN, subject(SUBJECTS.SURVEY, { ...data })),
    [ability, data]
  )

  useLayoutEffect(() => {
    setTabs(
      PRS_DETAILS_TABS.map((i) => {
        const isParticipantsDisabled =
          i.key === PRS_DETAILS_TABS[1].key &&
          tab === '0' &&
          id === 'new' &&
          (!data?.prs_request_images.length || isNextDisabled)
        const isVotingDanger =
          i.key === PRS_DETAILS_TABS[3].key &&
          !ability.can(ACTIONS.CREATE, subject(SUBJECTS.SURVEY, { ...data })) &&
          !ability.can(ACTIONS.OPEN, subject(SUBJECTS.VOTING, { ...data })) &&
          !ability.can(
            ACTIONS.VIEW,
            subject(SUBJECTS.VOTING_RESPONSE, { ...data })
          )
        const IsFacilityUsersDanger =
          i.key === PRS_DETAILS_TABS[2].key &&
          !ability.can(
            ACTIONS.VIEW,
            subject(SUBJECTS.FACILITY_USERS, { ...data })
          )
        const IsResultDanger =
          i.key === PRS_DETAILS_TABS[5].key &&
          !ability.can(ACTIONS.VIEW, subject(SUBJECTS.RESULTS_TAB, { ...data }))

        const IsDangerVoteCS =
          i.key === PRS_DETAILS_TABS[3].key &&
          user.role === ROLES.STAKEHOLDER &&
          !isFacilityUser

        const isTrialPreApprovalDanger =
          i.key === PRS_DETAILS_TABS[4].key &&
          !ability.can(
            ACTIONS.VIEW,
            subject(SUBJECTS.PRE_APPROVAL_TAB, { ...data })
          )

        return {
          ...i,
          disabled: isParticipantsDisabled,
          danger:
            isVotingDanger ||
            IsFacilityUsersDanger ||
            IsResultDanger ||
            isTrialPreApprovalDanger ||
            IsDangerVoteCS
        }
      })
    )
  }, [data, setTabs, isNextDisabled, id, tab, user, isFacilityUser])

  const state = useMemo(
    () => ({
      ...tabsState,
      data,
      isVCS,
      form,
      isNextDisabled,
      isShowButtons,
      changedValues,
      generalInfo,
      fields,
      participantsInfo,
      isSaveDisabled,
      isNew,
      isCanEdit,
      isAbleToEditCommunityResp,
      canCreateOrEditSurvey,
      canOpenSurvey,
      communityRespHs,
      isCommunityResp,
      isClinicalSponsor,
      clinicalRationaleData,
      clinicalRationaleResponse,
      isError,
      page,
      isRcaResponsible,
      isFacilityUser,
      prsContract,
      timelinesErrors
    }),
    [
      data,
      tabsState,
      isVCS,
      form,
      isNextDisabled,
      isShowButtons,
      changedValues,
      generalInfo,
      fields,
      participantsInfo,
      isSaveDisabled,
      isNew,
      isCanEdit,
      isAbleToEditCommunityResp,
      communityRespHs,
      canCreateOrEditSurvey,
      canOpenSurvey,
      isCommunityResp,
      isClinicalSponsor,
      clinicalRationaleData,
      clinicalRationaleResponse,
      isError,
      page,
      isRcaResponsible,
      isFacilityUser,
      prsContract,
      timelinesErrors
    ]
  )

  const actions = useMemo(
    () => ({
      ...tabsActions,
      handleGetPRSData,
      handleCancelPRS,
      handleChangePrsStatus,
      handleDeleteAttachment,
      handleUploadFile,
      onValueChange,
      setShowButtons,
      handleGeneralInfo,
      setFields,
      handleParticipantsInfo,
      resetFields,
      resetCRR,
      setSaveDisabled,
      resetChanged,
      setIsError,
      setClinicalRationaleData,
      setSelectedOptions,
      setPage,
      getSurveyFormForCS,
      resetForm,
      setupPrsDetailsTimeAsync,
      setTimelinesErrors
    }),
    [
      tabsActions,
      handleGetPRSData,
      handleCancelPRS,
      handleChangePrsStatus,
      handleDeleteAttachment,
      handleUploadFile,
      onValueChange,
      setShowButtons,
      handleGeneralInfo,
      setFields,
      handleParticipantsInfo,
      resetFields,
      resetCRR,
      setSaveDisabled,
      resetChanged,
      setIsError,
      setClinicalRationaleData,
      setSelectedOptions,
      setPage,
      getSurveyFormForCS,
      resetForm,
      setupPrsDetailsTimeAsync,
      setTimelinesErrors
    ]
  )

  return (
    <PRSDetailsContext.Provider value={{ state, actions }}>
      {children}
    </PRSDetailsContext.Provider>
  )
}

export const usePRSDetailsContext = () => useContext(PRSDetailsContext)

export default PRSDetailsContextProvider
