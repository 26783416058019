import Fetcher from '../../../../services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from '../../../../constants'

const fetcher = new Fetcher({})

export const initiateRebatesPayment = async (id: string) =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.REBATES}${id}/${API_DICTIONARY.PAYMENT}`,
    method: HTTP_METHODS.POST
  })

export const initiateCognusARPayment = async (id: string) =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.COGNUS_AR}${id}/${API_DICTIONARY.PAYMENT}`,
    method: HTTP_METHODS.POST
  })
