import './styles.scss'
import EmptyState from './EmptyState'
import { TAddBankAccountDetails } from './types'
import { Typography, TYPOGRAPHY_WEIGHT } from '../../../components/Typography'
import { Forms } from '../../../components/Forms'
import { Form } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { convertDetailsToBankAccountForm } from '../utils'
import { FloatFooter } from '../../../components/FloatFooter/FloatFooter'
import { BTN_TXT } from '../../../constants'
import ConfirmationModal from './ConfirmationModal'
import { Tooltip } from '../../../components/Tooltip/Tooltip'
import {
  SETTINGS_STATUSES,
  SETTINGS_STATUSES_CLASSNAME,
  SETTINGS_STATUSES_NAME,
  SETTINGS_STATUSES_TOOLTIP_BANK_ACCOUNT
} from '../../../pages/Settings/constants'
import { Status } from '../../../components/Status'

type TProps = {
  bankAccount: TAddBankAccountDetails
  getDetails: () => void
  isVerified: boolean
}
const BankAccount = ({ bankAccount, getDetails, isVerified }: TProps) => {
  const [isActive, setIsActive] = useState(false)
  const [isShowPopup, setIsShowPopup] = useState(false)

  const [form] = Form.useForm()

  const submitCancellation = () => {
    handleCancelForm()
  }

  const handleClosePopup = () => {
    setIsShowPopup(false)
  }
  const handleOpenPopup = () => {
    setIsShowPopup(true)
  }

  const handleSubmitPopup = () => {
    setIsShowPopup(false)
    form.submit()
  }

  const handleCancelForm = useCallback(() => {
    setIsActive(false)
    getDetails()
  }, [form, setIsActive, bankAccount])

  useEffect(() => {
    form.setFieldsValue(convertDetailsToBankAccountForm(bankAccount))
  }, [bankAccount])

  return (
    <div className="bank-account-container  full-width full-height">
      {bankAccount?.uuid ? (
        <>
          <div className="row mb-24 gap-16">
            <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.BOLD}>
              Bank account
            </Typography.Body1>
            <Tooltip
              placement="topLeft"
              title={SETTINGS_STATUSES_TOOLTIP_BANK_ACCOUNT[bankAccount.status]}
            >
              <div>
                <Status.Default
                  className={SETTINGS_STATUSES_CLASSNAME[bankAccount.status]}
                >
                  {
                    SETTINGS_STATUSES_NAME[
                      bankAccount.status || SETTINGS_STATUSES.CREATED
                    ]
                  }
                </Status.Default>
              </div>
            </Tooltip>
          </div>
          <Forms.AddBankAccount
            form={form}
            callback={() => {
              getDetails()
            }}
            setIsActive={setIsActive}
          />
          <FloatFooter
            isActive={isActive}
            onCancel={submitCancellation}
            onSave={handleOpenPopup}
            saveBtnText={BTN_TXT.SAVE}
          />
        </>
      ) : (
        <EmptyState isVerified={isVerified} />
      )}
      <ConfirmationModal
        isAccount
        visible={isShowPopup}
        handleSubmit={handleSubmitPopup}
        handleReject={handleClosePopup}
      />
    </div>
  )
}

export default BankAccount
