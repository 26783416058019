import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability'
import { IUser } from 'redux/store/user/types'
import { ROLES } from 'features/Permission'
import { ACTIONS, SUBJECTS } from './constants'
import {
  FinancialsAbilityBuilder,
  FinancialsAbility,
  PossibleAbilities,
  Conditions
} from './types'

const ContractAbilityClass = Ability as AbilityClass<FinancialsAbility>

export const ability = new Ability<PossibleAbilities, Conditions>()

export default (user: IUser) => {
  const builder = new AbilityBuilder(ContractAbilityClass)

  switch (user.role) {
    case ROLES.VENDOR_CONTRACT_ADMIN:
    case ROLES.VENDOR_FINANCE_OFFICER:
    case ROLES.VENDOR_ANALYST:
    case ROLES.VENDOR_CONTRACT_STEWARD:
      defineVendorsAbilities(builder, user)
      break
    case ROLES.COMMONS_VICE_PRESIDENT:
    case ROLES.COMMONS_CONTRACT_STEWARD:
    case ROLES.COMMONS_ANALYST:
      defineCognusCommonsAbilities(builder, user)
      break
    case ROLES.COMMUNITY_PRESIDENT:
    case ROLES.VICE_PRESIDENT:
    case ROLES.CONTRACT_STEWARD:
    case ROLES.ANALYST:
    case ROLES.COMMUNITY_RCA:
      defineCognusCommunityAbilities(builder, user)
  }

  return new Ability(builder.rules) as FinancialsAbility
}

const defineVendorsAbilities = (
  builder: FinancialsAbilityBuilder,
  user: IUser
) => {
  builder.can(ACTIONS.VIEW, SUBJECTS.REBATES_DASHBOARD)
  builder.can(ACTIONS.VIEW, SUBJECTS.COGNUS_AR)
  builder.can(ACTIONS.DISPUTE, SUBJECTS.INVOICE)
  builder.can(ACTIONS.VIEW, SUBJECTS.INVOICE_DETAILS)
  if (
    user.role === ROLES.VENDOR_CONTRACT_ADMIN ||
    user.role === ROLES.VENDOR_FINANCE_OFFICER
  ) {
    builder.can(ACTIONS.INITIATE, SUBJECTS.PAYMENT)
    builder.can(ACTIONS.ADD, SUBJECTS.BUSINESS_RESOURCE)
  }
}

const defineCognusCommonsAbilities = (
  builder: FinancialsAbilityBuilder,
  user: IUser
) => {
  builder.can(ACTIONS.VIEW, SUBJECTS.REBATES_DASHBOARD)
  builder.can(ACTIONS.VIEW, SUBJECTS.COGNUS_AR)
  builder.can(ACTIONS.VIEW, SUBJECTS.INVOICE_DETAILS)
  if (user.role === ROLES.COMMONS_VICE_PRESIDENT) {
    builder.can(ACTIONS.ADD, SUBJECTS.BUSINESS_RESOURCE)
  }
}

const defineCognusCommunityAbilities = (
  builder: FinancialsAbilityBuilder,
  user: IUser
) => {
  builder.can(ACTIONS.VIEW, SUBJECTS.REBATES_DASHBOARD)
  builder.can(ACTIONS.VIEW, SUBJECTS.COGNUS_AR)
  if (user.role !== ROLES.COMMUNITY_PRESIDENT) {
    builder.can(ACTIONS.VIEW, SUBJECTS.INVOICE_DETAILS)
    builder.can(ACTIONS.DISPUTE, SUBJECTS.INVOICE)
  }
  if (user.role === ROLES.VICE_PRESIDENT || user.role === ROLES.COMMUNITY_RCA) {
    builder.can(ACTIONS.INITIATE, SUBJECTS.PAYMENT)
  }
  if (user.role !== ROLES.CONTRACT_STEWARD && user.role !== ROLES.ANALYST) {
    builder.can(ACTIONS.ADD, SUBJECTS.BUSINESS_RESOURCE)
  }
}
