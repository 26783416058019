import { TDocumentDefinitions } from 'pdfmake/interfaces'

export const DEFAULT_PDF_DOC_TXT = {
  REMITTANCE_ADVICE: 'REMITTANCE ADVICE',
  REMIT_ADVICE: 'Remit Advice',
  INVOICE: 'Invoice',
  CONTRACT_CAT: 'CONTRACT CATEGORY',
  HOSPITAL: 'HOSPITAL',
  INVOICE_AMT: 'INVOICE AMT',
  REBATE_AMT: 'REBATE AMT',
  COGNUS_AMT: 'COGNUS AMT',
  DEPARTMENT: 'Department',
  DATE: 'DATE',
  DUE_ON: 'DUE ON',
  BILL_TO: 'BILL TO',
  TOTAL_REBATE: 'TOTAL REBATE',
  TOTAL_DUE: 'TOTAL DUE',
  TOTAL: 'TOTAL',
  PROCESSING_FEE: 'PROCESSING FEE',
  NET_TO_HOSPITAL: 'NET TO HOSPITAL',
  COGNUS_FEE: 'COGNUS PROCESSING FEE',
  COMMENTS_ON_SPEC_INSTR: 'COMMENTS OR SPECIAL INSTRUCTIONS',
  THANKS_BUSINESS: 'THANK YOU FOR YOUR BUSINESS',
  QUESTIONS_TEXT:
    'If you have any questions concerning this invoice, contact: info@CognusHealth.com'
}

export const COMMENTS_ON_INSTRUCTIONS = {
  CREDIT: 'ACH credit to bank account on file',
  CREDIT_NET: 'ACH credit to bank account on file net after processing fees',
  DEBIT: 'ACH debit to bank account on file'
}

export const defaultA4DocValues: TDocumentDefinitions = {
  pageSize: 'A4',
  content: [],
  pageMargins: [36, 35, 32, 48],
  defaultStyle: {
    fontSize: 12
  }
}

export const defaultFinancialsDocStyles: TDocumentDefinitions['styles'] = {
  header: {
    fontSize: 20,
    alignment: 'right',
    bold: true
  },
  bottomMargin: {
    marginBottom: 7
  },
  'doc-info': {
    fontSize: 11,
    alignment: 'right'
  },
  'vendor-info-header': {
    fontSize: 11,
    bold: true
  },
  tableHeader: {
    bold: true,
    fontSize: 11,
    alignment: 'center'
  }
}
