import {
  TContractPipelineFormParams,
  TCreateContractPipelineFormType
} from './types'
import { initialStateCreateContractPipelineForm } from './constants'
import { validateMessages } from '../../../helper/common'
import { Select } from '../../../components/Select'
import { LABELS } from '../../../constants'
import { Datepicker } from '../../../components/Datepicker'
import {
  timelineDateFormat,
  timelineDateFormatShort
} from '../../../components/Timelines/constants'
import { Input } from '../../../components/Input'
import { percentageParser } from '../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { Form } from 'antd'
import { Popup } from '../../../components/Popup'

export const CreateContractPipelineForm = ({
  form,
  errors,
  ledByOptions,
  contractCategoryNameOptions,
  disabledDate,
  successCloseConfirmationCallback,
  onSubmit,
  setChangedValues,
  changesHandler,
  setDiscardChanges,
  isDiscardChanges,
  className,
  ledByDisabled,
  editMode
}: TContractPipelineFormParams) => {
  const handleSubmitDiscardChanges = () => {
    setDiscardChanges(false)
    setChangedValues({})
    if (!editMode) {
      form.resetFields()
    }
    successCloseConfirmationCallback()
  }

  return (
    <>
      <Form<TCreateContractPipelineFormType>
        form={form}
        initialValues={initialStateCreateContractPipelineForm}
        validateMessages={validateMessages}
        onFinish={onSubmit}
        id="add-edit-contract-pipeline-form"
        onFieldsChange={changesHandler}
        className={className}
      >
        <div className="column gap-16">
          <Select
            propsSelect={{
              disabled: ledByDisabled
            }}
            options={ledByOptions}
            propsItem={{
              name: 'led_by',
              help: errors.led_by && errors.led_by[0],
              validateStatus: errors.led_by && 'error',
              label: LABELS.LED_BY,
              rules: [{ required: true }]
            }}
          />
          <Select
            options={contractCategoryNameOptions}
            propsItem={{
              name: 'contract_category',
              help: errors.contract_category && errors.contract_category[0],
              validateStatus: errors.contract_category && 'error',
              label: LABELS.CONTRACT_CATEGORY_NAME,
              rules: [{ required: true }]
            }}
          />
          <div className="row space-between align-center gap-16 mt-0">
            <Datepicker
              propsItem={{
                name: 'bid_start_date',
                help: errors.bid_start_date && errors.bid_start_date[0],
                validateStatus: errors.bid_start_date && 'error',
                label: LABELS.EST_BID_START_DATE,
                rules: [{ required: true, message: '' }]
              }}
              propsDate={{
                format: [timelineDateFormat, timelineDateFormatShort],
                disabledDate
              }}
            />
            <Input.Number
              propsItem={{
                name: 'estimated_savings',
                help: errors.estimated_savings && errors.estimated_savings[0],
                validateStatus: errors.estimated_savings && 'error',
                label: LABELS.EST_SAVINGS,
                rules: [{ required: true }]
              }}
              propsInputNumber={{
                min: '0',
                controls: false,
                parser: percentageParser,
                max: 100
              }}
            />
          </div>
        </div>
      </Form>
      <Popup.Discard
        visible={isDiscardChanges}
        onCancel={() => setDiscardChanges(false)}
        onSubmit={handleSubmitDiscardChanges}
        editMode={editMode}
      />
    </>
  )
}
