import { useCallback } from 'react'
import {
  ContentTable,
  ContentText,
  TDocumentDefinitions
} from 'pdfmake/interfaces'
import {
  TDocumentSection,
  THospitalSection,
  TTotalTableData,
  TVendorSection
} from './types'
import {
  DEFAULT_PDF_DOC_TXT,
  defaultA4DocValues,
  defaultFinancialsDocStyles
} from './constants'
import { TFinancialsDocumentInvoiceData } from '../../Financials/Rebates/RebatesTable/types'
import { TCognusARDocumentInvoiceData } from '../../Financials/CognusAR/CognusARTable/types'
import { generatePaginatedFooter, generatePDFDoc } from '../helpers'
import { currencyFormatter } from '../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'

export const useDocContentGeneration = () => {
  const getPdfFileName = useCallback(
    (name: string, id: string) => `${name} ${id}.pdf`,
    []
  )

  const generateDocumentHeader = useCallback(
    (header: string): ContentText[] => [
      {
        text: header.toUpperCase(),
        style: ['header', 'bottomMargin']
      }
    ],
    []
  )

  const generateReceiverInfo = useCallback(
    (hospitalInfo: THospitalSection): ContentText[] => [
      {
        text: hospitalInfo.hospitalName
      },
      {
        text: hospitalInfo.hospitalAddress
      },
      {
        text: `${DEFAULT_PDF_DOC_TXT.DEPARTMENT} #${hospitalInfo.hospitalId}`,
        style: 'bottomMargin'
      }
    ],
    []
  )

  const generateDocumentInfo = useCallback(
    (documentInfo: TDocumentSection): ContentText[] => [
      {
        text: `${documentInfo.docHeaderName.toUpperCase()} #${
          documentInfo.docId
        }`,
        style: 'doc-info'
      },
      {
        text: `${DEFAULT_PDF_DOC_TXT.DATE}: ${documentInfo.docDate}`,
        style: 'doc-info'
      },
      {
        text: `${DEFAULT_PDF_DOC_TXT.DUE_ON}: ${documentInfo.docDueOn}`,
        style: ['doc-info', 'bottomMargin']
      }
    ],
    []
  )

  const generateBillToInfo = useCallback(
    (vendorInfo: TVendorSection): ContentText[] => [
      {
        text: `${DEFAULT_PDF_DOC_TXT.BILL_TO}:`,
        style: 'vendor-info-header',
        marginBottom: 3
      },
      {
        text: vendorInfo.vendorName
      },
      {
        text: vendorInfo.vendorAddress
      },
      {
        text: vendorInfo.vendorPhoneNumber,
        marginBottom: 15
      }
    ],
    []
  )

  const generateCommentsSection = useCallback(
    (comment: string): ContentText[] => [
      {
        text: `${DEFAULT_PDF_DOC_TXT.COMMENTS_ON_SPEC_INSTR}:`,
        style: 'vendor-info-header',
        marginBottom: 3
      },
      {
        text: comment,
        marginBottom: 30
      }
    ],
    []
  )

  const generateThanksSection = useCallback(
    (): ContentText[] => [
      {
        text: DEFAULT_PDF_DOC_TXT.QUESTIONS_TEXT,
        marginBottom: 9,
        marginTop: 28
      },
      {
        text: `${DEFAULT_PDF_DOC_TXT.THANKS_BUSINESS}!`,
        alignment: 'center',
        bold: true
      }
    ],
    []
  )

  const generateRebateInvoiceTable = useCallback(
    (data: TFinancialsDocumentInvoiceData[]): ContentTable[] => [
      {
        table: {
          headerRows: 1,
          widths: [55, '*', 80, 80],
          body: [
            [
              {
                text: `${DEFAULT_PDF_DOC_TXT.INVOICE} #`,
                style: 'tableHeader'
              },
              { text: DEFAULT_PDF_DOC_TXT.CONTRACT_CAT, style: 'tableHeader' },
              { text: DEFAULT_PDF_DOC_TXT.INVOICE_AMT, style: 'tableHeader' },
              { text: DEFAULT_PDF_DOC_TXT.REBATE_AMT, style: 'tableHeader' }
            ],
            ...data.map((item) => [
              item.supplier_invoice_number,
              item.contract_category_name,
              `$${currencyFormatter(item.invoice_amount)}`,
              `$${currencyFormatter(item.rebate_amount)}`
            ])
          ]
        },
        layout: {
          hLineWidth: function (i) {
            return i === 0 ? 2 : 1
          }
        }
      }
    ],
    []
  )

  const generateCognusARInvoiceTable = useCallback(
    (data: TCognusARDocumentInvoiceData[]): ContentTable[] => [
      {
        table: {
          headerRows: 1,
          widths: [55, 55, '*', 80, 80],
          body: [
            [
              {
                text: `${DEFAULT_PDF_DOC_TXT.INVOICE} #`,
                style: 'tableHeader'
              },
              { text: DEFAULT_PDF_DOC_TXT.HOSPITAL, style: 'tableHeader' },
              { text: DEFAULT_PDF_DOC_TXT.CONTRACT_CAT, style: 'tableHeader' },
              { text: DEFAULT_PDF_DOC_TXT.INVOICE_AMT, style: 'tableHeader' },
              { text: DEFAULT_PDF_DOC_TXT.COGNUS_AMT, style: 'tableHeader' }
            ],
            ...data.map((item) => [
              item.supplier_invoice_number,
              item.hospital_name,
              item.contract_category_name,
              `$${currencyFormatter(item.invoice_amount)}`,
              `$${currencyFormatter(item.cognus_amount)}`
            ])
          ]
        },
        layout: {
          hLineWidth: function (i) {
            return i === 0 ? 2 : 1
          }
        }
      }
    ],
    []
  )

  const generateTotalTable = useCallback(
    (data: TTotalTableData): ContentTable[] => [
      {
        table: {
          headerRows: 0,
          widths: ['*', 80],
          body: data.map((item, idx) => [
            {
              alignment: 'right',
              text: item.label.toUpperCase(),
              border: [false, false, false, false]
            },
            {
              text: `$${currencyFormatter(item.value)}`,
              border: !!idx
                ? [true, false, true, true]
                : [true, true, true, true]
            }
          ])
        }
      }
    ],
    []
  )

  const launchDocGeneration = useCallback(
    (
      content: TDocumentDefinitions['content'],
      filename: string,
      callback?: () => void
    ) => {
      const completeDocTemplate: TDocumentDefinitions = {
        ...defaultA4DocValues,
        footer: generatePaginatedFooter(),
        content,
        styles: defaultFinancialsDocStyles
      }
      generatePDFDoc(completeDocTemplate, filename, callback)
    },
    []
  )

  return {
    getPdfFileName,
    generateDocumentHeader,
    generateReceiverInfo,
    generateDocumentInfo,
    generateBillToInfo,
    generateCommentsSection,
    generateThanksSection,
    generateRebateInvoiceTable,
    generateCognusARInvoiceTable,
    generateTotalTable,
    launchDocGeneration
  }
}
