import cn from 'classnames'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { currencyFormatter } from '../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { FinancialsChartLegendPartProps } from './types'
import './styles.scss'

export const FinancialsChartLegendPart = ({
  color,
  name,
  price,
  children
}: FinancialsChartLegendPartProps) => (
  <div
    className="legend__part row space-between align-center gap-4"
    data-testid="legendPartRow"
  >
    <div className="legend__title row align-center gap-8">
      <div className={cn('square', color)} />
      <Typography.Body1 className="row align-center">
        {name}
        {children}
      </Typography.Body1>
    </div>
    <Typography.Body1
      className="legend__price"
      weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
    >
      {`$${currencyFormatter(Number(price)?.toFixed(2))}`}
    </Typography.Body1>
  </div>
)
