import { Typography } from 'components/Typography'
import { Button, BUTTON_TYPES } from 'components/Button'
import { ROLES_NAMES } from 'constants/common'
import { Avatar } from 'components/Avatar/Avatar'
import { ReactComponent as ReassignIcon } from 'assets/svg/Reassign.svg'
import { ReactComponent as SignatoryEmail } from 'assets/svg/SignatoryEmail.svg'
import { IPartialUserInfo } from '../../../../../redux/store/user/types'
import { useMemo } from 'react'
import { Chips } from '../../../../../components/Chips/Chips'
import './styles.scss'
import cn from 'classnames'
import { getCapitalCharName } from '../../../../../helper/common'

interface IProps {
  user?: IPartialUserInfo
  email?: string
  reassignAvailable?: boolean
  onReassign?: () => void
  statusLabel?: string
  bigAvatar?: boolean
}

export const UserListItem = (props: IProps) => {
  const { user, email, reassignAvailable, onReassign, statusLabel, bigAvatar } =
    props

  const name = useMemo(() => {
    if (user) {
      return user.first_name + ' ' + user.last_name
    }
    if (email) {
      return email
    }
    return ''
  }, [email, user])

  return (
    <>
      <div className="rfp-details-responsible-user">
        <div className="flex row align-start">
          <div className="rfp-details-responsible-user__avatar">
            {user ? (
              <Avatar
                name={getCapitalCharName(user.first_name, user.last_name)}
                size={bigAvatar ? 32 : 22}
                alt="User image"
                src={user.avatar?.file_preview}
              />
            ) : (
              <SignatoryEmail />
            )}
          </div>
          <div className="rfp-details-responsible-user__content user-info-wrapper">
            <Typography.Body2
              className={cn({
                'user-info-text': !!user
              })}
            >
              {name}
            </Typography.Body2>
            {user && (
              <Typography.Caption className="rfp-details-responsible-user__role">
                {ROLES_NAMES[user.role]}
              </Typography.Caption>
            )}
            {statusLabel && (
              <Chips.Default className="mt-4" grey key={user?.uuid ?? email}>
                {statusLabel}
              </Chips.Default>
            )}
          </div>
        </div>
        <div className="rfp-details-responsible-user__actions">
          {reassignAvailable && (
            <Button.Icon
              type={BUTTON_TYPES.LINK}
              icon={<ReassignIcon />}
              onClick={onReassign}
              small
            />
          )}
        </div>
      </div>
    </>
  )
}
