import moment, { Moment } from 'moment'
export const requestDateFormat = 'YYYY-MM-DD'
export const requestTimeFormat12 = 'hh:mm A'

export const requestMonthFormat = 'MMM YYYY'
export const allFormatDate = 'YYYY-MM-DD HH:mm:ss Z'

export const weekdayAndDateFormat = 'ddd L'
export const hoursMinutesFormat = 'LT'
export const datepickerMonthFormat = 'YYYY-MM'

export const convertDateToRequestFormat = (date: Moment, format?: string) =>
  moment(date, format).format(requestDateFormat)

export const getQuarterAndYear = (data: Moment) => {
  const [year, quarter] = moment(data).format('YYYY-Q').split('-')
  return { year, quarter }
}

export const getCompletedQuarter = () => moment().subtract(1, 'quarter')

export const getCompletedMonth = () => moment().subtract(1, 'month')
