import * as Sentry from '@sentry/react'
import qs from 'qs'
import axios, {
  AxiosRequestHeaders,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  Method,
  CancelToken
} from 'axios'
import history from 'router/history'
import {
  cleanUpPrivateStorage,
  getLocalAccessToken,
  getLocalLastPage,
  getLocalRefreshToken,
  setLocalAccessToken,
  setLocalLastPage,
  setLocalRefreshToken
} from 'helper/common'
import { notification } from 'components/Notification'
import { routes } from 'router/Config/config.routes'
import { API_DICTIONARY } from '../constants'

interface IConfig {
  baseURL?: string
  timeout?: number
}

interface IRequest<TData> {
  url: string
  baseURL?: string
  version?: 'v1' | 'v2' // Add support for API versions
  method?: Method
  payload?: unknown
  params?: unknown
  cancelToken?: CancelToken
  data?: TData
  paramsSerializer?: AxiosRequestConfig['paramsSerializer']
  headers?: AxiosRequestHeaders
  isNotificationError?: boolean
  notification?: any
  successMessage?: string
  isLogin?: boolean
  onUploadProgress?: (data: any) => void
}

export const API_HOST =
  process.env.NODE_ENV === 'development'
    ? (window as Window).API_HOST
    : process.env.REACT_APP_HOST

export const API_URL = '/api/v1/'
export const API_URL_V2 = '/api/v2/'

class Fetcher {
  private static fetcherInstance: Fetcher
  private instance: AxiosInstance
  private isRefreshing = false
  private failedQueue: any[] = []

  constructor({
    baseURL = `${API_HOST}${API_URL}`, // Default to v1
    timeout = 60 * 1000
  }: IConfig) {
    if (!Fetcher.fetcherInstance) {
      Fetcher.fetcherInstance = this
    }
    this.instance = axios.create({
      baseURL,
      timeout
    })

    this.instance.interceptors.request.use((config) => {
      const token = getLocalAccessToken()
      if (!token) {
        return config
      }

      const headers = {
        authorization: `Bearer ${token}`,
        'X-Content-Type-Options': 'nosniff',
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'X-Frame-Options': 'SAMEORIGIN'
      }

      if (
        typeof config.headers === 'object' &&
        config.headers['Content-Type']
      ) {
        headers['Content-Type'] = config.headers['Content-Type']
      }

      return { ...config, headers }
    })

    this.instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config

        if (error.response?.status === 401 && !originalRequest._retry) {
          if (this.isRefreshing) {
            return new Promise((resolve, reject) => {
              this.failedQueue.push({ resolve, reject })
            })
              .then((token) => {
                originalRequest.headers['Authorization'] = `Bearer ${token}`
                return this.instance(originalRequest)
              })
              .catch((err) => Promise.reject(err))
          }

          originalRequest._retry = true
          this.isRefreshing = true

          try {
            const newToken = await this.refreshToken()
            if (newToken) {
              setLocalAccessToken(newToken)
              this.processQueue(null, newToken)
              originalRequest.headers['Authorization'] = `Bearer ${newToken}`
              return this.instance(originalRequest)
            }
          } catch (refreshError) {
            this.processQueue(refreshError, null)
            cleanUpPrivateStorage()
            history.push(routes.login)
            return Promise.reject(refreshError)
          } finally {
            this.isRefreshing = false
          }
        }

        Sentry.captureException(error)
        return Promise.reject(error)
      }
    )
    return Fetcher.fetcherInstance
  }

  handleErrorData = <TResponse>(data: TResponse) => {
    let message = ''
    const prepareError = (obj: any) => {
      if (typeof obj === 'string') {
        message = obj
      } else {
        Object.keys(obj).forEach((i) => {
          if (
            (Array.isArray(obj[i]) && typeof obj[i][0] === 'object') ||
            Array.isArray(obj)
          ) {
            prepareError(obj[i])
          } else if (typeof obj[i] === 'object') {
            prepareError(obj[i])
          } else if (i === 'non_field_errors') {
            message += obj[i]
          } else {
            message += obj[i]
          }
        })
      }
    }
    prepareError(data)
    notification.error({ message })
  }

  handlerCatch = <TResponse>(
    e: AxiosError<unknown, TResponse>,
    isNotificationError = false,
    isLogin = false
  ) => {
    const { response } = e
    const { status, data } = response as AxiosResponse<TResponse>
    if (status === 401) {
      // cleanUpPrivateStorage()
      const page = getLocalLastPage()
      cleanUpPrivateStorage()
      if (!page) setLocalLastPage(window.location.pathname.substring(1))
      history.push(routes.login)
    }
    if (status === 403 && !isLogin) {
      history.push('/')
    }
    if (isNotificationError && status !== 404 && status !== 500) {
      this.handleErrorData<TResponse>(
        (data as any).detail || (data as any).name
      )
    }
    throw {
      status,
      data
    }
  }
  private processQueue(error: any, token: string | null = null) {
    this.failedQueue.forEach((prom) => {
      if (token) {
        prom.resolve(token)
      } else {
        prom.reject(error)
      }
    })
    this.failedQueue = []
  }

  async refreshToken() {
    const refresh = getLocalRefreshToken()
    try {
      const response = await axios.post(
        `${API_HOST}${API_URL}${API_DICTIONARY.AUTH}${API_DICTIONARY.TOKEN}${API_DICTIONARY.REFRESH}`,
        {
          refresh
        }
      )
      setLocalAccessToken(response.data.access)
      setLocalRefreshToken(response.data.refresh)
      return response.data.access
    } catch (error) {
      const page = getLocalLastPage()
      cleanUpPrivateStorage()
      if (!page) setLocalLastPage(window.location.pathname.substring(1))
      history.push(routes.login)
      return null
    }
  }

  request = <TData = undefined, TResponse = unknown>({
    method = 'GET',
    url,
    params,
    version = 'v1', // Default to v1
    cancelToken,
    headers,
    baseURL,
    data,
    isNotificationError,
    successMessage,
    isLogin,
    onUploadProgress
  }: IRequest<TData>): Promise<AxiosResponse<TResponse>> => {
    // Dynamically determine baseURL based on version
    const apiBaseURL =
      version === 'v2' ? `${API_HOST}${API_URL_V2}` : `${API_HOST}${API_URL}`
    return this.instance
      .request({
        url,
        method,
        params,
        headers,
        cancelToken,
        baseURL: apiBaseURL || baseURL, // Use dynamic baseURL
        data,
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: 'comma' }),
        onUploadProgress
      })
      .then((resp) => {
        if (successMessage) {
          notification.success({ message: successMessage })
        }
        return resp
      })
      .catch((e: any) =>
        this.handlerCatch<TResponse>(e, isNotificationError, isLogin)
      )
  }
}

export default Fetcher
