import Popup from 'components/Popup/Popup'
import { Button, BUTTON_TYPES } from 'components/Button'
import { BTN_TXT } from 'constants/index'
import { usePRSDetailsPopupContext } from '../../Providers/PRSDetailsPopupProvider'
import SurveyTabs from './SurveyTabs'
import { usePRSDetailsContext } from '../../Providers/PRSDetailsContextProvider'
import { usePRSVotingContext } from '../../Providers/PRSVotingContext'
import { TQuestionsForm } from 'components/Forms/forms.surveyQuestions.d'
import { validateMessages } from 'helper/common'
import cn from 'classnames'
import {
  initialStateForSurveyCreation,
  PRS_SURVEY_TABS,
  QUESTION_TYPE
} from './constants'
import { setLoading } from 'redux/store/common/slice'
import { createSurvey, updateSurvey } from 'pages/ProductRequests/api'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Form } from 'antd'
import { notification } from '../../../../../components/Notification'
import { useMemo } from 'react'

const SurveyForm = () => {
  const { createSurveyPopup } = usePRSDetailsPopupContext()
  const { state, actions } = usePRSDetailsContext()
  const { id } = useParams()
  const votingContext = usePRSVotingContext()
  const {
    setVotingData,
    getSurveyForVoting,
    resetForm,
    setActiveTab,
    setVoteSurvey
  } = votingContext.actions
  const { activeTab, isAbleToVote, isEditMode, isCanViewSurveyResponse } =
    votingContext.state
  const dispatch = useDispatch()

  const [form] = Form.useForm()

  const closeModal = () => {
    createSurveyPopup.actions.close()
    form.resetFields()
    resetForm()
  }

  const getButtonText = useMemo(() => {
    if (isAbleToVote) {
      return BTN_TXT.SUBMIT_SURVEY
    } else if (isEditMode) {
      return BTN_TXT.SAVE
    } else return BTN_TXT.CREATE
  }, [isAbleToVote, isEditMode])

  const onSubmitForm = (values) => {
    const newValue =
      PRS_SURVEY_TABS[0].key === activeTab
        ? values
        : votingContext.state.votingData
    if (id) {
      if (
        newValue?.questions.find(
          (i) => i.type !== QUESTION_TYPE.TEXT && i.options?.length < 1
        )
      ) {
        votingContext.actions.setIsError(true)
        notification.error({
          message: 'Question should have 1 option for answer at least.'
        })
        return
      }
      if (isAbleToVote) {
        setVoteSurvey()
        return
      }
      dispatch(setLoading(true))
      const data = newValue?.questions?.map((i) => {
        let result = {
          ...i,
          options: i.options.map((i, idx) => ({
            ...i,
            order_number: idx
          })),
          has_other: !!i.options.find((o) => o.is_other)
        }

        if (result.type === QUESTION_TYPE.TEXT) {
          result = {
            ...result,
            has_other: true,
            options: [
              {
                text: '',
                is_other: true,
                order_number: 0
              }
            ]
          }
        }
        return result
      })
      if (votingContext.state.isEditMode) {
        updateSurvey(id, data)
          .then(() => {
            createSurveyPopup.actions.close()
            actions.handleGetPRSData()
            getSurveyForVoting()
          })
          .finally(() => {
            dispatch(setLoading(false))
            setActiveTab(PRS_SURVEY_TABS[0].key)
          })
      } else {
        createSurvey(id, data)
          .then(() => {
            createSurveyPopup.actions.close()
            actions.handleGetPRSData()
            getSurveyForVoting()
          })
          .finally(() => {
            dispatch(setLoading(false))
            setActiveTab(PRS_SURVEY_TABS[0].key)
          })
      }
    }
  }

  return (
    <div className="create-survey-prs-container__form-wrapper">
      <Popup
        visible={createSurveyPopup.state.visible}
        className="prs-create-survey-popup prs-modal-create-survey"
        onCancel={closeModal}
        title={
          !isEditMode && !isAbleToVote && !isCanViewSurveyResponse
            ? `Create survey`
            : `${state.data?.name} survey`
        }
        width={680}
        footer={
          !isCanViewSurveyResponse && (
            <>
              <Button
                onClick={closeModal}
                type={BUTTON_TYPES.DEFAULT}
                upperCase
              >
                {BTN_TXT.CANCEL}
              </Button>
              <Button
                type={BUTTON_TYPES.PRIMARY}
                upperCase
                htmlType="submit"
                disabled={
                  !votingContext.state.votingData?.questions?.find(
                    (i) => !!i?.type
                  )
                }
                form="prs-survey-form"
              >
                {getButtonText}
              </Button>
            </>
          )
        }
      >
        <Form<TQuestionsForm>
          validateMessages={validateMessages}
          form={form}
          className={cn('question-template-container')}
          layout="vertical"
          id="prs-survey-form"
          onFinish={onSubmitForm}
          initialValues={
            votingContext.state.initialVotingData ||
            initialStateForSurveyCreation
          }
          onValuesChange={(_, _all) => {
            votingContext.state.isError &&
              votingContext.actions.setIsError(false)
            const res = _all?.questions?.map((i, idx) => {
              if (!!i?.options?.find((o) => o?.is_other)) {
                const newOptions = i.options?.filter((opt) => !opt.is_other)
                return {
                  ...i,
                  selectedOptions:
                    votingContext.state.votingData.questions[idx]
                      ?.selectedOptions,
                  options: [
                    ...newOptions.filter((i) => !i.is_other),
                    {
                      ...i.options?.find((i) => i.is_other),
                      is_other: true
                    }
                  ]?.filter((i) => i?.text !== undefined)
                }
              } else
                return {
                  ...i,
                  options: i?.options?.filter((i) => i?.text !== undefined)
                }
            })
            form.setFieldsValue({ questions: res?.filter((i) => i.type) })
            setVotingData({ questions: res?.filter((i) => i.type) })
          }}
        >
          <SurveyTabs
            form={form}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </Form>
      </Popup>
    </div>
  )
}

export default SurveyForm
