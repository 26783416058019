import Input from '../../../../components/Input/Input'
import { AddAdditionalOptionProps } from './types'

export const AddAdditionalOption = ({
  otherType,
  onChangeOtherType,
  onChangeSelectedType,
  additionalItemInputProps
}: AddAdditionalOptionProps) => {
  const onChangeInputValue = (e) => {
    onChangeOtherType(e.currentTarget.value)
  }

  const onPressEnter = (e) => {
    onChangeSelectedType(e.currentTarget.value)
  }

  return (
    <div className="calendar__modal__type-of-event-select__add-type-option-container">
      <Input
        propsInput={{
          ...additionalItemInputProps,
          onChange: onChangeInputValue,
          value: otherType,
          onPressEnter,
          maxLength: 50
        }}
      />
    </div>
  )
}
