import './styles.scss'
import RadioGroup from '../../components/RadioGroup/RadioGroup'
import {
  addBusinessDetailsInitialValues,
  bankAccountDetailsInitialValues,
  PAYMENT_DETAILS_OPTIONS
} from './constants'
import { useCallback, useEffect, useMemo, useState } from 'react'
import BusinessDetails from './BusinessDetails/BusinessDetails'
import BankAccount from './BankAccount/BankAccount'
import { TAddBusinessDetails } from './BusinessDetails/types'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../redux/store/common/slice'
import { getUser } from '../../redux/store/user/getters'
import { getRequestForOrumDetails } from './utils'
import { TAddBankAccountDetails } from './BankAccount/types'
import { getBankAccount, getBusinessDetailsByUuid } from './api'
import { useParams } from 'react-router-dom'
import useRouter from '../../hooks/useRouter'
import { SETTINGS_STATUSES } from '../../pages/Settings/constants'

const PaymentDetails = () => {
  const dispatch = useDispatch()
  const user = useSelector(getUser)

  const { type } = useParams()
  const { push } = useRouter()
  const [activeSubTab, setActiveSubTab] = useState(
    PAYMENT_DETAILS_OPTIONS[0].value
  )
  const [businessDetails, setBusinessDetails] = useState<TAddBusinessDetails>(
    addBusinessDetailsInitialValues
  )
  const [bankAccount, setBankAccount] = useState<TAddBankAccountDetails>(
    bankAccountDetailsInitialValues
  )
  const handleTabs = (e) => {
    setActiveSubTab(e)
    push(e)
  }

  useEffect(() => {
    if (type) {
      setActiveSubTab(type)
    }
  }, [])

  const successCallback = async () => {
    if (!businessDetails.uuid) return
    try {
      dispatch(setLoading(true))
      const res = await getBusinessDetailsByUuid(businessDetails.uuid)
      setBusinessDetails(res?.data)
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }

  const getBankAccountDetails = useCallback(async () => {
    dispatch(setLoading(true))
    const resBankAccount = await getBankAccount(bankAccount.uuid)
    setBankAccount({
      ...resBankAccount.data,
      account_number: '*****' + resBankAccount.data.account_number,
      routing_number: '********' + resBankAccount.data.routing_number
    })
    dispatch(setLoading(false))
  }, [bankAccount.uuid])

  const getBusinessDetails = useCallback(async () => {
    try {
      dispatch(setLoading(true))
      const res = await getRequestForOrumDetails(user)
      if (!!res?.data?.business_resource)
        setBusinessDetails(res?.data?.business_resource)
      else {
        setBusinessDetails(addBusinessDetailsInitialValues)
      }
      if (!!res.data.business_resource?.bank_accounts?.length) {
        const resBankAccount = await getBankAccount(
          res.data.business_resource?.bank_accounts[0]?.uuid
        )
        setBankAccount({
          ...resBankAccount.data,
          account_number: '*****' + resBankAccount.data.account_number,
          routing_number: '********' + resBankAccount.data.routing_number
        })
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [user.role])

  const paymentsOptions = useMemo(() => {
    return PAYMENT_DETAILS_OPTIONS.map((item, idx) =>
      idx === 1
        ? {
            ...item,
            disabled: !businessDetails.uuid
          }
        : item
    )
  }, [businessDetails])

  useEffect(() => {
    getBusinessDetails()
  }, [])
  return (
    <div className="payment-details-container">
      <div className="mb-24">
        <RadioGroup.Buttons
          options={paymentsOptions}
          defaultValue={activeSubTab}
          onChange={handleTabs}
        />
      </div>
      {!activeSubTab && (
        <BusinessDetails
          businessDetails={businessDetails}
          getDetails={successCallback}
        />
      )}
      {activeSubTab === PAYMENT_DETAILS_OPTIONS[0].value && (
        <BusinessDetails
          businessDetails={businessDetails}
          getDetails={successCallback}
        />
      )}
      {activeSubTab === PAYMENT_DETAILS_OPTIONS[1].value && (
        <BankAccount
          bankAccount={bankAccount}
          isVerified={businessDetails?.status === SETTINGS_STATUSES.VERIFIED}
          getDetails={getBankAccountDetails}
        />
      )}
    </div>
  )
}
export default PaymentDetails
