import moment from 'moment'
import { Datepicker } from '../../../components/Datepicker'
import { Typography } from '../../../components/Typography'
import { CognusARTable } from './CognusARTable/CognusARTable'
import { FinancialsChartLegendPart } from '../FinancialsChartLegendPart/FinancialsChartLegendPart'
import { PaymentStatusChart } from '../PaymentStatusChart/PaymentStatusChart'
import { useFinancialsCognusARContext } from '../Providers/FinancialsCognusARProvider'
import { getFinancialStatusLegendOrder } from '../FinancialsChartLegendPart/constants'
import { FINANCIAL_STATUS_LABEL, REPORTS_COLORS } from '../constants'
import { timelineMonthFormat } from '../../../components/Timelines/constants'
import { ReactComponent as ArrowRight16 } from 'assets/svg/ArrowRight16.svg'
import { ReactComponent as ArrowLeft16 } from 'assets/svg/ArrowBack16.svg'

export const CognusAR = () => {
  const {
    state: { period, reports, totalAmount },
    actions: { setPeriod }
  } = useFinancialsCognusARContext()

  return (
    <div className="rebates column gap-24">
      <div className="rebates__date-filter">
        <Datepicker
          propsDate={{
            format: timelineMonthFormat,
            value: period,
            onChange: (date) => setPeriod(moment(date).startOf('month')),
            picker: 'month',
            superNextIcon: <ArrowRight16 />,
            superPrevIcon: <ArrowLeft16 />
          }}
          propsItem={{
            label: 'Period'
          }}
        />
      </div>
      <div className="rebates__container column gap-24">
        <Typography.Headline4>Payment Status</Typography.Headline4>
        <div className="rebates__chart-container row">
          <div className="rebates__chart">
            <PaymentStatusChart
              total={totalAmount}
              data={getFinancialStatusLegendOrder(reports)}
              needMultiply
            />
          </div>
          <div className="rebates__legend">
            {getFinancialStatusLegendOrder(reports).map((i, idx) => (
              <FinancialsChartLegendPart
                key={idx}
                color={REPORTS_COLORS[i.status]}
                name={FINANCIAL_STATUS_LABEL[i.status]}
                price={i.amount}
              />
            ))}
          </div>
        </div>
      </div>
      <CognusARTable />
    </div>
  )
}
