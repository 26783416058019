import { useState } from 'react'
import { Button, BUTTON_TYPES } from 'components/Button'
import { Popup } from 'components/Popup'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { Dropzone } from 'components/Dropzone/Dropzone'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { ReactComponent as Upload16 } from 'assets/svg/Upload16.svg'
import { TFileUpload } from './types'
import classNames from 'classnames'
import './styles.scss'

export const FileUpload = ({
  className,
  uploadBtnText,
  handleUploadFile,
  documentType,
  uploadFilesProps,
  disabled,
  uploadButtonText,
  tooltipText,
  isChat,
  small,
  icon,
  btnType
}: TFileUpload) => {
  const [isUploadPopupOpen, setIsUploadPopupOpen] = useState(false)
  return (
    <Tooltip
      title={tooltipText}
      overlayInnerStyle={{
        display: disabled ? 'block' : 'none',
        width: 364
      }}
      placement="topLeft"
    >
      <div className={classNames('file-upload', className)}>
        {uploadButtonText ? (
          <div
            className={classNames({
              'file-upload__button--not-allowed': disabled
            })}
          >
            <Button
              className={classNames({
                'file-upload__button--is-disabled': disabled
              })}
              type={btnType ? btnType : BUTTON_TYPES.PRIMARY}
              icon={icon ? icon : <Upload16 />}
              small={small}
              onClick={() => setIsUploadPopupOpen(true)}
              disabled={disabled}
              upperCase
            >
              {uploadButtonText}
            </Button>
          </div>
        ) : (
          <div
            className={classNames({
              'file-upload__button--not-allowed': disabled
            })}
          >
            <Button
              className={classNames({
                'file-upload__button--is-disabled': disabled
              })}
              type={BUTTON_TYPES.GHOST}
              onClick={() => setIsUploadPopupOpen(true)}
              icon={<Plus16 />}
              disabled={disabled}
              upperCase
            >
              {uploadBtnText}
            </Button>
          </div>
        )}
        <Popup
          visible={isUploadPopupOpen}
          onCancel={() => setIsUploadPopupOpen(false)}
          title="Upload file"
          width={676}
          footer={null}
        >
          <Dropzone
            {...uploadFilesProps}
            onDropAccepted={(file) => {
              handleUploadFile(file, documentType).then(() =>
                setIsUploadPopupOpen(false)
              )
              if (isChat) setIsUploadPopupOpen(false)
            }}
          />
        </Popup>
      </div>
    </Tooltip>
  )
}
