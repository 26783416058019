import { useMemo } from 'react'
import { Form } from 'antd'

import './styles.scss'

import { BTN_TXT, LABELS } from 'constants/txt'
import { validateFileTypes } from 'helper/common'
import { Typography } from 'components/Typography'
import { Datepicker } from 'components/Datepicker'
import { Button, BUTTON_TYPES } from 'components/Button'
import { FileUpload } from 'components/FileUpload/FileUpload'
import { FieldWithLabel } from 'components/FieldWithLabel/FieldWithLabel'
import { Attachment } from 'components/Attachment/Attachment'
import {
  timelineDateFormat,
  timelineDateFormatShort
} from 'components/Timelines/constants'
import useExtendContractForm from 'features/ContractDetails/ExtendContract/hooks/useExtendContractForm'
import { useContractDetailsPopup } from 'features/ContractDetails/Providers/ContractDetailsPopupProvider'
import { IExtendContractDetailsForm } from 'features/ContractDetails/ExtendContract/types'
import {
  ACCEPT_DOCUMENT_TYPES,
  UPLOAD_DOCUMENTS_CONFIG
} from 'features/ContractDetails/ExtendContract/constants'

const ExtendContract = () => {
  const { extendContractPopup } = useContractDetailsPopup()

  const {
    form,
    extensionDocument,
    handleUploadDocument,
    handleDeleteDocument,
    handleFinishForm,
    disableExtendContractButton,
    disabledDateSameOrBeforeStartDate
  } = useExtendContractForm()

  const FinishDateField = useMemo(
    () => (
      <Datepicker
        propsItem={{
          name: 'finish_date',
          label: LABELS.FINISH_DATE
        }}
        propsDate={{
          format: [timelineDateFormat, timelineDateFormatShort],
          disabledDate: disabledDateSameOrBeforeStartDate,
          placeholder: 'mm/dd/yyyy'
        }}
      />
    ),
    [disabledDateSameOrBeforeStartDate]
  )

  const UploadDocumentField = useMemo(
    () => (
      <FieldWithLabel className="column full-width" title="Extension document">
        {extensionDocument ? (
          <Attachment
            showDetails
            link={URL.createObjectURL(extensionDocument.file)}
            filename={extensionDocument.file.name}
            createdAt={extensionDocument.uploadedAt.toISOString()}
            onDelete={handleDeleteDocument}
          />
        ) : (
          <FileUpload
            documentType="attachments"
            uploadBtnText={BTN_TXT.UPLOAD}
            handleUploadFile={handleUploadDocument}
            uploadFilesProps={{
              ...UPLOAD_DOCUMENTS_CONFIG,
              onDropAccepted: handleUploadDocument,
              validator: (file) =>
                validateFileTypes(ACCEPT_DOCUMENT_TYPES, file)
            }}
          />
        )}
      </FieldWithLabel>
    ),
    [extensionDocument, handleDeleteDocument, handleUploadDocument]
  )

  const FormActions = useMemo(
    () => (
      <div className="extend-contract-form__actions">
        <Button onClick={extendContractPopup.actions.close} upperCase>
          {BTN_TXT.CANCEL}
        </Button>
        <Button
          type={BUTTON_TYPES.PRIMARY}
          form="extend-contract-form-id"
          htmlType="submit"
          upperCase
          disabled={disableExtendContractButton}
        >
          {BTN_TXT.EXTEND}
        </Button>
      </div>
    ),
    [disableExtendContractButton, extendContractPopup.actions.close]
  )

  return (
    <Form<IExtendContractDetailsForm>
      form={form}
      onFinish={handleFinishForm}
      id="extend-contract-form-id"
    >
      <Typography.Body1>
        To extend to contract you need to upload amendment. Please, provide new
        finish date.
      </Typography.Body1>
      <div className="row mt-24">{FinishDateField}</div>
      <div className="row mt-24">{UploadDocumentField}</div>
      {FormActions}
    </Form>
  )
}

export default ExtendContract
