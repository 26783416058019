import { Tooltip } from '../../../components/Tooltip/Tooltip'
import { StatusData } from './StatusData/StatusData'
import {
  FINANCIAL_STATUS_TYPES_TO_CLASSNAME,
  Status
} from '../../../components/Status'
import { FINANCIAL_STATUS_LABEL } from '../constants'
import { StatusCellProps } from './types'

export const StatusCell = ({ transfers, status }: StatusCellProps) =>
  !!transfers.length ? (
    <Tooltip
      title={transfers.map((i) => (
        <StatusData
          key={i.uuid}
          status={i.status}
          amount={i.amount}
          destination_type={i.destination_type}
        />
      ))}
    >
      <div>
        <Status.Default className={FINANCIAL_STATUS_TYPES_TO_CLASSNAME[status]}>
          {FINANCIAL_STATUS_LABEL[status]}
        </Status.Default>
      </div>
    </Tooltip>
  ) : (
    <Status.Default className={FINANCIAL_STATUS_TYPES_TO_CLASSNAME[status]}>
      {FINANCIAL_STATUS_LABEL[status]}
    </Status.Default>
  )
