import {
  createContext,
  FC,
  PropsWithChildren,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback
} from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getRebatesTableDocumentInfo } from '../Rebates/RebatesTable/api'
import { getRequestForOrumDetails } from '../../PaymentDetails/utils'
import { getUser } from '../../../redux/store/user/getters'
import { useFinancialsRebatesDocGeneration } from '../../PDFGenerator/Financials/useFinancialsRebatesDocGeneration'
import { getCognusARTableDocumentInfo } from '../CognusAR/CognusARTable/api'
import { useFinancialsCognusARDocGeneration } from '../../PDFGenerator/Financials/useFinancialsCognusARDocGeneration'
import { setLoaderProps, setLoading } from '../../../redux/store/common/slice'
import useTabs, {
  TabsReturnActions,
  TabsReturnState
} from '../../../hooks/useTabs'
import { FINANCIAL_TABS } from '../constants'
import { notification } from '../../../components/Notification'
import { VALIDATION_MESSAGES } from '../../../constants'
import { TAddBusinessDetails } from '../../PaymentDetails/BusinessDetails/types'

type ContextProps = {
  state: TabsReturnState & {
    id: string
    hasBankAccount: boolean
    businessResource: TAddBusinessDetails | null
  }
  actions: TabsReturnActions & {
    setId: Dispatch<SetStateAction<string>>
    generateAndDownloadRebateDocument: (id: string) => void
    generateAndDownloadCognusARDocument: (id: string) => void
  }
}
const FinancialsContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const FinancialsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [hasBankAccount, setHasBankAccount] = useState(false)
  const [businessResource, setBusinessResource] =
    useState<TAddBusinessDetails | null>(null)
  const user = useSelector(getUser)
  const [id, setId] = useState('')
  const { tab } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    onGetBankAccount()
  }, [])

  const {
    generateCommunityRebateRemitAdviceDoc,
    generateCommonsRebateRemitAdviceDoc,
    generateVendorRebateInvoiceDoc
  } = useFinancialsRebatesDocGeneration()
  const {
    generateCommonsCognusARRemitAdviceDoc,
    generateCommunityCognusARInvoiceDoc,
    generateVendorCognusARInvoiceDoc
  } = useFinancialsCognusARDocGeneration()

  const isVendor = !!user.vendor
  const isCommons = !!user.commons

  const { state: tabsState, actions: tabsActions } = useTabs({
    tabs: FINANCIAL_TABS,
    activeTab: tab || FINANCIAL_TABS[0].key
  })

  const _getTableRebatesDocumentData = useCallback(async (id: string) => {
    if (!id.length) {
      throw new Error('Wrong InvoiceID provided')
    }

    const result = await getRebatesTableDocumentInfo(id)
    if (result?.data) {
      return result.data
    }
    return undefined
  }, [])

  const _getTableCognusARDocumentData = useCallback(async (monthId: string) => {
    if (!monthId.length) {
      throw new Error('Wrong InvoiceID provided')
    }

    const result = await getCognusARTableDocumentInfo(monthId)
    if (result?.data) {
      return result.data
    }
    return undefined
  }, [])

  const _documentGenerationLoadingWrapper = useCallback(
    async (
      documentGeneration: (callback: () => void) => Promise<void>,
      successMessage: string,
      errorMessage: string,
      documentNameInfo: string
    ) => {
      dispatch(
        setLoaderProps({
          isFileUpload: true,
          message: `Generating ${documentNameInfo} document.`
        })
      )
      dispatch(setLoading(true))
      // reset loading callback
      // show success message if called from generation hook
      const callback = (isFail = false) => {
        dispatch(setLoading(false))
        dispatch(
          setLoaderProps({
            isFileUpload: false,
            message: ''
          })
        )
        if (!isFail) {
          notification.success({
            message: successMessage
          })
        }
      }

      try {
        await documentGeneration(callback)
      } catch (e) {
        notification.error({
          message: errorMessage
        })
        callback(true)
      }
    },
    [dispatch]
  )

  const generateAndDownloadRebateDocument = useCallback(
    async (id: string) => {
      const documentNameInfo = isVendor ? 'Invoice' : 'Remittance Advice'
      const successMessage = `${documentNameInfo} ${VALIDATION_MESSAGES.SM0167}`
      const errorMessage = `${documentNameInfo} ${VALIDATION_MESSAGES.SM0168}`
      await _documentGenerationLoadingWrapper(
        async (callback) => {
          const docData = await _getTableRebatesDocumentData(id)
          if (docData) {
            if (isVendor) {
              generateVendorRebateInvoiceDoc({
                vendorData: docData,
                callback
              })
            } else if (isCommons) {
              generateCommonsRebateRemitAdviceDoc({
                commonsData: docData,
                callback
              })
            } else {
              generateCommunityRebateRemitAdviceDoc({
                communityData: docData,
                callback
              })
            }
          } else {
            throw ''
          }
        },
        successMessage,
        errorMessage,
        documentNameInfo
      )
    },
    [
      _documentGenerationLoadingWrapper,
      _getTableRebatesDocumentData,
      generateCommonsRebateRemitAdviceDoc,
      generateCommunityRebateRemitAdviceDoc,
      generateVendorRebateInvoiceDoc,
      isCommons,
      isVendor
    ]
  )

  const generateAndDownloadCognusARDocument = useCallback(
    async (monthId: string) => {
      const documentNameInfo = isCommons ? 'Remittance Advice' : 'Invoice'
      const successMessage = `${documentNameInfo} ${VALIDATION_MESSAGES.SM0167}`
      const errorMessage = `${documentNameInfo} ${VALIDATION_MESSAGES.SM0168}`
      await _documentGenerationLoadingWrapper(
        async (callback) => {
          const docData = await _getTableCognusARDocumentData(monthId)
          if (docData) {
            if (isVendor) {
              generateVendorCognusARInvoiceDoc({
                vendorData: docData,
                callback
              })
            } else if (isCommons) {
              generateCommonsCognusARRemitAdviceDoc({
                commonsData: docData,
                callback
              })
            } else {
              generateCommunityCognusARInvoiceDoc({
                communityData: docData,
                callback
              })
            }
          } else {
            throw ''
          }
        },
        successMessage,
        errorMessage,
        documentNameInfo
      )
    },
    [
      _documentGenerationLoadingWrapper,
      _getTableCognusARDocumentData,
      generateCommonsCognusARRemitAdviceDoc,
      generateCommunityCognusARInvoiceDoc,
      generateVendorCognusARInvoiceDoc,
      isCommons,
      isVendor
    ]
  )

  const context = useMemo(
    () => ({
      state: {
        ...tabsState,
        hasBankAccount,
        businessResource,
        id
      },
      actions: {
        ...tabsActions,
        generateAndDownloadCognusARDocument,
        generateAndDownloadRebateDocument,
        setId
      }
    }),
    [
      tabsState,
      hasBankAccount,
      id,
      businessResource,
      tabsActions,
      generateAndDownloadCognusARDocument,
      generateAndDownloadRebateDocument
    ]
  )

  useEffect(() => {
    if (!!tab) tabsActions.setActiveTab(tab)
  }, [tab])

  const onGetBankAccount = async () => {
    const { data } = await getRequestForOrumDetails(user)
    setBusinessResource(data.business_resource)
    setHasBankAccount(!!data.business_resource?.bank_accounts?.length)
  }

  return (
    <FinancialsContext.Provider value={context}>
      {children}
    </FinancialsContext.Provider>
  )
}

export const useFinancialsContext = () => useContext(FinancialsContext)

export default FinancialsProvider
