import { IUser } from 'redux/store/user/types'
import {
  ContractCreationWays,
  ContractStatusUnion
} from 'features/ContractDetails/types'

export const isOwnerCondition = (user: IUser) => ({
  'creator.uuid': user.uuid
})

export const isInStatuses = (inStatuses: ContractStatusUnion[]) => ({
  status: {
    $in: inStatuses
  }
})

export const isNotInStatuses = (inStatuses: ContractStatusUnion[]) => ({
  status: {
    $nin: inStatuses
  }
})

export const isResponsibleVendorCondition = (user: IUser) => ({
  vendor_responsibles: {
    $elemMatch: { uuid: user.uuid }
  }
})

export const isResponsibleCommunityCondition = (user: IUser) => ({
  community_responsibles: {
    $elemMatch: {
      uuid: user.uuid
    }
  }
})
export const isResponsibleCommonsCondition = (user: IUser) => ({
  commons_responsibles: {
    $elemMatch: {
      uuid: user.uuid
    }
  }
})

export const isManualContract = () => ({
  creation_way: ContractCreationWays.MANUAL
})

export const isAutoContract = () => ({
  creation_way: ContractCreationWays.AUTO
})
export const isStartDateEmpty = () => ({
  start_date: { $exists: true, $in: [null] }
})

export const isFinishDateEmpty = () => ({
  finish_date: { $exists: true, $in: [null] }
})

export const areStartAndFinishNotEmptyForAuto = () => ({
  start_date: { $ne: null },
  finish_date: { $ne: null },
  creation_way: ContractCreationWays.AUTO
})
