import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useDispatch } from 'react-redux'
import moment, { Moment } from 'moment'
import { getCompletedMonth } from '../../../utils/moment'
import { getPaymentStatusData } from '../CognusAR/api'
import { getCognusARTableData } from '../CognusAR/CognusARTable/api'
import { getReportsData } from '../utils'
import { setLoading } from '../../../redux/store/common/slice'
import { DEFAULT_PAGE } from '../../../components/Table/constants'
import {
  CognusARData,
  CognusARRequestData,
  CognusARTableParams
} from '../CognusAR/CognusARTable/types'
import { PaymentStatus } from '../types'
import { TPageInfo } from '../../../components/Table/types'

type ContextProps = {
  state: {
    totalNumber: number
    totalAmount: number
    firstLoad: boolean
    pageInfo: TPageInfo
    period: Moment
    tableData: CognusARData[]
    reports: PaymentStatus[]
  }
  actions: {
    setPeriod: Dispatch<SetStateAction<Moment>>
    setPageInfo: Dispatch<SetStateAction<TPageInfo>>
    getTableData: (args: CognusARRequestData) => Promise<void>
    getChartsData: () => Promise<void>
    updateCognusARData: () => Promise<void>
  }
}

const FinancialsCognusARContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const FinancialsCognusARProvider: FC<PropsWithChildren> = ({ children }) => {
  const [totalAmount, setTotalAmount] = useState(0)
  const [totalNumber, setTotalNumber] = useState(0)
  const [firstLoad, setFirstLoad] = useState(true)
  const [period, setPeriod] = useState<Moment>(getCompletedMonth())
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const [tableData, setTableData] = useState<CognusARData[]>([])
  const [reports, setReports] = useState<PaymentStatus[]>([])
  const dispatch = useDispatch()

  useEffect(() => {
    if (period) {
      getChartsData()
      getTableData({ page: pageInfo })
    }
  }, [period])

  const getTableData = async ({ page }: CognusARRequestData) => {
    try {
      dispatch(setLoading(true))
      const params: CognusARTableParams = {
        period: moment(period).format('YYYY-MM')
      }
      const dataPage = page ? page : DEFAULT_PAGE
      params.limit = dataPage.pageSize
      params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
      const { data } = await getCognusARTableData({ params })
      setTableData(
        data.results.map((row) => ({
          ...row,
          key: row.uuid
        }))
      )
      setTotalNumber(data.count)
      if (firstLoad) {
        setFirstLoad(false)
      }
    } finally {
      dispatch(setLoading(false))
    }
  }

  const getChartsData = async () => {
    try {
      dispatch(setLoading(true))
      const { data } = await getPaymentStatusData({
        params: {
          period: moment(period).format('YYYY-MM')
        }
      })
      const results = getReportsData(data.results)
      setReports(results)
      setTotalAmount(results.reduce((prev, cur) => prev + cur.amount, 0))
    } finally {
      dispatch(setLoading(false))
    }
  }

  const updateCognusARData = async () => {
    await getTableData({ page: pageInfo })
    await getChartsData()
  }

  const context = useMemo(
    () => ({
      state: {
        totalNumber,
        tableData,
        firstLoad,
        totalAmount,
        reports,
        period,
        pageInfo
      },
      actions: {
        setPeriod,
        setPageInfo,
        getTableData,
        getChartsData,
        updateCognusARData
      }
    }),
    [totalNumber, tableData, firstLoad, totalAmount, reports, period, pageInfo]
  )

  return (
    <FinancialsCognusARContext.Provider value={context}>
      {children}
    </FinancialsCognusARContext.Provider>
  )
}

export const useFinancialsCognusARContext = () =>
  useContext(FinancialsCognusARContext)

export default FinancialsCognusARProvider
