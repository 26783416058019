import { addBusinessDetailsInitialValues } from './constants'
import { validateMessages } from '../../helper/common'
import cn from 'classnames'
import { Form } from 'antd'
import { useEffect, useState } from 'react'
import { setLoading } from '../../redux/store/common/slice'
import { useDispatch, useSelector } from 'react-redux'
import {
  TAddBusinessDetailsForm,
  TAddBusinessDetailsFormErrorType,
  TAddBusinessDetailsProps
} from './forms.addBusinessDetails.d'
import { Input } from '../Input'
import { LABELS, PLACEHOLDERS, VALIDATION_MESSAGES } from '../../constants'
import './forms.addBusinessDetails.scss'
import { Select } from '../Select'
import { Datepicker } from '../Datepicker'
import {
  timelineDateFormat,
  timelineDateFormatShort
} from '../Timelines/constants'
import Typography from '../Typography/Typography'
import {
  addBusinessAccount,
  fetchAddressTypeOptions,
  fetchCountriesOptions,
  fetchStateOptions,
  fetchTaxTypeOptions,
  updateBusinessAccount
} from '../../features/PaymentDetails/api'
import { TOption } from '../Select/types'
import { routes } from '../../router'
import useRouter from '../../hooks/useRouter'
import { notification } from '../Notification'
import { TAddBusinessDetails } from '../../features/PaymentDetails/BusinessDetails/types'
import { getFieldForOrumBusiness } from '../../features/PaymentDetails/utils'
import { getUser } from '../../redux/store/user/getters'
import moment from 'moment'
import { useParams } from 'react-router-dom'

export const FormsAddBusinessDetails = ({
  className,
  form,
  setIsActive,
  callback
}: TAddBusinessDetailsProps) => {
  const { push } = useRouter()
  const user = useSelector(getUser)
  const dispatch = useDispatch()
  const [taxTypeOptions, setTaxTypeOptions] = useState<TOption[]>([])
  const [countryList, setCountryList] = useState<TOption[]>([])
  const [stateList, setStateList] = useState<TOption[]>([])
  const [addressTypeList, setAddressTypeList] = useState<TOption[]>([])
  const [errors, setErrors] = useState<TAddBusinessDetailsFormErrorType>({})

  const uuid = form.getFieldValue('uuid')

  const { hospitalId } = useParams()
  const {
    query: { hsId }
  } = useRouter()

  const getTaxTypeList = async () => {
    await fetchTaxTypeOptions().then((res) => {
      setTaxTypeOptions(
        res.data.results.map((option) => ({
          label: option.value,
          value: option.id
        }))
      )
      form.setFieldsValue({ tax_id_type: res.data.results[0]?.id })
    })
  }
  const getCountriesList = async () => {
    await fetchCountriesOptions().then((res) => {
      setCountryList(
        res.data.results.map((option) => ({
          label: option.value,
          value: option.id
        }))
      )
      form.setFieldsValue({ country: res.data.results[0]?.id })
    })
  }
  const getStateList = async () => {
    await fetchStateOptions().then((res) => {
      setStateList(
        res.data.results.map((option) => ({
          label: option.value,
          value: option.id
        }))
      )
    })
  }
  const getAddressTypeList = async () => {
    await fetchAddressTypeOptions().then((res) => {
      setAddressTypeList(
        res.data.results.map((option) => ({
          label: option.value,
          value: option.id
        }))
      )
      form.setFieldsValue({ address_type: res.data.results[0]?.id })
    })
  }

  const getSelectorsData = async () => {
    dispatch(setLoading(true))
    try {
      getTaxTypeList()
      getCountriesList()
      getStateList()
      getAddressTypeList()
    } finally {
      dispatch(setLoading(false))
    }
  }

  useEffect(() => {
    getSelectorsData()
  }, [])

  const handleFinish = (formData: TAddBusinessDetailsForm) => {
    setIsActive(false)
    dispatch(setLoading(true))
    const dataForRequest = { ...formData }
    if (!!formData.incorporation_date) {
      dataForRequest.incorporation_date = moment(
        formData.incorporation_date
      ).format('YYYY-MM-DD')
    }
    const creationDetails = {
      ...dataForRequest,
      ...getFieldForOrumBusiness(user, hospitalId)
    }

    if (!uuid) {
      addBusinessAccount(creationDetails as Partial<TAddBusinessDetails>)
        .then(() => {
          notification.success({
            message: VALIDATION_MESSAGES.BUSINESS_RESOURCE_CREATED
          })

          if (hospitalId && hsId) {
            push(
              `${routes.hospitals}/${hospitalId}${routes.paymentDetails}?hsId=${hsId}`
            )
          } else {
            push(`${routes.settings}/6`)
          }
        })
        .catch((e) => {
          setIsActive(true)
          if (e.data.details) {
            notification.error({ message: e?.data.details })
          }
          setErrors(e.data)
        })
        .finally(() => {
          dispatch(setLoading(false))
        })
    } else {
      updateBusinessAccount(
        uuid,
        dataForRequest as Partial<TAddBusinessDetails>
      )
        .then(() => {
          notification.success({
            message: VALIDATION_MESSAGES.BUSINESS_RESOURCE_UPDATED
          })
          callback?.()
        })
        .catch((e) => {
          setIsActive(true)
          if (e.data.details) {
            notification.error({ message: e?.data.details })
          }
          setErrors(e.data)
        })
        .finally(() => {
          dispatch(setLoading(false))
        })
    }
  }

  return (
    <Form<TAddBusinessDetailsForm>
      form={form}
      onFinish={handleFinish}
      initialValues={addBusinessDetailsInitialValues}
      validateMessages={validateMessages}
      onValuesChange={() => {
        setIsActive(true)
      }}
      id="add-business-details-form"
      className={cn('add-business-details-form', className)}
    >
      <div className="row two-columns">
        <Input
          propsItem={{
            label: LABELS.LEGAL_NAME,
            name: 'legal_name',
            help: errors?.legal_name ? errors?.legal_name[0] : undefined,
            validateStatus: errors?.legal_name ? 'error' : undefined,
            rules: [
              {
                required: true
              }
            ]
          }}
          propsInput={{ maxLength: 255 }}
        />
      </div>
      <div className="row four-in-row gap-24">
        <Input
          propsItem={{
            name: 'tax_id',
            label: LABELS.TAX_ID,
            help: errors.tax_id && errors.tax_id[0],
            validateStatus: errors.tax_id && 'error',
            rules: [
              {
                required: true,
                pattern: /^(\d*\.)?\d{9,}$/
              }
            ]
          }}
          propsInput={{ maxLength: 9 }}
        />
        <Select
          options={taxTypeOptions}
          propsItem={{
            label: LABELS.TAX_ID_TYPE,
            name: 'tax_id_type',
            help: errors?.tax_id_type ? errors?.tax_id_type[0] : undefined
          }}
          propsSelect={{
            required: true,
            placeholder: PLACEHOLDERS.PLEASE_SELECT
          }}
        />
        <Datepicker
          propsItem={{
            name: 'incorporation_date',
            label: LABELS.INCORPORATION_DATE,
            rules: [
              {
                required: true
              }
            ]
          }}
          propsDate={{
            format: [timelineDateFormat, timelineDateFormatShort],
            placeholder: PLACEHOLDERS.MM_DD_YYYY,
            disabledDate: (current) => current.isSameOrAfter(moment())
          }}
        />
      </div>
      {/*//TODO: for current version need to remove description field*/}
      {/*<div className="mt-24 row description-row">*/}
      {/*  <Input.TextArea*/}
      {/*    propsItem={{*/}
      {/*      label: LABELS.DESCRIPTION,*/}
      {/*      name: 'description',*/}
      {/*      rules: [*/}
      {/*        {*/}
      {/*          max: 250*/}
      {/*        }*/}
      {/*      ]*/}
      {/*    }}*/}
      {/*    propsTextArea={{*/}
      {/*      showCount: true,*/}
      {/*      maxLength: 250*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</div>*/}
      <div className="gap-24 column mt-40">
        <Typography.Headline6>Address</Typography.Headline6>
        <div>
          <Input
            propsItem={{
              label: LABELS.ADDRESS_ETC,
              name: 'address',
              help: errors?.address && errors?.address[0],
              validateStatus: errors?.address && 'error',
              rules: [
                {
                  required: true
                }
              ]
            }}
            propsInput={{ maxLength: 255 }}
          />
        </div>
        <div className="row three-in-row space-between">
          <Select
            options={stateList}
            className="long-select"
            propsItem={{
              label: LABELS.STATE,
              name: 'state',
              help: errors?.state && errors?.state[0],
              validateStatus: errors?.state && 'error'
            }}
            propsSelect={{
              required: true,
              placeholder: PLACEHOLDERS.PLEASE_SELECT
            }}
          />
          <Input
            propsItem={{
              name: 'city',
              help: errors?.city && errors?.city[0],
              validateStatus: errors?.city && 'error',
              label: LABELS.CITY,
              rules: [
                {
                  required: true
                }
              ]
            }}
            propsInput={{ maxLength: 255 }}
          />
          <Input
            className="column-half"
            propsItem={{
              name: 'zip_code',
              label: LABELS.ZIP_CODE,
              help: errors?.zip_code && errors?.zip_code[0],
              validateStatus: errors?.zip_code && 'error',
              rules: [
                {
                  required: true,
                  pattern: /^(\d*\.)?\d{5,}$/
                }
              ]
            }}
            propsInput={{ maxLength: 5 }}
          />
        </div>
        <div className="row gap-24">
          <Select
            options={countryList}
            className="long-select"
            propsItem={{
              label: LABELS.COUNTRY,
              name: 'country',
              help: errors?.country && errors?.country[0],
              validateStatus: errors?.country && 'error'
            }}
            propsSelect={{
              required: true,
              placeholder: PLACEHOLDERS.PLEASE_SELECT
            }}
          />
          <Select
            options={addressTypeList}
            className="address-type"
            propsItem={{
              label: LABELS.ADDRESS_TYPE,
              name: 'address_type',
              help: errors?.address_type && errors?.address_type[0],
              validateStatus: errors?.address_type && 'error'
            }}
            propsSelect={{
              required: true,
              placeholder: PLACEHOLDERS.PLEASE_SELECT
            }}
          />
        </div>
      </div>
    </Form>
  )
}
