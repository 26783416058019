import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import { UserListItem } from './UserListItemSignatory/UserListItemSignatory'
import Typography from '../../../../components/Typography/Typography'
import './styles.scss'
import { useContractDetailsPopup } from '../../Providers/ContractDetailsPopupProvider'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { BTN_TXT } from '../../../../constants'
import { CONTRACT_STATUS, SIGNATORY_STATUS_LABELS } from '../../constants'
import cn from 'classnames'

const ContractDetailsSignUsers = () => {
  const contractDetailsContext = useContractDetailsContext()

  const {
    addSignatoryPopup: { actions }
  } = useContractDetailsPopup()

  const { contractDocuments, canAddSignatory, details } =
    contractDetailsContext.state

  const lastFile = contractDocuments?.[0]
  const acceptedAvailable = Boolean(lastFile?.accepted_by.length)

  return (
    <div className="mt-24">
      {acceptedAvailable && (
        <div className="flex row mb-16">
          <div className="text-wrapper flex ml-24">
            <Typography.Label className="text-wrapper--label">
              Accepted By
            </Typography.Label>
          </div>
          <div className="users-wrapper">
            {lastFile?.accepted_by &&
              lastFile.accepted_by.map((userInfo) => (
                <UserListItem user={userInfo} bigAvatar />
              ))}
          </div>
        </div>
      )}
      <div className="flex row mb-16">
        <div className="text-wrapper flex ml-24">
          <Typography.Label className="text-wrapper--label">
            Signatory
          </Typography.Label>
        </div>
        <div className="users-wrapper">
          {lastFile?.signatories &&
            lastFile.signatories.map((signatory) => {
              const statusLabel =
                details.status === CONTRACT_STATUS.OUT_FOR_SIGNATURE
                  ? SIGNATORY_STATUS_LABELS[signatory.status]
                  : undefined

              if (signatory.signatory)
                return (
                  <UserListItem
                    bigAvatar
                    user={signatory.signatory}
                    statusLabel={statusLabel}
                  />
                )
              else if (signatory.email)
                return (
                  <UserListItem
                    email={signatory.email}
                    statusLabel={statusLabel}
                  />
                )
            })}
          {canAddSignatory && (
            <Button
              type={BUTTON_TYPES.DEFAULT}
              className={cn('mb-10', {
                'mt-12': !!lastFile?.signatories.length
              })}
              small
              onClick={actions.open}
              upperCase
            >
              {BTN_TXT.ADD_SIGNATORY}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default ContractDetailsSignUsers
