import { Bar } from '@ant-design/plots'
import { TMyActivityChart } from '../../../../pages/Compliance/types'
import { OVERALL_CHART_COLORS } from '../OverallConversionCompliance/constants'

type TProps = {
  data: TMyActivityChart[]
}

export const Chart = ({ data }: TProps) => {
  const config = {
    data: data,
    appendPadding: 10,
    height: 250,
    xField: 'amount',
    yField: 'title',
    minBarWidth: 16,
    maxBarWidth: 16,
    yAxis: {
      tickLine: {
        length: 0
      },
      grid: {
        line: {
          style: {
            strokeOpacity: 0
          }
        }
      },
      label: {
        style: {
          fontFamily: 'Open Sans',
          fontSize: 12,
          fontWeight: 400,
          fill: '#363C43'
        }
      }
    },
    xAxis: {
      grid: {
        line: {
          style: {
            stroke: OVERALL_CHART_COLORS.compliant,
            lineWidth: 1,
            strokeOpacity: 0.3
          }
        }
      },
      tickCount: 4,
      position: 'left' as const,
      label: {
        style: {
          fontFamily: 'Open Sans',
          fontSize: 12,
          fontWeight: 400
        }
      }
    },
    indicator: false,
    barStyle: {
      radius: [2, 2, 0, 0]
    },
    color: OVERALL_CHART_COLORS['compliant'],
    tooltip: {
      customContent: (_title, data) => {
        const value = data.find((dataItem) => dataItem.title === _title)?.value
        return (
          <div className="chart__tooltip big flex space-between">
            <span>{_title}</span>
            <span>{value}</span>
          </div>
        )
      }
    }
  }
  return <Bar {...config} />
}
