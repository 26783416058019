import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { routes } from 'router'
import useRouter from 'hooks/useRouter'
import { confirmEmail, confirmResetPassword } from './api'
import { setLoading } from 'redux/store/common/slice'
import { setPasswordData } from 'redux/store/setPassword/slice'
import { notification } from 'components/Notification'

const EmailConfirmation = (): JSX.Element => {
  const dispatch = useDispatch()
  const { query, push, pathname } = useRouter()

  useEffect(() => {
    if (query.token) {
      if (pathname.indexOf(routes.resetPassword) >= 0) {
        confirmResetPassword({ token: query.token })
          .then(() => {
            dispatch(setLoading(true))

            dispatch(
              setPasswordData({
                token: query.token,
                email: query.email,
                isReset: true
              })
            )
            push(routes.setPassword)
          })
          .catch((response) => {
            const { status, data } = response

            if (status === 400 && data?.token && data.token[0]) {
              notification.error({ message: data.token[0] })
            }

            push(routes.portal)
          })
          .finally(() => {
            dispatch(setLoading(false))
          })
      } else {
        confirmEmail({ token: query.token })
          .then((resp) => {
            dispatch(setPasswordData(resp.data))
            push(routes.setPassword)
          })
          .catch((response) => {
            const { status, data } = response

            // if token is expired - redirecting to the link expired screen
            if (status === 400 && data?.token && data.token[0]) {
              dispatch(
                setPasswordData({
                  token: query.token,
                  email: query.email
                })
              )
              push(routes.linkExpired)
            } else {
              push(routes.portal)
            }
          })
          .finally(() => {
            dispatch(setLoading(false))
          })
      }
    }
  }, [query, pathname, dispatch, push])

  return <></>
}

export default EmailConfirmation
