import Fetcher from 'services/Fetcher'
import { HTTP_METHODS, API_DICTIONARY } from 'constants/api'
import { SUCCESS_MESSAGES } from 'constants/messages'
import { IPasswordData } from './types'
import { TFormForgotPasswordForm } from 'components/Forms/forms.forgotPassword.d'
import { TFormFaNumberForm } from 'components/Forms/forms.2FANumber.d'
import { TFormContactUsForm } from 'components/Forms/forms.contactUs.d'
import { TAuthToken, TCompleteProfileToken } from 'features/Auth/types'
import { TFormSignInForm } from 'components/Forms/forms.signIn.d'
import { TFormFaCodeForm } from '../../components/Forms/forms.2FACode.d'

const fetcher = new Fetcher({})

export const logUser = async (data: TFormSignInForm) =>
  fetcher.request<TFormSignInForm, TAuthToken>({
    url: `${API_DICTIONARY.AUTH}${API_DICTIONARY.SIGN_IN}${API_DICTIONARY.CONFIRM_CREDS}`,
    method: HTTP_METHODS.POST,
    isLogin: true,
    isNotificationError: true,
    data
  })

export const confirmSmsCode = async (data: TFormFaCodeForm) =>
  fetcher.request<TFormFaCodeForm, TAuthToken>({
    url: `${API_DICTIONARY.AUTH}${API_DICTIONARY.SIGN_IN}${API_DICTIONARY.CONFIRM_SMS_CODE}`,
    method: HTTP_METHODS.POST,
    isLogin: true,
    isNotificationError: true,
    data
  })
export const confirmSmsCodeSignUp = async (data: TFormFaCodeForm) =>
  fetcher.request<TFormFaCodeForm, TAuthToken>({
    url: `${API_DICTIONARY.AUTH}${API_DICTIONARY.SIGN_UP}${API_DICTIONARY.CONFIRM_SMS_CODE}`,
    method: HTTP_METHODS.POST,
    isLogin: true,
    data
  })
export const setPhoneNumberSignUp = async (data: TFormFaNumberForm) =>
  fetcher.request<TFormFaNumberForm, TAuthToken>({
    url: `${API_DICTIONARY.AUTH}${API_DICTIONARY.SET_PHONE_NUMBER}`,
    method: HTTP_METHODS.POST,
    isLogin: true,
    data
  })

export const setPassword = async (data: IPasswordData) =>
  fetcher.request<IPasswordData>({
    url: `${API_DICTIONARY.AUTH}${API_DICTIONARY.SIGN_UP}set_password/`,
    method: HTTP_METHODS.POST,
    data
  })

export const contact = async (data: TFormContactUsForm) =>
  fetcher.request({
    url: `${API_DICTIONARY.AUTH}support_request/`,
    method: HTTP_METHODS.POST,
    successMessage: SUCCESS_MESSAGES.NEED_HELP,
    data
  })

export const requestResetPassword = async (data: TFormForgotPasswordForm) =>
  fetcher.request<TFormForgotPasswordForm>({
    url: `${API_DICTIONARY.AUTH}${API_DICTIONARY.RESET_PASSWORD}`,
    method: HTTP_METHODS.POST,
    successMessage: SUCCESS_MESSAGES.REQUEST_PASSWORD,
    data
  })

export const resetPassword = async (data: IPasswordData) =>
  fetcher.request<IPasswordData>({
    url: `${API_DICTIONARY.AUTH}${API_DICTIONARY.RESET_PASSWORD}confirm/`,
    method: HTTP_METHODS.POST,
    data
  })

export const resendCode = async (data: TFormForgotPasswordForm) =>
  fetcher.request<TFormForgotPasswordForm>({
    url: `${API_DICTIONARY.AUTH}${API_DICTIONARY.RESEND_SMS_CODE}`,
    method: HTTP_METHODS.POST,
    data
  })
export const resendVerifyEmail = async (data: TFormForgotPasswordForm) =>
  fetcher.request<TFormForgotPasswordForm>({
    url: `${API_DICTIONARY.AUTH}${API_DICTIONARY.SELF_SIGN_UP}${API_DICTIONARY.RESEND}`,
    method: HTTP_METHODS.POST,
    data
  })

export const resendSignUpEmail = async (data: TCompleteProfileToken) =>
  fetcher.request<TCompleteProfileToken>({
    url: `${API_DICTIONARY.AUTH}${API_DICTIONARY.SIGN_UP}${API_DICTIONARY.RESEND_INVITATION}`,
    method: HTTP_METHODS.POST,
    data
  })

export const contactLanding = async (data: TFormContactUsForm) =>
  fetcher.request({
    url: `${API_DICTIONARY.SUPPORT}${API_DICTIONARY.CONTACT_US}`,
    method: HTTP_METHODS.POST,
    data
  })
