import { Table } from 'antd'
import cn from 'classnames'
import { Button, BUTTON_TYPES } from 'components/Button'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { Select } from 'components/Select'
import { PAGE_SIZES } from './constants'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { TTableProps } from './types'
import './table.default.scss'

export const Default = <T extends object>({
  className,
  hasSearchOrFilters,
  createButton,
  onChangePage,
  pageSize,
  pagination,
  renderButton,
  subtitle,
  emptyText,
  ...props
}: TTableProps<T>): JSX.Element => (
  <Table<T>
    className={cn('table-default', className)}
    id={'antd-table-component-' + className}
    tableLayout="auto"
    getPopupContainer={(triggerNode) =>
      document?.getElementById('antd-table-component-' + className) ??
      triggerNode
    }
    locale={{
      emptyText: () => (
        <div className="table-default__empty-state">
          <Typography.Body1
            className="table-default__empty-state__title"
            weight={TYPOGRAPHY_WEIGHT.BOLD}
          >
            {hasSearchOrFilters
              ? 'Nothing found'
              : emptyText || 'The list is empty'}
          </Typography.Body1>
          <Typography.Body1
            className="table-default__empty-state__subtitle"
            weight={TYPOGRAPHY_WEIGHT.NORMAL}
          >
            {!hasSearchOrFilters && subtitle}
          </Typography.Body1>
          {!hasSearchOrFilters && createButton?.availability && (
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={createButton.action}
              icon={<Plus16 />}
              upperCase
            >
              {createButton.buttonText}
            </Button>
          )}
          {!!renderButton && renderButton}
        </div>
      )
    }}
    scroll={{
      x: 1140
    }}
    pagination={
      pagination && pageSize && onChangePage
        ? {
            ...pagination,
            selectComponentClass: () => (
              <Select
                onPagination={true}
                options={PAGE_SIZES}
                propsSelect={{
                  showSearch: false,
                  value: pageSize,
                  onChange: (val) => onChangePage(val)
                }}
              />
            ),
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            showQuickJumper: false,
            showSizeChanger: true,
            size: 'small'
          }
        : false
    }
    {...props}
  />
)
