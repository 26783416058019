import { Button, BUTTON_TYPES } from '../../../components/Button'
import { BTN_TXT } from '../../../constants'
import { Typography, TYPOGRAPHY_WEIGHT } from '../../../components/Typography'
import Popup from '../../../components/Popup/Popup'
import { TPropsConfirmationModal } from './types'

const ConfirmationModal = ({
  isAccount,
  visible,
  handleSubmit,
  handleReject
}: TPropsConfirmationModal) => {
  return (
    <Popup
      visible={visible}
      onCancel={handleReject}
      title={`Update ${isAccount ? 'account' : 'business'}`}
      width={442}
      footer={
        <>
          <Button onClick={handleReject} type={BUTTON_TYPES.DEFAULT} upperCase>
            {BTN_TXT.CANCEL}
          </Button>
          <Button type={BUTTON_TYPES.PRIMARY} upperCase onClick={handleSubmit}>
            {BTN_TXT.UPDATE}
          </Button>
        </>
      }
    >
      <Typography.Headline6 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
        {isAccount ? 'An external account ' : 'A business '} update will require
        re-verification and may incur additional charges.
      </Typography.Headline6>
    </Popup>
  )
}

export default ConfirmationModal
